import { tuple } from '@digi-tim-19/utils';

// modo em que as quantidades são fornecidas pelo frontend
export const ProductionQuantityInputModes = tuple(
  // No modo byPoruduct a quantidade é configurada por produto.
  // Neste modo é possivel configurar as quantidades por fatorial ou quantityByPlace
  //   - por fatorial o calculo da quantidade final por local é feito no backend
  //     considerando quantos produtos cada local precisa baseado na sua estrutura
  'byProduct',

  // Quando o modo é byDestination o valor é sempre fixo por cada destino final
  'byDestination'
);

export type ProductionQuantityInputMode =
  typeof ProductionQuantityInputModes[number];

export interface ProductionItemDestinationsConfig {
  frontendGroupId: string; // usado no front para identificar qual grupo está sendo editado

  //Título do grupo
  title?: string;

  // - Filtro aplicado para encontrar os destinos que receberão os
  //   produtos ao final da produção
  destinationsFilters: ProductionDestinationFilter;

  // codigos SAP dos locais encontrados a partir dos destinationsFilters
  foundDestinationsSAPCodes: string[];
}

// agrupa produtos a serem entregues a um grupo de destinos (Lojas, Escriotorios, etc)
export interface ProductionItem extends ProductionItemDestinationsConfig {
  quantityInputMode: ProductionQuantityInputMode;

  // quando o modo de input das quantidades usada no front é byProduct
  // os dados de quantidade são salvos nesta entrada, mas a quantidade final por
  // local é sempre calculada no backend e salva em quantityByDestinationOutput
  quantityByProductInput: ProductionQuantityByProductInput[];
  quantityByProductOutput: ProductionQuantityByProductOutput[];

  // usado quando o quantityInputMode é byDestination
  quantityByDestinationInput: ProductionQuantityByDestinationInput[];

  // processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput
  // dependendo do quantityInputMode
  // quantityByDestinationOutput: ProductionQuantityByDestinationOutput[];

  // - Tipo de local aonde o fornecedor deve entregar o produto. Pode ser o
  //   proprio destino final (loja) ou um centro de distribuicão, escritorio regional, etc.
  supplierDeliveryDestinationType: SupplierDeliveryPlaceType;
}

export interface ProductionQuantityByProductOutput
  extends ProductionQuantityByProductInput {
  totalPlaces: number;
  extraQuantityByDistributionCenter: number;
  totalPlacesInFactorial: number; // quantidade de locais que precisam deste produto
  quantityFromFactorial: number; // quantidade total deste produto para um determinado grupo de locais usando fatorial
}

// Tipo usado para input dos dados quando o quantityInputMode é byProduct
// Representa a quantidade a ser entregue por produto
// Quando quantityInputMode é byDestination o item quantityByProduct fica vazio.
export type ProductionQuantityByProductInput = {
  layoutCodeT: string;

  // - quantidade final por local
  //      cada local receberá ${quantityByPlace} itens deste produto
  // - Quando isQuantityFromFactorial é true, o quantityByPlace é ignorado e
  //      a quantidade é definida pela estrutura de cada loja
  quantityByDestination: number;

  // é possivel usar fatorial para um item. Ou seja, se produziremos um adesivo
  // de mesa, então devemos encontrar quantos locais possuem mesa para então
  // calcular quantos itens precisam ser produzidos
  isQuantityFromFactorial: boolean;

  // quando um produto vai ser entregue no centro de distribuição
  // é possivel escolher uma quantidade extra que ficara no centro de distribuição
  // (para o caso de algum produto de uma loja estragar, etc)
  extraQuantityByDistributionCenter: number | undefined;
};

// usado determinar a quantidade de 1 determinado produto por local
// pode ter vindo direto do frontend, quando o quantityInputMode é byDestination
// ou calculado no backend quando o quantityInputMode é byProduct
export type ProductionQuantityByDestinationInput = {
  layoutCodeT: string;
  destinationSAPCode: string;
  quantity: number;
  isQuantityCalculatedFromFactorial: boolean;
  extraQuantityByDistributionCenter?: number;
};

export interface ProductionQuantityByDestinationOutput
  extends ProductionQuantityByDestinationInput {
  // globalExtraQuantityByDistributionCenter: soma de todos os extras dos items
  // com o mesmo layoutCodeT que este e que serão entregues no mesmo CDL
  globalExtraQuantityByDistributionCenter: number;

  // local aonde o fornecedor fará a entrega, encontrado a partir do
  // supplierDeliveryDestinationType e da UF do destino final (loja)
  supplierDeliveryDestinationSAPCode: string;
}

export type ProductionInterface = {
  _id: string;
  code: string;
  status: ProductionStatus;
  title: string;

  // quando uma producão é criada a partir de uma verba
  // salvamos o _id da verba
  originBudgetId?: string;

  productionItems: ProductionItem[];
};

export const ProductionStatus = tuple('draft', 'sent', 'grouped', 'canceled');
export type ProductionStatus = typeof ProductionStatus[number];

// filtro das lojas que receberão os produtos produzidos
export type ProductionDestinationFilter = {
  [k in DestinationFilterFields]?: string[];
};

// possiveis filtros de lojas que receberão os produtos produzidos
export enum DestinationFilterFields {
  type = 'type', // as vezes chamado de channel
  region = 'region',
  DDD = 'DDD',
  UF = 'UF',
  cityId = 'cityId',
  communicationFocus = 'communicationFocus',
  marketedProduct = 'marketedProduct',
  networkTechnology = 'networkTechnology',
  storeModel = 'storeModel',
  sapCode = 'sapCode',
  CNPJ = 'CNPJ'
}

export type DestinationFilterFieldKey = keyof typeof DestinationFilterFields;

// Esta interface é usada para replicar as informações dos produtos usados
// no momento em que a produção foi salva.
export type ProductionFinalProducts = {
  productId: string;
  productTitle: string; // titulo do produto usado para criar o layout
  productAmount: number;
  productTotalPrice: number;
  productPriceInterval: ProductionProductPriceInterval[];
  productFinalPriceByItem: number;

  supplierName: string;
  supplierId: string; // ID do fornecedor
  supplierCNPJ: string; // CNPJ do fornecedor

  // codigoT caso de layout:
  //   representa o codeT, gerado no cadastro do layout.
  //   Não confundir com codigoTM.
  //   o codigoTM é gerado apenas quando o produto é inserido
  //   no sistema SAP do cliente, não é um campo obrigatório
  layoutCodeT: string;
  layoutId: string;
  layoutTitle: string;

  campaignId: string;
  campaignTitle: string;
};

// os valores variam dependendo da quantidade
export interface ProductionProductPriceInterval {
  start: number;
  end: number;
  price: number;
}

export const DestinationPlaceChannel = tuple(
  'escritorio_regional',
  'centro_de_distribuicao_logistico',
  'loja_propria',
  'revenda',
  'master',
  'varejo',
  'distribuicao'
);

export type DestinationPlaceChannel = typeof DestinationPlaceChannel[number];

// Representa o local que um fornecedor deve entregar os produtos de uma producão
// antes de um produto ser entregue a loja (destino final),
// ele pode ser entregue em um centro de distribuição ou escritorio regional, etc
// dependendo da configuração na produção
export const SupplierDeliveryPlaceTypes = tuple(
  'centro_distribuicao_logistica',
  'escritorio_regional',
  'distribuicao',
  'agencia_varejo',
  'pdv'
);

export type SupplierDeliveryPlaceType =
  typeof SupplierDeliveryPlaceTypes[number];

// item processado pelo backend
// calculado valores com desconto pelas quantidade, etc
export interface LayoutPricingItem {
  layoutId: string;
  layoutCodeT: string;
  layoutTitle: string;
  productTitle: string;
  supplierName: string;
  productTotalPrice: number;
  productFinalPriceByItem: number;
  productAmount: number;
  destinationsCount: number;
  productPriceInterval: ProductionProductPriceInterval[];
}

export interface ProductionFormDataByDestination extends LayoutPricingItem {
  frontendGroupId: string;
  destinationCNPJ: string;
  destinationSAPCode: string;
  destinationRazaoSocial: string;
  destinationNomeFantasia: string;
  destinationDDD: string;
}

export interface ProductionFormDataByProduct extends LayoutPricingItem {
  frontendGroupId: string;
  layoutCodeT: string;
  extraQuantityByDistributionCenter: number;
  groupTotal: number;
  placesInFatorial: {
    sapCode: string;
    name: string;
    structural: string;
    qnt: number;
  }[];
  totalPlacesInFactorial: number;
  totalPlaces: number;
  quantityFromFactorial: number;
  isQuantityFromFactorial: boolean;
  quantityByDestination: number;
}

export interface ProductionServerCalcValuesState {
  output: {
    error?: string;
    // indexed by frontendGroupId
    byProduct: Record<string, ProductionFormDataByProduct[]>;
    byDestination: Record<string, ProductionFormDataByDestination[]>;
  };
  loading: boolean;
  error: string;
}
