/**
 * Configurações de rotas
 */
import { registerRoutes } from '@digi-tim-19/components';
import { API_URL } from './apiURL';
import { isDev } from '@digi-tim-19/utils';

declare global {
  interface Window {
    _APP_ROUTES_PREFIX_?: string;
  }
}

// o valor é injetado via scripts/server.js
const routesPrefix = isDev() ? '' : window._APP_ROUTES_PREFIX_ || '';

export const UPLOAD_URL = (() => {
  const path = API_URL.replace(/\/graphql$/, '/').replace(/\/api$/, '/');
  return `${routesPrefix}${path}`;
})();

export const routes = registerRoutes({
  example: {
    path: '/example/:example_id',
    mount: (example_id: number) => `/custom-example/${example_id}`
  },

  home: {
    path: '/',
    mount: () => '/'
  },

  team: {
    path: '/equipe',
    mount: () => '/equipe'
  },

  login: {
    path: '/login',
    mount: () => '/login'
  },

  dashBoard: {
    path: '/dashboard',
    mount: () => '/dashboard'
  },

  tablePrice: {
    path: '/tabela-de-precos',
    mount: () => '/tabela-de-precos'
  },

  tablePriceNew: {
    path: '/tabela-de-precos/novo',
    mount: () => '/tabela-de-precos/novo'
  },

  tabelaPricesEdit: {
    path: '/tabela-de-precos/editar/:tablePriceId',
    mount: (tablePriceId: string) => `/tabela-de-precos/editar/${tablePriceId}`
  },

  tablepriceView: {
    path: '/tabela-de-precos/visualizar/:tablePriceId',
    mount: (tablePriceId: string) =>
      `/tabela-de-precos/visualizar/${tablePriceId}`
  },

  tablepriceLegalText: {
    path: '/tabela-de-precos/textos-legais',
    mount: () => '/tabela-de-precos/textos-legais'
  },

  users: {
    path: '/usuarios',
    mount: () => '/usuarios'
  },

  userNew: {
    path: '/usuarios/novo',
    mount: () => '/usuarios/novo'
  },

  userEdit: {
    path: '/usuarios/editar/:user_id',
    mount: (user_id: string) => `/usuarios/editar/${user_id}`
  },

  places: {
    path: '/locais',
    mount: () => '/locais'
  },

  placesNew: {
    path: '/locais/novo',
    mount: () => '/locais/novo'
  },

  placeEdit: {
    path: '/locais/editar/:place_id',
    mount: (place_id: string) => `/locais/editar/${place_id}`
  },

  suppliers: {
    path: '/fornecedores',
    mount: () => '/fornecedores'
  },

  supplierNew: {
    path: '/fornecedores/novo',
    mount: () => '/fornecedores/novo'
  },

  supplierEdit: {
    path: '/fornecedores/editar/:supplier_id',
    mount: (supplier_id: string) => `/fornecedores/editar/${supplier_id}`
  },

  ativos: {
    path: '/ativos',
    mount: () => '/ativos'
  },

  digitalBrochures: {
    path: '/folhetos-digitais',
    mount: () => '/folhetos-digitais'
  },

  digitalBrochureNew: {
    path: '/folhetos-digitais/novo',
    mount: () => '/folhetos-digitais/novo'
  },

  digitalBrochureEdit: {
    path: '/folhetos-digitais/editar/:digital_brochure_id',
    mount: (digital_brochure_id: string) =>
      `/folhetos-digitais/editar/${digital_brochure_id}`
  },

  digitalBrochureViewer: {
    path: '/folheto-digital-publico/:brochure_id',
    mount: (brochure_id: string) => `/folheto-digital-publico/${brochure_id}`
  },

  devices: {
    path: '/dispositivos',
    mount: () => '/dispositivos'
  },

  devicesNotFoundAlerts: {
    path: '/aparelhos-nao-encontrados',
    mount: () => '/aparelhos-nao-encontrados'
  },

  deviceNew: {
    path: '/dispositivos/novo',
    mount: () => '/dispositivos/novo'
  },

  deviceEdit: {
    path: '/dispositivos/editar/:device_id',
    mount: (device_id: string) => `/dispositivos/editar/${device_id}`
  },

  campaigns: {
    path: '/campanhas',
    mount: () => '/campanhas'
  },

  campaignNew: {
    path: '/campanhas/novo',
    mount: () => '/campanhas/novo'
  },

  campaignEdit: {
    path: '/campanhas/editar/:campanha_id',
    mount: (campaign_id: string) => `/campanhas/editar/${campaign_id}`
  },

  layouts: {
    path: '/layouts',
    mount: () => '/layouts'
  },

  layoutNew: {
    path: '/layouts/novo',
    mount: () => '/layouts/novo'
  },

  layoutEdit: {
    path: '/layouts/editar/:layout_id',
    mount: (layout_id: string) => `/layouts/editar/${layout_id}`
  },

  budgets: {
    path: '/verbas',
    mount: () => '/verbas'
  },

  budgetNew: {
    path: '/verbas/novo',
    mount: () => '/verbas/novo'
  },

  budgetView: {
    path: '/verbas/visualizar/:budget_id',
    mount: (budget_id: string) => `/verbas/visualizar/${budget_id}`
  },

  budgetCheckout: {
    path: '/verbas-checkout/:budget_id',
    mount: (budget_id: string) => `/verbas-checkout/${budget_id}`
  },

  regionalBudgetView: {
    path: '/verbas-regionais/editar/:_id',
    mount: (_id: string) => `/verbas-regionais/editar/${_id}`
  },

  regionalBudgets: {
    path: '/verbas-regionais',
    mount: () => '/verbas-regionais'
  },

  productions: {
    path: '/producoes',
    mount: () => '/producoes'
  },

  productionNew: {
    path: '/producoes/novo',
    mount: () => `/producoes/novo`
  },

  productionView: {
    path: '/producoes/visualizar/:production_id',
    mount: (production_id: string) => `/producoes/visualizar/${production_id}`
  },

  changePassword: {
    path: '/open/alterar-senha',
    mount: (t: string) => `/open/alterar-senha`
  },

  geradorDeLaminas: {
    path: '/gerador-de-laminas',
    mount: () => '/gerador-de-laminas'
  },

  logisticaEntrega: {
    path: '/logistica/entrega',
    mount: () => '/logistica/entrega'
  },

  logisticaGradeIndependenteNew: {
    path: '/logistica/grade-independente/nova',
    mount: () => '/logistica/grade-independente/nova'
  },

  logisticaGradeIndependenteEdit: {
    path: '/logistica/grade-independente/editar/:grade_id',
    mount: (grade_id: string) =>
      `/logistica/grade-independente/editar/${grade_id}`
  },

  logisticaEstoque: {
    path: '/logistica/estoque',
    mount: () => '/logistica/estoque'
  },

  logisticaGrades: {
    path: '/logistica/grades',
    mount: () => '/logistica/grades'
  },

  logisticaRacionalProducao: {
    path: '/logistica/grades/racional-de-producao/:id',
    mount: (id: string) => `/logistica/grades/racional-de-producao/${id}`
  },

  logisticaGradesCart: {
    path: '/logistica/grades/cart',
    mount: () => '/logistica/grades/cart'
  },

  positivatorEdit: {
    path: '/positivador/editar',
    mount: () => '/positivador/editar'
  },

  positivatorResume: {
    path: '/positivador/resumo',
    mount: () => '/positivador/resumo'
  },

  positivatorNotification: {
    path: '/positivador/notificacoes',
    mount: () => '/positivador/notificacoes'
  },

  positivatorNotificationNew: {
    path: '/positivador/notificacoes/novo',
    mount: () => '/positivador/notificacoes/novo'
  },

  positivatorNotificationEdit: {
    path: '/positivador/notificacoes/editar/:notification_id',
    mount: (notification_id: string) =>
      `/positivador/notificacoes/editar/${notification_id}`
  },

  positivatorNotificationView: {
    path: '/positivador/notificacoes/visualizar/:notification_id',
    mount: (notification_id: string) =>
      `/positivador/notificacoes/visualizar/${notification_id}`
  },

  confirmEmailRead: {
    path: '/open/email-read/:token',
    mount: (token: string) => `/open/email-read/${token}`
  },

  structural: {
    path: '/estrutural',
    mount: () => '/estrutural'
  },

  structuralNewProject: {
    path: '/estrutural/novo-projeto',
    mount: () => '/estrutural/novo-projeto'
  },

  structuralProjectDetail: {
    path: '/estrutural/acompanhar/:projectId',
    mount: (projectId: string) => `/estrutural/acompanhar/${projectId}`
  },

  structuralProjectEdit: {
    path: '/estrutural/editar/:projectId',
    mount: (projectId: string) => `/estrutural/editar/${projectId}`
  },

  mapaAreaLoja: {
    path: '/mapaPDV/area-de-loja',
    mount: () => '/mapaPDV/area-de-loja'
  },

  mapaFormatos: {
    path: '/mapaPDV/conheca-formatos',
    mount: () => '/mapaPDV/conheca-formatos'
  },

  mapaPecas: {
    path: '/mapaPDV/layouts',
    mount: () => '/mapaPDV/layouts'
  },

  mapaRegramentoComunicacao: {
    path: '/mapaPDV/regramento-comunicacao',
    mount: () => '/mapaPDV/regramento-comunicacao'
  },

  mapaRegramentoCluster: {
    path: '/mapaPDV/regramento-aplicacao',
    mount: () => '/mapaPDV/regramento-aplicacao'
  },

  mapaGuia: {
    path: '/mapaPDV/guia',
    mount: () => '/mapaPDV/guia'
  },

  mapaPDV: {
    path: '/mapaPDV/mapa',
    mount: () => '/mapaPDV/mapa'
  },

  mapaPDVCreate: {
    path: '/mapaPDV/mapa/create/:_id',
    mount: (_id?: string) => _id ? `/mapaPDV/mapa/create/${_id}` : '/mapaPDV/mapa/create/new'
  },

  mapaPDVEdit: {
    path: '/mapaPDV/mapa/edit/:id',
    mount: (id: string) => `/mapaPDV/mapa/edit/${id}`
  }
});
