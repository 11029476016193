import {
  ProductionFormDataByDestination,
  ProductionFormDataByProduct
} from '../../Production/interfaces/ProductionInterfaces';

import { RegionalBudgetInterface } from '../../Production/interfaces/BudgetInterfaces';

export interface RegionalBudgetServerCalcValuesState {
  output: {
    error?: string;
    // indexed by frontendGroupId
    byProduct: Record<string, ProductionFormDataByProduct[]>;
    byDestination: Record<string, ProductionFormDataByDestination[]>;
    regionalUsedAmount: number; // apenas quando o parser é de regional
  };
  loading: boolean;
  error: string;
}

export interface RegionalBudgetFrontendData {
  usedValue: number;

  isRegionalProduction: boolean;

  activeGroupId: string;
  // atualizado via middleware ao alterar as configurações da produção,
  // atualizando valores com desconto por quantidades, etc
  serverCalculatedValues: RegionalBudgetServerCalcValuesState;
}

function pricingInitialState(): RegionalBudgetServerCalcValuesState {
  return {
    output: {
      byDestination: {},
      byProduct: {},
      regionalUsedAmount: 0
    },
    loading: false,
    error: ''
  };
}

function serverMock(): RegionalBudgetInterface {
  return {
    _id: '',
    maxValue: 0,
    usedValue: 0,
    remainingValue: 0,
    filledByRegional: false,
    parentBudgetId: '',
    region: 'TSP',
    status: 'initial',
    productionItems: [],
    availableAtChannels: [],
    allowedLayouts: [],
    parentBudgetTitle: '',
    deadlineFormatted: '',
    createdAt: ''
  };
}

function frontendInitialData(): RegionalBudgetFrontendData {
  return {
    usedValue: 0,
    isRegionalProduction: false,
    activeGroupId: '',
    serverCalculatedValues: pricingInitialState()
  };
}

export const initialRegionalBudgetData = {
  frontendInitialData,
  serverMock,
  initial() {
    return {
      ...serverMock(),
      ...frontendInitialData()
    };
  }
};
