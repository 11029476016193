import {
  parseFragmentConfig,
  OpKind,
  useClientFactory
} from 'graphql-clientgen';
import { Methods } from './types';

export const query = {
  RegionFindMany: (config?: any) => `
        query RegionFindMany($filter: FilterFindManyRegionInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionInput){
            RegionFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionFindOne: (config?: any) => `
        query RegionFindOne($filter: FilterFindOneRegionInput, $skip: Int, $sort: SortFindOneRegionInput){
            RegionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        itemIds
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionPagination: (config?: any) => `
        query RegionPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionInput, $sort: SortFindManyRegionInput){
            RegionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemFindMany: (config?: any) => `
        query RegionItemFindMany($filter: FilterFindManyRegionItemInput, $skip: Int, $limit: Int, $sort: SortFindManyRegionItemInput){
            RegionItemFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionItemFindOne: (config?: any) => `
        query RegionItemFindOne($filter: FilterFindOneRegionItemInput, $skip: Int, $sort: SortFindOneRegionItemInput){
            RegionItemFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        abbreviation
                        level
                        UF
                        DDD
                        parentId
                        IBGE
                        region
                        capital
                        isVirtualRegion
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RegionItemPagination: (config?: any) => `
        query RegionItemPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionItemInput, $sort: SortFindManyRegionItemInput){
            RegionItemPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegionItemDistinctDDD: (config?: any) => `
        query RegionItemDistinctDDD{
            RegionItemDistinctDDD{
                ${parseFragmentConfig(`items`, config)}
            }
        }`,

  RegionItemDistinctRegionAndUF: (config?: any) => `
        query RegionItemDistinctRegionAndUF{
                ${parseFragmentConfig(``, config)}
        }`,

  JobTitleFindOne: (config?: any) => `
        query JobTitleFindOne($filter: FilterFindOneJobTitleInput, $skip: Int, $sort: SortFindOneJobTitleInput){
            JobTitleFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  JobTitleFindMany: (config?: any) => `
        query JobTitleFindMany($filter: FilterFindManyJobTitleInput, $skip: Int, $limit: Int, $sort: SortFindManyJobTitleInput){
            JobTitleFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupFindOne: (config?: any) => `
        query RoleGroupFindOne($filter: FilterFindOneRoleGroupInput, $skip: Int, $sort: SortFindOneRoleGroupInput){
            RoleGroupFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupFindMany: (config?: any) => `
        query RoleGroupFindMany($filter: FilterFindManyRoleGroupInput, $skip: Int, $limit: Int, $sort: SortFindManyRoleGroupInput){
            RoleGroupFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        name
                        channelName
                        roles
                        sort
                        accessLevel
                        parentId
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  RoleGroupPagination: (config?: any) => `
        query RoleGroupPagination($page: Int, $perPage: Int, $filter: FilterFindManyRoleGroupInput, $sort: SortFindManyRoleGroupInput){
            RoleGroupPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ChannelFindMany: (config?: any) => `
        query ChannelFindMany($filter: FilterFindManyChannelInput, $skip: Int, $limit: Int, $sort: SortFindManyChannelInput){
            ChannelFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ChannelFindOne: (config?: any) => `
        query ChannelFindOne($filter: FilterFindOneChannelInput, $skip: Int, $sort: SortFindOneChannelInput){
            ChannelFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        parentId
                        parentName
                        name
                        availableAtChannels
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ChannelPagination: (config?: any) => `
        query ChannelPagination($page: Int, $perPage: Int, $filter: FilterFindManyChannelInput, $sort: SortFindManyChannelInput){
            ChannelPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserContentPermissionFindOne: (config?: any) => `
        query UserContentPermissionFindOne($filter: FilterFindOneUserContentPermissionInput, $skip: Int, $sort: SortFindOneUserContentPermissionInput){
            UserContentPermissionFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        roleGroups
                        regions
                        siteContentTypes
                        _id
                        updatedAt
                        createdAt
                        DDDs`,
                  config
                )}
            }
        }`,

  ContentPermissionsHelper: (config?: any) => `
        query ContentPermissionsHelper{
            ContentPermissionsHelper{
                ${parseFragmentConfig(
                  `id
                        label
                        value
                        pId
                        parentName
                        kind`,
                  config
                )}
            }
        }`,

  UserPagination: (config?: any) => `
        query UserPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserInput, $sort: SortFindManyUserInput, $limit: Int){
            UserPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort, limit: $limit){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserFindByIds: (config?: any) => `
        query UserFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserInput){
            UserFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindById: (config?: any) => `
        query UserFindById($_id: MongoID!){
            UserFindById(_id: $_id){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindOne: (config?: any) => `
        query UserFindOne($filter: FilterFindOneUserInput, $skip: Int, $sort: SortFindOneUserInput){
            UserFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserFindMany: (config?: any) => `
        query UserFindMany($filter: FilterFindManyUserInput, $skip: Int, $limit: Int, $sort: SortFindManyUserInput){
            UserFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  User: (config?: any) => `
        query User{
            User{
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserSearch: (config?: any) => `
        query UserSearch($search: String!, $roleGroup: [String]){
            UserSearch(search: $search, roleGroup: $roleGroup){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config
                )}
            }
        }`,

  UserSearchPagination: (config?: any) => `
        query UserSearchPagination($page: Int, $perPage: Int, $filter: UserSearchPaginationFilterInput, $sort: SortFindManyUserInput, $limit: Int){
            UserSearchPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort, limit: $limit){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  TextFindBlackList: (config?: any) => `
        query TextFindBlackList($text: String!){
            TextFindBlackList(text: $text){
                ${parseFragmentConfig(`isOk`, config)}
            }
        }`,

  UserFindByEmails: (config?: any) => `
        query UserFindByEmails($emails: [String]!){
            UserFindByEmails(emails: $emails){
                ${parseFragmentConfig(
                  `_id
                        name
                        email`,
                  config
                )}
            }
        }`,

  UserFindByMatricula: (config?: any) => `
        query UserFindByMatricula($isMatriculaNull: Boolean, $email: String, $name: String, $page: Int, $perPage: Int){
            UserFindByMatricula(isMatriculaNull: $isMatriculaNull, email: $email, name: $name, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  FileFindByIds: (config?: any) => `
        query FileFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsFileInput){
            FileFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config
                )}
            }
        }`,

  FileFindById: (config?: any) => `
        query FileFindById($_id: MongoID!){
            FileFindById(_id: $_id){
                ${parseFragmentConfig(
                  `origin
                        parentIds
                        kind
                        title
                        isPublic
                        uploaded
                        url
                        destinationKey
                        extension
                        size
                        sizeDescription
                        description
                        tags
                        owner
                        _id
                        updatedAt
                        createdAt
                        signedUrl
                        downloadUrl`,
                  config
                )}
            }
        }`,

  FilePagination: (config?: any) => `
        query FilePagination($page: Int, $perPage: Int, $filter: FilterFindManyFileInput, $sort: SortFindManyFileInput){
            FilePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialFindById: (config?: any) => `
        query MaterialFindById($_id: MongoID!, $filter: filter, $sort: sort){
            MaterialFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        publishNow
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialFindMany: (config?: any) => `
        query MaterialFindMany($filter: FilterFindManyMaterialInput, $skip: Int, $limit: Int, $sort: SortFindManyMaterialInput){
            MaterialFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        publishNow
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialFindOne: (config?: any) => `
        query MaterialFindOne($filter: FilterFindOneMaterialInput, $skip: Int, $sort: SortFindOneMaterialInput){
            MaterialFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        approvalFlow
                        mailingApprovers
                        usersToApprove
                        awaitingApprovers
                        approvedBy
                        description
                        isDraft
                        detachedVideo
                        latestPublications
                        orderLatestPublications
                        mainIdCategory
                        kind
                        tags
                        title
                        link
                        owner
                        fileIds
                        additionalCategories
                        enableSocialInteraction
                        messageToApprover
                        disapprovalMessage
                        notificationLink
                        previousStatus
                        publishedAt
                        lastSentAt
                        spotlight
                        html
                        editorFiles
                        externalSrc
                        isLive
                        associatedEvents
                        frequency
                        descriptionBulletin
                        publishNow
                        _id
                        updatedAt
                        createdAt
                        title_fuzzy
                        tags_fuzzy
                        numericId
                        validityEnum
                        kindName
                        pinned
                        isOwner`,
                  config
                )}
            }
        }`,

  MaterialPagination: (config?: any) => `
        query MaterialPagination($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput){
            MaterialPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MaterialCount: (config?: any) => `
        query MaterialCount($filter: FilterMaterialInput, $sort: sort){
                ${parseFragmentConfig(
                  `MaterialCount(filter: $filter, sort: $sort)`,
                  config
                )}
        }`,

  MaterialStatusOptions: (config?: any) => `
        query MaterialStatusOptions{
            MaterialStatusOptions{
                ${parseFragmentConfig(
                  `label
                        _id
                        value
                        description`,
                  config
                )}
            }
        }`,

  MaterialSearchOwners: (config?: any) => `
        query MaterialSearchOwners($kind: EnumMaterialKind){
            MaterialSearchOwners(kind: $kind){
                ${parseFragmentConfig(
                  `_id
                        name`,
                  config
                )}
            }
        }`,

  EmailFindMany: (config?: any) => `
        query EmailFindMany($filter: FilterFindManyEmailInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailInput){
            EmailFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailPagination: (config?: any) => `
        query EmailPagination($page: Int, $perPage: Int, $filter: FilterFindManyEmailInput, $sort: SortFindManyEmailInput){
            EmailPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EmailFindOne: (config?: any) => `
        query EmailFindOne($filter: FilterFindOneEmailInput, $skip: Int, $sort: SortFindOneEmailInput){
            EmailFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        subject
                        from
                        templateProps
                        templateName
                        status
                        sent
                        sentAt
                        sentBody
                        schedule
                        attachmentIds
                        statsId
                        plataform
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailCheckRead: (config?: any) => `
        query EmailCheckRead($emailId: String!, $userEmail: String!){
                ${parseFragmentConfig(
                  `EmailCheckRead(emailId: $emailId, userEmail: $userEmail)`,
                  config
                )}
        }`,

  MaterialSearch: (config?: any) => `
        query MaterialSearch($search: String!, $page: Int, $perPage: Int, $sort: SortFindManyMaterialInput, $category: String){
            MaterialSearch(search: $search, page: $page, perPage: $perPage, sort: $sort, category: $category){
                ${parseFragmentConfig(
                  `count
                        autocomplete`,
                  config
                )}
            }
        }`,

  MaterialFindPinned: (config?: any) => `
        query MaterialFindPinned($page: Int, $perPage: Int, $filter: FilterFindManyMaterialInput, $sort: SortFindManyMaterialInput){
            MaterialFindPinned(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  NotificationFindMany: (config?: any) => `
        query NotificationFindMany($filter: FilterFindManyNotificationInput, $skip: Int, $limit: Int, $sort: SortFindManyNotificationInput){
            NotificationFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config
                )}
            }
        }`,

  NotificationFindOne: (config?: any) => `
        query NotificationFindOne($filter: FilterFindOneNotificationInput, $skip: Int, $sort: SortFindOneNotificationInput){
            NotificationFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config
                )}
            }
        }`,

  NotificationPagination: (config?: any) => `
        query NotificationPagination($page: Int, $perPage: Int, $filter: FilterFindManyNotificationInput, $sort: SortFindManyNotificationInput){
            NotificationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  HelperAddress: (config?: any) => `
        query HelperAddress($zipCode: String!){
            HelperAddress(zipCode: $zipCode){
                ${parseFragmentConfig(
                  `zipCode
                        neighborhood
                        address
                        complement
                        DDD
                        UF
                        IBGE`,
                  config
                )}
            }
        }`,

  CategoryFindOne: (config?: any) => `
        query CategoryFindOne($filter: FilterFindOneCategoryInput, $skip: Int, $sort: SortFindOneCategoryInput){
            CategoryFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindById: (config?: any) => `
        query CategoryFindById($_id: MongoID!, $sort: SortMenuEnum, $filter: filter){
            CategoryFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindMany: (config?: any) => `
        query CategoryFindMany($filter: FilterFindManyCategoryInput, $skip: Int, $limit: Int, $sort: SortFindManyCategoryInput){
            CategoryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CategoryFindByIds: (config?: any) => `
        query CategoryFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsCategoryInput, $filter: filter){
            CategoryFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        _id
                        description
                        availableAtRoles
                        name
                        sort
                        parentId
                        routeName
                        typeIcon
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  WebPushPublicKey: (config?: any) => `
        query WebPushPublicKey{
                ${parseFragmentConfig(`WebPushPublicKey`, config)}
        }`,

  PinFindMany: (config?: any) => `
        query PinFindMany($filter: FilterFindManyPinInput, $skip: Int, $limit: Int, $sort: SortFindManyPinInput){
            PinFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        recordId
                        kind
                        url
                        description
                        folderId
                        otherData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PinPagination: (config?: any) => `
        query PinPagination($page: Int, $perPage: Int, $filter: FilterFindManyPinInput, $sort: SortFindManyPinInput){
            PinPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ShareFindMany: (config?: any) => `
        query ShareFindMany($filter: FilterFindManyShareInput, $skip: Int, $limit: Int, $sort: SortFindManyShareInput){
            ShareFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        title
                        recordId
                        userIds
                        message
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  SharePagination: (config?: any) => `
        query SharePagination($page: Int, $perPage: Int, $filter: FilterFindManyShareInput, $sort: SortFindManyShareInput){
            SharePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentPagination: (config?: any) => `
        query CommentPagination($page: Int, $perPage: Int, $filter: FilterFindManyCommentInput, $sort: SortFindManyCommentInput){
            CommentPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CommentFindOne: (config?: any) => `
        query CommentFindOne($filter: FilterFindOneCommentInput, $skip: Int, $sort: SortFindOneCommentInput){
            CommentFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentFindById: (config?: any) => `
        query CommentFindById($_id: MongoID!){
            CommentFindById(_id: $_id){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentFindMany: (config?: any) => `
        query CommentFindMany($filter: FilterFindManyCommentInput, $skip: Int, $limit: Int, $sort: SortFindManyCommentInput){
            CommentFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `parentId
                        roomId
                        owner
                        approved
                        content
                        replyRecipientId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CommentSortPagination: (config?: any) => `
        query CommentSortPagination($page: Int, $perPage: Int, $filter: CommentSortPaginationFilterInput, $sort: SortFindManyCommentInput){
            CommentSortPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLivePagination: (config?: any) => `
        query EventLivePagination($page: Int, $perPage: Int, $filter: FilterFindManyEventLiveInput, $sort: SortFindManyEventLiveInput){
            EventLivePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  EventLiveFindOne: (config?: any) => `
        query EventLiveFindOne($filter: FilterFindOneEventLiveInput, $skip: Int, $sort: SortFindOneEventLiveInput){
            EventLiveFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  EventLiveFindById: (config?: any) => `
        query EventLiveFindById($_id: MongoID!, $filter: filter){
            EventLiveFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        owner
                        closedBy
                        isDraft
                        videoUrl
                        eventDate
                        endTime
                        title
                        kind
                        description
                        status
                        previousStatus
                        publishedAt
                        fileIds
                        _id
                        updatedAt
                        createdAt
                        pinned`,
                  config
                )}
            }
        }`,

  BannerFindMany: (config?: any) => `
        query BannerFindMany($filter: FilterFindManyBannerInput, $skip: Int, $limit: Int, $sort: SortFindManyBannerInput){
            BannerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  BannerFindOne: (config?: any) => `
        query BannerFindOne($filter: FilterFindOneBannerInput, $skip: Int, $sort: SortFindOneBannerInput){
            BannerFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        availableAtRegions
                        availableAtChannels
                        availableAtRoleGroups
                        availableAtRegionalProfiles
                        availableForUsers
                        destinationEmailsFromMailingFile
                        position
                        title
                        link
                        status
                        textButton
                        sort
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  AnalyticsCount: (config?: any) => `
        query AnalyticsCount($filter: FilterAnalyticsInput){
                ${parseFragmentConfig(
                  `AnalyticsCount(filter: $filter)`,
                  config
                )}
        }`,

  AnalyticsPagination: (config?: any) => `
        query AnalyticsPagination($page: Int, $perPage: Int, $filter: FilterFindManyAnalyticsInput, $sort: SortFindManyAnalyticsInput){
            AnalyticsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  AnalyticsUsersReport: (config?: any) => `
        query AnalyticsUsersReport($page: Int, $perPage: Int){
            AnalyticsUsersReport(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        nome
                        cargo
                        canal
                        regional
                        uf
                        data_adesao
                        ativo
                        matricula
                        responsavelUOPDV
                        matriculaResponsavelUOPDV
                        cpf
                        email
                        data_criacao
                        data_atualizacao
                        data_ultimo_login`,
                  config
                )}
            }
        }`,

  AnalyticsFindReport: (config?: any) => `
        query AnalyticsFindReport($filter: AnalyticsReportFilterInput, $page: Int, $perPage: Int){
            AnalyticsFindReport(filter: $filter, page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `event
                        userId
                        userName
                        userChannel
                        userRoleGroup
                        userRegion
                        userGrupoEconomicoPDV
                        userRazaoSocialPDV
                        userResponsavelUOPDV
                        userIdArea
                        title
                        categories
                        date
                        materialCreatedAt
                        materialPublishedAt
                        status
                        day
                        kind
                        device
                        deviceKind
                        referer
                        route`,
                  config
                )}
            }
        }`,

  AnalyticsUserNotification: (config?: any) => `
        query AnalyticsUserNotification($page: Int, $perPage: Int){
            AnalyticsUserNotification(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        name
                        roleGroup
                        channels`,
                  config
                )}
            }
        }`,

  AnalyticsPin: (config?: any) => `
        query AnalyticsPin($page: Int, $perPage: Int){
            AnalyticsPin(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `userId
                        title
                        categories
                        createdAt`,
                  config
                )}
            }
        }`,

  AnalyticsComment: (config?: any) => `
        query AnalyticsComment($page: Int, $perPage: Int){
            AnalyticsComment(page: $page, perPage: $perPage){
                ${parseFragmentConfig(
                  `_id
                        userId
                        materialTitle
                        content
                        createdAt
                        likesCount
                        subCommentsCount
                        commentId`,
                  config
                )}
            }
        }`,

  AnalyticsPoints: (config?: any) => `
        query AnalyticsPoints($page: Int, $perPage: Int){
            AnalyticsPoints(page: $page, perPage: $perPage){
                ${parseFragmentConfig(`userId`, config)}
            }
        }`,

  AnalyticsDownloads: (config?: any) => `
        query AnalyticsDownloads($fileId: String){
                ${parseFragmentConfig(
                  `AnalyticsDownloads(fileId: $fileId)`,
                  config
                )}
        }`,

  SearchCountFindMany: (config?: any) => `
        query SearchCountFindMany($filter: FilterFindManySearchCountInput, $skip: Int, $limit: Int, $sort: SortFindManySearchCountInput){
            SearchCountFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `_id
                        term
                        count
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  SearchCountPagination: (config?: any) => `
        query SearchCountPagination($page: Int, $perPage: Int, $filter: FilterFindManySearchCountInput, $sort: SortFindManySearchCountInput){
            SearchCountPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FolderFindMany: (config?: any) => `
        query FolderFindMany($filter: FilterFindManyFolderInput, $skip: Int, $limit: Int, $sort: SortFindManyFolderInput){
            FolderFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FolderFindById: (config?: any) => `
        query FolderFindById($_id: MongoID!){
            FolderFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        kind
                        name
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  FolderPagination: (config?: any) => `
        query FolderPagination($page: Int, $perPage: Int, $filter: FilterFindManyFolderInput, $sort: SortFindManyFolderInput){
            FolderPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RecentsActivitiesPagination: (config?: any) => `
        query RecentsActivitiesPagination($page: Int, $perPage: Int, $filter: FilterFindManyRecentsActivitiesInput, $sort: SortFindManyRecentsActivitiesInput){
            RecentsActivitiesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserWordsBlackListFindMany: (config?: any) => `
        query UserWordsBlackListFindMany($filter: FilterFindManyUserWordsBlackListInput, $skip: Int, $limit: Int, $sort: SortFindManyUserWordsBlackListInput){
            UserWordsBlackListFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserWordsBlackListFindById: (config?: any) => `
        query UserWordsBlackListFindById($_id: MongoID!){
            UserWordsBlackListFindById(_id: $_id){
                ${parseFragmentConfig(
                  `owner
                        word
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DownloadSensitiveFilesPagination: (config?: any) => `
        query DownloadSensitiveFilesPagination($page: Int, $perPage: Int, $filter: FilterFindManyDownloadSensitiveFilesInput, $sort: SortFindManyDownloadSensitiveFilesInput){
            DownloadSensitiveFilesPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DownloadUsersInfo: (config?: any) => `
        query DownloadUsersInfo{
            DownloadUsersInfo{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadAcceptedTerms: (config?: any) => `
        query DownloadAcceptedTerms($incentiveCampaignId: String!){
            DownloadAcceptedTerms(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersAcceptedPoints: (config?: any) => `
        query DownloadUsersAcceptedPoints($incentiveCampaignId: String!){
            DownloadUsersAcceptedPoints(incentiveCampaignId: $incentiveCampaignId){
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersWithPoints: (config?: any) => `
        query DownloadUsersWithPoints{
            DownloadUsersWithPoints{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  DownloadUsersBase: (config?: any) => `
        query DownloadUsersBase{
            DownloadUsersBase{
                ${parseFragmentConfig(`url`, config)}
            }
        }`,

  ImportUsersPlanFindMany: (config?: any) => `
        query ImportUsersPlanFindMany($filter: FilterFindManyImportUsersPlanInput, $skip: Int, $limit: Int, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanFindById: (config?: any) => `
        query ImportUsersPlanFindById($_id: MongoID!, $filter: filter){
            ImportUsersPlanFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanFindByIds: (config?: any) => `
        query ImportUsersPlanFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsImportUsersPlanInput, $filter: filter){
            ImportUsersPlanFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `fileId
                        statusImportUser
                        origin
                        errorsFile
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ImportUsersPlanPagination: (config?: any) => `
        query ImportUsersPlanPagination($page: Int, $perPage: Int, $filter: FilterFindManyImportUsersPlanInput, $sort: SortFindManyImportUsersPlanInput){
            ImportUsersPlanPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserDeletedFindByIds: (config?: any) => `
        query UserDeletedFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsUserDeletedInput){
            UserDeletedFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserDeletedFindOne: (config?: any) => `
        query UserDeletedFindOne($filter: FilterFindOneUserDeletedInput, $skip: Int, $sort: SortFindOneUserDeletedInput){
            UserDeletedFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserDeletedFindMany: (config?: any) => `
        query UserDeletedFindMany($filter: FilterFindManyUserDeletedInput, $skip: Int, $limit: Int, $sort: SortFindManyUserDeletedInput){
            UserDeletedFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `name
                        CPF
                        matricula
                        email
                        roleGroup
                        channels
                        CNPJ
                        UF
                        allowedDDDs
                        region
                        regionPath
                        optInDate
                        optIn
                        userRoles
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserHistoryBlockFindMany: (config?: any) => `
        query UserHistoryBlockFindMany($filter: FilterFindManyUserHistoryBlockInput, $skip: Int, $limit: Int, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `owner
                        userId
                        parentId
                        isBlocked
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  UserHistoryBlockPagination: (config?: any) => `
        query UserHistoryBlockPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserHistoryBlockInput, $sort: SortFindManyUserHistoryBlockInput){
            UserHistoryBlockPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FutureOperationFindById: (config?: any) => `
        query FutureOperationFindById($_id: MongoID!){
            FutureOperationFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userCreditOperationId
                        userId
                        operation
                        pointsLeft
                        expiresAt
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailStatsFindOne: (config?: any) => `
        query EmailStatsFindOne($filter: FilterFindOneEmailStatsInput, $skip: Int, $sort: SortFindOneEmailStatsInput){
            EmailStatsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailStatsFindMany: (config?: any) => `
        query EmailStatsFindMany($filter: FilterFindManyEmailStatsInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailStatsInput){
            EmailStatsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `statsId
                        userEmail
                        status
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeFindOne: (config?: any) => `
        query EmailUnsubscribeFindOne($filter: FilterFindOneEmailUnsubscribeInput, $skip: Int, $sort: SortFindOneEmailUnsubscribeInput){
            EmailUnsubscribeFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeFindMany: (config?: any) => `
        query EmailUnsubscribeFindMany($filter: FilterFindManyEmailUnsubscribeInput, $skip: Int, $limit: Int, $sort: SortFindManyEmailUnsubscribeInput){
            EmailUnsubscribeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `email
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeAction: (config?: any) => `
        query EmailUnsubscribeAction($id: String, $email: String){
            EmailUnsubscribeAction(id: $id, email: $email){
                ${parseFragmentConfig(
                  `id
                        email`,
                  config
                )}
            }
        }`,

  HomebannerFindMany: (config?: any) => `
        query HomebannerFindMany($filter: FilterFindManyHomebannerInput, $skip: Int, $limit: Int, $sort: SortFindManyHomebannerInput){
            HomebannerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `key
                        title
                        fileId
                        type
                        link
                        visible
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PlaceFindMany: (config?: any) => `
        query PlaceFindMany($filter: FilterFindManyPlaceInput, $skip: Int, $limit: Int, $sort: SortFindManyPlaceInput){
            PlaceFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt
                        history
                        isActive`,
                  config
                )}
            }
        }`,

  PlaceFindOne: (config?: any) => `
        query PlaceFindOne($filter: FilterFindOnePlaceInput, $skip: Int, $sort: SortFindOnePlaceInput){
            PlaceFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt
                        history
                        isActive`,
                  config
                )}
            }
        }`,

  PlaceFindById: (config?: any) => `
        query PlaceFindById($_id: MongoID!, $sort: SortPlaceEnum, $filter: filter){
            PlaceFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt
                        history
                        isActive`,
                  config
                )}
            }
        }`,

  PlacePagination: (config?: any) => `
        query PlacePagination($page: Int, $perPage: Int, $filter: FilterFindManyPlaceInput, $sort: SortFindManyPlaceInput){
            PlacePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceSearch: (config?: any) => `
        query PlaceSearch($channel: [String], $DDD: [String], $uf: [String], $city: [String], $communicationFocus: [String], $storeModel: [String], $networkTechnology: [String], $marketedProduct: [String], $region: [String], $adminId: String, $CNPJ: [String], $perPage: Int, $page: Int, $razao_social: String){
            PlaceSearch(channel: $channel, DDD: $DDD, uf: $uf, city: $city, communicationFocus: $communicationFocus, storeModel: $storeModel, networkTechnology: $networkTechnology, marketedProduct: $marketedProduct, region: $region, adminId: $adminId, CNPJ: $CNPJ, perPage: $perPage, page: $page, razao_social: $razao_social){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceSearchByIdsTypeAndName: (config?: any) => `
        query PlaceSearchByIdsTypeAndName($id: [String], $name: String, $type: [String], $perPage: Int, $page: Int){
            PlaceSearchByIdsTypeAndName(id: $id, name: $name, type: $type, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceSearchByChannelSapCodeOrPlaceAndRegion: (config?: any) => `
        query PlaceSearchByChannelSapCodeOrPlaceAndRegion($disabled: Boolean, $filterLocationType: [String], $filterRegions: [String], $filterSapCodeOrPlaceName: String, $perPage: Int, $page: Int){
            PlaceSearchByChannelSapCodeOrPlaceAndRegion(disabled: $disabled, filterLocationType: $filterLocationType, filterRegions: $filterRegions, filterSapCodeOrPlaceName: $filterSapCodeOrPlaceName, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceSearchByIdsLpuCategoryAndContainsInventory: (config?: any) => `
        query PlaceSearchByIdsLpuCategoryAndContainsInventory($id: [String], $lpu: [String], $categoryLpu: String, $relatedToCategoryLpu: String, $containsInventory: Boolean, $perPage: Int, $page: Int){
            PlaceSearchByIdsLpuCategoryAndContainsInventory(id: $id, lpu: $lpu, categoryLpu: $categoryLpu, relatedToCategoryLpu: $relatedToCategoryLpu, containsInventory: $containsInventory, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlacePaginationCustom: (config?: any) => `
        query PlacePaginationCustom($page: Int, $perPage: Int, $limit: Int, $filter: JSON, $sort: SortFindManyPlaceInput){
            PlacePaginationCustom(page: $page, perPage: $perPage, limit: $limit, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  LocationOptions: (config?: any) => `
        query LocationOptions($uf: String, $region: String){
            LocationOptions(uf: $uf, region: $region){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceSearchByCnpj: (config?: any) => `
        query PlaceSearchByCnpj($CNPJ: [String]){
            PlaceSearchByCnpj(CNPJ: $CNPJ){
                ${parseFragmentConfig(
                  `type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt
                        history
                        isActive`,
                  config
                )}
            }
        }`,

  PlacesBaseDownload: (config?: any) => `
        query PlacesBaseDownload($filters: JSON, $isExample: Boolean){
            PlacesBaseDownload(filters: $filters, isExample: $isExample){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  PlacesBaseValidation: (config?: any) => `
        query PlacesBaseValidation($fileId: String!){
                ${parseFragmentConfig(
                  `PlacesBaseValidation(fileId: $fileId)`,
                  config
                )}
        }`,

  PlacesPermissions: (config?: any) => `
        query PlacesPermissions{
                ${parseFragmentConfig(`PlacesPermissions`, config)}
        }`,

  ContractPagination: (config?: any) => `
        query ContractPagination($page: Int, $perPage: Int, $filter: FilterFindManyContractInput, $sort: SortFindManyContractInput){
            ContractPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ContractFindOne: (config?: any) => `
        query ContractFindOne($filter: FilterFindOneContractInput, $skip: Int, $sort: SortFindOneContractInput){
            ContractFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `nr_clm
                        nr_contrato
                        inicio_vigencia
                        final_vigencia
                        valor_total
                        valor_utilizado
                        nr_contrato_derivado
                        inicio_vigencia_derivado
                        final_vigencia_derivado
                        valor_total_derivado
                        valor_utilizado_derivado
                        margem_saldo_derivado
                        natureza_contrato
                        natureza_verba
                        prazo_pagto
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ContractFindByIds: (config?: any) => `
        query ContractFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsContractInput, $filter: filter){
            ContractFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `nr_clm
                        nr_contrato
                        inicio_vigencia
                        final_vigencia
                        valor_total
                        valor_utilizado
                        nr_contrato_derivado
                        inicio_vigencia_derivado
                        final_vigencia_derivado
                        valor_total_derivado
                        valor_utilizado_derivado
                        margem_saldo_derivado
                        natureza_contrato
                        natureza_verba
                        prazo_pagto
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ProductPagination: (config?: any) => `
        query ProductPagination($page: Int, $perPage: Int, $filter: FilterFindManyProductInput, $sort: SortFindManyProductInput){
            ProductPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ProductFindById: (config?: any) => `
        query ProductFindById($_id: MongoID!, $sort: SortProductEnum, $filter: filter){
            ProductFindById(_id: $_id, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `nome_produto
                        preco_produto
                        categoria
                        abreviacao_producao
                        especificacao
                        faixa_preco
                        especificacao_peso
                        especificacao_largura
                        especificacao_altura
                        especificacao_profundidade
                        especificacao_preco
                        code_tm
                        qtd_dias
                        contagem
                        tipo_multiplicador
                        multiplicador_pdv
                        multiplicador_mobiliario
                        multiplicador_posicao
                        ncm
                        relatedTo
                        isDeleted
                        supplierSAPCode
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ProductFindByIds: (config?: any) => `
        query ProductFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsProductInput, $filter: filter){
            ProductFindByIds(_ids: $_ids, limit: $limit, sort: $sort, filter: $filter){
                ${parseFragmentConfig(
                  `nome_produto
                        preco_produto
                        categoria
                        abreviacao_producao
                        especificacao
                        faixa_preco
                        especificacao_peso
                        especificacao_largura
                        especificacao_altura
                        especificacao_profundidade
                        especificacao_preco
                        code_tm
                        qtd_dias
                        contagem
                        tipo_multiplicador
                        multiplicador_pdv
                        multiplicador_mobiliario
                        multiplicador_posicao
                        ncm
                        relatedTo
                        isDeleted
                        supplierSAPCode
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ProductBaseDownload: (config?: any) => `
        query ProductBaseDownload($filters: JSON, $isExample: Boolean){
            ProductBaseDownload(filters: $filters, isExample: $isExample){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  ProductBaseValidation: (config?: any) => `
        query ProductBaseValidation($fileId: String!){
                ${parseFragmentConfig(
                  `ProductBaseValidation(fileId: $fileId)`,
                  config
                )}
        }`,

  ProductSearchByNameOrSupplier: (config?: any) => `
        query ProductSearchByNameOrSupplier($description: String, $onlyStructural: Boolean, $name: String, $ids: [String], $supplier: String, $category: EnumProductCategoria, $perPage: Int, $page: Int){
            ProductSearchByNameOrSupplier(description: $description, onlyStructural: $onlyStructural, name: $name, ids: $ids, supplier: $supplier, category: $category, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ProductSearchByNameOrSupplierAndNotContainsSomeIds: (config?: any) => `
        query ProductSearchByNameOrSupplierAndNotContainsSomeIds($name: String, $supplier: String, $ids: [String], $perPage: Int, $page: Int){
            ProductSearchByNameOrSupplierAndNotContainsSomeIds(name: $name, supplier: $supplier, ids: $ids, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ProductSearchBySupplierZipCodeAndContainsInventory: (config?: any) => `
        query ProductSearchBySupplierZipCodeAndContainsInventory($id: [String]!, $zipcode: [String], $containsInventory: Boolean, $perPage: Int, $page: Int){
            ProductSearchBySupplierZipCodeAndContainsInventory(id: $id, zipcode: $zipcode, containsInventory: $containsInventory, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  SupplierPagination: (config?: any) => `
        query SupplierPagination($page: Int, $perPage: Int, $filter: FilterFindManySupplierInput, $sort: SortFindManySupplierInput){
            SupplierPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  SupplierFindOne: (config?: any) => `
        query SupplierFindOne($filter: FilterFindOneSupplierInput, $skip: Int, $sort: SortFindOneSupplierInput){
            SupplierFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `numero_fornecedor_sap
                        CNPJ
                        razao_social
                        nome_fantasia
                        address
                        regionais_ufs_atendidas
                        nome
                        telefone
                        matricula
                        email
                        nivel_aprovacao
                        valor_qualificacao
                        validade_qualificacao
                        produtos_unitarios
                        region
                        createdAt
                        disabled
                        isDeleted
                        _id
                        updatedAt`,
                  config
                )}
            }
        }`,

  SupplierFindMany: (config?: any) => `
        query SupplierFindMany($filter: FilterFindManySupplierInput, $skip: Int, $limit: Int, $sort: SortFindManySupplierInput){
            SupplierFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `numero_fornecedor_sap
                        CNPJ
                        razao_social
                        nome_fantasia
                        address
                        regionais_ufs_atendidas
                        nome
                        telefone
                        matricula
                        email
                        nivel_aprovacao
                        valor_qualificacao
                        validade_qualificacao
                        produtos_unitarios
                        region
                        createdAt
                        disabled
                        isDeleted
                        _id
                        updatedAt`,
                  config
                )}
            }
        }`,

  ResolverSupplierSearchByNameAndContainsLpu: (config?: any) => `
        query ResolverSupplierSearchByNameAndContainsLpu($name: String, $lpu: [String], $perPage: Int, $page: Int){
            ResolverSupplierSearchByNameAndContainsLpu(name: $name, lpu: $lpu, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  SupplierPermissions: (config?: any) => `
        query SupplierPermissions{
                ${parseFragmentConfig(`SupplierPermissions`, config)}
        }`,

  TablePriceFindMany: (config?: any) => `
        query TablePriceFindMany($filter: FilterFindManyTablePriceInput, $skip: Int, $limit: Int, $sort: SortFindManyTablePriceInput){
            TablePriceFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        locationType
                        description
                        disabled
                        upload
                        sapCode
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        coolText
                        schedule
                        _id
                        updatedAt
                        createdAt
                        uploadFileContent`,
                  config
                )}
            }
        }`,

  TablePriceFindOne: (config?: any) => `
        query TablePriceFindOne($filter: FilterFindOneTablePriceInput, $skip: Int, $sort: SortFindOneTablePriceInput){
            TablePriceFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        locationType
                        description
                        disabled
                        upload
                        sapCode
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        coolText
                        schedule
                        _id
                        updatedAt
                        createdAt
                        uploadFileContent`,
                  config
                )}
            }
        }`,

  TablePriceFindById: (config?: any) => `
        query TablePriceFindById($_id: MongoID!, $sort: SortSupplierEnum){
            TablePriceFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        locationType
                        description
                        disabled
                        upload
                        sapCode
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        coolText
                        schedule
                        _id
                        updatedAt
                        createdAt
                        uploadFileContent`,
                  config
                )}
            }
        }`,

  TablePricePagination: (config?: any) => `
        query TablePricePagination($page: Int, $perPage: Int, $filter: FilterFindManyTablePriceInput, $sort: SortFindManyTablePriceInput){
            TablePricePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  TablePrice: (config?: any) => `
        query TablePrice{
            TablePrice{
                ${parseFragmentConfig(
                  `availableAtRegions
                        locationType
                        description
                        disabled
                        upload
                        sapCode
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        coolText
                        schedule
                        _id
                        updatedAt
                        createdAt
                        uploadFileContent`,
                  config
                )}
            }
        }`,

  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds: (
    config?: any
  ) => `
        query TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds($regions: [String]!, $locationType: [String], $ids: [String]!){
            TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds(regions: $regions, locationType: $locationType, ids: $ids){
                ${parseFragmentConfig(
                  `availableAtRegions
                        locationType
                        description
                        disabled
                        upload
                        sapCode
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        coolText
                        schedule
                        _id
                        updatedAt
                        createdAt
                        uploadFileContent`,
                  config
                )}
            }
        }`,

  TablePriceAccessories: (config?: any) => `
        query TablePriceAccessories($tablePrice: String!){
            TablePriceAccessories(tablePrice: $tablePrice){
                ${parseFragmentConfig(
                  `timCode
                        title
                        price`,
                  config
                )}
            }
        }`,

  TablePriceSearchBySapCodeAndDeviceModel: (config?: any) => `
        query TablePriceSearchBySapCodeAndDeviceModel{
                ${parseFragmentConfig(``, config)}
        }`,

  TablePriceSearchBySapCode: (config?: any) => `
        query TablePriceSearchBySapCode($sapCode: [String], $status: String){
            TablePriceSearchBySapCode(sapCode: $sapCode, status: $status){
                ${parseFragmentConfig(
                  `list
                        tableId`,
                  config
                )}
            }
        }`,

  TablePriceDevices: (config?: any) => `
        query TablePriceDevices($devices: [String]!, $tablePrice: String!){
            TablePriceDevices(devices: $devices, tablePrice: $tablePrice){
                ${parseFragmentConfig(`legalText`, config)}
            }
        }`,

  TablePriceDeviceBySapCode: (config?: any) => `
        query TablePriceDeviceBySapCode{
                ${parseFragmentConfig(``, config)}
        }`,

  TablePricePermissions: (config?: any) => `
        query TablePricePermissions{
                ${parseFragmentConfig(`TablePricePermissions`, config)}
        }`,

  PricingTableFindById: (config?: any) => `
        query PricingTableFindById($_id: MongoID!, $sort: SortPricingTableEnum){
            PricingTableFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `status
                        description
                        availableAtRegions
                        locationType
                        disabled
                        xlsxId
                        activeSapCodes
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        legalText
                        schedule
                        createdBy
                        incidents
                        previous
                        plansDevice
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PricingTablePagination: (config?: any) => `
        query PricingTablePagination($page: Int, $perPage: Int, $filter: FilterFindManyPricingTableInput, $sort: SortFindManyPricingTableInput){
            PricingTablePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PricingTableFindMany: (config?: any) => `
        query PricingTableFindMany($filter: FilterFindManyPricingTableInput, $skip: Int, $limit: Int, $sort: SortFindManyPricingTableInput){
            PricingTableFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `status
                        description
                        availableAtRegions
                        locationType
                        disabled
                        xlsxId
                        activeSapCodes
                        removedSapCodes
                        planPre
                        planControl
                        planPos
                        legalText
                        schedule
                        createdBy
                        incidents
                        previous
                        plansDevice
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  LegalTextFindById: (config?: any) => `
        query LegalTextFindById($_id: MongoID!){
            LegalTextFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        content
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  LegalTextPagination: (config?: any) => `
        query LegalTextPagination($page: Int, $perPage: Int, $filter: FilterFindManyLegalTextInput, $sort: SortFindManyLegalTextInput){
            LegalTextPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DeviceFindMany: (config?: any) => `
        query DeviceFindMany($filter: FilterFindManyDeviceInput, $skip: Int, $limit: Int, $sort: SortFindManyDeviceInput){
            DeviceFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceFindOne: (config?: any) => `
        query DeviceFindOne($filter: FilterFindOneDeviceInput, $skip: Int, $sort: SortFindOneDeviceInput){
            DeviceFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceFindById: (config?: any) => `
        query DeviceFindById($_id: MongoID!, $filter: filter){
            DeviceFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DevicePagination: (config?: any) => `
        query DevicePagination($page: Int, $perPage: Int, $filter: FilterFindManyDeviceInput, $sort: SortFindManyDeviceInput){
            DevicePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DeviceSearch: (config?: any) => `
        query DeviceSearch($search: String, $manufacturers: [String], $skipManufacturers: [String]){
            DeviceSearch(search: $search, manufacturers: $manufacturers, skipManufacturers: $skipManufacturers){
                ${parseFragmentConfig(
                  `_id
                        model
                        codeTim
                        codeProvider`,
                  config
                )}
            }
        }`,

  DeviceFindManufacturers: (config?: any) => `
        query DeviceFindManufacturers{
            DeviceFindManufacturers{
                ${parseFragmentConfig(`name`, config)}
            }
        }`,

  DeviceSearchById: (config?: any) => `
        query DeviceSearchById($deviceId: String!){
            DeviceSearchById(deviceId: $deviceId){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceBaseDownload: (config?: any) => `
        query DeviceBaseDownload($filters: JSON, $isExample: Boolean){
            DeviceBaseDownload(filters: $filters, isExample: $isExample){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  DeviceBaseValidation: (config?: any) => `
        query DeviceBaseValidation($fileId: String!){
                ${parseFragmentConfig(
                  `DeviceBaseValidation(fileId: $fileId)`,
                  config
                )}
        }`,

  AppDevice: (config?: any) => `
        query AppDevice($CNPJ: String!, $model: String!){
            AppDevice(CNPJ: $CNPJ, model: $model){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  AppDeviceByManufacturer: (config?: any) => `
        query AppDeviceByManufacturer($CNPJ: String!, $manufacturer: String!){
            AppDeviceByManufacturer(CNPJ: $CNPJ, manufacturer: $manufacturer){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DevicePermissions: (config?: any) => `
        query DevicePermissions{
                ${parseFragmentConfig(`DevicePermissions`, config)}
        }`,

  DigitalBrochureFindMany: (config?: any) => `
        query DigitalBrochureFindMany($filter: FilterFindManyDigitalBrochureInput, $skip: Int, $limit: Int, $sort: SortFindManyDigitalBrochureInput){
            DigitalBrochureFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        title
                        service
                        file
                        thumbnailURL
                        thumbnailHDURL
                        qrCodeSVGURL
                        validity
                        link
                        thumbnailQuality
                        isVisibleToPublic
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureFindOne: (config?: any) => `
        query DigitalBrochureFindOne($filter: FilterFindOneDigitalBrochureInput, $skip: Int, $sort: SortFindOneDigitalBrochureInput){
            DigitalBrochureFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `availableAtRegions
                        title
                        service
                        file
                        thumbnailURL
                        thumbnailHDURL
                        qrCodeSVGURL
                        validity
                        link
                        thumbnailQuality
                        isVisibleToPublic
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureFindById: (config?: any) => `
        query DigitalBrochureFindById($_id: MongoID!, $filter: filter){
            DigitalBrochureFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `availableAtRegions
                        title
                        service
                        file
                        thumbnailURL
                        thumbnailHDURL
                        qrCodeSVGURL
                        validity
                        link
                        thumbnailQuality
                        isVisibleToPublic
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochurePagination: (config?: any) => `
        query DigitalBrochurePagination($page: Int, $perPage: Int, $filter: FilterFindManyDigitalBrochureInput, $sort: SortFindManyDigitalBrochureInput){
            DigitalBrochurePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DigitalBrochureSearchByDDDAndService: (config?: any) => `
        query DigitalBrochureSearchByDDDAndService($filterDDD: String, $filterService: String, $perPage: Int, $page: Int){
            DigitalBrochureSearchByDDDAndService(filterDDD: $filterDDD, filterService: $filterService, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DigitalBrochurePermissions: (config?: any) => `
        query DigitalBrochurePermissions{
                ${parseFragmentConfig(`DigitalBrochurePermissions`, config)}
        }`,

  CampaignPagination: (config?: any) => `
        query CampaignPagination($page: Int, $perPage: Int, $filter: FilterFindManyCampaignInput, $sort: SortFindManyCampaignInput){
            CampaignPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CampaignFindOne: (config?: any) => `
        query CampaignFindOne($filter: FilterFindOneCampaignInput, $skip: Int, $sort: SortFindOneCampaignInput){
            CampaignFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `code
                        owner
                        productType
                        releaseDate
                        archivingDone
                        archive
                        title
                        description
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CampaignFindById: (config?: any) => `
        query CampaignFindById($_id: MongoID!, $sort: SortCampaignEnum){
            CampaignFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `code
                        owner
                        productType
                        releaseDate
                        archivingDone
                        archive
                        title
                        description
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  CampaignSearchByTitleDescriptionOrStatus: (config?: any) => `
        query CampaignSearchByTitleDescriptionOrStatus($title: String, $archived: Boolean, $perPage: Int, $page: Int){
            CampaignSearchByTitleDescriptionOrStatus(title: $title, archived: $archived, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CampaignSearchByTitleOrDescription: (config?: any) => `
        query CampaignSearchByTitleOrDescription($title: String, $perPage: Int, $page: Int){
            CampaignSearchByTitleOrDescription(title: $title, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  CampaignPermissions: (config?: any) => `
        query CampaignPermissions{
                ${parseFragmentConfig(`CampaignPermissions`, config)}
        }`,

  LayoutPagination: (config?: any) => `
        query LayoutPagination($page: Int, $perPage: Int, $filter: FilterFindManyLayoutInput, $sort: SortFindManyLayoutInput){
            LayoutPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  LayoutFindOne: (config?: any) => `
        query LayoutFindOne($filter: FilterFindOneLayoutInput, $skip: Int, $sort: SortFindOneLayoutInput){
            LayoutFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `campaign
                        lpu
                        owner
                        suffix
                        link
                        password
                        codeT
                        codeTM
                        code
                        name
                        thumbnailID
                        _id
                        updatedAt
                        createdAt
                        descriptionTM`,
                  config
                )}
            }
        }`,

  LayoutFindMany: (config?: any) => `
        query LayoutFindMany($filter: FilterFindManyLayoutInput, $skip: Int, $limit: Int, $sort: SortFindManyLayoutInput){
            LayoutFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `campaign
                        lpu
                        owner
                        suffix
                        link
                        password
                        codeT
                        codeTM
                        code
                        name
                        thumbnailID
                        _id
                        updatedAt
                        createdAt
                        descriptionTM`,
                  config
                )}
            }
        }`,

  LayoutFindByIds: (config?: any) => `
        query LayoutFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsLayoutInput){
            LayoutFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `campaign
                        lpu
                        owner
                        suffix
                        link
                        password
                        codeT
                        codeTM
                        code
                        name
                        thumbnailID
                        _id
                        updatedAt
                        createdAt
                        descriptionTM`,
                  config
                )}
            }
        }`,

  LayoutSearchByName: (config?: any) => `
        query LayoutSearchByName($name: String, $perPage: Int, $page: Int){
            LayoutSearchByName(name: $name, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  LayoutSearchByNameAndContainsLpuIntervalQty: (config?: any) => `
        query LayoutSearchByNameAndContainsLpuIntervalQty($name: String, $containsIntervalQty: Boolean, $perPage: Int, $page: Int){
            LayoutSearchByNameAndContainsLpuIntervalQty(name: $name, containsIntervalQty: $containsIntervalQty, perPage: $perPage, page: $page){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  LayoutSearchContentByIds: (config?: any) => `
        query LayoutSearchContentByIds($ids: [String]){
            LayoutSearchContentByIds(ids: $ids){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  LayoutPermissions: (config?: any) => `
        query LayoutPermissions{
                ${parseFragmentConfig(`LayoutPermissions`, config)}
        }`,

  RegionalBudgetFindOne: (config?: any) => `
        query RegionalBudgetFindOne($filter: FilterFindOneRegionalBudgetInput, $skip: Int, $sort: SortFindOneRegionalBudgetInput){
            RegionalBudgetFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `parentBudgetId
                        region
                        deadline
                        maxValue
                        usedValue
                        remainingValue
                        availableAtChannels
                        filledByRegional
                        status
                        _id
                        updatedAt
                        createdAt
                        deadlineFormatted`,
                  config
                )}
            }
        }`,

  RegionalBudgetFindById: (config?: any) => `
        query RegionalBudgetFindById($_id: MongoID!){
            RegionalBudgetFindById(_id: $_id){
                ${parseFragmentConfig(
                  `parentBudgetId
                        region
                        deadline
                        maxValue
                        usedValue
                        remainingValue
                        availableAtChannels
                        filledByRegional
                        status
                        _id
                        updatedAt
                        createdAt
                        deadlineFormatted`,
                  config
                )}
            }
        }`,

  RegionalBudgetPagination: (config?: any) => `
        query RegionalBudgetPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegionalBudgetInput, $sort: SortFindManyRegionalBudgetInput){
            RegionalBudgetPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ProductionFindById: (config?: any) => `
        query ProductionFindById($_id: MongoID!, $filter: filter, $sort: SortProductionEnum){
            ProductionFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `code
                        title
                        originBudgetId
                        status
                        owner
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ProductionPagination: (config?: any) => `
        query ProductionPagination($page: Int, $perPage: Int, $filter: FilterFindManyProductionInput, $sort: SortFindManyProductionInput){
            ProductionPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  ProductionReport: (config?: any) => `
        query ProductionReport($productionId: String){
            ProductionReport(productionId: $productionId){
                ${parseFragmentConfig(
                  `productionTitle
                        rows`,
                  config
                )}
            }
        }`,

  ProductionDeliveryReport: (config?: any) => `
        query ProductionDeliveryReport($productionId: String){
            ProductionDeliveryReport(productionId: $productionId){
                ${parseFragmentConfig(
                  `productionTitle
                        rows`,
                  config
                )}
            }
        }`,

  ProductionPermissions: (config?: any) => `
        query ProductionPermissions{
                ${parseFragmentConfig(`ProductionPermissions`, config)}
        }`,

  BudgetFindOne: (config?: any) => `
        query BudgetFindOne($filter: FilterFindOneBudgetInput, $skip: Int, $sort: SortFindOneBudgetInput){
            BudgetFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `status
                        title
                        value
                        isRegionalProduction
                        availableAtChannels
                        usedInProductionId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  BudgetFindById: (config?: any) => `
        query BudgetFindById($_id: MongoID!){
            BudgetFindById(_id: $_id){
                ${parseFragmentConfig(
                  `status
                        title
                        value
                        isRegionalProduction
                        availableAtChannels
                        usedInProductionId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  BudgetPagination: (config?: any) => `
        query BudgetPagination($page: Int, $perPage: Int, $filter: FilterFindManyBudgetInput, $sort: SortFindManyBudgetInput){
            BudgetPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  BudgetPermissions: (config?: any) => `
        query BudgetPermissions{
                ${parseFragmentConfig(`BudgetPermissions`, config)}
        }`,

  SupplierDeliveryPagination: (config?: any) => `
        query SupplierDeliveryPagination($page: Int, $perPage: Int, $filter: FilterFindManySupplierDeliveryInput, $sort: SortFindManySupplierDeliveryInput){
            SupplierDeliveryPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  SupplierDeliveryFindMany: (config?: any) => `
        query SupplierDeliveryFindMany($filter: FilterFindManySupplierDeliveryInput, $skip: Int, $limit: Int, $sort: SortFindManySupplierDeliveryInput){
            SupplierDeliveryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `supplierSAPCode
                        layoutCodeT
                        layoutCodeTM
                        productionId
                        productionTitle
                        delivered
                        expectedDeliveryDate
                        supplierId
                        supplierName
                        sentEmailIds
                        additionalNotes
                        placeRegion
                        depositPath
                        depositNumber
                        deliveryPlaceId
                        deliveryPlaceType
                        deliveryPlaceTypeName
                        deliveryPlaceName
                        deliveryPlaceCNPJ
                        deliveryPlaceSAPCode
                        deliveryPlaceRazaoSocial
                        deliveryPlaceNomeFantasia
                        deliveryPlaceAddress
                        deliveryPlaceNumber
                        deliveryPlaceComplement
                        deliveryPlaceZipCode
                        deliveryPlaceNeighborhood
                        deliveryPlaceCity
                        deliveryPlaceState
                        deliveryPlaceIsRegional
                        quantityToDelivery
                        quantityDelivered
                        invoiceNumber
                        receiverPersonalName
                        receiverPersonalDocument
                        archivedToUserIds
                        _id
                        updatedAt
                        createdAt
                        productionStatus
                        deliveryStatus`,
                  config
                )}
            }
        }`,

  InventoryItemFindById: (config?: any) => `
        query InventoryItemFindById($_id: MongoID!, $filter: filter){
            InventoryItemFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `layoutCodeTM
                        quantity
                        locationSAPCode
                        depositNumber
                        depositPath
                        region
                        descriptionTM
                        uploadedAt
                        originData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  InventoryItemFindOne: (config?: any) => `
        query InventoryItemFindOne($filter: FilterFindOneInventoryItemInput, $skip: Int, $sort: SortFindOneInventoryItemInput){
            InventoryItemFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `layoutCodeTM
                        quantity
                        locationSAPCode
                        depositNumber
                        depositPath
                        region
                        descriptionTM
                        uploadedAt
                        originData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  InventoryItemPagination: (config?: any) => `
        query InventoryItemPagination($page: Int, $perPage: Int, $filter: FilterFindManyInventoryItemInput, $sort: SortFindManyInventoryItemInput){
            InventoryItemPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  InventoryItemFindMany: (config?: any) => `
        query InventoryItemFindMany($filter: FilterFindManyInventoryItemInput, $skip: Int, $limit: Int, $sort: SortFindManyInventoryItemInput){
            InventoryItemFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `layoutCodeTM
                        quantity
                        locationSAPCode
                        depositNumber
                        depositPath
                        region
                        descriptionTM
                        uploadedAt
                        originData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  InventoryWithDrawPagination: (config?: any) => `
        query InventoryWithDrawPagination($page: Int, $perPage: Int, $filter: FilterFindManyInventoryWithDrawInput, $sort: SortFindManyInventoryWithDrawInput){
            InventoryWithDrawPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  InventoryWithDrawFindById: (config?: any) => `
        query InventoryWithDrawFindById($_id: MongoID!){
            InventoryWithDrawFindById(_id: $_id){
                ${parseFragmentConfig(
                  `author
                        gradeMode
                        productionId
                        depositPath
                        quantityInStock
                        destinationPlaceSAPCode
                        layoutCodeTM
                        quantity
                        deliveryPlaceSAPCode
                        depositNumber
                        salesOrganization
                        distributionChannel
                        activitiesSector
                        productionCode
                        commercialOpStatus
                        commercialOpReason
                        orderZMP
                        processingDate
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeliveryOrderFindById: (config?: any) => `
        query DeliveryOrderFindById($_id: MongoID!, $sort: SortDeliveryOrderEnum){
            DeliveryOrderFindById(_id: $_id, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        status
                        origin
                        createdAt
                        updatedAt
                        executedAt
                        _id
                        qntPDVs
                        qntItems`,
                  config
                )}
            }
        }`,

  DeliveryOrderPagination: (config?: any) => `
        query DeliveryOrderPagination($page: Int, $perPage: Int, $filter: FilterFindManyDeliveryOrderInput, $sort: SortFindManyDeliveryOrderInput){
            DeliveryOrderPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DeliveryOrderValidation: (config?: any) => `
        query DeliveryOrderValidation($orderIds: [String]){
            DeliveryOrderValidation(orderIds: $orderIds){
                ${parseFragmentConfig(`isValid`, config)}
            }
        }`,

  DeliveryOrderExecution: (config?: any) => `
        query DeliveryOrderExecution($orderIds: [String]){
            DeliveryOrderExecution(orderIds: $orderIds){
                ${parseFragmentConfig(
                  `groupID
                        depositPath
                        originId
                        originSapCode
                        destinyId
                        destinySapCode
                        destinyName
                        destinyActivitieSector
                        destinyDistributionChannel
                        destinySalesOrganization
                        itemCodeTM
                        itemDescription
                        qnt
                        executedQnt`,
                  config
                )}
            }
        }`,

  UserLogisticsPermissions: (config?: any) => `
        query UserLogisticsPermissions{
                ${parseFragmentConfig(`UserLogisticsPermissions`, config)}
        }`,

  MetadataFindOne: (config?: any) => `
        query MetadataFindOne($filter: FilterFindOneMetadataInput, $skip: Int, $sort: SortFindOneMetadataInput){
            MetadataFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        module
                        type
                        metadata
                        createdAt
                        updatedAt
                        createdById
                        updatedById
                        _id`,
                  config
                )}
            }
        }`,

  MetadataFindById: (config?: any) => `
        query MetadataFindById($_id: MongoID!){
            MetadataFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        module
                        type
                        metadata
                        createdAt
                        updatedAt
                        createdById
                        updatedById
                        _id`,
                  config
                )}
            }
        }`,

  PositivatorFindById: (config?: any) => `
        query PositivatorFindById($_id: MongoID!, $filter: filter){
            PositivatorFindById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(
                  `isActive
                        isSubcategoryActive
                        isPositivatorActive
                        isMandatory
                        category
                        subCategory
                        channels
                        cnpjs
                        title
                        image
                        initialDate
                        finalDate
                        frequency
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PositivatorFindMany: (config?: any) => `
        query PositivatorFindMany($filter: FilterFindManyPositivatorInput, $skip: Int, $limit: Int, $sort: SortFindManyPositivatorInput){
            PositivatorFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `isActive
                        isSubcategoryActive
                        isPositivatorActive
                        isMandatory
                        category
                        subCategory
                        channels
                        cnpjs
                        title
                        image
                        initialDate
                        finalDate
                        frequency
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PositivatorPagination: (config?: any) => `
        query PositivatorPagination($page: Int, $perPage: Int, $filter: FilterFindManyPositivatorInput, $sort: SortFindManyPositivatorInput){
            PositivatorPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlacePositivators: (config?: any) => `
        query PlacePositivators{
            PlacePositivators{
                ${parseFragmentConfig(`notPositivatedCount`, config)}
            }
        }`,

  PositivationSumary: (config?: any) => `
        query PositivationSumary($region: String, $type: String, $initialDate: String, $finishDate: String){
                ${parseFragmentConfig(
                  `PositivationSumary(region: $region, type: $type, initialDate: $initialDate, finishDate: $finishDate)`,
                  config
                )}
        }`,

  PlacePositivationPagination: (config?: any) => `
        query PlacePositivationPagination($page: Int, $perPage: Int, $limit: Int, $filter: JSON, $sort: String){
            PlacePositivationPagination(page: $page, perPage: $perPage, limit: $limit, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PositivationReportByGroup: (config?: any) => `
        query PositivationReportByGroup($initialDate: String, $finishDate: String){
            PositivationReportByGroup(initialDate: $initialDate, finishDate: $finishDate){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  PositivationReportByItem: (config?: any) => `
        query PositivationReportByItem($initialDate: String, $finishDate: String){
            PositivationReportByItem(initialDate: $initialDate, finishDate: $finishDate){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  PositivationReportByPlace: (config?: any) => `
        query PositivationReportByPlace($initialDate: String, $finishDate: String){
            PositivationReportByPlace(initialDate: $initialDate, finishDate: $finishDate){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  PositivatorPermissions: (config?: any) => `
        query PositivatorPermissions{
                ${parseFragmentConfig(`PositivatorPermissions`, config)}
        }`,

  PositivatorItemFindById: (config?: any) => `
        query PositivatorItemFindById($_id: MongoID!){
            PositivatorItemFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        isActive
                        isMandatory
                        category
                        subCategory
                        positivator
                        image
                        imageUpload
                        status
                        place
                        region
                        uf
                        channel
                        initialDate
                        finalDate
                        frequency
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PositivatorItemPagination: (config?: any) => `
        query PositivatorItemPagination($page: Int, $perPage: Int, $filter: FilterFindManyPositivatorItemInput, $sort: SortFindManyPositivatorItemInput){
            PositivatorItemPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PositivatorItemFindMany: (config?: any) => `
        query PositivatorItemFindMany($filter: FilterFindManyPositivatorItemInput, $skip: Int, $limit: Int, $sort: SortFindManyPositivatorItemInput){
            PositivatorItemFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `title
                        isActive
                        isMandatory
                        category
                        subCategory
                        positivator
                        image
                        imageUpload
                        status
                        place
                        region
                        uf
                        channel
                        initialDate
                        finalDate
                        frequency
                        isDeleted
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceNotificationPagination: (config?: any) => `
        query DeviceNotificationPagination($page: Int, $perPage: Int, $filter: FilterFindManyDeviceNotificationInput, $sort: SortFindManyDeviceNotificationInput){
            DeviceNotificationPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UserMerchanPagination: (config?: any) => `
        query UserMerchanPagination($page: Int, $perPage: Int, $limit: Int, $filter: FilterFindManyUserInput, $sort: SortFindManyUserInput){
            UserMerchanPagination(page: $page, perPage: $perPage, limit: $limit, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  UsersBaseDownload: (config?: any) => `
        query UsersBaseDownload($filters: JSON, $isExample: Boolean){
            UsersBaseDownload(filters: $filters, isExample: $isExample){
                ${parseFragmentConfig(
                  `schema
                        data`,
                  config
                )}
            }
        }`,

  MerchanUserCheck: (config?: any) => `
        query MerchanUserCheck($CPF: String, $CNPJ: String){
            MerchanUserCheck(CPF: $CPF, CNPJ: $CNPJ){
                ${parseFragmentConfig(
                  `active
                        firstAccess
                        tempPassword
                        blockedByAtempts
                        maskedEmail`,
                  config
                )}
            }
        }`,

  UserMerchanPermissions: (config?: any) => `
        query UserMerchanPermissions{
                ${parseFragmentConfig(`UserMerchanPermissions`, config)}
        }`,

  PlaceAnalyticsPagination: (config?: any) => `
        query PlaceAnalyticsPagination($page: Int, $perPage: Int, $filter: FilterFindManyPlaceAnalyticsInput, $sort: SortFindManyPlaceAnalyticsInput){
            PlaceAnalyticsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PlaceAnalyticsFindOne: (config?: any) => `
        query PlaceAnalyticsFindOne($filter: FilterFindOnePlaceAnalyticsInput, $skip: Int, $sort: SortFindOnePlaceAnalyticsInput){
            PlaceAnalyticsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        logType
                        platformName
                        type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PlaceAnalyticsFindByIds: (config?: any) => `
        query PlaceAnalyticsFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsPlaceAnalyticsInput){
            PlaceAnalyticsFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        logType
                        platformName
                        type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PlaceAnalyticsFindById: (config?: any) => `
        query PlaceAnalyticsFindById($_id: MongoID!){
            PlaceAnalyticsFindById(_id: $_id){
                ${parseFragmentConfig(
                  `userId
                        logType
                        platformName
                        type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  PlaceAnalyticsFindMany: (config?: any) => `
        query PlaceAnalyticsFindMany($filter: FilterFindManyPlaceAnalyticsInput, $skip: Int, $limit: Int, $sort: SortFindManyPlaceAnalyticsInput){
            PlaceAnalyticsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `userId
                        logType
                        platformName
                        type
                        disabled
                        CNPJ
                        inscricao_estadual
                        razao_social
                        nome_fantasia
                        custcode
                        codigo_sap
                        relatedTo
                        region
                        DDDs
                        modelo_de_loja
                        tipo_de_loja
                        tecnologia_de_rede
                        produtos_comercializados
                        foco_de_comunicacao
                        cluster
                        canal_distribuicao
                        organizacao_vendas
                        setor_atividade
                        quantidade_de_funcionarios
                        localizacao
                        nome_da_galeria
                        nome_do_shopping
                        parcelamento
                        inicio_da_operacao_mes
                        inicio_da_operacao_ano
                        ultima_modificacao
                        data_de_modificacao_mes
                        data_de_modificacao_ano
                        tipo_de_fachada
                        revestimento_externo
                        posicoes_de_atendimento
                        posicoes_de_pos_venda
                        sala_de_gerente
                        back_office
                        wifi_clientes
                        sinal_live
                        display_de_senha
                        sistema_de_som
                        adminId
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  StructuralFindMany: (config?: any) => `
        query StructuralFindMany($filter: FilterFindManyStructuralInput, $skip: Int, $limit: Int, $sort: SortFindManyStructuralInput){
            StructuralFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `cnpj
                        description
                        nome
                        region
                        nome_fantasia
                        address
                        tipo_modificacao
                        tipo_loja
                        canal
                        classificacao_pdv
                        ponto_de_cessao
                        loja_sap
                        patrimonio_tombado
                        reembolso_obra
                        local
                        ddd
                        uf
                        escritorio_vt
                        escritorio_resp
                        status
                        stage
                        projectSLA
                        stageDATA
                        isDeleted
                        _id
                        updatedAt
                        createdAt
                        history`,
                  config
                )}
            }
        }`,

  StructuralFindOne: (config?: any) => `
        query StructuralFindOne($filter: FilterFindOneStructuralInput, $skip: Int, $sort: SortFindOneStructuralInput){
            StructuralFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `cnpj
                        description
                        nome
                        region
                        nome_fantasia
                        address
                        tipo_modificacao
                        tipo_loja
                        canal
                        classificacao_pdv
                        ponto_de_cessao
                        loja_sap
                        patrimonio_tombado
                        reembolso_obra
                        local
                        ddd
                        uf
                        escritorio_vt
                        escritorio_resp
                        status
                        stage
                        projectSLA
                        stageDATA
                        isDeleted
                        _id
                        updatedAt
                        createdAt
                        history`,
                  config
                )}
            }
        }`,

  StructuralFindById: (config?: any) => `
        query StructuralFindById($_id: MongoID!, $filter: filter, $sort: SortSupplierEnum){
            StructuralFindById(_id: $_id, filter: $filter, sort: $sort){
                ${parseFragmentConfig(
                  `cnpj
                        description
                        nome
                        region
                        nome_fantasia
                        address
                        tipo_modificacao
                        tipo_loja
                        canal
                        classificacao_pdv
                        ponto_de_cessao
                        loja_sap
                        patrimonio_tombado
                        reembolso_obra
                        local
                        ddd
                        uf
                        escritorio_vt
                        escritorio_resp
                        status
                        stage
                        projectSLA
                        stageDATA
                        isDeleted
                        _id
                        updatedAt
                        createdAt
                        history`,
                  config
                )}
            }
        }`,

  StructuralPagination: (config?: any) => `
        query StructuralPagination($page: Int, $perPage: Int, $filter: FilterFindManyStructuralInput, $sort: SortFindManyStructuralInput){
            StructuralPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  Structural: (config?: any) => `
        query Structural{
            Structural{
                ${parseFragmentConfig(
                  `cnpj
                        description
                        nome
                        region
                        nome_fantasia
                        address
                        tipo_modificacao
                        tipo_loja
                        canal
                        classificacao_pdv
                        ponto_de_cessao
                        loja_sap
                        patrimonio_tombado
                        reembolso_obra
                        local
                        ddd
                        uf
                        escritorio_vt
                        escritorio_resp
                        status
                        stage
                        projectSLA
                        stageDATA
                        isDeleted
                        _id
                        updatedAt
                        createdAt
                        history`,
                  config
                )}
            }
        }`,

  StructuralPermissions: (config?: any) => `
        query StructuralPermissions{
                ${parseFragmentConfig(`StructuralPermissions`, config)}
        }`,

  ProjectSLA: (config?: any) => `
        query ProjectSLA($technicalVisit: Boolean, $projetoComplementar: Boolean){
            ProjectSLA(technicalVisit: $technicalVisit, projetoComplementar: $projetoComplementar){
                ${parseFragmentConfig(
                  `creation
                        aprovacaoProjeto
                        briefing
                        agendamentoLevantamentoCadastral
                        levantamentoCadastral
                        estudoLayout
                        projetoExecutivo
                        rrt
                        threeDS
                        projetoComplementar`,
                  config
                )}
            }
        }`,

  DigitalBrochureAnalyticsPagination: (config?: any) => `
        query DigitalBrochureAnalyticsPagination($page: Int, $perPage: Int, $filter: FilterFindManyDigitalBrochureAnalyticsInput, $sort: SortFindManyDigitalBrochureAnalyticsInput){
            DigitalBrochureAnalyticsPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  DigitalBrochureAnalyticsFindMany: (config?: any) => `
        query DigitalBrochureAnalyticsFindMany($filter: FilterFindManyDigitalBrochureAnalyticsInput, $skip: Int, $limit: Int, $sort: SortFindManyDigitalBrochureAnalyticsInput){
            DigitalBrochureAnalyticsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `ddd
                        service
                        visualizedBrochures
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureAnalyticsFindById: (config?: any) => `
        query DigitalBrochureAnalyticsFindById($_id: MongoID!){
            DigitalBrochureAnalyticsFindById(_id: $_id){
                ${parseFragmentConfig(
                  `ddd
                        service
                        visualizedBrochures
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureAnalyticsFindByIds: (config?: any) => `
        query DigitalBrochureAnalyticsFindByIds($_ids: [MongoID]!, $limit: Int, $sort: SortFindByIdsDigitalBrochureAnalyticsInput){
            DigitalBrochureAnalyticsFindByIds(_ids: $_ids, limit: $limit, sort: $sort){
                ${parseFragmentConfig(
                  `ddd
                        service
                        visualizedBrochures
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureAnalyticsFindOne: (config?: any) => `
        query DigitalBrochureAnalyticsFindOne($filter: FilterFindOneDigitalBrochureAnalyticsInput, $skip: Int, $sort: SortFindOneDigitalBrochureAnalyticsInput){
            DigitalBrochureAnalyticsFindOne(filter: $filter, skip: $skip, sort: $sort){
                ${parseFragmentConfig(
                  `ddd
                        service
                        visualizedBrochures
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DigitalBrochureAnalyticsPermissions: (config?: any) => `
        query DigitalBrochureAnalyticsPermissions{
                ${parseFragmentConfig(
                  `DigitalBrochureAnalyticsPermissions`,
                  config
                )}
        }`,

  DigitalBrochureAnalyticsCount: (config?: any) => `
        query DigitalBrochureAnalyticsCount($_id: String!){
                ${parseFragmentConfig(
                  `DigitalBrochureAnalyticsCount(_id: $_id)`,
                  config
                )}
        }`,

  AreaLojaPagination: (config?: any) => `
        query AreaLojaPagination($page: Int, $perPage: Int, $filter: FilterFindManyAreaLojaInput, $sort: SortFindManyAreaLojaInput){
            AreaLojaPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  RegraMapaPagination: (config?: any) => `
        query RegraMapaPagination($page: Int, $perPage: Int, $filter: FilterFindManyRegraMapaInput, $sort: SortFindManyRegraMapaInput){
            RegraMapaPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  FormatoPagination: (config?: any) => `
        query FormatoPagination($page: Int, $perPage: Int, $filter: FilterFindManyFormatoInput, $sort: SortFindManyFormatoInput){
            FormatoPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  PecaMapaPagination: (config?: any) => `
        query PecaMapaPagination($page: Int, $perPage: Int, $filter: FilterFindManyPecaMapaInput, $sort: SortFindManyPecaMapaInput){
            PecaMapaPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MapaPDVPagination: (config?: any) => `
        query MapaPDVPagination($page: Int, $perPage: Int, $filter: FilterFindManyMapaPDVInput, $sort: SortFindManyMapaPDVInput){
            MapaPDVPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  MapaPDVFindById: (config?: any) => `
        query MapaPDVFindById($_id: MongoID!){
            MapaPDVFindById(_id: $_id){
                ${parseFragmentConfig(
                  `title
                        description
                        obs
                        hasObs
                        clusters
                        filterState
                        hasPeriod
                        start
                        end
                        pecas
                        status
                        _id
                        updatedAt
                        createdAt
                        creationInfo`,
                  config
                )}
            }
        }`,

  MyMapaPDV: (config?: any) => `
        query MyMapaPDV{
                ${parseFragmentConfig(``, config)}
        }`,

  RegionRemoveOne: (config?: any) => `
        mutation RegionRemoveOne($filter: FilterRemoveOneRegionInput, $sort: SortRemoveOneRegionInput){
            RegionRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserContentPermissionToggle: (config?: any) => `
        mutation UserContentPermissionToggle($userId: String!, $roleGroups: [String]!, $regions: [String]!, $contentTypes: [String]!, $viewOtherUsersContent: Boolean){
                ${parseFragmentConfig(
                  `UserContentPermissionToggle(userId: $userId, roleGroups: $roleGroups, regions: $regions, contentTypes: $contentTypes, viewOtherUsersContent: $viewOtherUsersContent)`,
                  config
                )}
        }`,

  UserUpdateOne: (config?: any) => `
        mutation UserUpdateOne($record: UpdateOneUserInput!, $filter: FilterUpdateOneUserInput, $sort: SortUpdateOneUserInput, $skip: Int){
            UserUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserRemoveById: (config?: any) => `
        mutation UserRemoveById($_id: MongoID!){
            UserRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserAcceptTerm: (config?: any) => `
        mutation UserAcceptTerm($term: String!){
                ${parseFragmentConfig(`UserAcceptTerm(term: $term)`, config)}
        }`,

  UserLoginWithPassword: (config?: any) => `
        mutation UserLoginWithPassword($user: String!, $password: String!, $platformName: PlatformNamesEnum){
            UserLoginWithPassword(user: $user, password: $password, platformName: $platformName){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserLoginWithToken: (config?: any) => `
        mutation UserLoginWithToken($token: String!, $platformName: PlatformNamesEnum){
            UserLoginWithToken(token: $token, platformName: $platformName){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserLoginRenewToken: (config?: any) => `
        mutation UserLoginRenewToken($token: String!){
            UserLoginRenewToken(token: $token){
                ${parseFragmentConfig(
                  `email
                        loggedAt
                        disabled
                        disabledWithPoints
                        disabledAt
                        _id
                        updatedAt
                        createdAt
                        approverAtChannels
                        isApprover
                        allowedDDDs
                        userRoles
                        isContentTypeAdmin
                        channels
                        name
                        CPF
                        isTempCPF
                        CNPJ
                        avatarId
                        UF
                        region
                        regionPath
                        address
                        phone
                        matricula
                        emailSecondary
                        optIn
                        optInDate
                        viewOtherUsersContent
                        blockedByFailedAttempts
                        isBeta
                        failedAtempts
                        isTempEmail
                        roles
                        isTempPassword
                        blockedCommenting
                        failedAttempts`,
                  config
                )}
            }
        }`,

  UserSaveLogoutAnalytics: (config?: any) => `
        mutation UserSaveLogoutAnalytics{
                ${parseFragmentConfig(`UserSaveLogoutAnalytics`, config)}
        }`,

  UserSaveLoginAnalyticsPCS: (config?: any) => `
        mutation UserSaveLoginAnalyticsPCS{
                ${parseFragmentConfig(`UserSaveLoginAnalyticsPCS`, config)}
        }`,

  UserFillEmail: (config?: any) => `
        mutation UserFillEmail($email: String!){
                ${parseFragmentConfig(`UserFillEmail(email: $email)`, config)}
        }`,

  UserFillAvatarId: (config?: any) => `
        mutation UserFillAvatarId($avatarId: String!){
                ${parseFragmentConfig(
                  `UserFillAvatarId(avatarId: $avatarId)`,
                  config
                )}
        }`,

  UserOptIn: (config?: any) => `
        mutation UserOptIn($accept: Boolean!){
                ${parseFragmentConfig(`UserOptIn(accept: $accept)`, config)}
        }`,

  UserMarkNotificationAsRead: (config?: any) => `
        mutation UserMarkNotificationAsRead($isRead: Boolean!, $notifications: [String]!){
                ${parseFragmentConfig(
                  `UserMarkNotificationAsRead(isRead: $isRead, notifications: $notifications)`,
                  config
                )}
        }`,

  UserDeleteNotification: (config?: any) => `
        mutation UserDeleteNotification($notificationId: String!){
                ${parseFragmentConfig(
                  `UserDeleteNotification(notificationId: $notificationId)`,
                  config
                )}
        }`,

  UserDeleteManyNotification: (config?: any) => `
        mutation UserDeleteManyNotification($notificationIds: [String!]){
                ${parseFragmentConfig(
                  `UserDeleteManyNotification(notificationIds: $notificationIds)`,
                  config
                )}
        }`,

  UserRegistration: (config?: any) => `
        mutation UserRegistration($record: UserRegistrationTypeInput!){
            UserRegistration(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserBlockComments: (config?: any) => `
        mutation UserBlockComments($userId: String!, $isBlocked: Boolean, $materialId: String){
                ${parseFragmentConfig(
                  `UserBlockComments(userId: $userId, isBlocked: $isBlocked, materialId: $materialId)`,
                  config
                )}
        }`,

  ChangeToUserTaNarede: (config?: any) => `
        mutation ChangeToUserTaNarede{
            ChangeToUserTaNarede{
                ${parseFragmentConfig(
                  `token
                        name`,
                  config
                )}
            }
        }`,

  UserChangePassword: (config?: any) => `
        mutation UserChangePassword($newPassword: String!){
                ${parseFragmentConfig(
                  `UserChangePassword(newPassword: $newPassword)`,
                  config
                )}
        }`,

  UserResetPassword: (config?: any) => `
        mutation UserResetPassword($user: String!, $domain: String){
                ${parseFragmentConfig(
                  `UserResetPassword(user: $user, domain: $domain)`,
                  config
                )}
        }`,

  FileCreateOne: (config?: any) => `
        mutation FileCreateOne($record: CreateOneFileInput!){
            FileCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileRemoveById: (config?: any) => `
        mutation FileRemoveById($_id: MongoID!){
            FileRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FileUpdateById: (config?: any) => `
        mutation FileUpdateById($record: UpdateByIdFileInput!){
            FileUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialCreateOne: (config?: any) => `
        mutation MaterialCreateOne($record: CreateOneMaterialInput!, $filter: filter, $sort: sort){
            MaterialCreateOne(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateOne: (config?: any) => `
        mutation MaterialUpdateOne($record: UpdateOneMaterialInput!, $filter: FilterUpdateOneMaterialInput, $sort: SortUpdateOneMaterialInput, $skip: Int){
            MaterialUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialUpdateById: (config?: any) => `
        mutation MaterialUpdateById($record: UpdateByIdMaterialInput!, $filter: filter, $sort: sort){
            MaterialUpdateById(record: $record, filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MaterialRemoveOne: (config?: any) => `
        mutation MaterialRemoveOne($filter: FilterRemoveOneMaterialInput, $sort: SortRemoveOneMaterialInput){
            MaterialRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationCreateOne: (config?: any) => `
        mutation CommunicationCreateOne($record: CreateOneMaterialInput!){
            CommunicationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationUpdateOne: (config?: any) => `
        mutation CommunicationUpdateOne($communicationId: String!, $record: UpdateOneMaterialInput){
            CommunicationUpdateOne(communicationId: $communicationId, record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommunicationApproveChannel: (config?: any) => `
        mutation CommunicationApproveChannel($recordId: String!){
                ${parseFragmentConfig(
                  `CommunicationApproveChannel(recordId: $recordId)`,
                  config
                )}
        }`,

  CommunicationDisapproveChannel: (config?: any) => `
        mutation CommunicationDisapproveChannel($recordId: String!, $message: String!){
                ${parseFragmentConfig(
                  `CommunicationDisapproveChannel(recordId: $recordId, message: $message)`,
                  config
                )}
        }`,

  CommunicationRemoveApprover: (config?: any) => `
        mutation CommunicationRemoveApprover($communicationId: String!, $userId: String!){
                ${parseFragmentConfig(
                  `CommunicationRemoveApprover(communicationId: $communicationId, userId: $userId)`,
                  config
                )}
        }`,

  EmailFillTemplate: (config?: any) => `
        mutation EmailFillTemplate($template: EmailTemplateNames, $props: MaterialCommunicationItemsConfigTemplatePropsInput){
                ${parseFragmentConfig(
                  `EmailFillTemplate(template: $template, props: $props)`,
                  config
                )}
        }`,

  EmailMarkAsRead: (config?: any) => `
        mutation EmailMarkAsRead($token: String!){
                ${parseFragmentConfig(`EmailMarkAsRead(token: $token)`, config)}
        }`,

  SendEmailFront: (config?: any) => `
        mutation SendEmailFront($subject: String, $text: String, $attach: [String], $categories: [String]){
                ${parseFragmentConfig(
                  `SendEmailFront(subject: $subject, text: $text, attach: $attach, categories: $categories)`,
                  config
                )}
        }`,

  MaterialSendTestEmail: (config?: any) => `
        mutation MaterialSendTestEmail($recordId: String!){
                ${parseFragmentConfig(
                  `MaterialSendTestEmail(recordId: $recordId)`,
                  config
                )}
        }`,

  MaterialLikeToggle: (config?: any) => `
        mutation MaterialLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `MaterialLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  UpdateDeteachedAndLatestVideo: (config?: any) => `
        mutation UpdateDeteachedAndLatestVideo($idDetachedVideo: String, $idLatestVideo1: String, $idLatestVideo2: String, $idLatestVideo3: String){
                ${parseFragmentConfig(
                  `UpdateDeteachedAndLatestVideo(idDetachedVideo: $idDetachedVideo, idLatestVideo1: $idLatestVideo1, idLatestVideo2: $idLatestVideo2, idLatestVideo3: $idLatestVideo3)`,
                  config
                )}
        }`,

  NotificationCreateOne: (config?: any) => `
        mutation NotificationCreateOne($record: CreateOneNotificationInput!){
            NotificationCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationCreateMany: (config?: any) => `
        mutation NotificationCreateMany($records: [CreateManyNotificationInput!]!){
            NotificationCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  NotificationUpdateOne: (config?: any) => `
        mutation NotificationUpdateOne($record: UpdateOneNotificationInput!, $filter: FilterUpdateOneNotificationInput, $sort: SortUpdateOneNotificationInput, $skip: Int){
            NotificationUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  NotificationRemoveById: (config?: any) => `
        mutation NotificationRemoveById($_id: MongoID!){
            NotificationRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateOne: (config?: any) => `
        mutation CategoryCreateOne($record: CreateOneCategoryInput!, $filter: filter){
            CategoryCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryUpdateOne: (config?: any) => `
        mutation CategoryUpdateOne($record: UpdateOneCategoryInput!, $filter: FilterUpdateOneCategoryInput, $sort: SortUpdateOneCategoryInput, $skip: Int){
            CategoryUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryRemoveById: (config?: any) => `
        mutation CategoryRemoveById($_id: MongoID!, $filter: filter){
            CategoryRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CategoryCreateMany: (config?: any) => `
        mutation CategoryCreateMany($records: [CreateManyCategoryInput!]!, $filter: filter){
            CategoryCreateMany(records: $records, filter: $filter){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  WebPushSubscribe: (config?: any) => `
        mutation WebPushSubscribe($subscription: JSON!){
                ${parseFragmentConfig(
                  `WebPushSubscribe(subscription: $subscription)`,
                  config
                )}
        }`,

  WebPushSendNotification: (config?: any) => `
        mutation WebPushSendNotification($userIds: [String]!, $notificationOptions: JSON!, $sync: Boolean){
                ${parseFragmentConfig(
                  `WebPushSendNotification(userIds: $userIds, notificationOptions: $notificationOptions, sync: $sync)`,
                  config
                )}
        }`,

  PinCreateOne: (config?: any) => `
        mutation PinCreateOne($record: CreateOnePinInput!){
            PinCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveOne: (config?: any) => `
        mutation PinRemoveOne($filter: FilterRemoveOnePinInput, $sort: SortRemoveOnePinInput){
            PinRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PinRemoveMany: (config?: any) => `
        mutation PinRemoveMany($filter: FilterRemoveManyPinInput!){
            PinRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  ShareCreateOne: (config?: any) => `
        mutation ShareCreateOne($record: CreateOneShareInput!){
            ShareCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveOne: (config?: any) => `
        mutation ShareRemoveOne($filter: FilterRemoveOneShareInput, $sort: SortRemoveOneShareInput){
            ShareRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ShareRemoveMe: (config?: any) => `
        mutation ShareRemoveMe($shareId: String){
                ${parseFragmentConfig(
                  `ShareRemoveMe(shareId: $shareId)`,
                  config
                )}
        }`,

  CommentCreateOne: (config?: any) => `
        mutation CommentCreateOne($record: CreateOneCommentInput!){
            CommentCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentUpdateOne: (config?: any) => `
        mutation CommentUpdateOne($record: UpdateOneCommentInput!, $filter: FilterUpdateOneCommentInput, $sort: SortUpdateOneCommentInput, $skip: Int){
            CommentUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentRemoveById: (config?: any) => `
        mutation CommentRemoveById($_id: MongoID!){
            CommentRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CommentLikeToggle: (config?: any) => `
        mutation CommentLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `CommentLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  EventLiveCreateOne: (config?: any) => `
        mutation EventLiveCreateOne($record: CreateOneEventLiveInput!, $filter: filter){
            EventLiveCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveRemoveById: (config?: any) => `
        mutation EventLiveRemoveById($_id: MongoID!, $filter: filter){
            EventLiveRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveUpdateOne: (config?: any) => `
        mutation EventLiveUpdateOne($record: UpdateOneEventLiveInput!, $filter: FilterUpdateOneEventLiveInput, $sort: SortUpdateOneEventLiveInput, $skip: Int){
            EventLiveUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EventLiveCloseRoom: (config?: any) => `
        mutation EventLiveCloseRoom($eventId: String!){
                ${parseFragmentConfig(
                  `EventLiveCloseRoom(eventId: $eventId)`,
                  config
                )}
        }`,

  EventLiveLikeToggle: (config?: any) => `
        mutation EventLiveLikeToggle($recordId: String!){
                ${parseFragmentConfig(
                  `EventLiveLikeToggle(recordId: $recordId)`,
                  config
                )}
        }`,

  BannerRemoveById: (config?: any) => `
        mutation BannerRemoveById($_id: MongoID!, $filter: filter){
            BannerRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerCreateOne: (config?: any) => `
        mutation BannerCreateOne($record: CreateOneBannerInput!, $filter: filter){
            BannerCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BannerUpdateOne: (config?: any) => `
        mutation BannerUpdateOne($record: UpdateOneBannerInput!, $filter: FilterUpdateOneBannerInput, $sort: SortUpdateOneBannerInput, $skip: Int){
            BannerUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AnalyticsPageView: (config?: any) => `
        mutation AnalyticsPageView($title: String!, $host: String, $route: String){
                ${parseFragmentConfig(
                  `AnalyticsPageView(title: $title, host: $host, route: $route)`,
                  config
                )}
        }`,

  AnalyticsTrackEvent: (config?: any) => `
        mutation AnalyticsTrackEvent($event: EnumAnalyticsEvent!, $kind: EnumAnalyticsParentKind!, $recordId: String, $entityName: String, $label: String){
                ${parseFragmentConfig(
                  `AnalyticsTrackEvent(event: $event, kind: $kind, recordId: $recordId, entityName: $entityName, label: $label)`,
                  config
                )}
        }`,

  FolderCreateOne: (config?: any) => `
        mutation FolderCreateOne($record: CreateOneFolderInput!){
            FolderCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveOne: (config?: any) => `
        mutation FolderRemoveOne($filter: FilterRemoveOneFolderInput, $sort: SortRemoveOneFolderInput){
            FolderRemoveOne(filter: $filter, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FolderRemoveMany: (config?: any) => `
        mutation FolderRemoveMany($filter: FilterRemoveManyFolderInput!){
            FolderRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  FolderUpdateOne: (config?: any) => `
        mutation FolderUpdateOne($record: UpdateOneFolderInput!, $filter: FilterUpdateOneFolderInput, $sort: SortUpdateOneFolderInput, $skip: Int){
            FolderUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RecentsActivitiesCreateOne: (config?: any) => `
        mutation RecentsActivitiesCreateOne($record: CreateOneRecentsActivitiesInput!){
            RecentsActivitiesCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserWordsBlackListCreateOne: (config?: any) => `
        mutation UserWordsBlackListCreateOne($record: CreateOneUserWordsBlackListInput!){
            UserWordsBlackListCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanCreateOne: (config?: any) => `
        mutation ImportUsersPlanCreateOne($record: CreateOneImportUsersPlanInput!, $filter: filter){
            ImportUsersPlanCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ImportUsersPlanUpdateOne: (config?: any) => `
        mutation ImportUsersPlanUpdateOne($record: UpdateOneImportUsersPlanInput!, $filter: FilterUpdateOneImportUsersPlanInput, $sort: SortUpdateOneImportUsersPlanInput, $skip: Int){
            ImportUsersPlanUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  UserHistoryBlockCreateOne: (config?: any) => `
        mutation UserHistoryBlockCreateOne($record: CreateOneUserHistoryBlockInput!, $sort: sort){
            UserHistoryBlockCreateOne(record: $record, sort: $sort){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailStatsCreateMany: (config?: any) => `
        mutation EmailStatsCreateMany($records: [CreateManyEmailStatsInput!]!){
            EmailStatsCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  EmailStatsCreateOne: (config?: any) => `
        mutation EmailStatsCreateOne($record: CreateOneEmailStatsInput!){
            EmailStatsCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  EmailUnsubscribeCreateMany: (config?: any) => `
        mutation EmailUnsubscribeCreateMany($records: [CreateManyEmailUnsubscribeInput!]!){
            EmailUnsubscribeCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  EmailUnsubscribeCreateOne: (config?: any) => `
        mutation EmailUnsubscribeCreateOne($record: CreateOneEmailUnsubscribeInput!){
            EmailUnsubscribeCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  HomebannerCreateOne: (config?: any) => `
        mutation HomebannerCreateOne($record: CreateOneHomebannerInput!){
            HomebannerCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  HomebannerUpdateById: (config?: any) => `
        mutation HomebannerUpdateById($record: UpdateByIdHomebannerInput!){
            HomebannerUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PlaceRemoveById: (config?: any) => `
        mutation PlaceRemoveById($_id: MongoID!, $filter: filter){
            PlaceRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PlaceCreateOne: (config?: any) => `
        mutation PlaceCreateOne($record: CreateOnePlaceInput!, $filter: filter){
            PlaceCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PlaceUpdateById: (config?: any) => `
        mutation PlaceUpdateById($record: UpdateByIdPlaceInput!, $filter: filter){
            PlaceUpdateById(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PlaceUpdateOne: (config?: any) => `
        mutation PlaceUpdateOne($record: UpdateOnePlaceInput!, $filter: FilterUpdateOnePlaceInput, $sort: SortUpdateOnePlaceInput, $skip: Int){
            PlaceUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PlacesBaseUpload: (config?: any) => `
        mutation PlacesBaseUpload($fileId: String!, $operations: [String!]!){
                ${parseFragmentConfig(
                  `PlacesBaseUpload(fileId: $fileId, operations: $operations)`,
                  config
                )}
        }`,

  ContractCreateOne: (config?: any) => `
        mutation ContractCreateOne($record: CreateOneContractInput!){
            ContractCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ContractCreateMany: (config?: any) => `
        mutation ContractCreateMany($records: [CreateManyContractInput!]!){
            ContractCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  ContractUpdateOne: (config?: any) => `
        mutation ContractUpdateOne($record: UpdateOneContractInput!, $filter: FilterUpdateOneContractInput, $sort: SortUpdateOneContractInput, $skip: Int){
            ContractUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ContractUpdateById: (config?: any) => `
        mutation ContractUpdateById($record: UpdateByIdContractInput!){
            ContractUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ContractRemoveById: (config?: any) => `
        mutation ContractRemoveById($_id: MongoID!){
            ContractRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductCreateOne: (config?: any) => `
        mutation ProductCreateOne($record: CreateOneProductInput!){
            ProductCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductUpdateOne: (config?: any) => `
        mutation ProductUpdateOne($record: UpdateOneProductInput!, $filter: FilterUpdateOneProductInput, $sort: SortUpdateOneProductInput, $skip: Int){
            ProductUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductCreateMany: (config?: any) => `
        mutation ProductCreateMany($records: [CreateManyProductInput!]!){
            ProductCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  ProductUpdateById: (config?: any) => `
        mutation ProductUpdateById($record: UpdateByIdProductInput!){
            ProductUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductRemoveById: (config?: any) => `
        mutation ProductRemoveById($_id: MongoID!){
            ProductRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductBaseUpload: (config?: any) => `
        mutation ProductBaseUpload($fileId: String!, $operations: [String!]!){
                ${parseFragmentConfig(
                  `ProductBaseUpload(fileId: $fileId, operations: $operations)`,
                  config
                )}
        }`,

  SupplierCreateOne: (config?: any) => `
        mutation SupplierCreateOne($record: CreateOneSupplierInput!, $filter: filter){
            SupplierCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SupplierUpdateOne: (config?: any) => `
        mutation SupplierUpdateOne($record: UpdateOneSupplierInput!, $filter: FilterUpdateOneSupplierInput, $sort: SortUpdateOneSupplierInput, $skip: Int){
            SupplierUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SupplierUpdateById: (config?: any) => `
        mutation SupplierUpdateById($record: UpdateByIdSupplierInput!, $filter: filter){
            SupplierUpdateById(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SupplierRemoveById: (config?: any) => `
        mutation SupplierRemoveById($_id: MongoID!, $filter: filter){
            SupplierRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  TablePriceCreateOne: (config?: any) => `
        mutation TablePriceCreateOne($record: CreateOneTablePriceInput!){
            TablePriceCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  TablePriceUpdateOne: (config?: any) => `
        mutation TablePriceUpdateOne($record: UpdateOneTablePriceInput!, $filter: FilterUpdateOneTablePriceInput, $sort: SortUpdateOneTablePriceInput, $skip: Int){
            TablePriceUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  TablePriceRemoveById: (config?: any) => `
        mutation TablePriceRemoveById($_id: MongoID!){
            TablePriceRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PricingTableCreateOne: (config?: any) => `
        mutation PricingTableCreateOne($record: CreateOnePricingTableInput!){
            PricingTableCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PricingTableRemoveById: (config?: any) => `
        mutation PricingTableRemoveById($_id: MongoID!){
            PricingTableRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PricingTableUpdateById: (config?: any) => `
        mutation PricingTableUpdateById($record: UpdateByIdPricingTableInput!){
            PricingTableUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LegalTextCreateOne: (config?: any) => `
        mutation LegalTextCreateOne($record: CreateOneLegalTextInput!){
            LegalTextCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LegalTextUpdateById: (config?: any) => `
        mutation LegalTextUpdateById($record: UpdateByIdLegalTextInput!){
            LegalTextUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LegalTextRemoveById: (config?: any) => `
        mutation LegalTextRemoveById($_id: MongoID!){
            LegalTextRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeviceCreateOne: (config?: any) => `
        mutation DeviceCreateOne($record: CreateOneDeviceInput!, $filter: filter){
            DeviceCreateOne(record: $record, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeviceUpdateOne: (config?: any) => `
        mutation DeviceUpdateOne($record: UpdateOneDeviceInput!, $filter: FilterUpdateOneDeviceInput, $sort: SortUpdateOneDeviceInput, $skip: Int){
            DeviceUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeviceRemoveById: (config?: any) => `
        mutation DeviceRemoveById($_id: MongoID!, $filter: filter){
            DeviceRemoveById(_id: $_id, filter: $filter){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeleteDevice: (config?: any) => `
        mutation DeleteDevice($recordId: String!){
            DeleteDevice(recordId: $recordId){
                ${parseFragmentConfig(
                  `manufacturer
                        model
                        inclusion
                        plan
                        pricingTable
                        featured
                        comments
                        chip
                        nfc
                        offer_date
                        launch_date
                        codeProvider
                        codeTim
                        releaseDateOf
                        availableColors
                        eSim
                        dualSim
                        connectionType5G
                        ram
                        internalMemory
                        capacity
                        windowSize
                        windowResolution
                        cameraMegaPixels
                        cameraFrontal
                        images
                        legalText
                        status
                        type
                        devicePlan
                        price
                        flags
                        operationalSystem
                        width
                        height
                        depth
                        weight
                        connectionType
                        processor
                        expandableMemory
                        pixelDensity
                        colors
                        cameraResolution
                        cameraResolutionBy
                        cameraOpeningCapacity
                        cameraStabilization
                        cameraAutofocus
                        cameraOpticalZoom
                        cameraDigitalZoom
                        cameraFlash
                        cameraHdr
                        cameraLocation
                        cameraFacialDetection
                        videoResolution
                        videoStabilization
                        videoAutofocus
                        videoSlowMotion
                        videoHdr
                        gps
                        compass
                        biometric
                        radio
                        tv
                        waterproof
                        coolText
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceBaseUpload: (config?: any) => `
        mutation DeviceBaseUpload($fileId: String!){
                ${parseFragmentConfig(
                  `DeviceBaseUpload(fileId: $fileId)`,
                  config
                )}
        }`,

  DigitalBrochureCreateOne: (config?: any) => `
        mutation DigitalBrochureCreateOne($record: CreateOneDigitalBrochureInput!){
            DigitalBrochureCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DigitalBrochureUpdateOne: (config?: any) => `
        mutation DigitalBrochureUpdateOne($record: UpdateOneDigitalBrochureInput!, $filter: FilterUpdateOneDigitalBrochureInput, $sort: SortUpdateOneDigitalBrochureInput, $skip: Int){
            DigitalBrochureUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DigitalBrochureRemoveById: (config?: any) => `
        mutation DigitalBrochureRemoveById($_id: MongoID!){
            DigitalBrochureRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BrochureUpdateThumbnail: (config?: any) => `
        mutation BrochureUpdateThumbnail($filter: FilterFindManyDigitalBrochureInput!, $density: Int){
            BrochureUpdateThumbnail(filter: $filter, density: $density){
                ${parseFragmentConfig(
                  `url
                        error
                        _id`,
                  config
                )}
            }
        }`,

  CampaignCreateOne: (config?: any) => `
        mutation CampaignCreateOne($record: CreateOneCampaignInput!){
            CampaignCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CampaignUpdateOne: (config?: any) => `
        mutation CampaignUpdateOne($record: UpdateOneCampaignInput!, $filter: FilterUpdateOneCampaignInput, $sort: SortUpdateOneCampaignInput, $skip: Int){
            CampaignUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  CampaignRemoveById: (config?: any) => `
        mutation CampaignRemoveById($_id: MongoID!){
            CampaignRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LayoutCreateOne: (config?: any) => `
        mutation LayoutCreateOne($record: CreateOneLayoutInput!){
            LayoutCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LayoutCreateMany: (config?: any) => `
        mutation LayoutCreateMany($records: [CreateManyLayoutInput!]!){
            LayoutCreateMany(records: $records){
                ${parseFragmentConfig(
                  `recordIds
                        createCount`,
                  config
                )}
            }
        }`,

  LayoutUpdateOne: (config?: any) => `
        mutation LayoutUpdateOne($record: UpdateOneLayoutInput!, $filter: FilterUpdateOneLayoutInput, $sort: SortUpdateOneLayoutInput, $skip: Int){
            LayoutUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LayoutUpdateById: (config?: any) => `
        mutation LayoutUpdateById($record: UpdateByIdLayoutInput!){
            LayoutUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LayoutRemoveById: (config?: any) => `
        mutation LayoutRemoveById($_id: MongoID!){
            LayoutRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  LayoutAssociateCodeTM: (config?: any) => `
        mutation LayoutAssociateCodeTM($items: [LayoutAssociateCodeItemInput]){
                ${parseFragmentConfig(
                  `LayoutAssociateCodeTM(items: $items)`,
                  config
                )}
        }`,

  RegionalBudgetMarkAsViewed: (config?: any) => `
        mutation RegionalBudgetMarkAsViewed($regionalBudgetId: String!){
                ${parseFragmentConfig(
                  `RegionalBudgetMarkAsViewed(regionalBudgetId: $regionalBudgetId)`,
                  config
                )}
        }`,

  BudgetMarkAsFinished: (config?: any) => `
        mutation BudgetMarkAsFinished($budgetId: String!){
                ${parseFragmentConfig(
                  `BudgetMarkAsFinished(budgetId: $budgetId)`,
                  config
                )}
        }`,

  RegionalBudgetParseItems: (config?: any) => `
        mutation RegionalBudgetParseItems($regionalBudgetId: String!, $parentBudgetId: String!, $items: [ProductionParsedItemsInput]){
            RegionalBudgetParseItems(regionalBudgetId: $regionalBudgetId, parentBudgetId: $parentBudgetId, items: $items){
                ${parseFragmentConfig(
                  `count
                        regionalUsedAmount`,
                  config
                )}
            }
        }`,

  RegionalBudgetUpdateProductionItems: (config?: any) => `
        mutation RegionalBudgetUpdateProductionItems($regionalBudgetId: String!, $productionItems: [ProductionItemInput]){
            RegionalBudgetUpdateProductionItems(regionalBudgetId: $regionalBudgetId, productionItems: $productionItems){
                ${parseFragmentConfig(
                  `parentBudgetId
                        region
                        deadline
                        maxValue
                        usedValue
                        remainingValue
                        availableAtChannels
                        filledByRegional
                        status
                        _id
                        updatedAt
                        createdAt
                        deadlineFormatted`,
                  config
                )}
            }
        }`,

  ProductionCreateOne: (config?: any) => `
        mutation ProductionCreateOne($record: CreateOneProductionInput!){
            ProductionCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductionUpdateById: (config?: any) => `
        mutation ProductionUpdateById($record: UpdateByIdProductionInput!){
            ProductionUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductionRemoveById: (config?: any) => `
        mutation ProductionRemoveById($_id: MongoID!){
            ProductionRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  ProductionArchiveItem: (config?: any) => `
        mutation ProductionArchiveItem($recordId: String!, $archive: Boolean){
            ProductionArchiveItem(recordId: $recordId, archive: $archive){
                ${parseFragmentConfig(
                  `code
                        title
                        originBudgetId
                        status
                        owner
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  ProductionParseItems: (config?: any) => `
        mutation ProductionParseItems($items: [ProductionParsedItemsInput]){
            ProductionParseItems(items: $items){
                ${parseFragmentConfig(`count`, config)}
            }
        }`,

  BudgetCreateOne: (config?: any) => `
        mutation BudgetCreateOne($record: CreateOneBudgetInput!){
            BudgetCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BudgetUpdateById: (config?: any) => `
        mutation BudgetUpdateById($record: UpdateByIdBudgetInput!){
            BudgetUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  BudgetRemoveById: (config?: any) => `
        mutation BudgetRemoveById($_id: MongoID!){
            BudgetRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  SupplierDeliveryUpdateStatus: (config?: any) => `
        mutation SupplierDeliveryUpdateStatus($productionId: String!, $records: [SupplierDeliveryUpdateStatusInput]!){
                ${parseFragmentConfig(
                  `SupplierDeliveryUpdateStatus(productionId: $productionId, records: $records)`,
                  config
                )}
        }`,

  InventorySync: (config?: any) => `
        mutation InventorySync($depositPaths: [String]!, $records: [InventorySyncProduct]!){
            InventorySync(depositPaths: $depositPaths, records: $records){
                ${parseFragmentConfig(
                  `layoutCodeTM
                        quantity
                        locationSAPCode
                        depositNumber
                        depositPath
                        region
                        descriptionTM
                        uploadedAt
                        originData
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  InventoryWithDrawCreateOne: (config?: any) => `
        mutation InventoryWithDrawCreateOne($record: CreateOneInventoryWithDrawInput!){
            InventoryWithDrawCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  InventoryCreateWithDraw: (config?: any) => `
        mutation InventoryCreateWithDraw($records: [CreateOneInventoryWithDrawInput]){
            InventoryCreateWithDraw(records: $records){
                ${parseFragmentConfig(
                  `author
                        gradeMode
                        productionId
                        depositPath
                        quantityInStock
                        destinationPlaceSAPCode
                        layoutCodeTM
                        quantity
                        deliveryPlaceSAPCode
                        depositNumber
                        salesOrganization
                        distributionChannel
                        activitiesSector
                        productionCode
                        commercialOpStatus
                        commercialOpReason
                        orderZMP
                        processingDate
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeliveryOrderCreateOne: (config?: any) => `
        mutation DeliveryOrderCreateOne($record: CreateOneDeliveryOrderInput!){
            DeliveryOrderCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeliveryOrderUpdateById: (config?: any) => `
        mutation DeliveryOrderUpdateById($record: UpdateByIdDeliveryOrderInput!){
            DeliveryOrderUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeliveryOrderRemoveById: (config?: any) => `
        mutation DeliveryOrderRemoveById($_id: MongoID!){
            DeliveryOrderRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MetadataCreateOne: (config?: any) => `
        mutation MetadataCreateOne($record: CreateOneMetadataInput!){
            MetadataCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MetadataUpdateOne: (config?: any) => `
        mutation MetadataUpdateOne($record: UpdateOneMetadataInput!, $filter: FilterUpdateOneMetadataInput, $sort: SortUpdateOneMetadataInput, $skip: Int){
            MetadataUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MetadataRemoveById: (config?: any) => `
        mutation MetadataRemoveById($_id: MongoID!){
            MetadataRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MetadataUpdateById: (config?: any) => `
        mutation MetadataUpdateById($record: UpdateByIdMetadataInput!){
            MetadataUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorCreateOne: (config?: any) => `
        mutation PositivatorCreateOne($record: CreateOnePositivatorInput!){
            PositivatorCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorUpdateOne: (config?: any) => `
        mutation PositivatorUpdateOne($record: UpdateOnePositivatorInput!, $filter: FilterUpdateOnePositivatorInput, $sort: SortUpdateOnePositivatorInput, $skip: Int){
            PositivatorUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorUpdateMany: (config?: any) => `
        mutation PositivatorUpdateMany($record: UpdateManyPositivatorInput!, $filter: FilterUpdateManyPositivatorInput, $sort: SortUpdateManyPositivatorInput, $skip: Int, $limit: Int){
            PositivatorUpdateMany(record: $record, filter: $filter, sort: $sort, skip: $skip, limit: $limit){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  PositivatorRemoveById: (config?: any) => `
        mutation PositivatorRemoveById($_id: MongoID!){
            PositivatorRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorRemoveMany: (config?: any) => `
        mutation PositivatorRemoveMany($filter: FilterRemoveManyPositivatorInput!){
            PositivatorRemoveMany(filter: $filter){
                ${parseFragmentConfig(`numAffected`, config)}
            }
        }`,

  PositivatorNotificationCreateOne: (config?: any) => `
        mutation PositivatorNotificationCreateOne($positivedPercentageInterval: PositivatorNumberIntervalInput, $regionItems: [String]!, $channels: [EnumPlaceType]!, $text: String!, $schedule: Date!){
            PositivatorNotificationCreateOne(positivedPercentageInterval: $positivedPercentageInterval, regionItems: $regionItems, channels: $channels, text: $text, schedule: $schedule){
                ${parseFragmentConfig(
                  `kind
                        parentId
                        title
                        body
                        iconUrl
                        fileIds
                        redirectionLink
                        sent
                        sentAt
                        schedule
                        clientData
                        _id
                        createdAt
                        wasRead`,
                  config
                )}
            }
        }`,

  PositivatorItemCreateOne: (config?: any) => `
        mutation PositivatorItemCreateOne($record: CreateOnePositivatorItemInput!){
            PositivatorItemCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorItemUpdateById: (config?: any) => `
        mutation PositivatorItemUpdateById($record: UpdateByIdPositivatorItemInput!){
            PositivatorItemUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PositivatorItemRemoveById: (config?: any) => `
        mutation PositivatorItemRemoveById($_id: MongoID!){
            PositivatorItemRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  DeviceNotificationMarkAsRead: (config?: any) => `
        mutation DeviceNotificationMarkAsRead($_id: String!){
            DeviceNotificationMarkAsRead(_id: $_id){
                ${parseFragmentConfig(
                  `codeProvider
                        model
                        notifiedEmails
                        notifiedUserIds
                        solved
                        name
                        CNPJ
                        sapCode
                        httpRequestDeviceInfo
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  DeviceNotificationMarkAsSolved: (config?: any) => `
        mutation DeviceNotificationMarkAsSolved($notificationId: String!, $devicesIds: [String!]!){
            DeviceNotificationMarkAsSolved(notificationId: $notificationId, devicesIds: $devicesIds){
                ${parseFragmentConfig(
                  `codeProvider
                        model
                        notifiedEmails
                        notifiedUserIds
                        solved
                        name
                        CNPJ
                        sapCode
                        httpRequestDeviceInfo
                        _id
                        updatedAt
                        createdAt`,
                  config
                )}
            }
        }`,

  MerchanRequestTempPassword: (config?: any) => `
        mutation MerchanRequestTempPassword($email: String!, $CPF: String, $CNPJ: String){
                ${parseFragmentConfig(
                  `MerchanRequestTempPassword(email: $email, CPF: $CPF, CNPJ: $CNPJ)`,
                  config
                )}
        }`,

  MerchanVerifyTempCode: (config?: any) => `
        mutation MerchanVerifyTempCode($code: String!, $CPF: String, $CNPJ: String){
                ${parseFragmentConfig(
                  `MerchanVerifyTempCode(code: $code, CPF: $CPF, CNPJ: $CNPJ)`,
                  config
                )}
        }`,

  MerchanChangePassword: (config?: any) => `
        mutation MerchanChangePassword($CPF: String, $CNPJ: String, $oldPassword: String!, $newPassword: String!){
                ${parseFragmentConfig(
                  `MerchanChangePassword(CPF: $CPF, CNPJ: $CNPJ, oldPassword: $oldPassword, newPassword: $newPassword)`,
                  config
                )}
        }`,

  StructuralCreateOne: (config?: any) => `
        mutation StructuralCreateOne($record: CreateOneStructuralInput!){
            StructuralCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  StructuralUpdateOne: (config?: any) => `
        mutation StructuralUpdateOne($record: UpdateOneStructuralInput!, $filter: FilterUpdateOneStructuralInput, $sort: SortUpdateOneStructuralInput, $skip: Int){
            StructuralUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  StructuralRemoveById: (config?: any) => `
        mutation StructuralRemoveById($_id: MongoID!){
            StructuralRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  StructuralUpdateById: (config?: any) => `
        mutation StructuralUpdateById($record: UpdateByIdStructuralInput!){
            StructuralUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  StructuralEditStatus: (config?: any) => `
        mutation StructuralEditStatus($projectId: String, $stage: EnumStructuralStage, $status: ProjectStageStatus, $data: JSON, $isComment: Boolean, $isFile: Boolean){
            StructuralEditStatus(projectId: $projectId, stage: $stage, status: $status, data: $data, isComment: $isComment, isFile: $isFile){
                ${parseFragmentConfig(`stageDATA`, config)}
            }
        }`,

  DigitalBrochureAnalyticsCreateOne: (config?: any) => `
        mutation DigitalBrochureAnalyticsCreateOne($record: CreateOneDigitalBrochureAnalyticsInput!){
            DigitalBrochureAnalyticsCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AreaLojaCreateOne: (config?: any) => `
        mutation AreaLojaCreateOne($record: CreateOneAreaLojaInput!){
            AreaLojaCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AreaLojaUpdateById: (config?: any) => `
        mutation AreaLojaUpdateById($record: UpdateByIdAreaLojaInput!){
            AreaLojaUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  AreaLojaRemoveById: (config?: any) => `
        mutation AreaLojaRemoveById($_id: MongoID!){
            AreaLojaRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RegraMapaCreateOne: (config?: any) => `
        mutation RegraMapaCreateOne($record: CreateOneRegraMapaInput!){
            RegraMapaCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RegraMapaUpdateById: (config?: any) => `
        mutation RegraMapaUpdateById($record: UpdateByIdRegraMapaInput!){
            RegraMapaUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  RegraMapaRemoveById: (config?: any) => `
        mutation RegraMapaRemoveById($_id: MongoID!){
            RegraMapaRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FormatoCreateOne: (config?: any) => `
        mutation FormatoCreateOne($record: CreateOneFormatoInput!){
            FormatoCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FormatoUpdateById: (config?: any) => `
        mutation FormatoUpdateById($record: UpdateByIdFormatoInput!){
            FormatoUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  FormatoRemoveById: (config?: any) => `
        mutation FormatoRemoveById($_id: MongoID!){
            FormatoRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PecaMapaCreateOne: (config?: any) => `
        mutation PecaMapaCreateOne($record: CreateOnePecaMapaInput!){
            PecaMapaCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PecaMapaUpdateById: (config?: any) => `
        mutation PecaMapaUpdateById($record: UpdateByIdPecaMapaInput!){
            PecaMapaUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PecaMapaRemoveById: (config?: any) => `
        mutation PecaMapaRemoveById($_id: MongoID!){
            PecaMapaRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  PecaMapaAddMultiple: (config?: any) => `
        mutation PecaMapaAddMultiple($pecas: [JSON]){
                ${parseFragmentConfig(
                  `PecaMapaAddMultiple(pecas: $pecas)`,
                  config
                )}
        }`,

  MapaPDVCreateOne: (config?: any) => `
        mutation MapaPDVCreateOne($record: CreateOneMapaPDVInput!){
            MapaPDVCreateOne(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MapaPDVUpdateById: (config?: any) => `
        mutation MapaPDVUpdateById($record: UpdateByIdMapaPDVInput!){
            MapaPDVUpdateById(record: $record){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`,

  MapaPDVRemoveById: (config?: any) => `
        mutation MapaPDVRemoveById($_id: MongoID!){
            MapaPDVRemoveById(_id: $_id){
                ${parseFragmentConfig(`recordId`, config)}
            }
        }`
};

export const AppMethods: Methods = {
  RegionFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindMany',
      query: query.RegionFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Region',
      schemaKey: 'RegionFindOne',
      query: query.RegionFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionPagination',
      schemaKey: 'RegionPagination',
      query: query.RegionPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindMany',
      query: query.RegionItemFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItem',
      schemaKey: 'RegionItemFindOne',
      query: query.RegionItemFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionItemPagination',
      schemaKey: 'RegionItemPagination',
      query: query.RegionItemPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionItemDistinctDDD: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctDDD',
        schemaKey: 'RegionItemDistinctDDD',
        query: query.RegionItemDistinctDDD(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  RegionItemDistinctRegionAndUF: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'RegionItemDistinctRegionAndUF',
        schemaKey: 'RegionItemDistinctRegionAndUF',
        query: query.RegionItemDistinctRegionAndUF(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  JobTitleFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindOne',
      query: query.JobTitleFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  JobTitleFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JobTitle',
      schemaKey: 'JobTitleFindMany',
      query: query.JobTitleFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindOne',
      query: query.RoleGroupFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroup',
      schemaKey: 'RoleGroupFindMany',
      query: query.RoleGroupFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  RoleGroupPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RoleGroupPagination',
      schemaKey: 'RoleGroupPagination',
      query: query.RoleGroupPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindMany',
      query: query.ChannelFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Channel',
      schemaKey: 'ChannelFindOne',
      query: query.ChannelFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  ChannelPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ChannelPagination',
      schemaKey: 'ChannelPagination',
      query: query.ChannelPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserContentPermissionFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserContentPermission',
      schemaKey: 'UserContentPermissionFindOne',
      query: query.UserContentPermissionFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  ContentPermissionsHelper: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ContentPermissionsHelperOption',
        schemaKey: 'ContentPermissionsHelper',
        query: query.ContentPermissionsHelper(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  UserPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserPagination',
      query: query.UserPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByIds',
      query: query.UserFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindById',
      query: query.UserFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindOne',
      query: query.UserFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindMany',
      query: query.UserFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  User: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'User',
        schemaKey: 'User',
        query: query.User(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  UserSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSearch',
      schemaKey: 'UserSearch',
      query: query.UserSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserSearchPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserPagination',
      schemaKey: 'UserSearchPagination',
      query: query.UserSearchPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  TextFindBlackList: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TextFindBlackList',
      schemaKey: 'TextFindBlackList',
      query: query.TextFindBlackList(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByEmails: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserSimpleResult',
      schemaKey: 'UserFindByEmails',
      query: query.UserFindByEmails(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserFindByMatricula: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserFindByMatricula',
      query: query.UserFindByMatricula(config),
      kind: OpKind.query,
      ...config
    });
  },

  FileFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindByIds',
      query: query.FileFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  FileFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'File',
      schemaKey: 'FileFindById',
      query: query.FileFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  FilePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FilePagination',
      schemaKey: 'FilePagination',
      query: query.FilePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindById',
      query: query.MaterialFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindMany',
      query: query.MaterialFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Material',
      schemaKey: 'MaterialFindOne',
      query: query.MaterialFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialPagination',
      query: query.MaterialPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'MaterialCount',
      query: query.MaterialCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialStatusOptions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MaterialStatusOption',
        schemaKey: 'MaterialStatusOptions',
        query: query.MaterialStatusOptions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  MaterialSearchOwners: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearchOwners',
      schemaKey: 'MaterialSearchOwners',
      query: query.MaterialSearchOwners(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindMany',
      query: query.EmailFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailPagination',
      schemaKey: 'EmailPagination',
      query: query.EmailPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Email',
      schemaKey: 'EmailFindOne',
      query: query.EmailFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailCheckRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailCheckRead',
      query: query.EmailCheckRead(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialSearch',
      schemaKey: 'MaterialSearch',
      query: query.MaterialSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  MaterialFindPinned: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MaterialPagination',
      schemaKey: 'MaterialFindPinned',
      query: query.MaterialFindPinned(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindMany',
      query: query.NotificationFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'NotificationFindOne',
      query: query.NotificationFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  NotificationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'NotificationPagination',
      schemaKey: 'NotificationPagination',
      query: query.NotificationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  HelperAddress: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AddressHelperPayload',
      schemaKey: 'HelperAddress',
      query: query.HelperAddress(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindOne',
      query: query.CategoryFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindById',
      query: query.CategoryFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindMany',
      query: query.CategoryFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  CategoryFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Category',
      schemaKey: 'CategoryFindByIds',
      query: query.CategoryFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  WebPushPublicKey: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'String',
        schemaKey: 'WebPushPublicKey',
        query: query.WebPushPublicKey(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PinFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Pin',
      schemaKey: 'PinFindMany',
      query: query.PinFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  PinPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PinPagination',
      schemaKey: 'PinPagination',
      query: query.PinPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ShareFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Share',
      schemaKey: 'ShareFindMany',
      query: query.ShareFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  SharePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SharePagination',
      schemaKey: 'SharePagination',
      query: query.SharePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentPagination',
      query: query.CommentPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindOne',
      query: query.CommentFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindById',
      query: query.CommentFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Comment',
      schemaKey: 'CommentFindMany',
      query: query.CommentFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  CommentSortPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CommentPagination',
      schemaKey: 'CommentSortPagination',
      query: query.CommentSortPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLivePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLivePagination',
      schemaKey: 'EventLivePagination',
      query: query.EventLivePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLiveFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindOne',
      query: query.EventLiveFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EventLiveFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EventLive',
      schemaKey: 'EventLiveFindById',
      query: query.EventLiveFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  BannerFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindMany',
      query: query.BannerFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  BannerFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Banner',
      schemaKey: 'BannerFindOne',
      query: query.BannerFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'AnalyticsCount',
      query: query.AnalyticsCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPagination',
      schemaKey: 'AnalyticsPagination',
      query: query.AnalyticsPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsUsersReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUser',
      schemaKey: 'AnalyticsUsersReport',
      query: query.AnalyticsUsersReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsFindReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsReportItem',
      schemaKey: 'AnalyticsFindReport',
      query: query.AnalyticsFindReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsUserNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsUserNotification',
      schemaKey: 'AnalyticsUserNotification',
      query: query.AnalyticsUserNotification(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPin: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPin',
      schemaKey: 'AnalyticsPin',
      query: query.AnalyticsPin(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsComment: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsComment',
      schemaKey: 'AnalyticsComment',
      query: query.AnalyticsComment(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AnalyticsPoints',
      schemaKey: 'AnalyticsPoints',
      query: query.AnalyticsPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  AnalyticsDownloads: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsDownloads',
      query: query.AnalyticsDownloads(config),
      kind: OpKind.query,
      ...config
    });
  },

  SearchCountFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCount',
      schemaKey: 'SearchCountFindMany',
      query: query.SearchCountFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  SearchCountPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SearchCountPagination',
      schemaKey: 'SearchCountPagination',
      query: query.SearchCountPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindMany',
      query: query.FolderFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Folder',
      schemaKey: 'FolderFindById',
      query: query.FolderFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  FolderPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FolderPagination',
      schemaKey: 'FolderPagination',
      query: query.FolderPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RecentsActivitiesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RecentsActivitiesPagination',
      schemaKey: 'RecentsActivitiesPagination',
      query: query.RecentsActivitiesPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserWordsBlackListFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindMany',
      query: query.UserWordsBlackListFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserWordsBlackListFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserWordsBlackList',
      schemaKey: 'UserWordsBlackListFindById',
      query: query.UserWordsBlackListFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadSensitiveFilesPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadSensitiveFilesPagination',
      schemaKey: 'DownloadSensitiveFilesPagination',
      query: query.DownloadSensitiveFilesPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersInfo: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersInfo',
        schemaKey: 'DownloadUsersInfo',
        query: query.DownloadUsersInfo(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadAcceptedTerms: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadAcceptedTerms',
      schemaKey: 'DownloadAcceptedTerms',
      query: query.DownloadAcceptedTerms(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersAcceptedPoints: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DownloadUsersAcceptedPoints',
      schemaKey: 'DownloadUsersAcceptedPoints',
      query: query.DownloadUsersAcceptedPoints(config),
      kind: OpKind.query,
      ...config
    });
  },

  DownloadUsersWithPoints: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersWithPoints',
        schemaKey: 'DownloadUsersWithPoints',
        query: query.DownloadUsersWithPoints(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DownloadUsersBase: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DownloadUsersInfo',
        schemaKey: 'DownloadUsersBase',
        query: query.DownloadUsersBase(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  ImportUsersPlanFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindMany',
      query: query.ImportUsersPlanFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindById',
      query: query.ImportUsersPlanFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlan',
      schemaKey: 'ImportUsersPlanFindByIds',
      query: query.ImportUsersPlanFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  ImportUsersPlanPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ImportUsersPlanPagination',
      schemaKey: 'ImportUsersPlanPagination',
      query: query.ImportUsersPlanPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindByIds',
      query: query.UserDeletedFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindOne',
      query: query.UserDeletedFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserDeletedFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserDeleted',
      schemaKey: 'UserDeletedFindMany',
      query: query.UserDeletedFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserHistoryBlockFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlock',
      schemaKey: 'UserHistoryBlockFindMany',
      query: query.UserHistoryBlockFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserHistoryBlockPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserHistoryBlockPagination',
      schemaKey: 'UserHistoryBlockPagination',
      query: query.UserHistoryBlockPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FutureOperationFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FutureOperation',
      schemaKey: 'FutureOperationFindById',
      query: query.FutureOperationFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailStatsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindOne',
      query: query.EmailStatsFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailStatsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailStats',
      schemaKey: 'EmailStatsFindMany',
      query: query.EmailStatsFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindOne',
      query: query.EmailUnsubscribeFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribe',
      schemaKey: 'EmailUnsubscribeFindMany',
      query: query.EmailUnsubscribeFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  EmailUnsubscribeAction: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'EmailUnsubscribeActionPayload',
      schemaKey: 'EmailUnsubscribeAction',
      query: query.EmailUnsubscribeAction(config),
      kind: OpKind.query,
      ...config
    });
  },

  HomebannerFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Homebanner',
      schemaKey: 'HomebannerFindMany',
      query: query.HomebannerFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Place',
      schemaKey: 'PlaceFindMany',
      query: query.PlaceFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Place',
      schemaKey: 'PlaceFindOne',
      query: query.PlaceFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Place',
      schemaKey: 'PlaceFindById',
      query: query.PlaceFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlacePagination',
      schemaKey: 'PlacePagination',
      query: query.PlacePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceSearch',
      schemaKey: 'PlaceSearch',
      query: query.PlaceSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceSearchByIdsTypeAndName: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceSearchByIdsTypeAndName',
      schemaKey: 'PlaceSearchByIdsTypeAndName',
      query: query.PlaceSearchByIdsTypeAndName(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceSearchByChannelSapCodeOrPlaceAndRegion: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
      schemaKey: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
      query: query.PlaceSearchByChannelSapCodeOrPlaceAndRegion(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceSearchByIdsLpuCategoryAndContainsInventory: (
    variables,
    config,
    client
  ) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
      schemaKey: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
      query: query.PlaceSearchByIdsLpuCategoryAndContainsInventory(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacePaginationCustom: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlacePaginationCustom',
      schemaKey: 'PlacePaginationCustom',
      query: query.PlacePaginationCustom(config),
      kind: OpKind.query,
      ...config
    });
  },

  LocationOptions: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LocationOptionsPayload',
      schemaKey: 'LocationOptions',
      query: query.LocationOptions(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceSearchByCnpj: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Place',
      schemaKey: 'PlaceSearchByCnpj',
      query: query.PlaceSearchByCnpj(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacesBaseDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'PlacesBaseDownload',
      query: query.PlacesBaseDownload(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacesBaseValidation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'PlacesBaseValidation',
      query: query.PlacesBaseValidation(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacesPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'PlacesPermissionsEnum',
        schemaKey: 'PlacesPermissions',
        query: query.PlacesPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  ContractPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ContractPagination',
      schemaKey: 'ContractPagination',
      query: query.ContractPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ContractFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Contract',
      schemaKey: 'ContractFindOne',
      query: query.ContractFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  ContractFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Contract',
      schemaKey: 'ContractFindByIds',
      query: query.ContractFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductPagination',
      schemaKey: 'ProductPagination',
      query: query.ProductPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Product',
      schemaKey: 'ProductFindById',
      query: query.ProductFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Product',
      schemaKey: 'ProductFindByIds',
      query: query.ProductFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductBaseDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'ProductBaseDownload',
      query: query.ProductBaseDownload(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductBaseValidation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'ProductBaseValidation',
      query: query.ProductBaseValidation(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductSearchByNameOrSupplier: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductSearchByNameOrSupplier',
      schemaKey: 'ProductSearchByNameOrSupplier',
      query: query.ProductSearchByNameOrSupplier(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductSearchByNameOrSupplierAndNotContainsSomeIds: (
    variables,
    config,
    client
  ) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
      schemaKey: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
      query: query.ProductSearchByNameOrSupplierAndNotContainsSomeIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductSearchBySupplierZipCodeAndContainsInventory: (
    variables,
    config,
    client
  ) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductSearchBySupplierZipCodeAndContainsInventory',
      schemaKey: 'ProductSearchBySupplierZipCodeAndContainsInventory',
      query: query.ProductSearchBySupplierZipCodeAndContainsInventory(config),
      kind: OpKind.query,
      ...config
    });
  },

  SupplierPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SupplierPagination',
      schemaKey: 'SupplierPagination',
      query: query.SupplierPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  SupplierFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Supplier',
      schemaKey: 'SupplierFindOne',
      query: query.SupplierFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  SupplierFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Supplier',
      schemaKey: 'SupplierFindMany',
      query: query.SupplierFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  ResolverSupplierSearchByNameAndContainsLpu: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ResolverSupplierSearchByNameAndContainsLpu',
      schemaKey: 'ResolverSupplierSearchByNameAndContainsLpu',
      query: query.ResolverSupplierSearchByNameAndContainsLpu(config),
      kind: OpKind.query,
      ...config
    });
  },

  SupplierPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'SupplierPermissionsEnum',
        schemaKey: 'SupplierPermissions',
        query: query.SupplierPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  TablePriceFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePrice',
      schemaKey: 'TablePriceFindMany',
      query: query.TablePriceFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePrice',
      schemaKey: 'TablePriceFindOne',
      query: query.TablePriceFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePrice',
      schemaKey: 'TablePriceFindById',
      query: query.TablePriceFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePricePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePricePagination',
      schemaKey: 'TablePricePagination',
      query: query.TablePricePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePrice: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'TablePrice',
        schemaKey: 'TablePrice',
        query: query.TablePrice(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds: (
    variables,
    config,
    client
  ) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePrice',
      schemaKey: 'TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds',
      query: query.TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds(
        config
      ),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceAccessories: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePriceAccessories',
      schemaKey: 'TablePriceAccessories',
      query: query.TablePriceAccessories(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceSearchBySapCodeAndDeviceModel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePriceSearchBySapCodeAndDeviceModel',
      schemaKey: 'TablePriceSearchBySapCodeAndDeviceModel',
      query: query.TablePriceSearchBySapCodeAndDeviceModel(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceSearchBySapCode: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'listCodes',
      schemaKey: 'TablePriceSearchBySapCode',
      query: query.TablePriceSearchBySapCode(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceDevices: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePriceDevices',
      schemaKey: 'TablePriceDevices',
      query: query.TablePriceDevices(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePriceDeviceBySapCode: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'TablePriceDeviceBySapCode',
      schemaKey: 'TablePriceDeviceBySapCode',
      query: query.TablePriceDeviceBySapCode(config),
      kind: OpKind.query,
      ...config
    });
  },

  TablePricePermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'TablePricePermissionsEnum',
        schemaKey: 'TablePricePermissions',
        query: query.TablePricePermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PricingTableFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PricingTable',
      schemaKey: 'PricingTableFindById',
      query: query.PricingTableFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PricingTablePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PricingTablePagination',
      schemaKey: 'PricingTablePagination',
      query: query.PricingTablePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PricingTableFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PricingTable',
      schemaKey: 'PricingTableFindMany',
      query: query.PricingTableFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  LegalTextFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LegalText',
      schemaKey: 'LegalTextFindById',
      query: query.LegalTextFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  LegalTextPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LegalTextPagination',
      schemaKey: 'LegalTextPagination',
      query: query.LegalTextPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'DeviceFindMany',
      query: query.DeviceFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'DeviceFindOne',
      query: query.DeviceFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'DeviceFindById',
      query: query.DeviceFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DevicePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DevicePagination',
      schemaKey: 'DevicePagination',
      query: query.DevicePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceSearch: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeviceSearch',
      schemaKey: 'DeviceSearch',
      query: query.DeviceSearch(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceFindManufacturers: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DeviceFindManufacturers',
        schemaKey: 'DeviceFindManufacturers',
        query: query.DeviceFindManufacturers(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DeviceSearchById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'DeviceSearchById',
      query: query.DeviceSearchById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceBaseDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeviceBaseDownloadPayload',
      schemaKey: 'DeviceBaseDownload',
      query: query.DeviceBaseDownload(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceBaseValidation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'DeviceBaseValidation',
      query: query.DeviceBaseValidation(config),
      kind: OpKind.query,
      ...config
    });
  },

  AppDevice: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'AppDevice',
      query: query.AppDevice(config),
      kind: OpKind.query,
      ...config
    });
  },

  AppDeviceByManufacturer: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'AppDeviceByManufacturer',
      query: query.AppDeviceByManufacturer(config),
      kind: OpKind.query,
      ...config
    });
  },

  DevicePermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DevicePermissionsEnum',
        schemaKey: 'DevicePermissions',
        query: query.DevicePermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DigitalBrochureFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochure',
      schemaKey: 'DigitalBrochureFindMany',
      query: query.DigitalBrochureFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochure',
      schemaKey: 'DigitalBrochureFindOne',
      query: query.DigitalBrochureFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochure',
      schemaKey: 'DigitalBrochureFindById',
      query: query.DigitalBrochureFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochurePagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochurePagination',
      schemaKey: 'DigitalBrochurePagination',
      query: query.DigitalBrochurePagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureSearchByDDDAndService: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureSearchByDDDAndService',
      schemaKey: 'DigitalBrochureSearchByDDDAndService',
      query: query.DigitalBrochureSearchByDDDAndService(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochurePermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DigitalBrochurePermissionsEnum',
        schemaKey: 'DigitalBrochurePermissions',
        query: query.DigitalBrochurePermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  CampaignPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignPagination',
      schemaKey: 'CampaignPagination',
      query: query.CampaignPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Campaign',
      schemaKey: 'CampaignFindOne',
      query: query.CampaignFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Campaign',
      schemaKey: 'CampaignFindById',
      query: query.CampaignFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignSearchByTitleDescriptionOrStatus: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignSearchByTitleDescriptionOrStatus',
      schemaKey: 'CampaignSearchByTitleDescriptionOrStatus',
      query: query.CampaignSearchByTitleDescriptionOrStatus(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignSearchByTitleOrDescription: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CampaignSearchByTitleOrDescription',
      schemaKey: 'CampaignSearchByTitleOrDescription',
      query: query.CampaignSearchByTitleOrDescription(config),
      kind: OpKind.query,
      ...config
    });
  },

  CampaignPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'CampaignPermissionsEnum',
        schemaKey: 'CampaignPermissions',
        query: query.CampaignPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  LayoutPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LayoutPagination',
      schemaKey: 'LayoutPagination',
      query: query.LayoutPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Layout',
      schemaKey: 'LayoutFindOne',
      query: query.LayoutFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Layout',
      schemaKey: 'LayoutFindMany',
      query: query.LayoutFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Layout',
      schemaKey: 'LayoutFindByIds',
      query: query.LayoutFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutSearchByName: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LayoutSearchByName',
      schemaKey: 'LayoutSearchByName',
      query: query.LayoutSearchByName(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutSearchByNameAndContainsLpuIntervalQty: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LayoutSearchByNameAndContainsLpuIntervalQty',
      schemaKey: 'LayoutSearchByNameAndContainsLpuIntervalQty',
      query: query.LayoutSearchByNameAndContainsLpuIntervalQty(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutSearchContentByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'LayoutContentListPayload',
      schemaKey: 'LayoutSearchContentByIds',
      query: query.LayoutSearchContentByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  LayoutPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'LayoutPermissionsEnum',
        schemaKey: 'LayoutPermissions',
        query: query.LayoutPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  RegionalBudgetFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionalBudget',
      schemaKey: 'RegionalBudgetFindOne',
      query: query.RegionalBudgetFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionalBudgetFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionalBudget',
      schemaKey: 'RegionalBudgetFindById',
      query: query.RegionalBudgetFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegionalBudgetPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionalBudgetPagination',
      schemaKey: 'RegionalBudgetPagination',
      query: query.RegionalBudgetPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductionFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Production',
      schemaKey: 'ProductionFindById',
      query: query.ProductionFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductionPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductionPagination',
      schemaKey: 'ProductionPagination',
      query: query.ProductionPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductionReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductionReportPayload',
      schemaKey: 'ProductionReport',
      query: query.ProductionReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductionDeliveryReport: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductionReportPayload',
      schemaKey: 'ProductionDeliveryReport',
      query: query.ProductionDeliveryReport(config),
      kind: OpKind.query,
      ...config
    });
  },

  ProductionPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'ProductionPermissionsEnum',
        schemaKey: 'ProductionPermissions',
        query: query.ProductionPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  BudgetFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Budget',
      schemaKey: 'BudgetFindOne',
      query: query.BudgetFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  BudgetFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Budget',
      schemaKey: 'BudgetFindById',
      query: query.BudgetFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  BudgetPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BudgetPagination',
      schemaKey: 'BudgetPagination',
      query: query.BudgetPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  BudgetPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'BudgetPermissionsEnum',
        schemaKey: 'BudgetPermissions',
        query: query.BudgetPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  SupplierDeliveryPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SupplierDeliveryPagination',
      schemaKey: 'SupplierDeliveryPagination',
      query: query.SupplierDeliveryPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  SupplierDeliveryFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'SupplierDelivery',
      schemaKey: 'SupplierDeliveryFindMany',
      query: query.SupplierDeliveryFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryItemFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryItem',
      schemaKey: 'InventoryItemFindById',
      query: query.InventoryItemFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryItemFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryItem',
      schemaKey: 'InventoryItemFindOne',
      query: query.InventoryItemFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryItemPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryItemPagination',
      schemaKey: 'InventoryItemPagination',
      query: query.InventoryItemPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryItemFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryItem',
      schemaKey: 'InventoryItemFindMany',
      query: query.InventoryItemFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryWithDrawPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryWithDrawPagination',
      schemaKey: 'InventoryWithDrawPagination',
      query: query.InventoryWithDrawPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  InventoryWithDrawFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryWithDraw',
      schemaKey: 'InventoryWithDrawFindById',
      query: query.InventoryWithDrawFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeliveryOrderFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeliveryOrder',
      schemaKey: 'DeliveryOrderFindById',
      query: query.DeliveryOrderFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeliveryOrderPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeliveryOrderPagination',
      schemaKey: 'DeliveryOrderPagination',
      query: query.DeliveryOrderPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeliveryOrderValidation: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeliveryOrderValidatedReport',
      schemaKey: 'DeliveryOrderValidation',
      query: query.DeliveryOrderValidation(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeliveryOrderExecution: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeliveryOrderRecords',
      schemaKey: 'DeliveryOrderExecution',
      query: query.DeliveryOrderExecution(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserLogisticsPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'LogisticsPermissionsEnum',
        schemaKey: 'UserLogisticsPermissions',
        query: query.UserLogisticsPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  MetadataFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Metadata',
      schemaKey: 'MetadataFindOne',
      query: query.MetadataFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  MetadataFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Metadata',
      schemaKey: 'MetadataFindById',
      query: query.MetadataFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Positivator',
      schemaKey: 'PositivatorFindById',
      query: query.PositivatorFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Positivator',
      schemaKey: 'PositivatorFindMany',
      query: query.PositivatorFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PositivatorPagination',
      schemaKey: 'PositivatorPagination',
      query: query.PositivatorPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacePositivators: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'PlacePositivatorsPayload',
        schemaKey: 'PlacePositivators',
        query: query.PlacePositivators(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PositivationSumary: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'PositivationSumary',
      query: query.PositivationSumary(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlacePositivationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlacePositivationPagination',
      schemaKey: 'PlacePositivationPagination',
      query: query.PlacePositivationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivationReportByGroup: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'PositivationReportByGroup',
      query: query.PositivationReportByGroup(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivationReportByItem: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'PositivationReportByItem',
      query: query.PositivationReportByItem(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivationReportByPlace: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'PositivationReportByPlace',
      query: query.PositivationReportByPlace(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'PositivatorPermissionsEnum',
        schemaKey: 'PositivatorPermissions',
        query: query.PositivatorPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PositivatorItemFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PositivatorItem',
      schemaKey: 'PositivatorItemFindById',
      query: query.PositivatorItemFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorItemPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PositivatorItemPagination',
      schemaKey: 'PositivatorItemPagination',
      query: query.PositivatorItemPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PositivatorItemFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PositivatorItem',
      schemaKey: 'PositivatorItemFindMany',
      query: query.PositivatorItemFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  DeviceNotificationPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeviceNotificationPagination',
      schemaKey: 'DeviceNotificationPagination',
      query: query.DeviceNotificationPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserMerchanPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserMerchanPagination',
      schemaKey: 'UserMerchanPagination',
      query: query.UserMerchanPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  UsersBaseDownload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BaseDownload',
      schemaKey: 'UsersBaseDownload',
      query: query.UsersBaseDownload(config),
      kind: OpKind.query,
      ...config
    });
  },

  MerchanUserCheck: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MerchanUserCheck',
      schemaKey: 'MerchanUserCheck',
      query: query.MerchanUserCheck(config),
      kind: OpKind.query,
      ...config
    });
  },

  UserMerchanPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'UserMerchanPermissionsEnum',
        schemaKey: 'UserMerchanPermissions',
        query: query.UserMerchanPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  PlaceAnalyticsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceAnalyticsPagination',
      schemaKey: 'PlaceAnalyticsPagination',
      query: query.PlaceAnalyticsPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceAnalyticsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceAnalytics',
      schemaKey: 'PlaceAnalyticsFindOne',
      query: query.PlaceAnalyticsFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceAnalyticsFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceAnalytics',
      schemaKey: 'PlaceAnalyticsFindByIds',
      query: query.PlaceAnalyticsFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceAnalyticsFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceAnalytics',
      schemaKey: 'PlaceAnalyticsFindById',
      query: query.PlaceAnalyticsFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  PlaceAnalyticsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PlaceAnalytics',
      schemaKey: 'PlaceAnalyticsFindMany',
      query: query.PlaceAnalyticsFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  StructuralFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Structural',
      schemaKey: 'StructuralFindMany',
      query: query.StructuralFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  StructuralFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Structural',
      schemaKey: 'StructuralFindOne',
      query: query.StructuralFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  StructuralFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Structural',
      schemaKey: 'StructuralFindById',
      query: query.StructuralFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  StructuralPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'StructuralPagination',
      schemaKey: 'StructuralPagination',
      query: query.StructuralPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  Structural: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Structural',
        schemaKey: 'Structural',
        query: query.Structural(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  StructuralPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'StructuralPermissionsEnum',
        schemaKey: 'StructuralPermissions',
        query: query.StructuralPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  ProjectSLA: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProjectSLAPayload',
      schemaKey: 'ProjectSLA',
      query: query.ProjectSLA(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureAnalyticsPagination',
      schemaKey: 'DigitalBrochureAnalyticsPagination',
      query: query.DigitalBrochureAnalyticsPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsFindMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureAnalytics',
      schemaKey: 'DigitalBrochureAnalyticsFindMany',
      query: query.DigitalBrochureAnalyticsFindMany(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureAnalytics',
      schemaKey: 'DigitalBrochureAnalyticsFindById',
      query: query.DigitalBrochureAnalyticsFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsFindByIds: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureAnalytics',
      schemaKey: 'DigitalBrochureAnalyticsFindByIds',
      query: query.DigitalBrochureAnalyticsFindByIds(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsFindOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DigitalBrochureAnalytics',
      schemaKey: 'DigitalBrochureAnalyticsFindOne',
      query: query.DigitalBrochureAnalyticsFindOne(config),
      kind: OpKind.query,
      ...config
    });
  },

  DigitalBrochureAnalyticsPermissions: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'DigitalBrochureAnalyticsPermissionsEnum',
        schemaKey: 'DigitalBrochureAnalyticsPermissions',
        query: query.DigitalBrochureAnalyticsPermissions(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  DigitalBrochureAnalyticsCount: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Int',
      schemaKey: 'DigitalBrochureAnalyticsCount',
      query: query.DigitalBrochureAnalyticsCount(config),
      kind: OpKind.query,
      ...config
    });
  },

  AreaLojaPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'AreaLojaPagination',
      schemaKey: 'AreaLojaPagination',
      query: query.AreaLojaPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  RegraMapaPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegraMapaPagination',
      schemaKey: 'RegraMapaPagination',
      query: query.RegraMapaPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  FormatoPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'FormatoPagination',
      schemaKey: 'FormatoPagination',
      query: query.FormatoPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  PecaMapaPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'PecaMapaPagination',
      schemaKey: 'PecaMapaPagination',
      query: query.PecaMapaPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MapaPDVPagination: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MapaPDVPagination',
      schemaKey: 'MapaPDVPagination',
      query: query.MapaPDVPagination(config),
      kind: OpKind.query,
      ...config
    });
  },

  MapaPDVFindById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'MapaPDV',
      schemaKey: 'MapaPDVFindById',
      query: query.MapaPDVFindById(config),
      kind: OpKind.query,
      ...config
    });
  },

  MyMapaPDV: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'MyMapaPDVPayload',
        schemaKey: 'MyMapaPDV',
        query: query.MyMapaPDV(config),
        kind: OpKind.query,
        ...config
      }
    );
  },

  RegionRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneRegionPayload',
      schemaKey: 'RegionRemoveOne',
      query: query.RegionRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserContentPermissionToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserContentPermissionToggle',
      query: query.UserContentPermissionToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneUserPayload',
      schemaKey: 'UserUpdateOne',
      query: query.UserUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdUserPayload',
      schemaKey: 'UserRemoveById',
      query: query.UserRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserAcceptTerm: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserAcceptTerm',
      query: query.UserAcceptTerm(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginWithPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithPassword',
      query: query.UserLoginWithPassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginWithToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginWithToken',
      query: query.UserLoginWithToken(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserLoginRenewToken: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'User',
      schemaKey: 'UserLoginRenewToken',
      query: query.UserLoginRenewToken(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserSaveLogoutAnalytics: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLogoutAnalytics',
        query: query.UserSaveLogoutAnalytics(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserSaveLoginAnalyticsPCS: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'Boolean',
        schemaKey: 'UserSaveLoginAnalyticsPCS',
        query: query.UserSaveLoginAnalyticsPCS(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserFillEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillEmail',
      query: query.UserFillEmail(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserFillAvatarId: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserFillAvatarId',
      query: query.UserFillAvatarId(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserOptIn: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserOptIn',
      query: query.UserOptIn(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserMarkNotificationAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserMarkNotificationAsRead',
      query: query.UserMarkNotificationAsRead(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserDeleteNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteNotification',
      query: query.UserDeleteNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserDeleteManyNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'UserDeleteManyNotification',
      query: query.UserDeleteManyNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserRegistration: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UserRegistrationPayload',
      schemaKey: 'UserRegistration',
      query: query.UserRegistration(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserBlockComments: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserBlockComments',
      query: query.UserBlockComments(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ChangeToUserTaNarede: ({}, config, client) => {
    return client.exec(
      {},
      {
        url: client.url,
        entityName: 'UserTaNaRede',
        schemaKey: 'ChangeToUserTaNarede',
        query: query.ChangeToUserTaNarede(config),
        kind: OpKind.mutation,
        ...config
      }
    );
  },

  UserChangePassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserChangePassword',
      query: query.UserChangePassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserResetPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UserResetPassword',
      query: query.UserResetPassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFilePayload',
      schemaKey: 'FileCreateOne',
      query: query.FileCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFilePayload',
      schemaKey: 'FileRemoveById',
      query: query.FileRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FileUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdFilePayload',
      schemaKey: 'FileUpdateById',
      query: query.FileUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'MaterialCreateOne',
      query: query.MaterialCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'MaterialUpdateOne',
      query: query.MaterialUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdMaterialPayload',
      schemaKey: 'MaterialUpdateById',
      query: query.MaterialUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneMaterialPayload',
      schemaKey: 'MaterialRemoveOne',
      query: query.MaterialRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMaterialPayload',
      schemaKey: 'CommunicationCreateOne',
      query: query.CommunicationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMaterialPayload',
      schemaKey: 'CommunicationUpdateOne',
      query: query.CommunicationUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationApproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationApproveChannel',
      query: query.CommunicationApproveChannel(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationDisapproveChannel: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationDisapproveChannel',
      query: query.CommunicationDisapproveChannel(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommunicationRemoveApprover: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommunicationRemoveApprover',
      query: query.CommunicationRemoveApprover(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailFillTemplate: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'EmailFillTemplate',
      query: query.EmailFillTemplate(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailMarkAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EmailMarkAsRead',
      query: query.EmailMarkAsRead(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SendEmailFront: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'SendEmailFront',
      query: query.SendEmailFront(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialSendTestEmail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialSendTestEmail',
      query: query.MaterialSendTestEmail(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MaterialLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MaterialLikeToggle',
      query: query.MaterialLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UpdateDeteachedAndLatestVideo: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'UpdateDeteachedAndLatestVideo',
      query: query.UpdateDeteachedAndLatestVideo(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneNotificationPayload',
      schemaKey: 'NotificationCreateOne',
      query: query.NotificationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyNotificationPayload',
      schemaKey: 'NotificationCreateMany',
      query: query.NotificationCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneNotificationPayload',
      schemaKey: 'NotificationUpdateOne',
      query: query.NotificationUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  NotificationRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdNotificationPayload',
      schemaKey: 'NotificationRemoveById',
      query: query.NotificationRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCategoryPayload',
      schemaKey: 'CategoryCreateOne',
      query: query.CategoryCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCategoryPayload',
      schemaKey: 'CategoryUpdateOne',
      query: query.CategoryUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCategoryPayload',
      schemaKey: 'CategoryRemoveById',
      query: query.CategoryRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CategoryCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyCategoryPayload',
      schemaKey: 'CategoryCreateMany',
      query: query.CategoryCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  WebPushSubscribe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'WebPushSubscribe',
      query: query.WebPushSubscribe(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  WebPushSendNotification: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'String',
      schemaKey: 'WebPushSendNotification',
      query: query.WebPushSendNotification(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePinPayload',
      schemaKey: 'PinCreateOne',
      query: query.PinCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOnePinPayload',
      schemaKey: 'PinRemoveOne',
      query: query.PinRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PinRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyPinPayload',
      schemaKey: 'PinRemoveMany',
      query: query.PinRemoveMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneSharePayload',
      schemaKey: 'ShareCreateOne',
      query: query.ShareCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneSharePayload',
      schemaKey: 'ShareRemoveOne',
      query: query.ShareRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ShareRemoveMe: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'ShareRemoveMe',
      query: query.ShareRemoveMe(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCommentPayload',
      schemaKey: 'CommentCreateOne',
      query: query.CommentCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCommentPayload',
      schemaKey: 'CommentUpdateOne',
      query: query.CommentUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCommentPayload',
      schemaKey: 'CommentRemoveById',
      query: query.CommentRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CommentLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'CommentLikeToggle',
      query: query.CommentLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEventLivePayload',
      schemaKey: 'EventLiveCreateOne',
      query: query.EventLiveCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdEventLivePayload',
      schemaKey: 'EventLiveRemoveById',
      query: query.EventLiveRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneEventLivePayload',
      schemaKey: 'EventLiveUpdateOne',
      query: query.EventLiveUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveCloseRoom: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveCloseRoom',
      query: query.EventLiveCloseRoom(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EventLiveLikeToggle: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'EventLiveLikeToggle',
      query: query.EventLiveLikeToggle(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdBannerPayload',
      schemaKey: 'BannerRemoveById',
      query: query.BannerRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneBannerPayload',
      schemaKey: 'BannerCreateOne',
      query: query.BannerCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BannerUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneBannerPayload',
      schemaKey: 'BannerUpdateOne',
      query: query.BannerUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnalyticsPageView: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsPageView',
      query: query.AnalyticsPageView(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AnalyticsTrackEvent: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'AnalyticsTrackEvent',
      query: query.AnalyticsTrackEvent(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFolderPayload',
      schemaKey: 'FolderCreateOne',
      query: query.FolderCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderRemoveOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveOneFolderPayload',
      schemaKey: 'FolderRemoveOne',
      query: query.FolderRemoveOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyFolderPayload',
      schemaKey: 'FolderRemoveMany',
      query: query.FolderRemoveMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FolderUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneFolderPayload',
      schemaKey: 'FolderUpdateOne',
      query: query.FolderUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RecentsActivitiesCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneRecentsActivitiesPayload',
      schemaKey: 'RecentsActivitiesCreateOne',
      query: query.RecentsActivitiesCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserWordsBlackListCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserWordsBlackListPayload',
      schemaKey: 'UserWordsBlackListCreateOne',
      query: query.UserWordsBlackListCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ImportUsersPlanCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanCreateOne',
      query: query.ImportUsersPlanCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ImportUsersPlanUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneImportUsersPlanPayload',
      schemaKey: 'ImportUsersPlanUpdateOne',
      query: query.ImportUsersPlanUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  UserHistoryBlockCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneUserHistoryBlockPayload',
      schemaKey: 'UserHistoryBlockCreateOne',
      query: query.UserHistoryBlockCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailStatsCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailStatsPayload',
      schemaKey: 'EmailStatsCreateMany',
      query: query.EmailStatsCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailStatsCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailStatsPayload',
      schemaKey: 'EmailStatsCreateOne',
      query: query.EmailStatsCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailUnsubscribeCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateMany',
      query: query.EmailUnsubscribeCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  EmailUnsubscribeCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneEmailUnsubscribePayload',
      schemaKey: 'EmailUnsubscribeCreateOne',
      query: query.EmailUnsubscribeCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  HomebannerCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneHomebannerPayload',
      schemaKey: 'HomebannerCreateOne',
      query: query.HomebannerCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  HomebannerUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdHomebannerPayload',
      schemaKey: 'HomebannerUpdateById',
      query: query.HomebannerUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PlaceRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdPlacePayload',
      schemaKey: 'PlaceRemoveById',
      query: query.PlaceRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PlaceCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePlacePayload',
      schemaKey: 'PlaceCreateOne',
      query: query.PlaceCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PlaceUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdPlacePayload',
      schemaKey: 'PlaceUpdateById',
      query: query.PlaceUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PlaceUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOnePlacePayload',
      schemaKey: 'PlaceUpdateOne',
      query: query.PlaceUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PlacesBaseUpload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'PlacesBaseUpload',
      query: query.PlacesBaseUpload(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ContractCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneContractPayload',
      schemaKey: 'ContractCreateOne',
      query: query.ContractCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ContractCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyContractPayload',
      schemaKey: 'ContractCreateMany',
      query: query.ContractCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ContractUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneContractPayload',
      schemaKey: 'ContractUpdateOne',
      query: query.ContractUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ContractUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdContractPayload',
      schemaKey: 'ContractUpdateById',
      query: query.ContractUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ContractRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdContractPayload',
      schemaKey: 'ContractRemoveById',
      query: query.ContractRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneProductPayload',
      schemaKey: 'ProductCreateOne',
      query: query.ProductCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneProductPayload',
      schemaKey: 'ProductUpdateOne',
      query: query.ProductUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyProductPayload',
      schemaKey: 'ProductCreateMany',
      query: query.ProductCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdProductPayload',
      schemaKey: 'ProductUpdateById',
      query: query.ProductUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdProductPayload',
      schemaKey: 'ProductRemoveById',
      query: query.ProductRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductBaseUpload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'ProductBaseUpload',
      query: query.ProductBaseUpload(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SupplierCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneSupplierPayload',
      schemaKey: 'SupplierCreateOne',
      query: query.SupplierCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SupplierUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneSupplierPayload',
      schemaKey: 'SupplierUpdateOne',
      query: query.SupplierUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SupplierUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdSupplierPayload',
      schemaKey: 'SupplierUpdateById',
      query: query.SupplierUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SupplierRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdSupplierPayload',
      schemaKey: 'SupplierRemoveById',
      query: query.SupplierRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  TablePriceCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneTablePricePayload',
      schemaKey: 'TablePriceCreateOne',
      query: query.TablePriceCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  TablePriceUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneTablePricePayload',
      schemaKey: 'TablePriceUpdateOne',
      query: query.TablePriceUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  TablePriceRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdTablePricePayload',
      schemaKey: 'TablePriceRemoveById',
      query: query.TablePriceRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PricingTableCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePricingTablePayload',
      schemaKey: 'PricingTableCreateOne',
      query: query.PricingTableCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PricingTableRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdPricingTablePayload',
      schemaKey: 'PricingTableRemoveById',
      query: query.PricingTableRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PricingTableUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdPricingTablePayload',
      schemaKey: 'PricingTableUpdateById',
      query: query.PricingTableUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LegalTextCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneLegalTextPayload',
      schemaKey: 'LegalTextCreateOne',
      query: query.LegalTextCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LegalTextUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdLegalTextPayload',
      schemaKey: 'LegalTextUpdateById',
      query: query.LegalTextUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LegalTextRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdLegalTextPayload',
      schemaKey: 'LegalTextRemoveById',
      query: query.LegalTextRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneDevicePayload',
      schemaKey: 'DeviceCreateOne',
      query: query.DeviceCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneDevicePayload',
      schemaKey: 'DeviceUpdateOne',
      query: query.DeviceUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdDevicePayload',
      schemaKey: 'DeviceRemoveById',
      query: query.DeviceRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeleteDevice: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Device',
      schemaKey: 'DeleteDevice',
      query: query.DeleteDevice(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceBaseUpload: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'JSON',
      schemaKey: 'DeviceBaseUpload',
      query: query.DeviceBaseUpload(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DigitalBrochureCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneDigitalBrochurePayload',
      schemaKey: 'DigitalBrochureCreateOne',
      query: query.DigitalBrochureCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DigitalBrochureUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneDigitalBrochurePayload',
      schemaKey: 'DigitalBrochureUpdateOne',
      query: query.DigitalBrochureUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DigitalBrochureRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdDigitalBrochurePayload',
      schemaKey: 'DigitalBrochureRemoveById',
      query: query.DigitalBrochureRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BrochureUpdateThumbnail: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'BrochureUpdateThumbnailPayload',
      schemaKey: 'BrochureUpdateThumbnail',
      query: query.BrochureUpdateThumbnail(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CampaignCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneCampaignPayload',
      schemaKey: 'CampaignCreateOne',
      query: query.CampaignCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CampaignUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneCampaignPayload',
      schemaKey: 'CampaignUpdateOne',
      query: query.CampaignUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  CampaignRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdCampaignPayload',
      schemaKey: 'CampaignRemoveById',
      query: query.CampaignRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneLayoutPayload',
      schemaKey: 'LayoutCreateOne',
      query: query.LayoutCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutCreateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateManyLayoutPayload',
      schemaKey: 'LayoutCreateMany',
      query: query.LayoutCreateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneLayoutPayload',
      schemaKey: 'LayoutUpdateOne',
      query: query.LayoutUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdLayoutPayload',
      schemaKey: 'LayoutUpdateById',
      query: query.LayoutUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdLayoutPayload',
      schemaKey: 'LayoutRemoveById',
      query: query.LayoutRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  LayoutAssociateCodeTM: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'LayoutAssociateCodeTM',
      query: query.LayoutAssociateCodeTM(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegionalBudgetMarkAsViewed: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'RegionalBudgetMarkAsViewed',
      query: query.RegionalBudgetMarkAsViewed(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BudgetMarkAsFinished: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'BudgetMarkAsFinished',
      query: query.BudgetMarkAsFinished(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegionalBudgetParseItems: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionalBudgetParseItemsPayload',
      schemaKey: 'RegionalBudgetParseItems',
      query: query.RegionalBudgetParseItems(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegionalBudgetUpdateProductionItems: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RegionalBudget',
      schemaKey: 'RegionalBudgetUpdateProductionItems',
      query: query.RegionalBudgetUpdateProductionItems(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductionCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneProductionPayload',
      schemaKey: 'ProductionCreateOne',
      query: query.ProductionCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductionUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdProductionPayload',
      schemaKey: 'ProductionUpdateById',
      query: query.ProductionUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductionRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdProductionPayload',
      schemaKey: 'ProductionRemoveById',
      query: query.ProductionRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductionArchiveItem: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Production',
      schemaKey: 'ProductionArchiveItem',
      query: query.ProductionArchiveItem(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  ProductionParseItems: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'ProductionParseItemsPayload',
      schemaKey: 'ProductionParseItems',
      query: query.ProductionParseItems(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BudgetCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneBudgetPayload',
      schemaKey: 'BudgetCreateOne',
      query: query.BudgetCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BudgetUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdBudgetPayload',
      schemaKey: 'BudgetUpdateById',
      query: query.BudgetUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  BudgetRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdBudgetPayload',
      schemaKey: 'BudgetRemoveById',
      query: query.BudgetRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  SupplierDeliveryUpdateStatus: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'SupplierDeliveryUpdateStatus',
      query: query.SupplierDeliveryUpdateStatus(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  InventorySync: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryItem',
      schemaKey: 'InventorySync',
      query: query.InventorySync(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  InventoryWithDrawCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneInventoryWithDrawPayload',
      schemaKey: 'InventoryWithDrawCreateOne',
      query: query.InventoryWithDrawCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  InventoryCreateWithDraw: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'InventoryWithDraw',
      schemaKey: 'InventoryCreateWithDraw',
      query: query.InventoryCreateWithDraw(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeliveryOrderCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneDeliveryOrderPayload',
      schemaKey: 'DeliveryOrderCreateOne',
      query: query.DeliveryOrderCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeliveryOrderUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdDeliveryOrderPayload',
      schemaKey: 'DeliveryOrderUpdateById',
      query: query.DeliveryOrderUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeliveryOrderRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdDeliveryOrderPayload',
      schemaKey: 'DeliveryOrderRemoveById',
      query: query.DeliveryOrderRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MetadataCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMetadataPayload',
      schemaKey: 'MetadataCreateOne',
      query: query.MetadataCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MetadataUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneMetadataPayload',
      schemaKey: 'MetadataUpdateOne',
      query: query.MetadataUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MetadataRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdMetadataPayload',
      schemaKey: 'MetadataRemoveById',
      query: query.MetadataRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MetadataUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdMetadataPayload',
      schemaKey: 'MetadataUpdateById',
      query: query.MetadataUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePositivatorPayload',
      schemaKey: 'PositivatorCreateOne',
      query: query.PositivatorCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOnePositivatorPayload',
      schemaKey: 'PositivatorUpdateOne',
      query: query.PositivatorUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorUpdateMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateManyPositivatorPayload',
      schemaKey: 'PositivatorUpdateMany',
      query: query.PositivatorUpdateMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdPositivatorPayload',
      schemaKey: 'PositivatorRemoveById',
      query: query.PositivatorRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorRemoveMany: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveManyPositivatorPayload',
      schemaKey: 'PositivatorRemoveMany',
      query: query.PositivatorRemoveMany(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorNotificationCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Notification',
      schemaKey: 'PositivatorNotificationCreateOne',
      query: query.PositivatorNotificationCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorItemCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePositivatorItemPayload',
      schemaKey: 'PositivatorItemCreateOne',
      query: query.PositivatorItemCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorItemUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdPositivatorItemPayload',
      schemaKey: 'PositivatorItemUpdateById',
      query: query.PositivatorItemUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PositivatorItemRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdPositivatorItemPayload',
      schemaKey: 'PositivatorItemRemoveById',
      query: query.PositivatorItemRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceNotificationMarkAsRead: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeviceNotification',
      schemaKey: 'DeviceNotificationMarkAsRead',
      query: query.DeviceNotificationMarkAsRead(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DeviceNotificationMarkAsSolved: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'DeviceNotification',
      schemaKey: 'DeviceNotificationMarkAsSolved',
      query: query.DeviceNotificationMarkAsSolved(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MerchanRequestTempPassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MerchanRequestTempPassword',
      query: query.MerchanRequestTempPassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MerchanVerifyTempCode: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MerchanVerifyTempCode',
      query: query.MerchanVerifyTempCode(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MerchanChangePassword: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'MerchanChangePassword',
      query: query.MerchanChangePassword(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  StructuralCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneStructuralPayload',
      schemaKey: 'StructuralCreateOne',
      query: query.StructuralCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  StructuralUpdateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateOneStructuralPayload',
      schemaKey: 'StructuralUpdateOne',
      query: query.StructuralUpdateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  StructuralRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdStructuralPayload',
      schemaKey: 'StructuralRemoveById',
      query: query.StructuralRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  StructuralUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdStructuralPayload',
      schemaKey: 'StructuralUpdateById',
      query: query.StructuralUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  StructuralEditStatus: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'StructuralEditStatusPayload',
      schemaKey: 'StructuralEditStatus',
      query: query.StructuralEditStatus(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  DigitalBrochureAnalyticsCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneDigitalBrochureAnalyticsPayload',
      schemaKey: 'DigitalBrochureAnalyticsCreateOne',
      query: query.DigitalBrochureAnalyticsCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AreaLojaCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneAreaLojaPayload',
      schemaKey: 'AreaLojaCreateOne',
      query: query.AreaLojaCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AreaLojaUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdAreaLojaPayload',
      schemaKey: 'AreaLojaUpdateById',
      query: query.AreaLojaUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  AreaLojaRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdAreaLojaPayload',
      schemaKey: 'AreaLojaRemoveById',
      query: query.AreaLojaRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegraMapaCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneRegraMapaPayload',
      schemaKey: 'RegraMapaCreateOne',
      query: query.RegraMapaCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegraMapaUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdRegraMapaPayload',
      schemaKey: 'RegraMapaUpdateById',
      query: query.RegraMapaUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  RegraMapaRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdRegraMapaPayload',
      schemaKey: 'RegraMapaRemoveById',
      query: query.RegraMapaRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FormatoCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneFormatoPayload',
      schemaKey: 'FormatoCreateOne',
      query: query.FormatoCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FormatoUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdFormatoPayload',
      schemaKey: 'FormatoUpdateById',
      query: query.FormatoUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  FormatoRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdFormatoPayload',
      schemaKey: 'FormatoRemoveById',
      query: query.FormatoRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PecaMapaCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOnePecaMapaPayload',
      schemaKey: 'PecaMapaCreateOne',
      query: query.PecaMapaCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PecaMapaUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdPecaMapaPayload',
      schemaKey: 'PecaMapaUpdateById',
      query: query.PecaMapaUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PecaMapaRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdPecaMapaPayload',
      schemaKey: 'PecaMapaRemoveById',
      query: query.PecaMapaRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  PecaMapaAddMultiple: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'Boolean',
      schemaKey: 'PecaMapaAddMultiple',
      query: query.PecaMapaAddMultiple(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MapaPDVCreateOne: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'CreateOneMapaPDVPayload',
      schemaKey: 'MapaPDVCreateOne',
      query: query.MapaPDVCreateOne(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MapaPDVUpdateById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'UpdateByIdMapaPDVPayload',
      schemaKey: 'MapaPDVUpdateById',
      query: query.MapaPDVUpdateById(config),
      kind: OpKind.mutation,
      ...config
    });
  },

  MapaPDVRemoveById: (variables, config, client) => {
    return client.exec(variables, {
      url: client.url,
      entityName: 'RemoveByIdMapaPDVPayload',
      schemaKey: 'MapaPDVRemoveById',
      query: query.MapaPDVRemoveById(config),
      kind: OpKind.mutation,
      ...config
    });
  }
};

export const AppMethodsInfo = {
  RegionFindMany: {
    type: '[Region]',
    schemaKey: 'RegionFindMany',
    entityName: 'Region',
    isList: true,
    argsTSName: 'QueryRegionFindManyArgs',
    returnTSName: "Query['RegionFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Region]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' }
      ],
      name: 'RegionFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionFindOne: {
    type: 'Region',
    schemaKey: 'RegionFindOne',
    entityName: 'Region',
    isList: false,
    argsTSName: 'QueryRegionFindOneArgs',
    returnTSName: "Query['RegionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Region',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRegionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionInput' }
      ],
      name: 'RegionFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionPagination: {
    type: 'RegionPagination',
    schemaKey: 'RegionPagination',
    entityName: 'RegionPagination',
    isList: false,
    argsTSName: 'QueryRegionPaginationArgs',
    returnTSName: "Query['RegionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRegionInput' }
      ],
      name: 'RegionPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemFindMany: {
    type: '[RegionItem]',
    schemaKey: 'RegionItemFindMany',
    entityName: 'RegionItem',
    isList: true,
    argsTSName: 'QueryRegionItemFindManyArgs',
    returnTSName: "Query['RegionItemFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RegionItem]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' }
      ],
      name: 'RegionItemFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemFindOne: {
    type: 'RegionItem',
    schemaKey: 'RegionItemFindOne',
    entityName: 'RegionItem',
    isList: false,
    argsTSName: 'QueryRegionItemFindOneArgs',
    returnTSName: "Query['RegionItemFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItem',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRegionItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRegionItemInput' }
      ],
      name: 'RegionItemFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemPagination: {
    type: 'RegionItemPagination',
    schemaKey: 'RegionItemPagination',
    entityName: 'RegionItemPagination',
    isList: false,
    argsTSName: 'QueryRegionItemPaginationArgs',
    returnTSName: "Query['RegionItemPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionItemPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionItemInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRegionItemInput' }
      ],
      name: 'RegionItemPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemDistinctDDD: {
    type: 'RegionItemDistinctDDD',
    schemaKey: 'RegionItemDistinctDDD',
    entityName: 'RegionItemDistinctDDD',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctDDD']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos itens de região',
      deprecationReason: null,
      type: 'RegionItemDistinctDDD',
      args: [],
      name: 'RegionItemDistinctDDD',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionItemDistinctRegionAndUF: {
    type: 'RegionItemDistinctRegionAndUF',
    schemaKey: 'RegionItemDistinctRegionAndUF',
    entityName: 'RegionItemDistinctRegionAndUF',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['RegionItemDistinctRegionAndUF']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro)',
      deprecationReason: null,
      type: 'RegionItemDistinctRegionAndUF',
      args: [],
      name: 'RegionItemDistinctRegionAndUF',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  JobTitleFindOne: {
    type: 'JobTitle',
    schemaKey: 'JobTitleFindOne',
    entityName: 'JobTitle',
    isList: false,
    argsTSName: 'QueryJobTitleFindOneArgs',
    returnTSName: "Query['JobTitleFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JobTitle',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneJobTitleInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneJobTitleInput' }
      ],
      name: 'JobTitleFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  JobTitleFindMany: {
    type: '[JobTitle]',
    schemaKey: 'JobTitleFindMany',
    entityName: 'JobTitle',
    isList: true,
    argsTSName: 'QueryJobTitleFindManyArgs',
    returnTSName: "Query['JobTitleFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[JobTitle]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyJobTitleInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyJobTitleInput' }
      ],
      name: 'JobTitleFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupFindOne: {
    type: 'RoleGroup',
    schemaKey: 'RoleGroupFindOne',
    entityName: 'RoleGroup',
    isList: false,
    argsTSName: 'QueryRoleGroupFindOneArgs',
    returnTSName: "Query['RoleGroupFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroup',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRoleGroupInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneRoleGroupInput' }
      ],
      name: 'RoleGroupFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupFindMany: {
    type: '[RoleGroup]',
    schemaKey: 'RoleGroupFindMany',
    entityName: 'RoleGroup',
    isList: true,
    argsTSName: 'QueryRoleGroupFindManyArgs',
    returnTSName: "Query['RoleGroupFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[RoleGroup]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRoleGroupInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' }
      ],
      name: 'RoleGroupFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RoleGroupPagination: {
    type: 'RoleGroupPagination',
    schemaKey: 'RoleGroupPagination',
    entityName: 'RoleGroupPagination',
    isList: false,
    argsTSName: 'QueryRoleGroupPaginationArgs',
    returnTSName: "Query['RoleGroupPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RoleGroupPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRoleGroupInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRoleGroupInput' }
      ],
      name: 'RoleGroupPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelFindMany: {
    type: '[Channel]',
    schemaKey: 'ChannelFindMany',
    entityName: 'Channel',
    isList: true,
    argsTSName: 'QueryChannelFindManyArgs',
    returnTSName: "Query['ChannelFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Channel]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyChannelInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' }
      ],
      name: 'ChannelFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelFindOne: {
    type: 'Channel',
    schemaKey: 'ChannelFindOne',
    entityName: 'Channel',
    isList: false,
    argsTSName: 'QueryChannelFindOneArgs',
    returnTSName: "Query['ChannelFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Channel',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneChannelInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneChannelInput' }
      ],
      name: 'ChannelFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ChannelPagination: {
    type: 'ChannelPagination',
    schemaKey: 'ChannelPagination',
    entityName: 'ChannelPagination',
    isList: false,
    argsTSName: 'QueryChannelPaginationArgs',
    returnTSName: "Query['ChannelPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ChannelPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyChannelInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyChannelInput' }
      ],
      name: 'ChannelPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserContentPermissionFindOne: {
    type: 'UserContentPermission',
    schemaKey: 'UserContentPermissionFindOne',
    entityName: 'UserContentPermission',
    isList: false,
    argsTSName: 'QueryUserContentPermissionFindOneArgs',
    returnTSName: "Query['UserContentPermissionFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserContentPermission',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserContentPermissionInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneUserContentPermissionInput'
        }
      ],
      name: 'UserContentPermissionFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ContentPermissionsHelper: {
    type: '[ContentPermissionsHelperOption]',
    schemaKey: 'ContentPermissionsHelper',
    entityName: 'ContentPermissionsHelperOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['ContentPermissionsHelper']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Lista de possiveis content-permissions que os usuários podem ter.',
      deprecationReason: null,
      type: '[ContentPermissionsHelperOption]',
      args: [],
      name: 'ContentPermissionsHelper',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserPagination: {
    type: 'UserPagination',
    schemaKey: 'UserPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserPaginationArgs',
    returnTSName: "Query['UserPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
        { name: 'limit', description: null, type: 'Int' }
      ],
      name: 'UserPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByIds: {
    type: '[User]',
    schemaKey: 'UserFindByIds',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByIdsArgs',
    returnTSName: "Query['UserFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsUserInput' }
      ],
      name: 'UserFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindById: {
    type: 'User',
    schemaKey: 'UserFindById',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindByIdArgs',
    returnTSName: "Query['UserFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindOne: {
    type: 'User',
    schemaKey: 'UserFindOne',
    entityName: 'User',
    isList: false,
    argsTSName: 'QueryUserFindOneArgs',
    returnTSName: "Query['UserFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserInput' }
      ],
      name: 'UserFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindMany: {
    type: '[User]',
    schemaKey: 'UserFindMany',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindManyArgs',
    returnTSName: "Query['UserFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[User]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' }
      ],
      name: 'UserFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  User: {
    type: 'User',
    schemaKey: 'User',
    entityName: 'User',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['User']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [],
      name: 'User',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserSearch: {
    type: '[UserSearch]',
    schemaKey: 'UserSearch',
    entityName: 'UserSearch',
    isList: true,
    argsTSName: 'QueryUserSearchArgs',
    returnTSName: "Query['UserSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSearch]',
      args: [
        {
          name: 'search',
          description: 'Nome parcial, email parcial ou ObjectId',
          type: 'String!'
        },
        { name: 'roleGroup', description: null, type: '[String]' }
      ],
      name: 'UserSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserSearchPagination: {
    type: 'UserPagination',
    schemaKey: 'UserSearchPagination',
    entityName: 'UserPagination',
    isList: false,
    argsTSName: 'QueryUserSearchPaginationArgs',
    returnTSName: "Query['UserSearchPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'UserSearchPaginationFilterInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' },
        { name: 'limit', description: null, type: 'Int' }
      ],
      name: 'UserSearchPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TextFindBlackList: {
    type: 'TextFindBlackList',
    schemaKey: 'TextFindBlackList',
    entityName: 'TextFindBlackList',
    isList: false,
    argsTSName: 'QueryTextFindBlackListArgs',
    returnTSName: "Query['TextFindBlackList']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'TextFindBlackList',
      args: [{ name: 'text', description: null, type: 'String!' }],
      name: 'TextFindBlackList',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByEmails: {
    type: '[UserSimpleResult]',
    schemaKey: 'UserFindByEmails',
    entityName: 'UserSimpleResult',
    isList: true,
    argsTSName: 'QueryUserFindByEmailsArgs',
    returnTSName: "Query['UserFindByEmails']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do usuario',
      deprecationReason: null,
      type: '[UserSimpleResult]',
      args: [
        {
          name: 'emails',
          description: 'Lista de emails de usuários',
          type: '[String]!'
        }
      ],
      name: 'UserFindByEmails',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserFindByMatricula: {
    type: '[User]',
    schemaKey: 'UserFindByMatricula',
    entityName: 'User',
    isList: true,
    argsTSName: 'QueryUserFindByMatriculaArgs',
    returnTSName: "Query['UserFindByMatricula']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[User]',
      args: [
        { name: 'isMatriculaNull', description: null, type: 'Boolean' },
        { name: 'email', description: null, type: 'String' },
        { name: 'name', description: null, type: 'String' },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'UserFindByMatricula',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FileFindByIds: {
    type: '[File]',
    schemaKey: 'FileFindByIds',
    entityName: 'File',
    isList: true,
    argsTSName: 'QueryFileFindByIdsArgs',
    returnTSName: "Query['FileFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[File]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsFileInput' }
      ],
      name: 'FileFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FileFindById: {
    type: 'File',
    schemaKey: 'FileFindById',
    entityName: 'File',
    isList: false,
    argsTSName: 'QueryFileFindByIdArgs',
    returnTSName: "Query['FileFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'File',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FilePagination: {
    type: 'FilePagination',
    schemaKey: 'FilePagination',
    entityName: 'FilePagination',
    isList: false,
    argsTSName: 'QueryFilePaginationArgs',
    returnTSName: "Query['FilePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FilePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFileInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFileInput' }
      ],
      name: 'FilePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindById: {
    type: 'Material',
    schemaKey: 'MaterialFindById',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindByIdArgs',
    returnTSName: "Query['MaterialFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindMany: {
    type: '[Material]',
    schemaKey: 'MaterialFindMany',
    entityName: 'Material',
    isList: true,
    argsTSName: 'QueryMaterialFindManyArgs',
    returnTSName: "Query['MaterialFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Material]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindOne: {
    type: 'Material',
    schemaKey: 'MaterialFindOne',
    entityName: 'Material',
    isList: false,
    argsTSName: 'QueryMaterialFindOneArgs',
    returnTSName: "Query['MaterialFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Material',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneMaterialInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneMaterialInput' }
      ],
      name: 'MaterialFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialPagination: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialPagination',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialPaginationArgs',
    returnTSName: "Query['MaterialPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialCount: {
    type: 'Int',
    schemaKey: 'MaterialCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryMaterialCountArgs',
    returnTSName: "Query['MaterialCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterMaterialInput'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialCount',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialStatusOptions: {
    type: '[MaterialStatusOption]',
    schemaKey: 'MaterialStatusOptions',
    entityName: 'MaterialStatusOption',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['MaterialStatusOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[MaterialStatusOption]',
      args: [],
      name: 'MaterialStatusOptions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialSearchOwners: {
    type: '[MaterialSearchOwners]',
    schemaKey: 'MaterialSearchOwners',
    entityName: 'MaterialSearchOwners',
    isList: true,
    argsTSName: 'QueryMaterialSearchOwnersArgs',
    returnTSName: "Query['MaterialSearchOwners']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[MaterialSearchOwners]',
      args: [{ name: 'kind', description: null, type: 'EnumMaterialKind' }],
      name: 'MaterialSearchOwners',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailFindMany: {
    type: '[Email]',
    schemaKey: 'EmailFindMany',
    entityName: 'Email',
    isList: true,
    argsTSName: 'QueryEmailFindManyArgs',
    returnTSName: "Query['EmailFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Email]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' }
      ],
      name: 'EmailFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailPagination: {
    type: 'EmailPagination',
    schemaKey: 'EmailPagination',
    entityName: 'EmailPagination',
    isList: false,
    argsTSName: 'QueryEmailPaginationArgs',
    returnTSName: "Query['EmailPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyEmailInput' }
      ],
      name: 'EmailPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailFindOne: {
    type: 'Email',
    schemaKey: 'EmailFindOne',
    entityName: 'Email',
    isList: false,
    argsTSName: 'QueryEmailFindOneArgs',
    returnTSName: "Query['EmailFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Email',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailInput' }
      ],
      name: 'EmailFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailCheckRead: {
    type: 'Boolean',
    schemaKey: 'EmailCheckRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryEmailCheckReadArgs',
    returnTSName: "Query['EmailCheckRead']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Retorna true se o email foi marcado como lido pelo destinatario informado',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'emailId', description: null, type: 'String!' },
        { name: 'userEmail', description: null, type: 'String!' }
      ],
      name: 'EmailCheckRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialSearch: {
    type: 'MaterialSearch',
    schemaKey: 'MaterialSearch',
    entityName: 'MaterialSearch',
    isList: false,
    argsTSName: 'QueryMaterialSearchArgs',
    returnTSName: "Query['MaterialSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'MaterialSearch',
      args: [
        { name: 'search', description: null, type: 'String!' },
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' },
        { name: 'category', description: null, type: 'String' }
      ],
      name: 'MaterialSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MaterialFindPinned: {
    type: 'MaterialPagination',
    schemaKey: 'MaterialFindPinned',
    entityName: 'MaterialPagination',
    isList: false,
    argsTSName: 'QueryMaterialFindPinnedArgs',
    returnTSName: "Query['MaterialFindPinned']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MaterialPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        {
          name: 'filter',
          description: null,
          type: 'FilterFindManyMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyMaterialInput' }
      ],
      name: 'MaterialFindPinned',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationFindMany: {
    type: '[Notification]',
    schemaKey: 'NotificationFindMany',
    entityName: 'Notification',
    isList: true,
    argsTSName: 'QueryNotificationFindManyArgs',
    returnTSName: "Query['NotificationFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Notification]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyNotificationInput'
        }
      ],
      name: 'NotificationFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationFindOne: {
    type: 'Notification',
    schemaKey: 'NotificationFindOne',
    entityName: 'Notification',
    isList: false,
    argsTSName: 'QueryNotificationFindOneArgs',
    returnTSName: "Query['NotificationFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Notification',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneNotificationInput'
        }
      ],
      name: 'NotificationFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  NotificationPagination: {
    type: 'NotificationPagination',
    schemaKey: 'NotificationPagination',
    entityName: 'NotificationPagination',
    isList: false,
    argsTSName: 'QueryNotificationPaginationArgs',
    returnTSName: "Query['NotificationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'NotificationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyNotificationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyNotificationInput'
        }
      ],
      name: 'NotificationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  HelperAddress: {
    type: 'AddressHelperPayload',
    schemaKey: 'HelperAddress',
    entityName: 'AddressHelperPayload',
    isList: false,
    argsTSName: 'QueryHelperAddressArgs',
    returnTSName: "Query['HelperAddress']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'AddressHelperPayload',
      args: [{ name: 'zipCode', description: null, type: 'String!' }],
      name: 'HelperAddress',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindOne: {
    type: 'Category',
    schemaKey: 'CategoryFindOne',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindOneArgs',
    returnTSName: "Query['CategoryFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCategoryInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCategoryInput' }
      ],
      name: 'CategoryFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindById: {
    type: 'Category',
    schemaKey: 'CategoryFindById',
    entityName: 'Category',
    isList: false,
    argsTSName: 'QueryCategoryFindByIdArgs',
    returnTSName: "Query['CategoryFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Category',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortMenuEnum' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindMany: {
    type: '[Category]',
    schemaKey: 'CategoryFindMany',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindManyArgs',
    returnTSName: "Query['CategoryFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCategoryInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCategoryInput' }
      ],
      name: 'CategoryFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CategoryFindByIds: {
    type: '[Category]',
    schemaKey: 'CategoryFindByIds',
    entityName: 'Category',
    isList: true,
    argsTSName: 'QueryCategoryFindByIdsArgs',
    returnTSName: "Query['CategoryFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Category]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsCategoryInput' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  WebPushPublicKey: {
    type: 'String!',
    schemaKey: 'WebPushPublicKey',
    entityName: 'String',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['WebPushPublicKey']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Retorna a chave publica (VAPID key) pra a subscription de web push notifications',
      deprecationReason: null,
      type: 'String!',
      args: [],
      name: 'WebPushPublicKey',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'query'
  },

  PinFindMany: {
    type: '[Pin]',
    schemaKey: 'PinFindMany',
    entityName: 'Pin',
    isList: true,
    argsTSName: 'QueryPinFindManyArgs',
    returnTSName: "Query['PinFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Pin]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPinInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' }
      ],
      name: 'PinFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PinPagination: {
    type: 'PinPagination',
    schemaKey: 'PinPagination',
    entityName: 'PinPagination',
    isList: false,
    argsTSName: 'QueryPinPaginationArgs',
    returnTSName: "Query['PinPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PinPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPinInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyPinInput' }
      ],
      name: 'PinPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ShareFindMany: {
    type: '[Share]',
    schemaKey: 'ShareFindMany',
    entityName: 'Share',
    isList: true,
    argsTSName: 'QueryShareFindManyArgs',
    returnTSName: "Query['ShareFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Share]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyShareInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' }
      ],
      name: 'ShareFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SharePagination: {
    type: 'SharePagination',
    schemaKey: 'SharePagination',
    entityName: 'SharePagination',
    isList: false,
    argsTSName: 'QuerySharePaginationArgs',
    returnTSName: "Query['SharePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SharePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyShareInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyShareInput' }
      ],
      name: 'SharePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentPaginationArgs',
    returnTSName: "Query['CommentPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCommentInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindOne: {
    type: 'Comment',
    schemaKey: 'CommentFindOne',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindOneArgs',
    returnTSName: "Query['CommentFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCommentInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCommentInput' }
      ],
      name: 'CommentFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindById: {
    type: 'Comment',
    schemaKey: 'CommentFindById',
    entityName: 'Comment',
    isList: false,
    argsTSName: 'QueryCommentFindByIdArgs',
    returnTSName: "Query['CommentFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Comment',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentFindMany: {
    type: '[Comment]',
    schemaKey: 'CommentFindMany',
    entityName: 'Comment',
    isList: true,
    argsTSName: 'QueryCommentFindManyArgs',
    returnTSName: "Query['CommentFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Comment]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCommentInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CommentSortPagination: {
    type: 'CommentPagination',
    schemaKey: 'CommentSortPagination',
    entityName: 'CommentPagination',
    isList: false,
    argsTSName: 'QueryCommentSortPaginationArgs',
    returnTSName: "Query['CommentSortPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CommentPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        {
          name: 'filter',
          description: null,
          type: 'CommentSortPaginationFilterInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCommentInput' }
      ],
      name: 'CommentSortPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLivePagination: {
    type: 'EventLivePagination',
    schemaKey: 'EventLivePagination',
    entityName: 'EventLivePagination',
    isList: false,
    argsTSName: 'QueryEventLivePaginationArgs',
    returnTSName: "Query['EventLivePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLivePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEventLiveInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyEventLiveInput' }
      ],
      name: 'EventLivePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLiveFindOne: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindOne',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindOneArgs',
    returnTSName: "Query['EventLiveFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEventLiveInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEventLiveInput' }
      ],
      name: 'EventLiveFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EventLiveFindById: {
    type: 'EventLive',
    schemaKey: 'EventLiveFindById',
    entityName: 'EventLive',
    isList: false,
    argsTSName: 'QueryEventLiveFindByIdArgs',
    returnTSName: "Query['EventLiveFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EventLive',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BannerFindMany: {
    type: '[Banner]',
    schemaKey: 'BannerFindMany',
    entityName: 'Banner',
    isList: true,
    argsTSName: 'QueryBannerFindManyArgs',
    returnTSName: "Query['BannerFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Banner]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyBannerInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyBannerInput' }
      ],
      name: 'BannerFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BannerFindOne: {
    type: 'Banner',
    schemaKey: 'BannerFindOne',
    entityName: 'Banner',
    isList: false,
    argsTSName: 'QueryBannerFindOneArgs',
    returnTSName: "Query['BannerFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Banner',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneBannerInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneBannerInput' }
      ],
      name: 'BannerFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsCount: {
    type: 'Int',
    schemaKey: 'AnalyticsCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryAnalyticsCountArgs',
    returnTSName: "Query['AnalyticsCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterAnalyticsInput'
        }
      ],
      name: 'AnalyticsCount',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPagination: {
    type: 'AnalyticsPagination',
    schemaKey: 'AnalyticsPagination',
    entityName: 'AnalyticsPagination',
    isList: false,
    argsTSName: 'QueryAnalyticsPaginationArgs',
    returnTSName: "Query['AnalyticsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AnalyticsPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyAnalyticsInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyAnalyticsInput' }
      ],
      name: 'AnalyticsPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsUsersReport: {
    type: '[AnalyticsUser]',
    schemaKey: 'AnalyticsUsersReport',
    entityName: 'AnalyticsUser',
    isList: true,
    argsTSName: 'QueryAnalyticsUsersReportArgs',
    returnTSName: "Query['AnalyticsUsersReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: '[AnalyticsUser]',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int', defaultValue: 20 }
      ],
      name: 'AnalyticsUsersReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsFindReport: {
    type: '[AnalyticsReportItem]',
    schemaKey: 'AnalyticsFindReport',
    entityName: 'AnalyticsReportItem',
    isList: true,
    argsTSName: 'QueryAnalyticsFindReportArgs',
    returnTSName: "Query['AnalyticsFindReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsReportItem]',
      args: [
        {
          name: 'filter',
          description: null,
          type: 'AnalyticsReportFilterInput'
        },
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsFindReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsUserNotification: {
    type: '[AnalyticsUserNotification]',
    schemaKey: 'AnalyticsUserNotification',
    entityName: 'AnalyticsUserNotification',
    isList: true,
    argsTSName: 'QueryAnalyticsUserNotificationArgs',
    returnTSName: "Query['AnalyticsUserNotification']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsUserNotification]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsUserNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPin: {
    type: '[AnalyticsPin]',
    schemaKey: 'AnalyticsPin',
    entityName: 'AnalyticsPin',
    isList: true,
    argsTSName: 'QueryAnalyticsPinArgs',
    returnTSName: "Query['AnalyticsPin']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPin]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsPin',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsComment: {
    type: '[AnalyticsComment]',
    schemaKey: 'AnalyticsComment',
    entityName: 'AnalyticsComment',
    isList: true,
    argsTSName: 'QueryAnalyticsCommentArgs',
    returnTSName: "Query['AnalyticsComment']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsComment]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsComment',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsPoints: {
    type: '[AnalyticsPoints]',
    schemaKey: 'AnalyticsPoints',
    entityName: 'AnalyticsPoints',
    isList: true,
    argsTSName: 'QueryAnalyticsPointsArgs',
    returnTSName: "Query['AnalyticsPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[AnalyticsPoints]',
      args: [
        { name: 'page', description: 'Página atual', type: 'Int' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' }
      ],
      name: 'AnalyticsPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AnalyticsDownloads: {
    type: 'Boolean',
    schemaKey: 'AnalyticsDownloads',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'QueryAnalyticsDownloadsArgs',
    returnTSName: "Query['AnalyticsDownloads']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'fileId', description: null, type: 'String' }],
      name: 'AnalyticsDownloads',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SearchCountFindMany: {
    type: '[SearchCount]',
    schemaKey: 'SearchCountFindMany',
    entityName: 'SearchCount',
    isList: true,
    argsTSName: 'QuerySearchCountFindManyArgs',
    returnTSName: "Query['SearchCountFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[SearchCount]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySearchCountInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySearchCountInput'
        }
      ],
      name: 'SearchCountFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SearchCountPagination: {
    type: 'SearchCountPagination',
    schemaKey: 'SearchCountPagination',
    entityName: 'SearchCountPagination',
    isList: false,
    argsTSName: 'QuerySearchCountPaginationArgs',
    returnTSName: "Query['SearchCountPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SearchCountPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySearchCountInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySearchCountInput'
        }
      ],
      name: 'SearchCountPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderFindMany: {
    type: '[Folder]',
    schemaKey: 'FolderFindMany',
    entityName: 'Folder',
    isList: true,
    argsTSName: 'QueryFolderFindManyArgs',
    returnTSName: "Query['FolderFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Folder]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFolderInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' }
      ],
      name: 'FolderFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderFindById: {
    type: 'Folder',
    schemaKey: 'FolderFindById',
    entityName: 'Folder',
    isList: false,
    argsTSName: 'QueryFolderFindByIdArgs',
    returnTSName: "Query['FolderFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Folder',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FolderFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FolderPagination: {
    type: 'FolderPagination',
    schemaKey: 'FolderPagination',
    entityName: 'FolderPagination',
    isList: false,
    argsTSName: 'QueryFolderPaginationArgs',
    returnTSName: "Query['FolderPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FolderPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFolderInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFolderInput' }
      ],
      name: 'FolderPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RecentsActivitiesPagination: {
    type: 'RecentsActivitiesPagination',
    schemaKey: 'RecentsActivitiesPagination',
    entityName: 'RecentsActivitiesPagination',
    isList: false,
    argsTSName: 'QueryRecentsActivitiesPaginationArgs',
    returnTSName: "Query['RecentsActivitiesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RecentsActivitiesPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRecentsActivitiesInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyRecentsActivitiesInput'
        }
      ],
      name: 'RecentsActivitiesPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserWordsBlackListFindMany: {
    type: '[UserWordsBlackList]',
    schemaKey: 'UserWordsBlackListFindMany',
    entityName: 'UserWordsBlackList',
    isList: true,
    argsTSName: 'QueryUserWordsBlackListFindManyArgs',
    returnTSName: "Query['UserWordsBlackListFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserWordsBlackList]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserWordsBlackListInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserWordsBlackListInput'
        }
      ],
      name: 'UserWordsBlackListFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserWordsBlackListFindById: {
    type: 'UserWordsBlackList',
    schemaKey: 'UserWordsBlackListFindById',
    entityName: 'UserWordsBlackList',
    isList: false,
    argsTSName: 'QueryUserWordsBlackListFindByIdArgs',
    returnTSName: "Query['UserWordsBlackListFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserWordsBlackList',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserWordsBlackListFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadSensitiveFilesPagination: {
    type: 'DownloadSensitiveFilesPagination',
    schemaKey: 'DownloadSensitiveFilesPagination',
    entityName: 'DownloadSensitiveFilesPagination',
    isList: false,
    argsTSName: 'QueryDownloadSensitiveFilesPaginationArgs',
    returnTSName: "Query['DownloadSensitiveFilesPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DownloadSensitiveFilesPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDownloadSensitiveFilesInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDownloadSensitiveFilesInput'
        }
      ],
      name: 'DownloadSensitiveFilesPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersInfo: {
    type: 'DownloadUsersInfo',
    schemaKey: 'DownloadUsersInfo',
    entityName: 'DownloadUsersInfo',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersInfo']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersInfo',
      args: [],
      name: 'DownloadUsersInfo',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadAcceptedTerms: {
    type: 'DownloadAcceptedTerms',
    schemaKey: 'DownloadAcceptedTerms',
    entityName: 'DownloadAcceptedTerms',
    isList: false,
    argsTSName: 'QueryDownloadAcceptedTermsArgs',
    returnTSName: "Query['DownloadAcceptedTerms']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadAcceptedTerms',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' }
      ],
      name: 'DownloadAcceptedTerms',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersAcceptedPoints: {
    type: 'DownloadUsersAcceptedPoints',
    schemaKey: 'DownloadUsersAcceptedPoints',
    entityName: 'DownloadUsersAcceptedPoints',
    isList: false,
    argsTSName: 'QueryDownloadUsersAcceptedPointsArgs',
    returnTSName: "Query['DownloadUsersAcceptedPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersAcceptedPoints',
      args: [
        { name: 'incentiveCampaignId', description: null, type: 'String!' }
      ],
      name: 'DownloadUsersAcceptedPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersWithPoints: {
    type: 'DownloadUsersWithPoints',
    schemaKey: 'DownloadUsersWithPoints',
    entityName: 'DownloadUsersWithPoints',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersWithPoints']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersWithPoints',
      args: [],
      name: 'DownloadUsersWithPoints',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DownloadUsersBase: {
    type: 'DownloadUsersInfo',
    schemaKey: 'DownloadUsersBase',
    entityName: 'DownloadUsersInfo',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['DownloadUsersBase']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'DownloadUsersInfo',
      args: [],
      name: 'DownloadUsersBase',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindMany: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindMany',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindManyArgs',
    returnTSName: "Query['ImportUsersPlanFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyImportUsersPlanInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyImportUsersPlanInput'
        }
      ],
      name: 'ImportUsersPlanFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindById: {
    type: 'ImportUsersPlan',
    schemaKey: 'ImportUsersPlanFindById',
    entityName: 'ImportUsersPlan',
    isList: false,
    argsTSName: 'QueryImportUsersPlanFindByIdArgs',
    returnTSName: "Query['ImportUsersPlanFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlan',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanFindByIds: {
    type: '[ImportUsersPlan]',
    schemaKey: 'ImportUsersPlanFindByIds',
    entityName: 'ImportUsersPlan',
    isList: true,
    argsTSName: 'QueryImportUsersPlanFindByIdsArgs',
    returnTSName: "Query['ImportUsersPlanFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ImportUsersPlan]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsImportUsersPlanInput'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ImportUsersPlanPagination: {
    type: 'ImportUsersPlanPagination',
    schemaKey: 'ImportUsersPlanPagination',
    entityName: 'ImportUsersPlanPagination',
    isList: false,
    argsTSName: 'QueryImportUsersPlanPaginationArgs',
    returnTSName: "Query['ImportUsersPlanPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ImportUsersPlanPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyImportUsersPlanInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyImportUsersPlanInput'
        }
      ],
      name: 'ImportUsersPlanPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindByIds: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindByIds',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindByIdsArgs',
    returnTSName: "Query['UserDeletedFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsUserDeletedInput'
        }
      ],
      name: 'UserDeletedFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindOne: {
    type: 'UserDeleted',
    schemaKey: 'UserDeletedFindOne',
    entityName: 'UserDeleted',
    isList: false,
    argsTSName: 'QueryUserDeletedFindOneArgs',
    returnTSName: "Query['UserDeletedFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserDeleted',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneUserDeletedInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneUserDeletedInput' }
      ],
      name: 'UserDeletedFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserDeletedFindMany: {
    type: '[UserDeleted]',
    schemaKey: 'UserDeletedFindMany',
    entityName: 'UserDeleted',
    isList: true,
    argsTSName: 'QueryUserDeletedFindManyArgs',
    returnTSName: "Query['UserDeletedFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserDeleted]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserDeletedInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserDeletedInput'
        }
      ],
      name: 'UserDeletedFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserHistoryBlockFindMany: {
    type: '[UserHistoryBlock]',
    schemaKey: 'UserHistoryBlockFindMany',
    entityName: 'UserHistoryBlock',
    isList: true,
    argsTSName: 'QueryUserHistoryBlockFindManyArgs',
    returnTSName: "Query['UserHistoryBlockFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserHistoryBlock]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserHistoryBlockInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserHistoryBlockInput'
        }
      ],
      name: 'UserHistoryBlockFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserHistoryBlockPagination: {
    type: 'UserHistoryBlockPagination',
    schemaKey: 'UserHistoryBlockPagination',
    entityName: 'UserHistoryBlockPagination',
    isList: false,
    argsTSName: 'QueryUserHistoryBlockPaginationArgs',
    returnTSName: "Query['UserHistoryBlockPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserHistoryBlockPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyUserHistoryBlockInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyUserHistoryBlockInput'
        }
      ],
      name: 'UserHistoryBlockPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FutureOperationFindById: {
    type: 'FutureOperation',
    schemaKey: 'FutureOperationFindById',
    entityName: 'FutureOperation',
    isList: false,
    argsTSName: 'QueryFutureOperationFindByIdArgs',
    returnTSName: "Query['FutureOperationFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FutureOperation',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FutureOperationFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailStatsFindOne: {
    type: 'EmailStats',
    schemaKey: 'EmailStatsFindOne',
    entityName: 'EmailStats',
    isList: false,
    argsTSName: 'QueryEmailStatsFindOneArgs',
    returnTSName: "Query['EmailStatsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailStats',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailStatsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneEmailStatsInput' }
      ],
      name: 'EmailStatsFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailStatsFindMany: {
    type: '[EmailStats]',
    schemaKey: 'EmailStatsFindMany',
    entityName: 'EmailStats',
    isList: true,
    argsTSName: 'QueryEmailStatsFindManyArgs',
    returnTSName: "Query['EmailStatsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailStats]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailStatsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyEmailStatsInput' }
      ],
      name: 'EmailStatsFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeFindOne: {
    type: 'EmailUnsubscribe',
    schemaKey: 'EmailUnsubscribeFindOne',
    entityName: 'EmailUnsubscribe',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeFindOneArgs',
    returnTSName: "Query['EmailUnsubscribeFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'EmailUnsubscribe',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneEmailUnsubscribeInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneEmailUnsubscribeInput'
        }
      ],
      name: 'EmailUnsubscribeFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeFindMany: {
    type: '[EmailUnsubscribe]',
    schemaKey: 'EmailUnsubscribeFindMany',
    entityName: 'EmailUnsubscribe',
    isList: true,
    argsTSName: 'QueryEmailUnsubscribeFindManyArgs',
    returnTSName: "Query['EmailUnsubscribeFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[EmailUnsubscribe]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyEmailUnsubscribeInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyEmailUnsubscribeInput'
        }
      ],
      name: 'EmailUnsubscribeFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  EmailUnsubscribeAction: {
    type: 'EmailUnsubscribeActionPayload',
    schemaKey: 'EmailUnsubscribeAction',
    entityName: 'EmailUnsubscribeActionPayload',
    isList: false,
    argsTSName: 'QueryEmailUnsubscribeActionArgs',
    returnTSName: "Query['EmailUnsubscribeAction']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Descadastra o e-mail para recebimento futuro de e-mails',
      deprecationReason: null,
      type: 'EmailUnsubscribeActionPayload',
      args: [
        { name: 'id', description: null, type: 'String' },
        { name: 'email', description: null, type: 'String' }
      ],
      name: 'EmailUnsubscribeAction',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  HomebannerFindMany: {
    type: '[Homebanner]',
    schemaKey: 'HomebannerFindMany',
    entityName: 'Homebanner',
    isList: true,
    argsTSName: 'QueryHomebannerFindManyArgs',
    returnTSName: "Query['HomebannerFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Homebanner]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyHomebannerInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyHomebannerInput' }
      ],
      name: 'HomebannerFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceFindMany: {
    type: '[Place]',
    schemaKey: 'PlaceFindMany',
    entityName: 'Place',
    isList: true,
    argsTSName: 'QueryPlaceFindManyArgs',
    returnTSName: "Query['PlaceFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Place]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPlaceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyPlaceInput' }
      ],
      name: 'PlaceFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceFindOne: {
    type: 'Place',
    schemaKey: 'PlaceFindOne',
    entityName: 'Place',
    isList: false,
    argsTSName: 'QueryPlaceFindOneArgs',
    returnTSName: "Query['PlaceFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Place',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOnePlaceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOnePlaceInput' }
      ],
      name: 'PlaceFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceFindById: {
    type: 'Place',
    schemaKey: 'PlaceFindById',
    entityName: 'Place',
    isList: false,
    argsTSName: 'QueryPlaceFindByIdArgs',
    returnTSName: "Query['PlaceFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Place',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortPlaceEnum' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'PlaceFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacePagination: {
    type: 'PlacePagination',
    schemaKey: 'PlacePagination',
    entityName: 'PlacePagination',
    isList: false,
    argsTSName: 'QueryPlacePaginationArgs',
    returnTSName: "Query['PlacePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlacePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPlaceInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyPlaceInput' }
      ],
      name: 'PlacePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceSearch: {
    type: 'PlaceSearch',
    schemaKey: 'PlaceSearch',
    entityName: 'PlaceSearch',
    isList: false,
    argsTSName: 'QueryPlaceSearchArgs',
    returnTSName: "Query['PlaceSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca de places por canal,\n      ufs, DDDs, cidades, focu de comunicação, modelo de loja,\n      tecnologias de rede e produtos comercializados',
      deprecationReason: null,
      type: 'PlaceSearch',
      args: [
        { name: 'channel', description: 'Lista de canais', type: '[String]' },
        { name: 'DDD', description: 'Lista de DDDs', type: '[String]' },
        { name: 'uf', description: 'Lista de estados', type: '[String]' },
        { name: 'city', description: 'Lista de cidades', type: '[String]' },
        {
          name: 'communicationFocus',
          description: 'Lista de focu de comunicação',
          type: '[String]'
        },
        {
          name: 'storeModel',
          description: 'Lista de modelo de loja',
          type: '[String]'
        },
        {
          name: 'networkTechnology',
          description: 'Lista de tecnologias de rede',
          type: '[String]'
        },
        {
          name: 'marketedProduct',
          description: 'Lista de produtos comercializados',
          type: '[String]'
        },
        {
          name: 'region',
          description: 'Regional relacionada ao local',
          type: '[String]'
        },
        {
          name: 'adminId',
          description: 'ID do admin do local',
          type: 'String'
        },
        { name: 'CNPJ', description: 'CNPJ do local', type: '[String]' },
        {
          name: 'perPage',
          description: 'Número de resultados por página',
          type: 'Int'
        },
        { name: 'page', description: 'Página dos dados', type: 'Int' },
        { name: 'razao_social', description: 'Razão social', type: 'String' }
      ],
      name: 'PlaceSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceSearchByIdsTypeAndName: {
    type: 'PlaceSearchByIdsTypeAndName',
    schemaKey: 'PlaceSearchByIdsTypeAndName',
    entityName: 'PlaceSearchByIdsTypeAndName',
    isList: false,
    argsTSName: 'QueryPlaceSearchByIdsTypeAndNameArgs',
    returnTSName: "Query['PlaceSearchByIdsTypeAndName']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos locais filtrando por id e produtos relacionados ao inventário',
      deprecationReason: null,
      type: 'PlaceSearchByIdsTypeAndName',
      args: [
        {
          name: 'id',
          description: 'Lista de ids relacionadas ao local',
          type: '[String]'
        },
        {
          name: 'name',
          description: 'Nome fantasia ou razão social relacionada ao local',
          type: 'String'
        },
        {
          name: 'type',
          description: 'Lista de tipos relacionados aos locais',
          type: '[String]'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'PlaceSearchByIdsTypeAndName',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceSearchByChannelSapCodeOrPlaceAndRegion: {
    type: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
    schemaKey: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
    entityName: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
    isList: false,
    argsTSName: 'QueryPlaceSearchByChannelSapCodeOrPlaceAndRegionArgs',
    returnTSName: "Query['PlaceSearchByChannelSapCodeOrPlaceAndRegion']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca da tabela de preço por região e canal',
      deprecationReason: null,
      type: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
      args: [
        {
          name: 'disabled',
          description: 'Status da tabela de preço',
          type: 'Boolean'
        },
        {
          name: 'filterLocationType',
          description: 'Canal da tabela de preço',
          type: '[String]'
        },
        {
          name: 'filterRegions',
          description: 'Região da tabela de preço',
          type: '[String]'
        },
        {
          name: 'filterSapCodeOrPlaceName',
          description: 'Código SAP ou nome do local',
          type: 'String'
        },
        {
          name: 'perPage',
          description: 'Número de resultados por página',
          type: 'Int'
        },
        { name: 'page', description: 'Página dos dados', type: 'Int' }
      ],
      name: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceSearchByIdsLpuCategoryAndContainsInventory: {
    type: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
    schemaKey: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
    entityName: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
    isList: false,
    argsTSName: 'QueryPlaceSearchByIdsLpuCategoryAndContainsInventoryArgs',
    returnTSName: "Query['PlaceSearchByIdsLpuCategoryAndContainsInventory']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos locais filtrando por id e produtos relacionados ao inventário',
      deprecationReason: null,
      type: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
      args: [
        {
          name: 'id',
          description: 'Lista de ids relacionadas ao local',
          type: '[String]'
        },
        {
          name: 'lpu',
          description: 'Lista de ids relacionadas a lpu',
          type: '[String]'
        },
        {
          name: 'categoryLpu',
          description:
            'Se a lpu precisa ter uma categoria em especifico (estrutural/merchandising)',
          type: 'String'
        },
        {
          name: 'relatedToCategoryLpu',
          description:
            'Se a lpu precisa estar relacionada a outra lpu a categoria em questão',
          type: 'String'
        },
        {
          name: 'containsInventory',
          description: 'Se tem inventario ou não',
          type: 'Boolean'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'PlaceSearchByIdsLpuCategoryAndContainsInventory',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacePaginationCustom: {
    type: 'PlacePaginationCustom',
    schemaKey: 'PlacePaginationCustom',
    entityName: 'PlacePaginationCustom',
    isList: false,
    argsTSName: 'QueryPlacePaginationCustomArgs',
    returnTSName: "Query['PlacePaginationCustom']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlacePaginationCustom',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int' },
        { name: 'filter', description: null, type: 'JSON' },
        { name: 'sort', description: null, type: 'SortFindManyPlaceInput' }
      ],
      name: 'PlacePaginationCustom',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LocationOptions: {
    type: 'LocationOptionsPayload',
    schemaKey: 'LocationOptions',
    entityName: 'LocationOptionsPayload',
    isList: false,
    argsTSName: 'QueryLocationOptionsArgs',
    returnTSName: "Query['LocationOptions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'LocationOptionsPayload',
      args: [
        { name: 'uf', description: 'UF', type: 'String' },
        { name: 'region', description: 'Região', type: 'String' }
      ],
      name: 'LocationOptions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceSearchByCnpj: {
    type: 'Place',
    schemaKey: 'PlaceSearchByCnpj',
    entityName: 'Place',
    isList: false,
    argsTSName: 'QueryPlaceSearchByCnpjArgs',
    returnTSName: "Query['PlaceSearchByCnpj']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca de um place através do CNPJ',
      deprecationReason: null,
      type: 'Place',
      args: [{ name: 'CNPJ', description: 'CNPJ do local', type: '[String]' }],
      name: 'PlaceSearchByCnpj',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacesBaseDownload: {
    type: 'BaseDownload',
    schemaKey: 'PlacesBaseDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryPlacesBaseDownloadArgs',
    returnTSName: "Query['PlacesBaseDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'filters', description: null, type: 'JSON' },
        { name: 'isExample', description: null, type: 'Boolean' }
      ],
      name: 'PlacesBaseDownload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacesBaseValidation: {
    type: 'JSON',
    schemaKey: 'PlacesBaseValidation',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryPlacesBaseValidationArgs',
    returnTSName: "Query['PlacesBaseValidation']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [{ name: 'fileId', description: null, type: 'String!' }],
      name: 'PlacesBaseValidation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacesPermissions: {
    type: '[PlacesPermissionsEnum]',
    schemaKey: 'PlacesPermissions',
    entityName: 'PlacesPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['PlacesPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PlacesPermissionsEnum]',
      args: [],
      name: 'PlacesPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ContractPagination: {
    type: 'ContractPagination',
    schemaKey: 'ContractPagination',
    entityName: 'ContractPagination',
    isList: false,
    argsTSName: 'QueryContractPaginationArgs',
    returnTSName: "Query['ContractPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ContractPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyContractInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyContractInput' }
      ],
      name: 'ContractPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ContractFindOne: {
    type: 'Contract',
    schemaKey: 'ContractFindOne',
    entityName: 'Contract',
    isList: false,
    argsTSName: 'QueryContractFindOneArgs',
    returnTSName: "Query['ContractFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Contract',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneContractInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneContractInput' }
      ],
      name: 'ContractFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ContractFindByIds: {
    type: '[Contract]',
    schemaKey: 'ContractFindByIds',
    entityName: 'Contract',
    isList: true,
    argsTSName: 'QueryContractFindByIdsArgs',
    returnTSName: "Query['ContractFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Contract]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsContractInput' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ContractFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductPagination: {
    type: 'ProductPagination',
    schemaKey: 'ProductPagination',
    entityName: 'ProductPagination',
    isList: false,
    argsTSName: 'QueryProductPaginationArgs',
    returnTSName: "Query['ProductPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProductPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyProductInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyProductInput' }
      ],
      name: 'ProductPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductFindById: {
    type: 'Product',
    schemaKey: 'ProductFindById',
    entityName: 'Product',
    isList: false,
    argsTSName: 'QueryProductFindByIdArgs',
    returnTSName: "Query['ProductFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Product',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortProductEnum' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ProductFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductFindByIds: {
    type: '[Product]',
    schemaKey: 'ProductFindByIds',
    entityName: 'Product',
    isList: true,
    argsTSName: 'QueryProductFindByIdsArgs',
    returnTSName: "Query['ProductFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Product]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsProductInput' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ProductFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductBaseDownload: {
    type: 'BaseDownload',
    schemaKey: 'ProductBaseDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryProductBaseDownloadArgs',
    returnTSName: "Query['ProductBaseDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'filters', description: null, type: 'JSON' },
        { name: 'isExample', description: null, type: 'Boolean' }
      ],
      name: 'ProductBaseDownload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductBaseValidation: {
    type: 'JSON',
    schemaKey: 'ProductBaseValidation',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryProductBaseValidationArgs',
    returnTSName: "Query['ProductBaseValidation']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [{ name: 'fileId', description: null, type: 'String!' }],
      name: 'ProductBaseValidation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductSearchByNameOrSupplier: {
    type: 'ProductSearchByNameOrSupplier',
    schemaKey: 'ProductSearchByNameOrSupplier',
    entityName: 'ProductSearchByNameOrSupplier',
    isList: false,
    argsTSName: 'QueryProductSearchByNameOrSupplierArgs',
    returnTSName: "Query['ProductSearchByNameOrSupplier']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos produtos por nome',
      deprecationReason: null,
      type: 'ProductSearchByNameOrSupplier',
      args: [
        {
          name: 'description',
          description: 'Abreviação produção',
          type: 'String'
        },
        { name: 'onlyStructural', description: null, type: 'Boolean' },
        { name: 'name', description: 'Nome dos produtos', type: 'String' },
        { name: 'ids', description: null, type: '[String]' },
        {
          name: 'supplier',
          description: 'Razão social ou Nome Fantasia do fornecedor',
          type: 'String'
        },
        {
          name: 'category',
          description: 'Categoria ',
          type: 'EnumProductCategoria'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'ProductSearchByNameOrSupplier',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductSearchByNameOrSupplierAndNotContainsSomeIds: {
    type: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
    schemaKey: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
    entityName: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
    isList: false,
    argsTSName: 'QueryProductSearchByNameOrSupplierAndNotContainsSomeIdsArgs',
    returnTSName: "Query['ProductSearchByNameOrSupplierAndNotContainsSomeIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos produtos por nome',
      deprecationReason: null,
      type: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
      args: [
        { name: 'name', description: 'Nome dos produtos', type: 'String' },
        {
          name: 'supplier',
          description: 'Razão social ou Nome Fantasia do fornecedor',
          type: 'String'
        },
        { name: 'ids', description: 'Ids ignorados', type: '[String]' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductSearchBySupplierZipCodeAndContainsInventory: {
    type: 'ProductSearchBySupplierZipCodeAndContainsInventory',
    schemaKey: 'ProductSearchBySupplierZipCodeAndContainsInventory',
    entityName: 'ProductSearchBySupplierZipCodeAndContainsInventory',
    isList: false,
    argsTSName: 'QueryProductSearchBySupplierZipCodeAndContainsInventoryArgs',
    returnTSName: "Query['ProductSearchBySupplierZipCodeAndContainsInventory']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Executa a busca dos produtos filtrando por id, local do fornecedor e que contem inventário ou não',
      deprecationReason: null,
      type: 'ProductSearchBySupplierZipCodeAndContainsInventory',
      args: [
        {
          name: 'id',
          description: 'Lista de ids relacionadas a lpu',
          type: '[String]!'
        },
        {
          name: 'zipcode',
          description: 'Lista de ceps relacionadas ao fornecedor',
          type: '[String]'
        },
        {
          name: 'containsInventory',
          description: 'Se tem inventario ou não',
          type: 'Boolean'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'ProductSearchBySupplierZipCodeAndContainsInventory',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierPagination: {
    type: 'SupplierPagination',
    schemaKey: 'SupplierPagination',
    entityName: 'SupplierPagination',
    isList: false,
    argsTSName: 'QuerySupplierPaginationArgs',
    returnTSName: "Query['SupplierPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SupplierPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySupplierInput'
        },
        { name: 'sort', description: null, type: 'SortFindManySupplierInput' }
      ],
      name: 'SupplierPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierFindOne: {
    type: 'Supplier',
    schemaKey: 'SupplierFindOne',
    entityName: 'Supplier',
    isList: false,
    argsTSName: 'QuerySupplierFindOneArgs',
    returnTSName: "Query['SupplierFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Supplier',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneSupplierInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneSupplierInput' }
      ],
      name: 'SupplierFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierFindMany: {
    type: '[Supplier]',
    schemaKey: 'SupplierFindMany',
    entityName: 'Supplier',
    isList: true,
    argsTSName: 'QuerySupplierFindManyArgs',
    returnTSName: "Query['SupplierFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Supplier]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySupplierInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManySupplierInput' }
      ],
      name: 'SupplierFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ResolverSupplierSearchByNameAndContainsLpu: {
    type: 'ResolverSupplierSearchByNameAndContainsLpu',
    schemaKey: 'ResolverSupplierSearchByNameAndContainsLpu',
    entityName: 'ResolverSupplierSearchByNameAndContainsLpu',
    isList: false,
    argsTSName: 'QueryResolverSupplierSearchByNameAndContainsLpuArgs',
    returnTSName: "Query['ResolverSupplierSearchByNameAndContainsLpu']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos produtos por nome',
      deprecationReason: null,
      type: 'ResolverSupplierSearchByNameAndContainsLpu',
      args: [
        {
          name: 'name',
          description: 'Razão social ou nome fantasia do fornecedor',
          type: 'String'
        },
        {
          name: 'lpu',
          description: 'Lista de id das lpu relacionadas com o fornecedor',
          type: '[String]'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'ResolverSupplierSearchByNameAndContainsLpu',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierPermissions: {
    type: '[SupplierPermissionsEnum]',
    schemaKey: 'SupplierPermissions',
    entityName: 'SupplierPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['SupplierPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[SupplierPermissionsEnum]',
      args: [],
      name: 'SupplierPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceFindMany: {
    type: '[TablePrice]',
    schemaKey: 'TablePriceFindMany',
    entityName: 'TablePrice',
    isList: true,
    argsTSName: 'QueryTablePriceFindManyArgs',
    returnTSName: "Query['TablePriceFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[TablePrice]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyTablePriceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyTablePriceInput' }
      ],
      name: 'TablePriceFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceFindOne: {
    type: 'TablePrice',
    schemaKey: 'TablePriceFindOne',
    entityName: 'TablePrice',
    isList: false,
    argsTSName: 'QueryTablePriceFindOneArgs',
    returnTSName: "Query['TablePriceFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'TablePrice',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneTablePriceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneTablePriceInput' }
      ],
      name: 'TablePriceFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceFindById: {
    type: 'TablePrice',
    schemaKey: 'TablePriceFindById',
    entityName: 'TablePrice',
    isList: false,
    argsTSName: 'QueryTablePriceFindByIdArgs',
    returnTSName: "Query['TablePriceFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'TablePrice',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortSupplierEnum' }
      ],
      name: 'TablePriceFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePricePagination: {
    type: 'TablePricePagination',
    schemaKey: 'TablePricePagination',
    entityName: 'TablePricePagination',
    isList: false,
    argsTSName: 'QueryTablePricePaginationArgs',
    returnTSName: "Query['TablePricePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'TablePricePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyTablePriceInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyTablePriceInput' }
      ],
      name: 'TablePricePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePrice: {
    type: 'TablePrice',
    schemaKey: 'TablePrice',
    entityName: 'TablePrice',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['TablePrice']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'TablePrice',
      args: [],
      name: 'TablePrice',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds: {
    type: '[TablePrice]',
    schemaKey: 'TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds',
    entityName: 'TablePrice',
    isList: true,
    argsTSName:
      'QueryTablePriceSearchByRegionsLocationTypeAndNotContainsSomeIdsArgs',
    returnTSName:
      "Query['TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Busca por tabelas de preço em alguma das regiões listada',
      deprecationReason: null,
      type: '[TablePrice]',
      args: [
        {
          name: 'regions',
          description: 'Lista de regiões usadas no filtro',
          type: '[String]!'
        },
        {
          name: 'locationType',
          description: 'Lista com tipos de local usadas no filtro',
          type: '[String]'
        },
        {
          name: 'ids',
          description: 'Lista de ids que devem ser ignoradas',
          type: '[String]!'
        }
      ],
      name: 'TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceAccessories: {
    type: '[TablePriceAccessories]',
    schemaKey: 'TablePriceAccessories',
    entityName: 'TablePriceAccessories',
    isList: true,
    argsTSName: 'QueryTablePriceAccessoriesArgs',
    returnTSName: "Query['TablePriceAccessories']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Busca os acessórios na tabela de preço',
      deprecationReason: null,
      type: '[TablePriceAccessories]',
      args: [{ name: 'tablePrice', description: null, type: 'String!' }],
      name: 'TablePriceAccessories',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceSearchBySapCodeAndDeviceModel: {
    type: 'TablePriceSearchBySapCodeAndDeviceModel',
    schemaKey: 'TablePriceSearchBySapCodeAndDeviceModel',
    entityName: 'TablePriceSearchBySapCodeAndDeviceModel',
    isList: false,
    argsTSName: 'QueryTablePriceSearchBySapCodeAndDeviceModelArgs',
    returnTSName: "Query['TablePriceSearchBySapCodeAndDeviceModel']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho',
      deprecationReason: null,
      type: 'TablePriceSearchBySapCodeAndDeviceModel',
      args: [
        { name: 'sapCode', description: null, type: '[String!]' },
        { name: 'status', description: null, type: 'String' },
        { name: 'deviceModel', description: null, type: 'String' },
        { name: 'deviceId', description: null, type: 'String' },
        {
          name: 'debuggerInfo',
          description: null,
          type: 'DeviceNotificationDebuggerInfoInput'
        }
      ],
      name: 'TablePriceSearchBySapCodeAndDeviceModel',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceSearchBySapCode: {
    type: 'listCodes',
    schemaKey: 'TablePriceSearchBySapCode',
    entityName: 'listCodes',
    isList: false,
    argsTSName: 'QueryTablePriceSearchBySapCodeArgs',
    returnTSName: "Query['TablePriceSearchBySapCode']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Busca por tabelas de preço de um código SAP',
      deprecationReason: null,
      type: 'listCodes',
      args: [
        { name: 'sapCode', description: null, type: '[String]' },
        { name: 'status', description: null, type: 'String' }
      ],
      name: 'TablePriceSearchBySapCode',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceDevices: {
    type: '[TablePriceDevices]',
    schemaKey: 'TablePriceDevices',
    entityName: 'TablePriceDevices',
    isList: true,
    argsTSName: 'QueryTablePriceDevicesArgs',
    returnTSName: "Query['TablePriceDevices']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Busca os preços dos dispositivos na tabela de preço',
      deprecationReason: null,
      type: '[TablePriceDevices]',
      args: [
        { name: 'devices', description: null, type: '[String]!' },
        { name: 'tablePrice', description: null, type: 'String!' }
      ],
      name: 'TablePriceDevices',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePriceDeviceBySapCode: {
    type: 'TablePriceDeviceBySapCode',
    schemaKey: 'TablePriceDeviceBySapCode',
    entityName: 'TablePriceDeviceBySapCode',
    isList: false,
    argsTSName: 'QueryTablePriceDeviceBySapCodeArgs',
    returnTSName: "Query['TablePriceDeviceBySapCode']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description:
        'Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho',
      deprecationReason: null,
      type: 'TablePriceDeviceBySapCode',
      args: [
        { name: 'CNPJ', description: null, type: 'String!' },
        { name: 'sapCode', description: null, type: '[String!]' },
        { name: 'deviceModel', description: null, type: 'String' },
        { name: 'deviceId', description: null, type: 'String' },
        {
          name: 'debuggerInfo',
          description: null,
          type: 'DeviceNotificationDebuggerInfoInput'
        }
      ],
      name: 'TablePriceDeviceBySapCode',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  TablePricePermissions: {
    type: '[TablePricePermissionsEnum]',
    schemaKey: 'TablePricePermissions',
    entityName: 'TablePricePermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['TablePricePermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[TablePricePermissionsEnum]',
      args: [],
      name: 'TablePricePermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PricingTableFindById: {
    type: 'PricingTable',
    schemaKey: 'PricingTableFindById',
    entityName: 'PricingTable',
    isList: false,
    argsTSName: 'QueryPricingTableFindByIdArgs',
    returnTSName: "Query['PricingTableFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PricingTable',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortPricingTableEnum' }
      ],
      name: 'PricingTableFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PricingTablePagination: {
    type: 'PricingTablePagination',
    schemaKey: 'PricingTablePagination',
    entityName: 'PricingTablePagination',
    isList: false,
    argsTSName: 'QueryPricingTablePaginationArgs',
    returnTSName: "Query['PricingTablePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PricingTablePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPricingTableInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPricingTableInput'
        }
      ],
      name: 'PricingTablePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PricingTableFindMany: {
    type: '[PricingTable]',
    schemaKey: 'PricingTableFindMany',
    entityName: 'PricingTable',
    isList: true,
    argsTSName: 'QueryPricingTableFindManyArgs',
    returnTSName: "Query['PricingTableFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PricingTable]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPricingTableInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPricingTableInput'
        }
      ],
      name: 'PricingTableFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LegalTextFindById: {
    type: 'LegalText',
    schemaKey: 'LegalTextFindById',
    entityName: 'LegalText',
    isList: false,
    argsTSName: 'QueryLegalTextFindByIdArgs',
    returnTSName: "Query['LegalTextFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'LegalText',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'LegalTextFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LegalTextPagination: {
    type: 'LegalTextPagination',
    schemaKey: 'LegalTextPagination',
    entityName: 'LegalTextPagination',
    isList: false,
    argsTSName: 'QueryLegalTextPaginationArgs',
    returnTSName: "Query['LegalTextPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'LegalTextPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyLegalTextInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyLegalTextInput' }
      ],
      name: 'LegalTextPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceFindMany: {
    type: '[Device]',
    schemaKey: 'DeviceFindMany',
    entityName: 'Device',
    isList: true,
    argsTSName: 'QueryDeviceFindManyArgs',
    returnTSName: "Query['DeviceFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Device]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDeviceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyDeviceInput' }
      ],
      name: 'DeviceFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceFindOne: {
    type: 'Device',
    schemaKey: 'DeviceFindOne',
    entityName: 'Device',
    isList: false,
    argsTSName: 'QueryDeviceFindOneArgs',
    returnTSName: "Query['DeviceFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Device',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneDeviceInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneDeviceInput' }
      ],
      name: 'DeviceFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceFindById: {
    type: 'Device',
    schemaKey: 'DeviceFindById',
    entityName: 'Device',
    isList: false,
    argsTSName: 'QueryDeviceFindByIdArgs',
    returnTSName: "Query['DeviceFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Device',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'DeviceFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DevicePagination: {
    type: 'DevicePagination',
    schemaKey: 'DevicePagination',
    entityName: 'DevicePagination',
    isList: false,
    argsTSName: 'QueryDevicePaginationArgs',
    returnTSName: "Query['DevicePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DevicePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDeviceInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyDeviceInput' }
      ],
      name: 'DevicePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceSearch: {
    type: '[DeviceSearch]',
    schemaKey: 'DeviceSearch',
    entityName: 'DeviceSearch',
    isList: true,
    argsTSName: 'QueryDeviceSearchArgs',
    returnTSName: "Query['DeviceSearch']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do device',
      deprecationReason: null,
      type: '[DeviceSearch]',
      args: [
        { name: 'search', description: 'Modelo parcial', type: 'String' },
        {
          name: 'manufacturers',
          description: 'Filtrar por fabricantes específicos',
          type: '[String]'
        },
        {
          name: 'skipManufacturers',
          description: 'Pular fabricantes específicos',
          type: '[String]'
        }
      ],
      name: 'DeviceSearch',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceFindManufacturers: {
    type: '[DeviceFindManufacturers]',
    schemaKey: 'DeviceFindManufacturers',
    entityName: 'DeviceFindManufacturers',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['DeviceFindManufacturers']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Busca por todas fabricantes dos aparelhos cadastrados',
      deprecationReason: null,
      type: '[DeviceFindManufacturers]',
      args: [],
      name: 'DeviceFindManufacturers',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceSearchById: {
    type: 'Device',
    schemaKey: 'DeviceSearchById',
    entityName: 'Device',
    isList: false,
    argsTSName: 'QueryDeviceSearchByIdArgs',
    returnTSName: "Query['DeviceSearchById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca do device por ID',
      deprecationReason: null,
      type: 'Device',
      args: [
        { name: 'deviceId', description: 'ID do device', type: 'String!' }
      ],
      name: 'DeviceSearchById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceBaseDownload: {
    type: 'DeviceBaseDownloadPayload',
    schemaKey: 'DeviceBaseDownload',
    entityName: 'DeviceBaseDownloadPayload',
    isList: false,
    argsTSName: 'QueryDeviceBaseDownloadArgs',
    returnTSName: "Query['DeviceBaseDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeviceBaseDownloadPayload',
      args: [
        { name: 'filters', description: null, type: 'JSON' },
        { name: 'isExample', description: null, type: 'Boolean' }
      ],
      name: 'DeviceBaseDownload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceBaseValidation: {
    type: 'JSON',
    schemaKey: 'DeviceBaseValidation',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryDeviceBaseValidationArgs',
    returnTSName: "Query['DeviceBaseValidation']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [{ name: 'fileId', description: null, type: 'String!' }],
      name: 'DeviceBaseValidation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AppDevice: {
    type: 'Device',
    schemaKey: 'AppDevice',
    entityName: 'Device',
    isList: false,
    argsTSName: 'QueryAppDeviceArgs',
    returnTSName: "Query['AppDevice']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Device',
      args: [
        { name: 'CNPJ', description: null, type: 'String!' },
        { name: 'model', description: null, type: 'String!' }
      ],
      name: 'AppDevice',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  AppDeviceByManufacturer: {
    type: '[Device]',
    schemaKey: 'AppDeviceByManufacturer',
    entityName: 'Device',
    isList: true,
    argsTSName: 'QueryAppDeviceByManufacturerArgs',
    returnTSName: "Query['AppDeviceByManufacturer']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Device]',
      args: [
        { name: 'CNPJ', description: null, type: 'String!' },
        { name: 'manufacturer', description: null, type: 'String!' }
      ],
      name: 'AppDeviceByManufacturer',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DevicePermissions: {
    type: '[DevicePermissionsEnum]',
    schemaKey: 'DevicePermissions',
    entityName: 'DevicePermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['DevicePermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DevicePermissionsEnum]',
      args: [],
      name: 'DevicePermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureFindMany: {
    type: '[DigitalBrochure]',
    schemaKey: 'DigitalBrochureFindMany',
    entityName: 'DigitalBrochure',
    isList: true,
    argsTSName: 'QueryDigitalBrochureFindManyArgs',
    returnTSName: "Query['DigitalBrochureFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DigitalBrochure]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDigitalBrochureInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDigitalBrochureInput'
        }
      ],
      name: 'DigitalBrochureFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureFindOne: {
    type: 'DigitalBrochure',
    schemaKey: 'DigitalBrochureFindOne',
    entityName: 'DigitalBrochure',
    isList: false,
    argsTSName: 'QueryDigitalBrochureFindOneArgs',
    returnTSName: "Query['DigitalBrochureFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochure',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneDigitalBrochureInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneDigitalBrochureInput'
        }
      ],
      name: 'DigitalBrochureFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureFindById: {
    type: 'DigitalBrochure',
    schemaKey: 'DigitalBrochureFindById',
    entityName: 'DigitalBrochure',
    isList: false,
    argsTSName: 'QueryDigitalBrochureFindByIdArgs',
    returnTSName: "Query['DigitalBrochureFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochure',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'DigitalBrochureFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochurePagination: {
    type: 'DigitalBrochurePagination',
    schemaKey: 'DigitalBrochurePagination',
    entityName: 'DigitalBrochurePagination',
    isList: false,
    argsTSName: 'QueryDigitalBrochurePaginationArgs',
    returnTSName: "Query['DigitalBrochurePagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochurePagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDigitalBrochureInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDigitalBrochureInput'
        }
      ],
      name: 'DigitalBrochurePagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureSearchByDDDAndService: {
    type: 'DigitalBrochureSearchByDDDAndService',
    schemaKey: 'DigitalBrochureSearchByDDDAndService',
    entityName: 'DigitalBrochureSearchByDDDAndService',
    isList: false,
    argsTSName: 'QueryDigitalBrochureSearchByDddAndServiceArgs',
    returnTSName: "Query['DigitalBrochureSearchByDDDAndService']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos folhetos digitais',
      deprecationReason: null,
      type: 'DigitalBrochureSearchByDDDAndService',
      args: [
        {
          name: 'filterDDD',
          description: 'DDD da região do folheto digital',
          type: 'String'
        },
        {
          name: 'filterService',
          description: 'Serviço relacionado ao folheto digital',
          type: 'String'
        },
        {
          name: 'perPage',
          description: 'Número de resultados por página',
          type: 'Int'
        },
        { name: 'page', description: 'Página dos dados', type: 'Int' }
      ],
      name: 'DigitalBrochureSearchByDDDAndService',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochurePermissions: {
    type: '[DigitalBrochurePermissionsEnum]',
    schemaKey: 'DigitalBrochurePermissions',
    entityName: 'DigitalBrochurePermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['DigitalBrochurePermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DigitalBrochurePermissionsEnum]',
      args: [],
      name: 'DigitalBrochurePermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignPagination: {
    type: 'CampaignPagination',
    schemaKey: 'CampaignPagination',
    entityName: 'CampaignPagination',
    isList: false,
    argsTSName: 'QueryCampaignPaginationArgs',
    returnTSName: "Query['CampaignPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CampaignPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyCampaignInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyCampaignInput' }
      ],
      name: 'CampaignPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignFindOne: {
    type: 'Campaign',
    schemaKey: 'CampaignFindOne',
    entityName: 'Campaign',
    isList: false,
    argsTSName: 'QueryCampaignFindOneArgs',
    returnTSName: "Query['CampaignFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Campaign',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneCampaignInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneCampaignInput' }
      ],
      name: 'CampaignFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignFindById: {
    type: 'Campaign',
    schemaKey: 'CampaignFindById',
    entityName: 'Campaign',
    isList: false,
    argsTSName: 'QueryCampaignFindByIdArgs',
    returnTSName: "Query['CampaignFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Campaign',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortCampaignEnum' }
      ],
      name: 'CampaignFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignSearchByTitleDescriptionOrStatus: {
    type: 'CampaignSearchByTitleDescriptionOrStatus',
    schemaKey: 'CampaignSearchByTitleDescriptionOrStatus',
    entityName: 'CampaignSearchByTitleDescriptionOrStatus',
    isList: false,
    argsTSName: 'QueryCampaignSearchByTitleDescriptionOrStatusArgs',
    returnTSName: "Query['CampaignSearchByTitleDescriptionOrStatus']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca das campanhas por titulo e descricao',
      deprecationReason: null,
      type: 'CampaignSearchByTitleDescriptionOrStatus',
      args: [
        {
          name: 'title',
          description: 'Titulo ou descrição das campanhas',
          type: 'String'
        },
        {
          name: 'archived',
          description: 'Se a campanha está arquivada',
          type: 'Boolean'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'CampaignSearchByTitleDescriptionOrStatus',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignSearchByTitleOrDescription: {
    type: 'CampaignSearchByTitleOrDescription',
    schemaKey: 'CampaignSearchByTitleOrDescription',
    entityName: 'CampaignSearchByTitleOrDescription',
    isList: false,
    argsTSName: 'QueryCampaignSearchByTitleOrDescriptionArgs',
    returnTSName: "Query['CampaignSearchByTitleOrDescription']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca das campanhas por titulo e descricao',
      deprecationReason: null,
      type: 'CampaignSearchByTitleOrDescription',
      args: [
        {
          name: 'title',
          description: 'Titulo ou descrição das campanhas',
          type: 'String'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'CampaignSearchByTitleOrDescription',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  CampaignPermissions: {
    type: '[CampaignPermissionsEnum]',
    schemaKey: 'CampaignPermissions',
    entityName: 'CampaignPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['CampaignPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[CampaignPermissionsEnum]',
      args: [],
      name: 'CampaignPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutPagination: {
    type: 'LayoutPagination',
    schemaKey: 'LayoutPagination',
    entityName: 'LayoutPagination',
    isList: false,
    argsTSName: 'QueryLayoutPaginationArgs',
    returnTSName: "Query['LayoutPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'LayoutPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyLayoutInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyLayoutInput' }
      ],
      name: 'LayoutPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutFindOne: {
    type: 'Layout',
    schemaKey: 'LayoutFindOne',
    entityName: 'Layout',
    isList: false,
    argsTSName: 'QueryLayoutFindOneArgs',
    returnTSName: "Query['LayoutFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Layout',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneLayoutInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneLayoutInput' }
      ],
      name: 'LayoutFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutFindMany: {
    type: '[Layout]',
    schemaKey: 'LayoutFindMany',
    entityName: 'Layout',
    isList: true,
    argsTSName: 'QueryLayoutFindManyArgs',
    returnTSName: "Query['LayoutFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Layout]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyLayoutInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyLayoutInput' }
      ],
      name: 'LayoutFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutFindByIds: {
    type: '[Layout]',
    schemaKey: 'LayoutFindByIds',
    entityName: 'Layout',
    isList: true,
    argsTSName: 'QueryLayoutFindByIdsArgs',
    returnTSName: "Query['LayoutFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Layout]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindByIdsLayoutInput' }
      ],
      name: 'LayoutFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutSearchByName: {
    type: 'LayoutSearchByName',
    schemaKey: 'LayoutSearchByName',
    entityName: 'LayoutSearchByName',
    isList: false,
    argsTSName: 'QueryLayoutSearchByNameArgs',
    returnTSName: "Query['LayoutSearchByName']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos layouts por nome',
      deprecationReason: null,
      type: 'LayoutSearchByName',
      args: [
        { name: 'name', description: 'Nome dos layouts', type: 'String' },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'LayoutSearchByName',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutSearchByNameAndContainsLpuIntervalQty: {
    type: 'LayoutSearchByNameAndContainsLpuIntervalQty',
    schemaKey: 'LayoutSearchByNameAndContainsLpuIntervalQty',
    entityName: 'LayoutSearchByNameAndContainsLpuIntervalQty',
    isList: false,
    argsTSName: 'QueryLayoutSearchByNameAndContainsLpuIntervalQtyArgs',
    returnTSName: "Query['LayoutSearchByNameAndContainsLpuIntervalQty']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca dos layouts por nome',
      deprecationReason: null,
      type: 'LayoutSearchByNameAndContainsLpuIntervalQty',
      args: [
        { name: 'name', description: 'Nome dos layouts', type: 'String' },
        {
          name: 'containsIntervalQty',
          description: 'Contain intervao de quantidade nos layouts',
          type: 'Boolean'
        },
        { name: 'perPage', description: 'Resultados por página', type: 'Int' },
        { name: 'page', description: 'Página atual', type: 'Int' }
      ],
      name: 'LayoutSearchByNameAndContainsLpuIntervalQty',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutSearchContentByIds: {
    type: 'LayoutContentListPayload',
    schemaKey: 'LayoutSearchContentByIds',
    entityName: 'LayoutContentListPayload',
    isList: false,
    argsTSName: 'QueryLayoutSearchContentByIdsArgs',
    returnTSName: "Query['LayoutSearchContentByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: 'Executa a busca de conteúdo dos layouts por data',
      deprecationReason: null,
      type: 'LayoutContentListPayload',
      args: [
        {
          name: 'ids',
          description: 'Lista de dados dos layouts',
          type: '[String]'
        }
      ],
      name: 'LayoutSearchContentByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  LayoutPermissions: {
    type: '[LayoutPermissionsEnum]',
    schemaKey: 'LayoutPermissions',
    entityName: 'LayoutPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['LayoutPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[LayoutPermissionsEnum]',
      args: [],
      name: 'LayoutPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionalBudgetFindOne: {
    type: 'RegionalBudget',
    schemaKey: 'RegionalBudgetFindOne',
    entityName: 'RegionalBudget',
    isList: false,
    argsTSName: 'QueryRegionalBudgetFindOneArgs',
    returnTSName: "Query['RegionalBudgetFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionalBudget',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneRegionalBudgetInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneRegionalBudgetInput'
        }
      ],
      name: 'RegionalBudgetFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionalBudgetFindById: {
    type: 'RegionalBudget',
    schemaKey: 'RegionalBudgetFindById',
    entityName: 'RegionalBudget',
    isList: false,
    argsTSName: 'QueryRegionalBudgetFindByIdArgs',
    returnTSName: "Query['RegionalBudgetFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionalBudget',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'RegionalBudgetFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionalBudgetPagination: {
    type: 'RegionalBudgetPagination',
    schemaKey: 'RegionalBudgetPagination',
    entityName: 'RegionalBudgetPagination',
    isList: false,
    argsTSName: 'QueryRegionalBudgetPaginationArgs',
    returnTSName: "Query['RegionalBudgetPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionalBudgetPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegionalBudgetInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyRegionalBudgetInput'
        }
      ],
      name: 'RegionalBudgetPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductionFindById: {
    type: 'Production',
    schemaKey: 'ProductionFindById',
    entityName: 'Production',
    isList: false,
    argsTSName: 'QueryProductionFindByIdArgs',
    returnTSName: "Query['ProductionFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Production',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'SortProductionEnum' }
      ],
      name: 'ProductionFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductionPagination: {
    type: 'ProductionPagination',
    schemaKey: 'ProductionPagination',
    entityName: 'ProductionPagination',
    isList: false,
    argsTSName: 'QueryProductionPaginationArgs',
    returnTSName: "Query['ProductionPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProductionPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyProductionInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyProductionInput' }
      ],
      name: 'ProductionPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductionReport: {
    type: 'ProductionReportPayload',
    schemaKey: 'ProductionReport',
    entityName: 'ProductionReportPayload',
    isList: false,
    argsTSName: 'QueryProductionReportArgs',
    returnTSName: "Query['ProductionReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProductionReportPayload',
      args: [{ name: 'productionId', description: null, type: 'String' }],
      name: 'ProductionReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductionDeliveryReport: {
    type: 'ProductionReportPayload',
    schemaKey: 'ProductionDeliveryReport',
    entityName: 'ProductionReportPayload',
    isList: false,
    argsTSName: 'QueryProductionDeliveryReportArgs',
    returnTSName: "Query['ProductionDeliveryReport']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProductionReportPayload',
      args: [{ name: 'productionId', description: null, type: 'String' }],
      name: 'ProductionDeliveryReport',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProductionPermissions: {
    type: '[ProductionPermissionsEnum]',
    schemaKey: 'ProductionPermissions',
    entityName: 'ProductionPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['ProductionPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[ProductionPermissionsEnum]',
      args: [],
      name: 'ProductionPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BudgetFindOne: {
    type: 'Budget',
    schemaKey: 'BudgetFindOne',
    entityName: 'Budget',
    isList: false,
    argsTSName: 'QueryBudgetFindOneArgs',
    returnTSName: "Query['BudgetFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Budget',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneBudgetInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneBudgetInput' }
      ],
      name: 'BudgetFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BudgetFindById: {
    type: 'Budget',
    schemaKey: 'BudgetFindById',
    entityName: 'Budget',
    isList: false,
    argsTSName: 'QueryBudgetFindByIdArgs',
    returnTSName: "Query['BudgetFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Budget',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'BudgetFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BudgetPagination: {
    type: 'BudgetPagination',
    schemaKey: 'BudgetPagination',
    entityName: 'BudgetPagination',
    isList: false,
    argsTSName: 'QueryBudgetPaginationArgs',
    returnTSName: "Query['BudgetPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BudgetPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyBudgetInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyBudgetInput' }
      ],
      name: 'BudgetPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  BudgetPermissions: {
    type: '[BudgetPermissionsEnum]',
    schemaKey: 'BudgetPermissions',
    entityName: 'BudgetPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['BudgetPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[BudgetPermissionsEnum]',
      args: [],
      name: 'BudgetPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierDeliveryPagination: {
    type: 'SupplierDeliveryPagination',
    schemaKey: 'SupplierDeliveryPagination',
    entityName: 'SupplierDeliveryPagination',
    isList: false,
    argsTSName: 'QuerySupplierDeliveryPaginationArgs',
    returnTSName: "Query['SupplierDeliveryPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'SupplierDeliveryPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySupplierDeliveryInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySupplierDeliveryInput'
        }
      ],
      name: 'SupplierDeliveryPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  SupplierDeliveryFindMany: {
    type: '[SupplierDelivery]',
    schemaKey: 'SupplierDeliveryFindMany',
    entityName: 'SupplierDelivery',
    isList: true,
    argsTSName: 'QuerySupplierDeliveryFindManyArgs',
    returnTSName: "Query['SupplierDeliveryFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[SupplierDelivery]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManySupplierDeliveryInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManySupplierDeliveryInput'
        }
      ],
      name: 'SupplierDeliveryFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryItemFindById: {
    type: 'InventoryItem',
    schemaKey: 'InventoryItemFindById',
    entityName: 'InventoryItem',
    isList: false,
    argsTSName: 'QueryInventoryItemFindByIdArgs',
    returnTSName: "Query['InventoryItemFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'InventoryItem',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'InventoryItemFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryItemFindOne: {
    type: 'InventoryItem',
    schemaKey: 'InventoryItemFindOne',
    entityName: 'InventoryItem',
    isList: false,
    argsTSName: 'QueryInventoryItemFindOneArgs',
    returnTSName: "Query['InventoryItemFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'InventoryItem',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneInventoryItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneInventoryItemInput'
        }
      ],
      name: 'InventoryItemFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryItemPagination: {
    type: 'InventoryItemPagination',
    schemaKey: 'InventoryItemPagination',
    entityName: 'InventoryItemPagination',
    isList: false,
    argsTSName: 'QueryInventoryItemPaginationArgs',
    returnTSName: "Query['InventoryItemPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'InventoryItemPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyInventoryItemInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyInventoryItemInput'
        }
      ],
      name: 'InventoryItemPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryItemFindMany: {
    type: '[InventoryItem]',
    schemaKey: 'InventoryItemFindMany',
    entityName: 'InventoryItem',
    isList: true,
    argsTSName: 'QueryInventoryItemFindManyArgs',
    returnTSName: "Query['InventoryItemFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[InventoryItem]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyInventoryItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyInventoryItemInput'
        }
      ],
      name: 'InventoryItemFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryWithDrawPagination: {
    type: 'InventoryWithDrawPagination',
    schemaKey: 'InventoryWithDrawPagination',
    entityName: 'InventoryWithDrawPagination',
    isList: false,
    argsTSName: 'QueryInventoryWithDrawPaginationArgs',
    returnTSName: "Query['InventoryWithDrawPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'InventoryWithDrawPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyInventoryWithDrawInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyInventoryWithDrawInput'
        }
      ],
      name: 'InventoryWithDrawPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  InventoryWithDrawFindById: {
    type: 'InventoryWithDraw',
    schemaKey: 'InventoryWithDrawFindById',
    entityName: 'InventoryWithDraw',
    isList: false,
    argsTSName: 'QueryInventoryWithDrawFindByIdArgs',
    returnTSName: "Query['InventoryWithDrawFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'InventoryWithDraw',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'InventoryWithDrawFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeliveryOrderFindById: {
    type: 'DeliveryOrder',
    schemaKey: 'DeliveryOrderFindById',
    entityName: 'DeliveryOrder',
    isList: false,
    argsTSName: 'QueryDeliveryOrderFindByIdArgs',
    returnTSName: "Query['DeliveryOrderFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeliveryOrder',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'sort', description: null, type: 'SortDeliveryOrderEnum' }
      ],
      name: 'DeliveryOrderFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeliveryOrderPagination: {
    type: 'DeliveryOrderPagination',
    schemaKey: 'DeliveryOrderPagination',
    entityName: 'DeliveryOrderPagination',
    isList: false,
    argsTSName: 'QueryDeliveryOrderPaginationArgs',
    returnTSName: "Query['DeliveryOrderPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeliveryOrderPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDeliveryOrderInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDeliveryOrderInput'
        }
      ],
      name: 'DeliveryOrderPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeliveryOrderValidation: {
    type: 'DeliveryOrderValidatedReport',
    schemaKey: 'DeliveryOrderValidation',
    entityName: 'DeliveryOrderValidatedReport',
    isList: false,
    argsTSName: 'QueryDeliveryOrderValidationArgs',
    returnTSName: "Query['DeliveryOrderValidation']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeliveryOrderValidatedReport',
      args: [{ name: 'orderIds', description: null, type: '[String]' }],
      name: 'DeliveryOrderValidation',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeliveryOrderExecution: {
    type: '[DeliveryOrderRecords]',
    schemaKey: 'DeliveryOrderExecution',
    entityName: 'DeliveryOrderRecords',
    isList: true,
    argsTSName: 'QueryDeliveryOrderExecutionArgs',
    returnTSName: "Query['DeliveryOrderExecution']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DeliveryOrderRecords]',
      args: [{ name: 'orderIds', description: null, type: '[String]' }],
      name: 'DeliveryOrderExecution',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserLogisticsPermissions: {
    type: '[LogisticsPermissionsEnum]',
    schemaKey: 'UserLogisticsPermissions',
    entityName: 'LogisticsPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['UserLogisticsPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[LogisticsPermissionsEnum]',
      args: [],
      name: 'UserLogisticsPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MetadataFindOne: {
    type: 'Metadata',
    schemaKey: 'MetadataFindOne',
    entityName: 'Metadata',
    isList: false,
    argsTSName: 'QueryMetadataFindOneArgs',
    returnTSName: "Query['MetadataFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Metadata',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneMetadataInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneMetadataInput' }
      ],
      name: 'MetadataFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MetadataFindById: {
    type: 'Metadata',
    schemaKey: 'MetadataFindById',
    entityName: 'Metadata',
    isList: false,
    argsTSName: 'QueryMetadataFindByIdArgs',
    returnTSName: "Query['MetadataFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Metadata',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MetadataFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorFindById: {
    type: 'Positivator',
    schemaKey: 'PositivatorFindById',
    entityName: 'Positivator',
    isList: false,
    argsTSName: 'QueryPositivatorFindByIdArgs',
    returnTSName: "Query['PositivatorFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Positivator',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'PositivatorFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorFindMany: {
    type: '[Positivator]',
    schemaKey: 'PositivatorFindMany',
    entityName: 'Positivator',
    isList: true,
    argsTSName: 'QueryPositivatorFindManyArgs',
    returnTSName: "Query['PositivatorFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Positivator]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPositivatorInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPositivatorInput'
        }
      ],
      name: 'PositivatorFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorPagination: {
    type: 'PositivatorPagination',
    schemaKey: 'PositivatorPagination',
    entityName: 'PositivatorPagination',
    isList: false,
    argsTSName: 'QueryPositivatorPaginationArgs',
    returnTSName: "Query['PositivatorPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PositivatorPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPositivatorInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPositivatorInput'
        }
      ],
      name: 'PositivatorPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacePositivators: {
    type: 'PlacePositivatorsPayload',
    schemaKey: 'PlacePositivators',
    entityName: 'PlacePositivatorsPayload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['PlacePositivators']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlacePositivatorsPayload',
      args: [],
      name: 'PlacePositivators',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivationSumary: {
    type: 'JSON',
    schemaKey: 'PositivationSumary',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'QueryPositivationSumaryArgs',
    returnTSName: "Query['PositivationSumary']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [
        { name: 'region', description: null, type: 'String' },
        { name: 'type', description: null, type: 'String' },
        { name: 'initialDate', description: null, type: 'String' },
        { name: 'finishDate', description: null, type: 'String' }
      ],
      name: 'PositivationSumary',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlacePositivationPagination: {
    type: 'PlacePositivationPagination',
    schemaKey: 'PlacePositivationPagination',
    entityName: 'PlacePositivationPagination',
    isList: false,
    argsTSName: 'QueryPlacePositivationPaginationArgs',
    returnTSName: "Query['PlacePositivationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'PlacePositivationPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int' },
        { name: 'filter', description: null, type: 'JSON' },
        { name: 'sort', description: null, type: 'String' }
      ],
      name: 'PlacePositivationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivationReportByGroup: {
    type: 'BaseDownload',
    schemaKey: 'PositivationReportByGroup',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryPositivationReportByGroupArgs',
    returnTSName: "Query['PositivationReportByGroup']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'initialDate', description: null, type: 'String' },
        { name: 'finishDate', description: null, type: 'String' }
      ],
      name: 'PositivationReportByGroup',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivationReportByItem: {
    type: 'BaseDownload',
    schemaKey: 'PositivationReportByItem',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryPositivationReportByItemArgs',
    returnTSName: "Query['PositivationReportByItem']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'initialDate', description: null, type: 'String' },
        { name: 'finishDate', description: null, type: 'String' }
      ],
      name: 'PositivationReportByItem',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivationReportByPlace: {
    type: 'BaseDownload',
    schemaKey: 'PositivationReportByPlace',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryPositivationReportByPlaceArgs',
    returnTSName: "Query['PositivationReportByPlace']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'initialDate', description: null, type: 'String' },
        { name: 'finishDate', description: null, type: 'String' }
      ],
      name: 'PositivationReportByPlace',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorPermissions: {
    type: '[PositivatorPermissionsEnum]',
    schemaKey: 'PositivatorPermissions',
    entityName: 'PositivatorPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['PositivatorPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PositivatorPermissionsEnum]',
      args: [],
      name: 'PositivatorPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorItemFindById: {
    type: 'PositivatorItem',
    schemaKey: 'PositivatorItemFindById',
    entityName: 'PositivatorItem',
    isList: false,
    argsTSName: 'QueryPositivatorItemFindByIdArgs',
    returnTSName: "Query['PositivatorItemFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PositivatorItem',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PositivatorItemFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorItemPagination: {
    type: 'PositivatorItemPagination',
    schemaKey: 'PositivatorItemPagination',
    entityName: 'PositivatorItemPagination',
    isList: false,
    argsTSName: 'QueryPositivatorItemPaginationArgs',
    returnTSName: "Query['PositivatorItemPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PositivatorItemPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPositivatorItemInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPositivatorItemInput'
        }
      ],
      name: 'PositivatorItemPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PositivatorItemFindMany: {
    type: '[PositivatorItem]',
    schemaKey: 'PositivatorItemFindMany',
    entityName: 'PositivatorItem',
    isList: true,
    argsTSName: 'QueryPositivatorItemFindManyArgs',
    returnTSName: "Query['PositivatorItemFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PositivatorItem]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPositivatorItemInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPositivatorItemInput'
        }
      ],
      name: 'PositivatorItemFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DeviceNotificationPagination: {
    type: 'DeviceNotificationPagination',
    schemaKey: 'DeviceNotificationPagination',
    entityName: 'DeviceNotificationPagination',
    isList: false,
    argsTSName: 'QueryDeviceNotificationPaginationArgs',
    returnTSName: "Query['DeviceNotificationPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeviceNotificationPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDeviceNotificationInput',
          defaultValue: { includeSolved: false }
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDeviceNotificationInput'
        }
      ],
      name: 'DeviceNotificationPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserMerchanPagination: {
    type: 'UserMerchanPagination',
    schemaKey: 'UserMerchanPagination',
    entityName: 'UserMerchanPagination',
    isList: false,
    argsTSName: 'QueryUserMerchanPaginationArgs',
    returnTSName: "Query['UserMerchanPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserMerchanPagination',
      args: [
        { name: 'page', description: null, type: 'Int' },
        { name: 'perPage', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int' },
        { name: 'filter', description: null, type: 'FilterFindManyUserInput' },
        { name: 'sort', description: null, type: 'SortFindManyUserInput' }
      ],
      name: 'UserMerchanPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UsersBaseDownload: {
    type: 'BaseDownload',
    schemaKey: 'UsersBaseDownload',
    entityName: 'BaseDownload',
    isList: false,
    argsTSName: 'QueryUsersBaseDownloadArgs',
    returnTSName: "Query['UsersBaseDownload']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'BaseDownload',
      args: [
        { name: 'filters', description: null, type: 'JSON' },
        { name: 'isExample', description: null, type: 'Boolean' }
      ],
      name: 'UsersBaseDownload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MerchanUserCheck: {
    type: 'MerchanUserCheck',
    schemaKey: 'MerchanUserCheck',
    entityName: 'MerchanUserCheck',
    isList: false,
    argsTSName: 'QueryMerchanUserCheckArgs',
    returnTSName: "Query['MerchanUserCheck']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MerchanUserCheck',
      args: [
        { name: 'CPF', description: null, type: 'String' },
        { name: 'CNPJ', description: null, type: 'String' }
      ],
      name: 'MerchanUserCheck',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  UserMerchanPermissions: {
    type: '[UserMerchanPermissionsEnum]',
    schemaKey: 'UserMerchanPermissions',
    entityName: 'UserMerchanPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['UserMerchanPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[UserMerchanPermissionsEnum]',
      args: [],
      name: 'UserMerchanPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceAnalyticsPagination: {
    type: 'PlaceAnalyticsPagination',
    schemaKey: 'PlaceAnalyticsPagination',
    entityName: 'PlaceAnalyticsPagination',
    isList: false,
    argsTSName: 'QueryPlaceAnalyticsPaginationArgs',
    returnTSName: "Query['PlaceAnalyticsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlaceAnalyticsPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPlaceAnalyticsInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPlaceAnalyticsInput'
        }
      ],
      name: 'PlaceAnalyticsPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceAnalyticsFindOne: {
    type: 'PlaceAnalytics',
    schemaKey: 'PlaceAnalyticsFindOne',
    entityName: 'PlaceAnalytics',
    isList: false,
    argsTSName: 'QueryPlaceAnalyticsFindOneArgs',
    returnTSName: "Query['PlaceAnalyticsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlaceAnalytics',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOnePlaceAnalyticsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOnePlaceAnalyticsInput'
        }
      ],
      name: 'PlaceAnalyticsFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceAnalyticsFindByIds: {
    type: '[PlaceAnalytics]',
    schemaKey: 'PlaceAnalyticsFindByIds',
    entityName: 'PlaceAnalytics',
    isList: true,
    argsTSName: 'QueryPlaceAnalyticsFindByIdsArgs',
    returnTSName: "Query['PlaceAnalyticsFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PlaceAnalytics]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsPlaceAnalyticsInput'
        }
      ],
      name: 'PlaceAnalyticsFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceAnalyticsFindById: {
    type: 'PlaceAnalytics',
    schemaKey: 'PlaceAnalyticsFindById',
    entityName: 'PlaceAnalytics',
    isList: false,
    argsTSName: 'QueryPlaceAnalyticsFindByIdArgs',
    returnTSName: "Query['PlaceAnalyticsFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PlaceAnalytics',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PlaceAnalyticsFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PlaceAnalyticsFindMany: {
    type: '[PlaceAnalytics]',
    schemaKey: 'PlaceAnalyticsFindMany',
    entityName: 'PlaceAnalytics',
    isList: true,
    argsTSName: 'QueryPlaceAnalyticsFindManyArgs',
    returnTSName: "Query['PlaceAnalyticsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[PlaceAnalytics]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPlaceAnalyticsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyPlaceAnalyticsInput'
        }
      ],
      name: 'PlaceAnalyticsFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  StructuralFindMany: {
    type: '[Structural]',
    schemaKey: 'StructuralFindMany',
    entityName: 'Structural',
    isList: true,
    argsTSName: 'QueryStructuralFindManyArgs',
    returnTSName: "Query['StructuralFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[Structural]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyStructuralInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        { name: 'sort', description: null, type: 'SortFindManyStructuralInput' }
      ],
      name: 'StructuralFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  StructuralFindOne: {
    type: 'Structural',
    schemaKey: 'StructuralFindOne',
    entityName: 'Structural',
    isList: false,
    argsTSName: 'QueryStructuralFindOneArgs',
    returnTSName: "Query['StructuralFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Structural',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneStructuralInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'sort', description: null, type: 'SortFindOneStructuralInput' }
      ],
      name: 'StructuralFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  StructuralFindById: {
    type: 'Structural',
    schemaKey: 'StructuralFindById',
    entityName: 'Structural',
    isList: false,
    argsTSName: 'QueryStructuralFindByIdArgs',
    returnTSName: "Query['StructuralFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Structural',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'SortSupplierEnum' }
      ],
      name: 'StructuralFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  StructuralPagination: {
    type: 'StructuralPagination',
    schemaKey: 'StructuralPagination',
    entityName: 'StructuralPagination',
    isList: false,
    argsTSName: 'QueryStructuralPaginationArgs',
    returnTSName: "Query['StructuralPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'StructuralPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyStructuralInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyStructuralInput' }
      ],
      name: 'StructuralPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  Structural: {
    type: 'Structural',
    schemaKey: 'Structural',
    entityName: 'Structural',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['Structural']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Structural',
      args: [],
      name: 'Structural',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  StructuralPermissions: {
    type: '[StructuralPermissionsEnum]',
    schemaKey: 'StructuralPermissions',
    entityName: 'StructuralPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['StructuralPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[StructuralPermissionsEnum]',
      args: [],
      name: 'StructuralPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  ProjectSLA: {
    type: 'ProjectSLAPayload',
    schemaKey: 'ProjectSLA',
    entityName: 'ProjectSLAPayload',
    isList: false,
    argsTSName: 'QueryProjectSlaArgs',
    returnTSName: "Query['ProjectSLA']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProjectSLAPayload',
      args: [
        {
          name: 'technicalVisit',
          description: 'Haverá visita técnica?',
          type: 'Boolean'
        },
        {
          name: 'projetoComplementar',
          description: 'Haverá projeto complementar?',
          type: 'Boolean'
        }
      ],
      name: 'ProjectSLA',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsPagination: {
    type: 'DigitalBrochureAnalyticsPagination',
    schemaKey: 'DigitalBrochureAnalyticsPagination',
    entityName: 'DigitalBrochureAnalyticsPagination',
    isList: false,
    argsTSName: 'QueryDigitalBrochureAnalyticsPaginationArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochureAnalyticsPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDigitalBrochureAnalyticsInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDigitalBrochureAnalyticsInput'
        }
      ],
      name: 'DigitalBrochureAnalyticsPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsFindMany: {
    type: '[DigitalBrochureAnalytics]',
    schemaKey: 'DigitalBrochureAnalyticsFindMany',
    entityName: 'DigitalBrochureAnalytics',
    isList: true,
    argsTSName: 'QueryDigitalBrochureAnalyticsFindManyArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsFindMany']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DigitalBrochureAnalytics]',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyDigitalBrochureAnalyticsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindManyDigitalBrochureAnalyticsInput'
        }
      ],
      name: 'DigitalBrochureAnalyticsFindMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsFindById: {
    type: 'DigitalBrochureAnalytics',
    schemaKey: 'DigitalBrochureAnalyticsFindById',
    entityName: 'DigitalBrochureAnalytics',
    isList: false,
    argsTSName: 'QueryDigitalBrochureAnalyticsFindByIdArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochureAnalytics',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'DigitalBrochureAnalyticsFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsFindByIds: {
    type: '[DigitalBrochureAnalytics]',
    schemaKey: 'DigitalBrochureAnalyticsFindByIds',
    entityName: 'DigitalBrochureAnalytics',
    isList: true,
    argsTSName: 'QueryDigitalBrochureAnalyticsFindByIdsArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsFindByIds']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DigitalBrochureAnalytics]',
      args: [
        { name: '_ids', description: null, type: '[MongoID]!' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 },
        {
          name: 'sort',
          description: null,
          type: 'SortFindByIdsDigitalBrochureAnalyticsInput'
        }
      ],
      name: 'DigitalBrochureAnalyticsFindByIds',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsFindOne: {
    type: 'DigitalBrochureAnalytics',
    schemaKey: 'DigitalBrochureAnalyticsFindOne',
    entityName: 'DigitalBrochureAnalytics',
    isList: false,
    argsTSName: 'QueryDigitalBrochureAnalyticsFindOneArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsFindOne']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DigitalBrochureAnalytics',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindOneDigitalBrochureAnalyticsInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        {
          name: 'sort',
          description: null,
          type: 'SortFindOneDigitalBrochureAnalyticsInput'
        }
      ],
      name: 'DigitalBrochureAnalyticsFindOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsPermissions: {
    type: '[DigitalBrochureAnalyticsPermissionsEnum]',
    schemaKey: 'DigitalBrochureAnalyticsPermissions',
    entityName: 'DigitalBrochureAnalyticsPermissionsEnum',
    isList: true,
    argsTSName: '{}',
    returnTSName: "Query['DigitalBrochureAnalyticsPermissions']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[DigitalBrochureAnalyticsPermissionsEnum]',
      args: [],
      name: 'DigitalBrochureAnalyticsPermissions',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  DigitalBrochureAnalyticsCount: {
    type: 'Int!',
    schemaKey: 'DigitalBrochureAnalyticsCount',
    entityName: 'Int',
    isList: false,
    argsTSName: 'QueryDigitalBrochureAnalyticsCountArgs',
    returnTSName: "Query['DigitalBrochureAnalyticsCount']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Int!',
      args: [{ name: '_id', description: null, type: 'String!' }],
      name: 'DigitalBrochureAnalyticsCount',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'query'
  },

  AreaLojaPagination: {
    type: 'AreaLojaPagination',
    schemaKey: 'AreaLojaPagination',
    entityName: 'AreaLojaPagination',
    isList: false,
    argsTSName: 'QueryAreaLojaPaginationArgs',
    returnTSName: "Query['AreaLojaPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'AreaLojaPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyAreaLojaInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyAreaLojaInput' }
      ],
      name: 'AreaLojaPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegraMapaPagination: {
    type: 'RegraMapaPagination',
    schemaKey: 'RegraMapaPagination',
    entityName: 'RegraMapaPagination',
    isList: false,
    argsTSName: 'QueryRegraMapaPaginationArgs',
    returnTSName: "Query['RegraMapaPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegraMapaPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyRegraMapaInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyRegraMapaInput' }
      ],
      name: 'RegraMapaPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  FormatoPagination: {
    type: 'FormatoPagination',
    schemaKey: 'FormatoPagination',
    entityName: 'FormatoPagination',
    isList: false,
    argsTSName: 'QueryFormatoPaginationArgs',
    returnTSName: "Query['FormatoPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'FormatoPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyFormatoInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyFormatoInput' }
      ],
      name: 'FormatoPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  PecaMapaPagination: {
    type: 'PecaMapaPagination',
    schemaKey: 'PecaMapaPagination',
    entityName: 'PecaMapaPagination',
    isList: false,
    argsTSName: 'QueryPecaMapaPaginationArgs',
    returnTSName: "Query['PecaMapaPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'PecaMapaPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyPecaMapaInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyPecaMapaInput' }
      ],
      name: 'PecaMapaPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MapaPDVPagination: {
    type: 'MapaPDVPagination',
    schemaKey: 'MapaPDVPagination',
    entityName: 'MapaPDVPagination',
    isList: false,
    argsTSName: 'QueryMapaPdvPaginationArgs',
    returnTSName: "Query['MapaPDVPagination']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MapaPDVPagination',
      args: [
        {
          name: 'page',
          description: 'Page number for displaying',
          type: 'Int'
        },
        { name: 'perPage', description: '', type: 'Int', defaultValue: 20 },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterFindManyMapaPDVInput'
        },
        { name: 'sort', description: null, type: 'SortFindManyMapaPDVInput' }
      ],
      name: 'MapaPDVPagination',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MapaPDVFindById: {
    type: 'MapaPDV',
    schemaKey: 'MapaPDVFindById',
    entityName: 'MapaPDV',
    isList: false,
    argsTSName: 'QueryMapaPdvFindByIdArgs',
    returnTSName: "Query['MapaPDVFindById']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MapaPDV',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MapaPDVFindById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  MyMapaPDV: {
    type: 'MyMapaPDVPayload',
    schemaKey: 'MyMapaPDV',
    entityName: 'MyMapaPDVPayload',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Query['MyMapaPDV']",
    isMutation: false,
    isQuery: true,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'MyMapaPDVPayload',
      args: [],
      name: 'MyMapaPDV',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'query'
  },

  RegionRemoveOne: {
    type: 'RemoveOneRegionPayload',
    schemaKey: 'RegionRemoveOne',
    entityName: 'RemoveOneRegionPayload',
    isList: false,
    argsTSName: 'MutationRegionRemoveOneArgs',
    returnTSName: "Mutation['RegionRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneRegionPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneRegionInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneRegionInput' }
      ],
      name: 'RegionRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserContentPermissionToggle: {
    type: 'Boolean',
    schemaKey: 'UserContentPermissionToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserContentPermissionToggleArgs',
    returnTSName: "Mutation['UserContentPermissionToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'roleGroups', description: null, type: '[String]!' },
        { name: 'regions', description: null, type: '[String]!' },
        { name: 'contentTypes', description: null, type: '[String]!' },
        { name: 'viewOtherUsersContent', description: null, type: 'Boolean' }
      ],
      name: 'UserContentPermissionToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserUpdateOne: {
    type: 'UpdateOneUserPayload',
    schemaKey: 'UserUpdateOne',
    entityName: 'UpdateOneUserPayload',
    isList: false,
    argsTSName: 'MutationUserUpdateOneArgs',
    returnTSName: "Mutation['UserUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneUserPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneUserInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneUserInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneUserInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'UserUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserRemoveById: {
    type: 'RemoveByIdUserPayload',
    schemaKey: 'UserRemoveById',
    entityName: 'RemoveByIdUserPayload',
    isList: false,
    argsTSName: 'MutationUserRemoveByIdArgs',
    returnTSName: "Mutation['UserRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdUserPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'UserRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserAcceptTerm: {
    type: 'Boolean',
    schemaKey: 'UserAcceptTerm',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserAcceptTermArgs',
    returnTSName: "Mutation['UserAcceptTerm']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'term', description: null, type: 'String!' }],
      name: 'UserAcceptTerm',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginWithPassword: {
    type: 'User',
    schemaKey: 'UserLoginWithPassword',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithPasswordArgs',
    returnTSName: "Mutation['UserLoginWithPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        {
          name: 'user',
          description: 'email, CPF, CNPJ ou matricula',
          type: 'String!'
        },
        { name: 'password', description: null, type: 'String!' },
        { name: 'platformName', description: null, type: 'PlatformNamesEnum' }
      ],
      name: 'UserLoginWithPassword',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginWithToken: {
    type: 'User',
    schemaKey: 'UserLoginWithToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginWithTokenArgs',
    returnTSName: "Mutation['UserLoginWithToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [
        { name: 'token', description: null, type: 'String!' },
        { name: 'platformName', description: null, type: 'PlatformNamesEnum' }
      ],
      name: 'UserLoginWithToken',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserLoginRenewToken: {
    type: 'User',
    schemaKey: 'UserLoginRenewToken',
    entityName: 'User',
    isList: false,
    argsTSName: 'MutationUserLoginRenewTokenArgs',
    returnTSName: "Mutation['UserLoginRenewToken']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'User',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'UserLoginRenewToken',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserSaveLogoutAnalytics: {
    type: 'Boolean',
    schemaKey: 'UserSaveLogoutAnalytics',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLogoutAnalytics']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLogoutAnalytics',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserSaveLoginAnalyticsPCS: {
    type: 'Boolean',
    schemaKey: 'UserSaveLoginAnalyticsPCS',
    entityName: 'Boolean',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['UserSaveLoginAnalyticsPCS']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [],
      name: 'UserSaveLoginAnalyticsPCS',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserFillEmail: {
    type: 'Boolean',
    schemaKey: 'UserFillEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillEmailArgs',
    returnTSName: "Mutation['UserFillEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'email', description: null, type: 'String!' }],
      name: 'UserFillEmail',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserFillAvatarId: {
    type: 'Boolean',
    schemaKey: 'UserFillAvatarId',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserFillAvatarIdArgs',
    returnTSName: "Mutation['UserFillAvatarId']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'avatarId', description: null, type: 'String!' }],
      name: 'UserFillAvatarId',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserOptIn: {
    type: 'Boolean',
    schemaKey: 'UserOptIn',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserOptInArgs',
    returnTSName: "Mutation['UserOptIn']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'accept', description: null, type: 'Boolean!' }],
      name: 'UserOptIn',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserMarkNotificationAsRead: {
    type: 'Boolean',
    schemaKey: 'UserMarkNotificationAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserMarkNotificationAsReadArgs',
    returnTSName: "Mutation['UserMarkNotificationAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'isRead', description: null, type: 'Boolean!' },
        { name: 'notifications', description: null, type: '[String]!' }
      ],
      name: 'UserMarkNotificationAsRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserDeleteNotification: {
    type: 'String',
    schemaKey: 'UserDeleteNotification',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationUserDeleteNotificationArgs',
    returnTSName: "Mutation['UserDeleteNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'String',
      args: [{ name: 'notificationId', description: null, type: 'String!' }],
      name: 'UserDeleteNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserDeleteManyNotification: {
    type: '[String]',
    schemaKey: 'UserDeleteManyNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationUserDeleteManyNotificationArgs',
    returnTSName: "Mutation['UserDeleteManyNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[String]',
      args: [{ name: 'notificationIds', description: null, type: '[String!]' }],
      name: 'UserDeleteManyNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserRegistration: {
    type: 'UserRegistrationPayload',
    schemaKey: 'UserRegistration',
    entityName: 'UserRegistrationPayload',
    isList: false,
    argsTSName: 'MutationUserRegistrationArgs',
    returnTSName: "Mutation['UserRegistration']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UserRegistrationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UserRegistrationTypeInput!'
        }
      ],
      name: 'UserRegistration',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserBlockComments: {
    type: 'Boolean',
    schemaKey: 'UserBlockComments',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserBlockCommentsArgs',
    returnTSName: "Mutation['UserBlockComments']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'userId', description: null, type: 'String!' },
        { name: 'isBlocked', description: null, type: 'Boolean' },
        { name: 'materialId', description: null, type: 'String' }
      ],
      name: 'UserBlockComments',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ChangeToUserTaNarede: {
    type: 'UserTaNaRede',
    schemaKey: 'ChangeToUserTaNarede',
    entityName: 'UserTaNaRede',
    isList: false,
    argsTSName: '{}',
    returnTSName: "Mutation['ChangeToUserTaNarede']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'UserTaNaRede',
      args: [],
      name: 'ChangeToUserTaNarede',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserChangePassword: {
    type: 'Boolean',
    schemaKey: 'UserChangePassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserChangePasswordArgs',
    returnTSName: "Mutation['UserChangePassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'newPassword', description: null, type: 'String!' }],
      name: 'UserChangePassword',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserResetPassword: {
    type: 'Boolean',
    schemaKey: 'UserResetPassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUserResetPasswordArgs',
    returnTSName: "Mutation['UserResetPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'user', description: null, type: 'String!' },
        { name: 'domain', description: null, type: 'String' }
      ],
      name: 'UserResetPassword',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileCreateOne: {
    type: 'CreateOneFilePayload',
    schemaKey: 'FileCreateOne',
    entityName: 'CreateOneFilePayload',
    isList: false,
    argsTSName: 'MutationFileCreateOneArgs',
    returnTSName: "Mutation['FileCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFilePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFileInput!' }
      ],
      name: 'FileCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileRemoveById: {
    type: 'RemoveByIdFilePayload',
    schemaKey: 'FileRemoveById',
    entityName: 'RemoveByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileRemoveByIdArgs',
    returnTSName: "Mutation['FileRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFilePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FileRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FileUpdateById: {
    type: 'UpdateByIdFilePayload',
    schemaKey: 'FileUpdateById',
    entityName: 'UpdateByIdFilePayload',
    isList: false,
    argsTSName: 'MutationFileUpdateByIdArgs',
    returnTSName: "Mutation['FileUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdFilePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdFileInput!' }
      ],
      name: 'FileUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'MaterialCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialCreateOneArgs',
    returnTSName: "Mutation['MaterialCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'MaterialUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateOneArgs',
    returnTSName: "Mutation['MaterialUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneMaterialInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneMaterialInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'MaterialUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialUpdateById: {
    type: 'UpdateByIdMaterialPayload',
    schemaKey: 'MaterialUpdateById',
    entityName: 'UpdateByIdMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialUpdateByIdArgs',
    returnTSName: "Mutation['MaterialUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdMaterialInput!' },
        { name: 'filter', description: null, type: 'filter' },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'MaterialUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialRemoveOne: {
    type: 'RemoveOneMaterialPayload',
    schemaKey: 'MaterialRemoveOne',
    entityName: 'RemoveOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationMaterialRemoveOneArgs',
    returnTSName: "Mutation['MaterialRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneMaterialPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneMaterialInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneMaterialInput' }
      ],
      name: 'MaterialRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationCreateOne: {
    type: 'CreateOneMaterialPayload',
    schemaKey: 'CommunicationCreateOne',
    entityName: 'CreateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationCreateOneArgs',
    returnTSName: "Mutation['CommunicationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'CreateOneMaterialPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMaterialInput!' }
      ],
      name: 'CommunicationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationUpdateOne: {
    type: 'UpdateOneMaterialPayload',
    schemaKey: 'CommunicationUpdateOne',
    entityName: 'UpdateOneMaterialPayload',
    isList: false,
    argsTSName: 'MutationCommunicationUpdateOneArgs',
    returnTSName: "Mutation['CommunicationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'UpdateOneMaterialPayload',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'record', description: null, type: 'UpdateOneMaterialInput' }
      ],
      name: 'CommunicationUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationApproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationApproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationApproveChannelArgs',
    returnTSName: "Mutation['CommunicationApproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommunicationApproveChannel',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationDisapproveChannel: {
    type: 'Boolean',
    schemaKey: 'CommunicationDisapproveChannel',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationDisapproveChannelArgs',
    returnTSName: "Mutation['CommunicationDisapproveChannel']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'recordId', description: null, type: 'String!' },
        { name: 'message', description: null, type: 'String!' }
      ],
      name: 'CommunicationDisapproveChannel',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommunicationRemoveApprover: {
    type: 'Boolean',
    schemaKey: 'CommunicationRemoveApprover',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommunicationRemoveApproverArgs',
    returnTSName: "Mutation['CommunicationRemoveApprover']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'communicationId', description: null, type: 'String!' },
        { name: 'userId', description: null, type: 'String!' }
      ],
      name: 'CommunicationRemoveApprover',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailFillTemplate: {
    type: 'String!',
    schemaKey: 'EmailFillTemplate',
    entityName: 'String',
    isList: false,
    argsTSName: 'MutationEmailFillTemplateArgs',
    returnTSName: "Mutation['EmailFillTemplate']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'String!',
      args: [
        { name: 'template', description: null, type: 'EmailTemplateNames' },
        {
          name: 'props',
          description: null,
          type: 'MaterialCommunicationItemsConfigTemplatePropsInput'
        }
      ],
      name: 'EmailFillTemplate',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  EmailMarkAsRead: {
    type: 'Boolean',
    schemaKey: 'EmailMarkAsRead',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEmailMarkAsReadArgs',
    returnTSName: "Mutation['EmailMarkAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Marca um email como lido pelo destinatario informado pelo token recebido no e-mail',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'token', description: null, type: 'String!' }],
      name: 'EmailMarkAsRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SendEmailFront: {
    type: 'Boolean',
    schemaKey: 'SendEmailFront',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationSendEmailFrontArgs',
    returnTSName: "Mutation['SendEmailFront']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'subject', description: null, type: 'String' },
        { name: 'text', description: null, type: 'String' },
        { name: 'attach', description: null, type: '[String]' },
        { name: 'categories', description: null, type: '[String]' }
      ],
      name: 'SendEmailFront',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MaterialSendTestEmail: {
    type: 'Boolean!',
    schemaKey: 'MaterialSendTestEmail',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialSendTestEmailArgs',
    returnTSName: "Mutation['MaterialSendTestEmail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialSendTestEmail',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  MaterialLikeToggle: {
    type: 'Boolean',
    schemaKey: 'MaterialLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMaterialLikeToggleArgs',
    returnTSName: "Mutation['MaterialLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'MaterialLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UpdateDeteachedAndLatestVideo: {
    type: 'Boolean',
    schemaKey: 'UpdateDeteachedAndLatestVideo',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationUpdateDeteachedAndLatestVideoArgs',
    returnTSName: "Mutation['UpdateDeteachedAndLatestVideo']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'idDetachedVideo', description: null, type: 'String' },
        { name: 'idLatestVideo1', description: null, type: 'String' },
        { name: 'idLatestVideo2', description: null, type: 'String' },
        { name: 'idLatestVideo3', description: null, type: 'String' }
      ],
      name: 'UpdateDeteachedAndLatestVideo',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationCreateOne: {
    type: 'CreateOneNotificationPayload',
    schemaKey: 'NotificationCreateOne',
    entityName: 'CreateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateOneArgs',
    returnTSName: "Mutation['NotificationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneNotificationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneNotificationInput!'
        }
      ],
      name: 'NotificationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationCreateMany: {
    type: 'CreateManyNotificationPayload',
    schemaKey: 'NotificationCreateMany',
    entityName: 'CreateManyNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationCreateManyArgs',
    returnTSName: "Mutation['NotificationCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyNotificationPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyNotificationInput!]!'
        }
      ],
      name: 'NotificationCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationUpdateOne: {
    type: 'UpdateOneNotificationPayload',
    schemaKey: 'NotificationUpdateOne',
    entityName: 'UpdateOneNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationUpdateOneArgs',
    returnTSName: "Mutation['NotificationUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneNotificationPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneNotificationInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneNotificationInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneNotificationInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'NotificationUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  NotificationRemoveById: {
    type: 'RemoveByIdNotificationPayload',
    schemaKey: 'NotificationRemoveById',
    entityName: 'RemoveByIdNotificationPayload',
    isList: false,
    argsTSName: 'MutationNotificationRemoveByIdArgs',
    returnTSName: "Mutation['NotificationRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdNotificationPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'NotificationRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryCreateOne: {
    type: 'CreateOneCategoryPayload',
    schemaKey: 'CategoryCreateOne',
    entityName: 'CreateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateOneArgs',
    returnTSName: "Mutation['CategoryCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCategoryInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryUpdateOne: {
    type: 'UpdateOneCategoryPayload',
    schemaKey: 'CategoryUpdateOne',
    entityName: 'UpdateOneCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryUpdateOneArgs',
    returnTSName: "Mutation['CategoryUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCategoryPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCategoryInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneCategoryInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneCategoryInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'CategoryUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryRemoveById: {
    type: 'RemoveByIdCategoryPayload',
    schemaKey: 'CategoryRemoveById',
    entityName: 'RemoveByIdCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryRemoveByIdArgs',
    returnTSName: "Mutation['CategoryRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCategoryPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CategoryCreateMany: {
    type: 'CreateManyCategoryPayload',
    schemaKey: 'CategoryCreateMany',
    entityName: 'CreateManyCategoryPayload',
    isList: false,
    argsTSName: 'MutationCategoryCreateManyArgs',
    returnTSName: "Mutation['CategoryCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyCategoryPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyCategoryInput!]!'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'CategoryCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  WebPushSubscribe: {
    type: 'Boolean',
    schemaKey: 'WebPushSubscribe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationWebPushSubscribeArgs',
    returnTSName: "Mutation['WebPushSubscribe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario.',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'subscription', description: null, type: 'JSON!' }],
      name: 'WebPushSubscribe',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  WebPushSendNotification: {
    type: '[String]',
    schemaKey: 'WebPushSendNotification',
    entityName: 'String',
    isList: true,
    argsTSName: 'MutationWebPushSendNotificationArgs',
    returnTSName: "Mutation['WebPushSendNotification']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Envia uma web push notification para uma lista de userIds',
      deprecationReason: null,
      type: '[String]',
      args: [
        { name: 'userIds', description: null, type: '[String]!' },
        { name: 'notificationOptions', description: null, type: 'JSON!' },
        { name: 'sync', description: null, type: 'Boolean' }
      ],
      name: 'WebPushSendNotification',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinCreateOne: {
    type: 'CreateOnePinPayload',
    schemaKey: 'PinCreateOne',
    entityName: 'CreateOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinCreateOneArgs',
    returnTSName: "Mutation['PinCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePinPayload',
      args: [{ name: 'record', description: null, type: 'CreateOnePinInput!' }],
      name: 'PinCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinRemoveOne: {
    type: 'RemoveOnePinPayload',
    schemaKey: 'PinRemoveOne',
    entityName: 'RemoveOnePinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveOneArgs',
    returnTSName: "Mutation['PinRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOnePinPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOnePinInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOnePinInput' }
      ],
      name: 'PinRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PinRemoveMany: {
    type: 'RemoveManyPinPayload',
    schemaKey: 'PinRemoveMany',
    entityName: 'RemoveManyPinPayload',
    isList: false,
    argsTSName: 'MutationPinRemoveManyArgs',
    returnTSName: "Mutation['PinRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyPinPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveManyPinInput!'
        }
      ],
      name: 'PinRemoveMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareCreateOne: {
    type: 'CreateOneSharePayload',
    schemaKey: 'ShareCreateOne',
    entityName: 'CreateOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareCreateOneArgs',
    returnTSName: "Mutation['ShareCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneSharePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneShareInput!' }
      ],
      name: 'ShareCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareRemoveOne: {
    type: 'RemoveOneSharePayload',
    schemaKey: 'ShareRemoveOne',
    entityName: 'RemoveOneSharePayload',
    isList: false,
    argsTSName: 'MutationShareRemoveOneArgs',
    returnTSName: "Mutation['ShareRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneSharePayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneShareInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneShareInput' }
      ],
      name: 'ShareRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ShareRemoveMe: {
    type: 'Boolean!',
    schemaKey: 'ShareRemoveMe',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationShareRemoveMeArgs',
    returnTSName: "Mutation['ShareRemoveMe']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Remove o usuário atual de um compartilhamento.',
      deprecationReason: null,
      type: 'Boolean!',
      args: [{ name: 'shareId', description: null, type: 'String' }],
      name: 'ShareRemoveMe',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  CommentCreateOne: {
    type: 'CreateOneCommentPayload',
    schemaKey: 'CommentCreateOne',
    entityName: 'CreateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentCreateOneArgs',
    returnTSName: "Mutation['CommentCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCommentPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCommentInput!' }
      ],
      name: 'CommentCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentUpdateOne: {
    type: 'UpdateOneCommentPayload',
    schemaKey: 'CommentUpdateOne',
    entityName: 'UpdateOneCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentUpdateOneArgs',
    returnTSName: "Mutation['CommentUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCommentPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCommentInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneCommentInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneCommentInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'CommentUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentRemoveById: {
    type: 'RemoveByIdCommentPayload',
    schemaKey: 'CommentRemoveById',
    entityName: 'RemoveByIdCommentPayload',
    isList: false,
    argsTSName: 'MutationCommentRemoveByIdArgs',
    returnTSName: "Mutation['CommentRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCommentPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CommentRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CommentLikeToggle: {
    type: 'Boolean',
    schemaKey: 'CommentLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationCommentLikeToggleArgs',
    returnTSName: "Mutation['CommentLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'CommentLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveCreateOne: {
    type: 'CreateOneEventLivePayload',
    schemaKey: 'EventLiveCreateOne',
    entityName: 'CreateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveCreateOneArgs',
    returnTSName: "Mutation['EventLiveCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEventLiveInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveRemoveById: {
    type: 'RemoveByIdEventLivePayload',
    schemaKey: 'EventLiveRemoveById',
    entityName: 'RemoveByIdEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveRemoveByIdArgs',
    returnTSName: "Mutation['EventLiveRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdEventLivePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'EventLiveRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveUpdateOne: {
    type: 'UpdateOneEventLivePayload',
    schemaKey: 'EventLiveUpdateOne',
    entityName: 'UpdateOneEventLivePayload',
    isList: false,
    argsTSName: 'MutationEventLiveUpdateOneArgs',
    returnTSName: "Mutation['EventLiveUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneEventLivePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneEventLiveInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneEventLiveInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneEventLiveInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'EventLiveUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveCloseRoom: {
    type: 'Boolean',
    schemaKey: 'EventLiveCloseRoom',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveCloseRoomArgs',
    returnTSName: "Mutation['EventLiveCloseRoom']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'eventId', description: null, type: 'String!' }],
      name: 'EventLiveCloseRoom',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EventLiveLikeToggle: {
    type: 'Boolean',
    schemaKey: 'EventLiveLikeToggle',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationEventLiveLikeToggleArgs',
    returnTSName: "Mutation['EventLiveLikeToggle']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: null,
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'EventLiveLikeToggle',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerRemoveById: {
    type: 'RemoveByIdBannerPayload',
    schemaKey: 'BannerRemoveById',
    entityName: 'RemoveByIdBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerRemoveByIdArgs',
    returnTSName: "Mutation['BannerRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdBannerPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'BannerRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerCreateOne: {
    type: 'CreateOneBannerPayload',
    schemaKey: 'BannerCreateOne',
    entityName: 'CreateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerCreateOneArgs',
    returnTSName: "Mutation['BannerCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneBannerInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'BannerCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BannerUpdateOne: {
    type: 'UpdateOneBannerPayload',
    schemaKey: 'BannerUpdateOne',
    entityName: 'UpdateOneBannerPayload',
    isList: false,
    argsTSName: 'MutationBannerUpdateOneArgs',
    returnTSName: "Mutation['BannerUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneBannerPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneBannerInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneBannerInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneBannerInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'BannerUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnalyticsPageView: {
    type: 'Boolean',
    schemaKey: 'AnalyticsPageView',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsPageViewArgs',
    returnTSName: "Mutation['AnalyticsPageView']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'title', description: null, type: 'String!' },
        { name: 'host', description: null, type: 'String' },
        { name: 'route', description: null, type: 'String' }
      ],
      name: 'AnalyticsPageView',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AnalyticsTrackEvent: {
    type: 'Boolean',
    schemaKey: 'AnalyticsTrackEvent',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationAnalyticsTrackEventArgs',
    returnTSName: "Mutation['AnalyticsTrackEvent']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'event', description: null, type: 'EnumAnalyticsEvent!' },
        { name: 'kind', description: null, type: 'EnumAnalyticsParentKind!' },
        { name: 'recordId', description: null, type: 'String' },
        { name: 'entityName', description: null, type: 'String' },
        { name: 'label', description: null, type: 'String' }
      ],
      name: 'AnalyticsTrackEvent',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderCreateOne: {
    type: 'CreateOneFolderPayload',
    schemaKey: 'FolderCreateOne',
    entityName: 'CreateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderCreateOneArgs',
    returnTSName: "Mutation['FolderCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFolderPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFolderInput!' }
      ],
      name: 'FolderCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderRemoveOne: {
    type: 'RemoveOneFolderPayload',
    schemaKey: 'FolderRemoveOne',
    entityName: 'RemoveOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveOneArgs',
    returnTSName: "Mutation['FolderRemoveOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveOneFolderPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveOneFolderInput'
        },
        { name: 'sort', description: null, type: 'SortRemoveOneFolderInput' }
      ],
      name: 'FolderRemoveOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderRemoveMany: {
    type: 'RemoveManyFolderPayload',
    schemaKey: 'FolderRemoveMany',
    entityName: 'RemoveManyFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderRemoveManyArgs',
    returnTSName: "Mutation['FolderRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyFolderPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveManyFolderInput!'
        }
      ],
      name: 'FolderRemoveMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FolderUpdateOne: {
    type: 'UpdateOneFolderPayload',
    schemaKey: 'FolderUpdateOne',
    entityName: 'UpdateOneFolderPayload',
    isList: false,
    argsTSName: 'MutationFolderUpdateOneArgs',
    returnTSName: "Mutation['FolderUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneFolderPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneFolderInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneFolderInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneFolderInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'FolderUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RecentsActivitiesCreateOne: {
    type: 'CreateOneRecentsActivitiesPayload',
    schemaKey: 'RecentsActivitiesCreateOne',
    entityName: 'CreateOneRecentsActivitiesPayload',
    isList: false,
    argsTSName: 'MutationRecentsActivitiesCreateOneArgs',
    returnTSName: "Mutation['RecentsActivitiesCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneRecentsActivitiesPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneRecentsActivitiesInput!'
        }
      ],
      name: 'RecentsActivitiesCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserWordsBlackListCreateOne: {
    type: 'CreateOneUserWordsBlackListPayload',
    schemaKey: 'UserWordsBlackListCreateOne',
    entityName: 'CreateOneUserWordsBlackListPayload',
    isList: false,
    argsTSName: 'MutationUserWordsBlackListCreateOneArgs',
    returnTSName: "Mutation['UserWordsBlackListCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserWordsBlackListPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneUserWordsBlackListInput!'
        }
      ],
      name: 'UserWordsBlackListCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ImportUsersPlanCreateOne: {
    type: 'CreateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanCreateOne',
    entityName: 'CreateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanCreateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneImportUsersPlanPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneImportUsersPlanInput!'
        },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'ImportUsersPlanCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ImportUsersPlanUpdateOne: {
    type: 'UpdateOneImportUsersPlanPayload',
    schemaKey: 'ImportUsersPlanUpdateOne',
    entityName: 'UpdateOneImportUsersPlanPayload',
    isList: false,
    argsTSName: 'MutationImportUsersPlanUpdateOneArgs',
    returnTSName: "Mutation['ImportUsersPlanUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneImportUsersPlanPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneImportUsersPlanInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneImportUsersPlanInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneImportUsersPlanInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'ImportUsersPlanUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  UserHistoryBlockCreateOne: {
    type: 'CreateOneUserHistoryBlockPayload',
    schemaKey: 'UserHistoryBlockCreateOne',
    entityName: 'CreateOneUserHistoryBlockPayload',
    isList: false,
    argsTSName: 'MutationUserHistoryBlockCreateOneArgs',
    returnTSName: "Mutation['UserHistoryBlockCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneUserHistoryBlockPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneUserHistoryBlockInput!'
        },
        { name: 'sort', description: null, type: 'sort' }
      ],
      name: 'UserHistoryBlockCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailStatsCreateMany: {
    type: 'CreateManyEmailStatsPayload',
    schemaKey: 'EmailStatsCreateMany',
    entityName: 'CreateManyEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateManyArgs',
    returnTSName: "Mutation['EmailStatsCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailStatsPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyEmailStatsInput!]!'
        }
      ],
      name: 'EmailStatsCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailStatsCreateOne: {
    type: 'CreateOneEmailStatsPayload',
    schemaKey: 'EmailStatsCreateOne',
    entityName: 'CreateOneEmailStatsPayload',
    isList: false,
    argsTSName: 'MutationEmailStatsCreateOneArgs',
    returnTSName: "Mutation['EmailStatsCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailStatsPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneEmailStatsInput!' }
      ],
      name: 'EmailStatsCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailUnsubscribeCreateMany: {
    type: 'CreateManyEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateMany',
    entityName: 'CreateManyEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateManyArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyEmailUnsubscribePayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyEmailUnsubscribeInput!]!'
        }
      ],
      name: 'EmailUnsubscribeCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  EmailUnsubscribeCreateOne: {
    type: 'CreateOneEmailUnsubscribePayload',
    schemaKey: 'EmailUnsubscribeCreateOne',
    entityName: 'CreateOneEmailUnsubscribePayload',
    isList: false,
    argsTSName: 'MutationEmailUnsubscribeCreateOneArgs',
    returnTSName: "Mutation['EmailUnsubscribeCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneEmailUnsubscribePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneEmailUnsubscribeInput!'
        }
      ],
      name: 'EmailUnsubscribeCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  HomebannerCreateOne: {
    type: 'CreateOneHomebannerPayload',
    schemaKey: 'HomebannerCreateOne',
    entityName: 'CreateOneHomebannerPayload',
    isList: false,
    argsTSName: 'MutationHomebannerCreateOneArgs',
    returnTSName: "Mutation['HomebannerCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneHomebannerPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneHomebannerInput!' }
      ],
      name: 'HomebannerCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  HomebannerUpdateById: {
    type: 'UpdateByIdHomebannerPayload',
    schemaKey: 'HomebannerUpdateById',
    entityName: 'UpdateByIdHomebannerPayload',
    isList: false,
    argsTSName: 'MutationHomebannerUpdateByIdArgs',
    returnTSName: "Mutation['HomebannerUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdHomebannerPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdHomebannerInput!'
        }
      ],
      name: 'HomebannerUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PlaceRemoveById: {
    type: 'RemoveByIdPlacePayload',
    schemaKey: 'PlaceRemoveById',
    entityName: 'RemoveByIdPlacePayload',
    isList: false,
    argsTSName: 'MutationPlaceRemoveByIdArgs',
    returnTSName: "Mutation['PlaceRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdPlacePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'PlaceRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PlaceCreateOne: {
    type: 'CreateOnePlacePayload',
    schemaKey: 'PlaceCreateOne',
    entityName: 'CreateOnePlacePayload',
    isList: false,
    argsTSName: 'MutationPlaceCreateOneArgs',
    returnTSName: "Mutation['PlaceCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePlacePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOnePlaceInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'PlaceCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PlaceUpdateById: {
    type: 'UpdateByIdPlacePayload',
    schemaKey: 'PlaceUpdateById',
    entityName: 'UpdateByIdPlacePayload',
    isList: false,
    argsTSName: 'MutationPlaceUpdateByIdArgs',
    returnTSName: "Mutation['PlaceUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdPlacePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdPlaceInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'PlaceUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PlaceUpdateOne: {
    type: 'UpdateOnePlacePayload',
    schemaKey: 'PlaceUpdateOne',
    entityName: 'UpdateOnePlacePayload',
    isList: false,
    argsTSName: 'MutationPlaceUpdateOneArgs',
    returnTSName: "Mutation['PlaceUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOnePlacePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOnePlaceInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOnePlaceInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOnePlaceInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'PlaceUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PlacesBaseUpload: {
    type: 'JSON',
    schemaKey: 'PlacesBaseUpload',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'MutationPlacesBaseUploadArgs',
    returnTSName: "Mutation['PlacesBaseUpload']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [
        { name: 'fileId', description: null, type: 'String!' },
        { name: 'operations', description: null, type: '[String!]!' }
      ],
      name: 'PlacesBaseUpload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ContractCreateOne: {
    type: 'CreateOneContractPayload',
    schemaKey: 'ContractCreateOne',
    entityName: 'CreateOneContractPayload',
    isList: false,
    argsTSName: 'MutationContractCreateOneArgs',
    returnTSName: "Mutation['ContractCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneContractPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneContractInput!' }
      ],
      name: 'ContractCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ContractCreateMany: {
    type: 'CreateManyContractPayload',
    schemaKey: 'ContractCreateMany',
    entityName: 'CreateManyContractPayload',
    isList: false,
    argsTSName: 'MutationContractCreateManyArgs',
    returnTSName: "Mutation['ContractCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyContractPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyContractInput!]!'
        }
      ],
      name: 'ContractCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ContractUpdateOne: {
    type: 'UpdateOneContractPayload',
    schemaKey: 'ContractUpdateOne',
    entityName: 'UpdateOneContractPayload',
    isList: false,
    argsTSName: 'MutationContractUpdateOneArgs',
    returnTSName: "Mutation['ContractUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneContractPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneContractInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneContractInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneContractInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'ContractUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ContractUpdateById: {
    type: 'UpdateByIdContractPayload',
    schemaKey: 'ContractUpdateById',
    entityName: 'UpdateByIdContractPayload',
    isList: false,
    argsTSName: 'MutationContractUpdateByIdArgs',
    returnTSName: "Mutation['ContractUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdContractPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdContractInput!' }
      ],
      name: 'ContractUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ContractRemoveById: {
    type: 'RemoveByIdContractPayload',
    schemaKey: 'ContractRemoveById',
    entityName: 'RemoveByIdContractPayload',
    isList: false,
    argsTSName: 'MutationContractRemoveByIdArgs',
    returnTSName: "Mutation['ContractRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdContractPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ContractRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductCreateOne: {
    type: 'CreateOneProductPayload',
    schemaKey: 'ProductCreateOne',
    entityName: 'CreateOneProductPayload',
    isList: false,
    argsTSName: 'MutationProductCreateOneArgs',
    returnTSName: "Mutation['ProductCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneProductPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneProductInput!' }
      ],
      name: 'ProductCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductUpdateOne: {
    type: 'UpdateOneProductPayload',
    schemaKey: 'ProductUpdateOne',
    entityName: 'UpdateOneProductPayload',
    isList: false,
    argsTSName: 'MutationProductUpdateOneArgs',
    returnTSName: "Mutation['ProductUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneProductPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneProductInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneProductInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneProductInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'ProductUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductCreateMany: {
    type: 'CreateManyProductPayload',
    schemaKey: 'ProductCreateMany',
    entityName: 'CreateManyProductPayload',
    isList: false,
    argsTSName: 'MutationProductCreateManyArgs',
    returnTSName: "Mutation['ProductCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyProductPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyProductInput!]!'
        }
      ],
      name: 'ProductCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductUpdateById: {
    type: 'UpdateByIdProductPayload',
    schemaKey: 'ProductUpdateById',
    entityName: 'UpdateByIdProductPayload',
    isList: false,
    argsTSName: 'MutationProductUpdateByIdArgs',
    returnTSName: "Mutation['ProductUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdProductPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdProductInput!' }
      ],
      name: 'ProductUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductRemoveById: {
    type: 'RemoveByIdProductPayload',
    schemaKey: 'ProductRemoveById',
    entityName: 'RemoveByIdProductPayload',
    isList: false,
    argsTSName: 'MutationProductRemoveByIdArgs',
    returnTSName: "Mutation['ProductRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdProductPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ProductRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductBaseUpload: {
    type: 'JSON',
    schemaKey: 'ProductBaseUpload',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'MutationProductBaseUploadArgs',
    returnTSName: "Mutation['ProductBaseUpload']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [
        { name: 'fileId', description: null, type: 'String!' },
        { name: 'operations', description: null, type: '[String!]!' }
      ],
      name: 'ProductBaseUpload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SupplierCreateOne: {
    type: 'CreateOneSupplierPayload',
    schemaKey: 'SupplierCreateOne',
    entityName: 'CreateOneSupplierPayload',
    isList: false,
    argsTSName: 'MutationSupplierCreateOneArgs',
    returnTSName: "Mutation['SupplierCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneSupplierPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneSupplierInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'SupplierCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SupplierUpdateOne: {
    type: 'UpdateOneSupplierPayload',
    schemaKey: 'SupplierUpdateOne',
    entityName: 'UpdateOneSupplierPayload',
    isList: false,
    argsTSName: 'MutationSupplierUpdateOneArgs',
    returnTSName: "Mutation['SupplierUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneSupplierPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneSupplierInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneSupplierInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneSupplierInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'SupplierUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SupplierUpdateById: {
    type: 'UpdateByIdSupplierPayload',
    schemaKey: 'SupplierUpdateById',
    entityName: 'UpdateByIdSupplierPayload',
    isList: false,
    argsTSName: 'MutationSupplierUpdateByIdArgs',
    returnTSName: "Mutation['SupplierUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdSupplierPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdSupplierInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'SupplierUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SupplierRemoveById: {
    type: 'RemoveByIdSupplierPayload',
    schemaKey: 'SupplierRemoveById',
    entityName: 'RemoveByIdSupplierPayload',
    isList: false,
    argsTSName: 'MutationSupplierRemoveByIdArgs',
    returnTSName: "Mutation['SupplierRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdSupplierPayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'SupplierRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  TablePriceCreateOne: {
    type: 'CreateOneTablePricePayload',
    schemaKey: 'TablePriceCreateOne',
    entityName: 'CreateOneTablePricePayload',
    isList: false,
    argsTSName: 'MutationTablePriceCreateOneArgs',
    returnTSName: "Mutation['TablePriceCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneTablePricePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneTablePriceInput!' }
      ],
      name: 'TablePriceCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  TablePriceUpdateOne: {
    type: 'UpdateOneTablePricePayload',
    schemaKey: 'TablePriceUpdateOne',
    entityName: 'UpdateOneTablePricePayload',
    isList: false,
    argsTSName: 'MutationTablePriceUpdateOneArgs',
    returnTSName: "Mutation['TablePriceUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneTablePricePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneTablePriceInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneTablePriceInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneTablePriceInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'TablePriceUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  TablePriceRemoveById: {
    type: 'RemoveByIdTablePricePayload',
    schemaKey: 'TablePriceRemoveById',
    entityName: 'RemoveByIdTablePricePayload',
    isList: false,
    argsTSName: 'MutationTablePriceRemoveByIdArgs',
    returnTSName: "Mutation['TablePriceRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdTablePricePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'TablePriceRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PricingTableCreateOne: {
    type: 'CreateOnePricingTablePayload',
    schemaKey: 'PricingTableCreateOne',
    entityName: 'CreateOnePricingTablePayload',
    isList: false,
    argsTSName: 'MutationPricingTableCreateOneArgs',
    returnTSName: "Mutation['PricingTableCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePricingTablePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOnePricingTableInput!'
        }
      ],
      name: 'PricingTableCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PricingTableRemoveById: {
    type: 'RemoveByIdPricingTablePayload',
    schemaKey: 'PricingTableRemoveById',
    entityName: 'RemoveByIdPricingTablePayload',
    isList: false,
    argsTSName: 'MutationPricingTableRemoveByIdArgs',
    returnTSName: "Mutation['PricingTableRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdPricingTablePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PricingTableRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PricingTableUpdateById: {
    type: 'UpdateByIdPricingTablePayload',
    schemaKey: 'PricingTableUpdateById',
    entityName: 'UpdateByIdPricingTablePayload',
    isList: false,
    argsTSName: 'MutationPricingTableUpdateByIdArgs',
    returnTSName: "Mutation['PricingTableUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdPricingTablePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdPricingTableInput!'
        }
      ],
      name: 'PricingTableUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LegalTextCreateOne: {
    type: 'CreateOneLegalTextPayload',
    schemaKey: 'LegalTextCreateOne',
    entityName: 'CreateOneLegalTextPayload',
    isList: false,
    argsTSName: 'MutationLegalTextCreateOneArgs',
    returnTSName: "Mutation['LegalTextCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneLegalTextPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneLegalTextInput!' }
      ],
      name: 'LegalTextCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LegalTextUpdateById: {
    type: 'UpdateByIdLegalTextPayload',
    schemaKey: 'LegalTextUpdateById',
    entityName: 'UpdateByIdLegalTextPayload',
    isList: false,
    argsTSName: 'MutationLegalTextUpdateByIdArgs',
    returnTSName: "Mutation['LegalTextUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdLegalTextPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdLegalTextInput!' }
      ],
      name: 'LegalTextUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LegalTextRemoveById: {
    type: 'RemoveByIdLegalTextPayload',
    schemaKey: 'LegalTextRemoveById',
    entityName: 'RemoveByIdLegalTextPayload',
    isList: false,
    argsTSName: 'MutationLegalTextRemoveByIdArgs',
    returnTSName: "Mutation['LegalTextRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdLegalTextPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'LegalTextRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceCreateOne: {
    type: 'CreateOneDevicePayload',
    schemaKey: 'DeviceCreateOne',
    entityName: 'CreateOneDevicePayload',
    isList: false,
    argsTSName: 'MutationDeviceCreateOneArgs',
    returnTSName: "Mutation['DeviceCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneDevicePayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneDeviceInput!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'DeviceCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceUpdateOne: {
    type: 'UpdateOneDevicePayload',
    schemaKey: 'DeviceUpdateOne',
    entityName: 'UpdateOneDevicePayload',
    isList: false,
    argsTSName: 'MutationDeviceUpdateOneArgs',
    returnTSName: "Mutation['DeviceUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneDevicePayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneDeviceInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneDeviceInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneDeviceInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'DeviceUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceRemoveById: {
    type: 'RemoveByIdDevicePayload',
    schemaKey: 'DeviceRemoveById',
    entityName: 'RemoveByIdDevicePayload',
    isList: false,
    argsTSName: 'MutationDeviceRemoveByIdArgs',
    returnTSName: "Mutation['DeviceRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdDevicePayload',
      args: [
        { name: '_id', description: null, type: 'MongoID!' },
        { name: 'filter', description: null, type: 'filter' }
      ],
      name: 'DeviceRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeleteDevice: {
    type: 'Device',
    schemaKey: 'DeleteDevice',
    entityName: 'Device',
    isList: false,
    argsTSName: 'MutationDeleteDeviceArgs',
    returnTSName: "Mutation['DeleteDevice']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: 'Marca um device como deletado',
      deprecationReason: null,
      type: 'Device',
      args: [{ name: 'recordId', description: null, type: 'String!' }],
      name: 'DeleteDevice',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceBaseUpload: {
    type: 'JSON',
    schemaKey: 'DeviceBaseUpload',
    entityName: 'JSON',
    isList: false,
    argsTSName: 'MutationDeviceBaseUploadArgs',
    returnTSName: "Mutation['DeviceBaseUpload']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'JSON',
      args: [{ name: 'fileId', description: null, type: 'String!' }],
      name: 'DeviceBaseUpload',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DigitalBrochureCreateOne: {
    type: 'CreateOneDigitalBrochurePayload',
    schemaKey: 'DigitalBrochureCreateOne',
    entityName: 'CreateOneDigitalBrochurePayload',
    isList: false,
    argsTSName: 'MutationDigitalBrochureCreateOneArgs',
    returnTSName: "Mutation['DigitalBrochureCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneDigitalBrochurePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneDigitalBrochureInput!'
        }
      ],
      name: 'DigitalBrochureCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DigitalBrochureUpdateOne: {
    type: 'UpdateOneDigitalBrochurePayload',
    schemaKey: 'DigitalBrochureUpdateOne',
    entityName: 'UpdateOneDigitalBrochurePayload',
    isList: false,
    argsTSName: 'MutationDigitalBrochureUpdateOneArgs',
    returnTSName: "Mutation['DigitalBrochureUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneDigitalBrochurePayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneDigitalBrochureInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneDigitalBrochureInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneDigitalBrochureInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'DigitalBrochureUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DigitalBrochureRemoveById: {
    type: 'RemoveByIdDigitalBrochurePayload',
    schemaKey: 'DigitalBrochureRemoveById',
    entityName: 'RemoveByIdDigitalBrochurePayload',
    isList: false,
    argsTSName: 'MutationDigitalBrochureRemoveByIdArgs',
    returnTSName: "Mutation['DigitalBrochureRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdDigitalBrochurePayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'DigitalBrochureRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BrochureUpdateThumbnail: {
    type: '[BrochureUpdateThumbnailPayload]!',
    schemaKey: 'BrochureUpdateThumbnail',
    entityName: 'BrochureUpdateThumbnailPayload',
    isList: false,
    argsTSName: 'MutationBrochureUpdateThumbnailArgs',
    returnTSName: "Mutation['BrochureUpdateThumbnail']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[BrochureUpdateThumbnailPayload]!',
      args: [
        {
          name: 'filter',
          description: null,
          type: 'FilterFindManyDigitalBrochureInput!'
        },
        { name: 'density', description: null, type: 'Int', defaultValue: 150 }
      ],
      name: 'BrochureUpdateThumbnail',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  CampaignCreateOne: {
    type: 'CreateOneCampaignPayload',
    schemaKey: 'CampaignCreateOne',
    entityName: 'CreateOneCampaignPayload',
    isList: false,
    argsTSName: 'MutationCampaignCreateOneArgs',
    returnTSName: "Mutation['CampaignCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneCampaignInput!' }
      ],
      name: 'CampaignCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CampaignUpdateOne: {
    type: 'UpdateOneCampaignPayload',
    schemaKey: 'CampaignUpdateOne',
    entityName: 'UpdateOneCampaignPayload',
    isList: false,
    argsTSName: 'MutationCampaignUpdateOneArgs',
    returnTSName: "Mutation['CampaignUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneCampaignPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneCampaignInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneCampaignInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneCampaignInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'CampaignUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  CampaignRemoveById: {
    type: 'RemoveByIdCampaignPayload',
    schemaKey: 'CampaignRemoveById',
    entityName: 'RemoveByIdCampaignPayload',
    isList: false,
    argsTSName: 'MutationCampaignRemoveByIdArgs',
    returnTSName: "Mutation['CampaignRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdCampaignPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'CampaignRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutCreateOne: {
    type: 'CreateOneLayoutPayload',
    schemaKey: 'LayoutCreateOne',
    entityName: 'CreateOneLayoutPayload',
    isList: false,
    argsTSName: 'MutationLayoutCreateOneArgs',
    returnTSName: "Mutation['LayoutCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneLayoutPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneLayoutInput!' }
      ],
      name: 'LayoutCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutCreateMany: {
    type: 'CreateManyLayoutPayload',
    schemaKey: 'LayoutCreateMany',
    entityName: 'CreateManyLayoutPayload',
    isList: false,
    argsTSName: 'MutationLayoutCreateManyArgs',
    returnTSName: "Mutation['LayoutCreateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Creates Many documents with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateManyLayoutPayload',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateManyLayoutInput!]!'
        }
      ],
      name: 'LayoutCreateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutUpdateOne: {
    type: 'UpdateOneLayoutPayload',
    schemaKey: 'LayoutUpdateOne',
    entityName: 'UpdateOneLayoutPayload',
    isList: false,
    argsTSName: 'MutationLayoutUpdateOneArgs',
    returnTSName: "Mutation['LayoutUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneLayoutPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneLayoutInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneLayoutInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneLayoutInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'LayoutUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutUpdateById: {
    type: 'UpdateByIdLayoutPayload',
    schemaKey: 'LayoutUpdateById',
    entityName: 'UpdateByIdLayoutPayload',
    isList: false,
    argsTSName: 'MutationLayoutUpdateByIdArgs',
    returnTSName: "Mutation['LayoutUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdLayoutPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdLayoutInput!' }
      ],
      name: 'LayoutUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutRemoveById: {
    type: 'RemoveByIdLayoutPayload',
    schemaKey: 'LayoutRemoveById',
    entityName: 'RemoveByIdLayoutPayload',
    isList: false,
    argsTSName: 'MutationLayoutRemoveByIdArgs',
    returnTSName: "Mutation['LayoutRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdLayoutPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'LayoutRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  LayoutAssociateCodeTM: {
    type: 'Boolean',
    schemaKey: 'LayoutAssociateCodeTM',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationLayoutAssociateCodeTmArgs',
    returnTSName: "Mutation['LayoutAssociateCodeTM']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        {
          name: 'items',
          description: null,
          type: '[LayoutAssociateCodeItemInput]'
        }
      ],
      name: 'LayoutAssociateCodeTM',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegionalBudgetMarkAsViewed: {
    type: 'Boolean',
    schemaKey: 'RegionalBudgetMarkAsViewed',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationRegionalBudgetMarkAsViewedArgs',
    returnTSName: "Mutation['RegionalBudgetMarkAsViewed']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'regionalBudgetId', description: null, type: 'String!' }],
      name: 'RegionalBudgetMarkAsViewed',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BudgetMarkAsFinished: {
    type: 'Boolean',
    schemaKey: 'BudgetMarkAsFinished',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationBudgetMarkAsFinishedArgs',
    returnTSName: "Mutation['BudgetMarkAsFinished']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'budgetId', description: null, type: 'String!' }],
      name: 'BudgetMarkAsFinished',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegionalBudgetParseItems: {
    type: 'RegionalBudgetParseItemsPayload',
    schemaKey: 'RegionalBudgetParseItems',
    entityName: 'RegionalBudgetParseItemsPayload',
    isList: false,
    argsTSName: 'MutationRegionalBudgetParseItemsArgs',
    returnTSName: "Mutation['RegionalBudgetParseItems']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionalBudgetParseItemsPayload',
      args: [
        { name: 'regionalBudgetId', description: null, type: 'String!' },
        { name: 'parentBudgetId', description: null, type: 'String!' },
        {
          name: 'items',
          description: null,
          type: '[ProductionParsedItemsInput]'
        }
      ],
      name: 'RegionalBudgetParseItems',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegionalBudgetUpdateProductionItems: {
    type: 'RegionalBudget',
    schemaKey: 'RegionalBudgetUpdateProductionItems',
    entityName: 'RegionalBudget',
    isList: false,
    argsTSName: 'MutationRegionalBudgetUpdateProductionItemsArgs',
    returnTSName: "Mutation['RegionalBudgetUpdateProductionItems']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'RegionalBudget',
      args: [
        { name: 'regionalBudgetId', description: null, type: 'String!' },
        {
          name: 'productionItems',
          description: null,
          type: '[ProductionItemInput]'
        }
      ],
      name: 'RegionalBudgetUpdateProductionItems',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductionCreateOne: {
    type: 'CreateOneProductionPayload',
    schemaKey: 'ProductionCreateOne',
    entityName: 'CreateOneProductionPayload',
    isList: false,
    argsTSName: 'MutationProductionCreateOneArgs',
    returnTSName: "Mutation['ProductionCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneProductionPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneProductionInput!' }
      ],
      name: 'ProductionCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductionUpdateById: {
    type: 'UpdateByIdProductionPayload',
    schemaKey: 'ProductionUpdateById',
    entityName: 'UpdateByIdProductionPayload',
    isList: false,
    argsTSName: 'MutationProductionUpdateByIdArgs',
    returnTSName: "Mutation['ProductionUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdProductionPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdProductionInput!'
        }
      ],
      name: 'ProductionUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductionRemoveById: {
    type: 'RemoveByIdProductionPayload',
    schemaKey: 'ProductionRemoveById',
    entityName: 'RemoveByIdProductionPayload',
    isList: false,
    argsTSName: 'MutationProductionRemoveByIdArgs',
    returnTSName: "Mutation['ProductionRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdProductionPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'ProductionRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductionArchiveItem: {
    type: 'Production',
    schemaKey: 'ProductionArchiveItem',
    entityName: 'Production',
    isList: false,
    argsTSName: 'MutationProductionArchiveItemArgs',
    returnTSName: "Mutation['ProductionArchiveItem']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        "Marca um item como arquivado para que nao apareca mais na listagem na pagina 'Logística' para o fornecedor",
      deprecationReason: null,
      type: 'Production',
      args: [
        { name: 'recordId', description: null, type: 'String!' },
        {
          name: 'archive',
          description: null,
          type: 'Boolean',
          defaultValue: true
        }
      ],
      name: 'ProductionArchiveItem',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  ProductionParseItems: {
    type: 'ProductionParseItemsPayload',
    schemaKey: 'ProductionParseItems',
    entityName: 'ProductionParseItemsPayload',
    isList: false,
    argsTSName: 'MutationProductionParseItemsArgs',
    returnTSName: "Mutation['ProductionParseItems']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'ProductionParseItemsPayload',
      args: [
        {
          name: 'items',
          description: null,
          type: '[ProductionParsedItemsInput]'
        }
      ],
      name: 'ProductionParseItems',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BudgetCreateOne: {
    type: 'CreateOneBudgetPayload',
    schemaKey: 'BudgetCreateOne',
    entityName: 'CreateOneBudgetPayload',
    isList: false,
    argsTSName: 'MutationBudgetCreateOneArgs',
    returnTSName: "Mutation['BudgetCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneBudgetPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneBudgetInput!' }
      ],
      name: 'BudgetCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BudgetUpdateById: {
    type: 'UpdateByIdBudgetPayload',
    schemaKey: 'BudgetUpdateById',
    entityName: 'UpdateByIdBudgetPayload',
    isList: false,
    argsTSName: 'MutationBudgetUpdateByIdArgs',
    returnTSName: "Mutation['BudgetUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdBudgetPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdBudgetInput!' }
      ],
      name: 'BudgetUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  BudgetRemoveById: {
    type: 'RemoveByIdBudgetPayload',
    schemaKey: 'BudgetRemoveById',
    entityName: 'RemoveByIdBudgetPayload',
    isList: false,
    argsTSName: 'MutationBudgetRemoveByIdArgs',
    returnTSName: "Mutation['BudgetRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdBudgetPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'BudgetRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  SupplierDeliveryUpdateStatus: {
    type: 'Boolean',
    schemaKey: 'SupplierDeliveryUpdateStatus',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationSupplierDeliveryUpdateStatusArgs',
    returnTSName: "Mutation['SupplierDeliveryUpdateStatus']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [
        { name: 'productionId', description: null, type: 'String!' },
        {
          name: 'records',
          description: null,
          type: '[SupplierDeliveryUpdateStatusInput]!'
        }
      ],
      name: 'SupplierDeliveryUpdateStatus',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  InventorySync: {
    type: '[InventoryItem]',
    schemaKey: 'InventorySync',
    entityName: 'InventoryItem',
    isList: true,
    argsTSName: 'MutationInventorySyncArgs',
    returnTSName: "Mutation['InventorySync']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Sincroniza o estoque com nos dados vindos do xlsx (parse do xlsx feito no front). Remove todo o estoque existente no local informado e sincroniza.',
      deprecationReason: null,
      type: '[InventoryItem]',
      args: [
        { name: 'depositPaths', description: null, type: '[String]!' },
        { name: 'records', description: null, type: '[InventorySyncProduct]!' }
      ],
      name: 'InventorySync',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  InventoryWithDrawCreateOne: {
    type: 'CreateOneInventoryWithDrawPayload',
    schemaKey: 'InventoryWithDrawCreateOne',
    entityName: 'CreateOneInventoryWithDrawPayload',
    isList: false,
    argsTSName: 'MutationInventoryWithDrawCreateOneArgs',
    returnTSName: "Mutation['InventoryWithDrawCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneInventoryWithDrawPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneInventoryWithDrawInput!'
        }
      ],
      name: 'InventoryWithDrawCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  InventoryCreateWithDraw: {
    type: '[InventoryWithDraw]',
    schemaKey: 'InventoryCreateWithDraw',
    entityName: 'InventoryWithDraw',
    isList: true,
    argsTSName: 'MutationInventoryCreateWithDrawArgs',
    returnTSName: "Mutation['InventoryCreateWithDraw']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: '[InventoryWithDraw]',
      args: [
        {
          name: 'records',
          description: null,
          type: '[CreateOneInventoryWithDrawInput]'
        }
      ],
      name: 'InventoryCreateWithDraw',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeliveryOrderCreateOne: {
    type: 'CreateOneDeliveryOrderPayload',
    schemaKey: 'DeliveryOrderCreateOne',
    entityName: 'CreateOneDeliveryOrderPayload',
    isList: false,
    argsTSName: 'MutationDeliveryOrderCreateOneArgs',
    returnTSName: "Mutation['DeliveryOrderCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneDeliveryOrderPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneDeliveryOrderInput!'
        }
      ],
      name: 'DeliveryOrderCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeliveryOrderUpdateById: {
    type: 'UpdateByIdDeliveryOrderPayload',
    schemaKey: 'DeliveryOrderUpdateById',
    entityName: 'UpdateByIdDeliveryOrderPayload',
    isList: false,
    argsTSName: 'MutationDeliveryOrderUpdateByIdArgs',
    returnTSName: "Mutation['DeliveryOrderUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdDeliveryOrderPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdDeliveryOrderInput!'
        }
      ],
      name: 'DeliveryOrderUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeliveryOrderRemoveById: {
    type: 'RemoveByIdDeliveryOrderPayload',
    schemaKey: 'DeliveryOrderRemoveById',
    entityName: 'RemoveByIdDeliveryOrderPayload',
    isList: false,
    argsTSName: 'MutationDeliveryOrderRemoveByIdArgs',
    returnTSName: "Mutation['DeliveryOrderRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdDeliveryOrderPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'DeliveryOrderRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MetadataCreateOne: {
    type: 'CreateOneMetadataPayload',
    schemaKey: 'MetadataCreateOne',
    entityName: 'CreateOneMetadataPayload',
    isList: false,
    argsTSName: 'MutationMetadataCreateOneArgs',
    returnTSName: "Mutation['MetadataCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMetadataPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMetadataInput!' }
      ],
      name: 'MetadataCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MetadataUpdateOne: {
    type: 'UpdateOneMetadataPayload',
    schemaKey: 'MetadataUpdateOne',
    entityName: 'UpdateOneMetadataPayload',
    isList: false,
    argsTSName: 'MutationMetadataUpdateOneArgs',
    returnTSName: "Mutation['MetadataUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneMetadataPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateOneMetadataInput!' },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneMetadataInput'
        },
        { name: 'sort', description: null, type: 'SortUpdateOneMetadataInput' },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'MetadataUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MetadataRemoveById: {
    type: 'RemoveByIdMetadataPayload',
    schemaKey: 'MetadataRemoveById',
    entityName: 'RemoveByIdMetadataPayload',
    isList: false,
    argsTSName: 'MutationMetadataRemoveByIdArgs',
    returnTSName: "Mutation['MetadataRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdMetadataPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MetadataRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MetadataUpdateById: {
    type: 'UpdateByIdMetadataPayload',
    schemaKey: 'MetadataUpdateById',
    entityName: 'UpdateByIdMetadataPayload',
    isList: false,
    argsTSName: 'MutationMetadataUpdateByIdArgs',
    returnTSName: "Mutation['MetadataUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdMetadataPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdMetadataInput!' }
      ],
      name: 'MetadataUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorCreateOne: {
    type: 'CreateOnePositivatorPayload',
    schemaKey: 'PositivatorCreateOne',
    entityName: 'CreateOnePositivatorPayload',
    isList: false,
    argsTSName: 'MutationPositivatorCreateOneArgs',
    returnTSName: "Mutation['PositivatorCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePositivatorPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOnePositivatorInput!'
        }
      ],
      name: 'PositivatorCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorUpdateOne: {
    type: 'UpdateOnePositivatorPayload',
    schemaKey: 'PositivatorUpdateOne',
    entityName: 'UpdateOnePositivatorPayload',
    isList: false,
    argsTSName: 'MutationPositivatorUpdateOneArgs',
    returnTSName: "Mutation['PositivatorUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOnePositivatorPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOnePositivatorInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOnePositivatorInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOnePositivatorInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'PositivatorUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorUpdateMany: {
    type: 'UpdateManyPositivatorPayload',
    schemaKey: 'PositivatorUpdateMany',
    entityName: 'UpdateManyPositivatorPayload',
    isList: false,
    argsTSName: 'MutationPositivatorUpdateManyArgs',
    returnTSName: "Mutation['PositivatorUpdateMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'UpdateManyPositivatorPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateManyPositivatorInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateManyPositivatorInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateManyPositivatorInput'
        },
        { name: 'skip', description: null, type: 'Int' },
        { name: 'limit', description: null, type: 'Int', defaultValue: 1000 }
      ],
      name: 'PositivatorUpdateMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorRemoveById: {
    type: 'RemoveByIdPositivatorPayload',
    schemaKey: 'PositivatorRemoveById',
    entityName: 'RemoveByIdPositivatorPayload',
    isList: false,
    argsTSName: 'MutationPositivatorRemoveByIdArgs',
    returnTSName: "Mutation['PositivatorRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdPositivatorPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PositivatorRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorRemoveMany: {
    type: 'RemoveManyPositivatorPayload',
    schemaKey: 'PositivatorRemoveMany',
    entityName: 'RemoveManyPositivatorPayload',
    isList: false,
    argsTSName: 'MutationPositivatorRemoveManyArgs',
    returnTSName: "Mutation['PositivatorRemoveMany']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ',
      deprecationReason: null,
      type: 'RemoveManyPositivatorPayload',
      args: [
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterRemoveManyPositivatorInput!'
        }
      ],
      name: 'PositivatorRemoveMany',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorNotificationCreateOne: {
    type: 'Notification',
    schemaKey: 'PositivatorNotificationCreateOne',
    entityName: 'Notification',
    isList: false,
    argsTSName: 'MutationPositivatorNotificationCreateOneArgs',
    returnTSName: "Mutation['PositivatorNotificationCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Notification',
      args: [
        {
          name: 'positivedPercentageInterval',
          description: null,
          type: 'PositivatorNumberIntervalInput'
        },
        { name: 'regionItems', description: null, type: '[String]!' },
        { name: 'channels', description: null, type: '[EnumPlaceType]!' },
        { name: 'text', description: null, type: 'String!' },
        { name: 'schedule', description: null, type: 'Date!' }
      ],
      name: 'PositivatorNotificationCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorItemCreateOne: {
    type: 'CreateOnePositivatorItemPayload',
    schemaKey: 'PositivatorItemCreateOne',
    entityName: 'CreateOnePositivatorItemPayload',
    isList: false,
    argsTSName: 'MutationPositivatorItemCreateOneArgs',
    returnTSName: "Mutation['PositivatorItemCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePositivatorItemPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOnePositivatorItemInput!'
        }
      ],
      name: 'PositivatorItemCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorItemUpdateById: {
    type: 'UpdateByIdPositivatorItemPayload',
    schemaKey: 'PositivatorItemUpdateById',
    entityName: 'UpdateByIdPositivatorItemPayload',
    isList: false,
    argsTSName: 'MutationPositivatorItemUpdateByIdArgs',
    returnTSName: "Mutation['PositivatorItemUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdPositivatorItemPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdPositivatorItemInput!'
        }
      ],
      name: 'PositivatorItemUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PositivatorItemRemoveById: {
    type: 'RemoveByIdPositivatorItemPayload',
    schemaKey: 'PositivatorItemRemoveById',
    entityName: 'RemoveByIdPositivatorItemPayload',
    isList: false,
    argsTSName: 'MutationPositivatorItemRemoveByIdArgs',
    returnTSName: "Mutation['PositivatorItemRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdPositivatorItemPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PositivatorItemRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceNotificationMarkAsRead: {
    type: 'DeviceNotification',
    schemaKey: 'DeviceNotificationMarkAsRead',
    entityName: 'DeviceNotification',
    isList: false,
    argsTSName: 'MutationDeviceNotificationMarkAsReadArgs',
    returnTSName: "Mutation['DeviceNotificationMarkAsRead']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeviceNotification',
      args: [{ name: '_id', description: null, type: 'String!' }],
      name: 'DeviceNotificationMarkAsRead',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DeviceNotificationMarkAsSolved: {
    type: 'DeviceNotification',
    schemaKey: 'DeviceNotificationMarkAsSolved',
    entityName: 'DeviceNotification',
    isList: false,
    argsTSName: 'MutationDeviceNotificationMarkAsSolvedArgs',
    returnTSName: "Mutation['DeviceNotificationMarkAsSolved']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'DeviceNotification',
      args: [
        { name: 'notificationId', description: null, type: 'String!' },
        { name: 'devicesIds', description: null, type: '[String!]!' }
      ],
      name: 'DeviceNotificationMarkAsSolved',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MerchanRequestTempPassword: {
    type: 'Boolean!',
    schemaKey: 'MerchanRequestTempPassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMerchanRequestTempPasswordArgs',
    returnTSName: "Mutation['MerchanRequestTempPassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean!',
      args: [
        { name: 'email', description: null, type: 'String!' },
        { name: 'CPF', description: null, type: 'String' },
        { name: 'CNPJ', description: null, type: 'String' }
      ],
      name: 'MerchanRequestTempPassword',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  MerchanVerifyTempCode: {
    type: 'Boolean!',
    schemaKey: 'MerchanVerifyTempCode',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMerchanVerifyTempCodeArgs',
    returnTSName: "Mutation['MerchanVerifyTempCode']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean!',
      args: [
        { name: 'code', description: null, type: 'String!' },
        { name: 'CPF', description: null, type: 'String' },
        { name: 'CNPJ', description: null, type: 'String' }
      ],
      name: 'MerchanVerifyTempCode',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  MerchanChangePassword: {
    type: 'Boolean!',
    schemaKey: 'MerchanChangePassword',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationMerchanChangePasswordArgs',
    returnTSName: "Mutation['MerchanChangePassword']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean!',
      args: [
        { name: 'CPF', description: null, type: 'String' },
        { name: 'CNPJ', description: null, type: 'String' },
        { name: 'oldPassword', description: null, type: 'String!' },
        { name: 'newPassword', description: null, type: 'String!' }
      ],
      name: 'MerchanChangePassword',
      isDeprecated: false
    },
    isNonNull: true,
    kind: 'mutation'
  },

  StructuralCreateOne: {
    type: 'CreateOneStructuralPayload',
    schemaKey: 'StructuralCreateOne',
    entityName: 'CreateOneStructuralPayload',
    isList: false,
    argsTSName: 'MutationStructuralCreateOneArgs',
    returnTSName: "Mutation['StructuralCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneStructuralPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneStructuralInput!' }
      ],
      name: 'StructuralCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  StructuralUpdateOne: {
    type: 'UpdateOneStructuralPayload',
    schemaKey: 'StructuralUpdateOne',
    entityName: 'UpdateOneStructuralPayload',
    isList: false,
    argsTSName: 'MutationStructuralUpdateOneArgs',
    returnTSName: "Mutation['StructuralUpdateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateOneStructuralPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateOneStructuralInput!'
        },
        {
          name: 'filter',
          description: 'Filter by fields',
          type: 'FilterUpdateOneStructuralInput'
        },
        {
          name: 'sort',
          description: null,
          type: 'SortUpdateOneStructuralInput'
        },
        { name: 'skip', description: null, type: 'Int' }
      ],
      name: 'StructuralUpdateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  StructuralRemoveById: {
    type: 'RemoveByIdStructuralPayload',
    schemaKey: 'StructuralRemoveById',
    entityName: 'RemoveByIdStructuralPayload',
    isList: false,
    argsTSName: 'MutationStructuralRemoveByIdArgs',
    returnTSName: "Mutation['StructuralRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdStructuralPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'StructuralRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  StructuralUpdateById: {
    type: 'UpdateByIdStructuralPayload',
    schemaKey: 'StructuralUpdateById',
    entityName: 'UpdateByIdStructuralPayload',
    isList: false,
    argsTSName: 'MutationStructuralUpdateByIdArgs',
    returnTSName: "Mutation['StructuralUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdStructuralPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'UpdateByIdStructuralInput!'
        }
      ],
      name: 'StructuralUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  StructuralEditStatus: {
    type: 'StructuralEditStatusPayload',
    schemaKey: 'StructuralEditStatus',
    entityName: 'StructuralEditStatusPayload',
    isList: false,
    argsTSName: 'MutationStructuralEditStatusArgs',
    returnTSName: "Mutation['StructuralEditStatus']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'StructuralEditStatusPayload',
      args: [
        { name: 'projectId', description: null, type: 'String' },
        { name: 'stage', description: null, type: 'EnumStructuralStage' },
        { name: 'status', description: null, type: 'ProjectStageStatus' },
        { name: 'data', description: null, type: 'JSON' },
        {
          name: 'isComment',
          description: 'A alteração é um comentário?',
          type: 'Boolean'
        },
        {
          name: 'isFile',
          description: 'É um upload de arquivo?',
          type: 'Boolean'
        }
      ],
      name: 'StructuralEditStatus',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  DigitalBrochureAnalyticsCreateOne: {
    type: 'CreateOneDigitalBrochureAnalyticsPayload',
    schemaKey: 'DigitalBrochureAnalyticsCreateOne',
    entityName: 'CreateOneDigitalBrochureAnalyticsPayload',
    isList: false,
    argsTSName: 'MutationDigitalBrochureAnalyticsCreateOneArgs',
    returnTSName: "Mutation['DigitalBrochureAnalyticsCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneDigitalBrochureAnalyticsPayload',
      args: [
        {
          name: 'record',
          description: null,
          type: 'CreateOneDigitalBrochureAnalyticsInput!'
        }
      ],
      name: 'DigitalBrochureAnalyticsCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AreaLojaCreateOne: {
    type: 'CreateOneAreaLojaPayload',
    schemaKey: 'AreaLojaCreateOne',
    entityName: 'CreateOneAreaLojaPayload',
    isList: false,
    argsTSName: 'MutationAreaLojaCreateOneArgs',
    returnTSName: "Mutation['AreaLojaCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneAreaLojaPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneAreaLojaInput!' }
      ],
      name: 'AreaLojaCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AreaLojaUpdateById: {
    type: 'UpdateByIdAreaLojaPayload',
    schemaKey: 'AreaLojaUpdateById',
    entityName: 'UpdateByIdAreaLojaPayload',
    isList: false,
    argsTSName: 'MutationAreaLojaUpdateByIdArgs',
    returnTSName: "Mutation['AreaLojaUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdAreaLojaPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdAreaLojaInput!' }
      ],
      name: 'AreaLojaUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  AreaLojaRemoveById: {
    type: 'RemoveByIdAreaLojaPayload',
    schemaKey: 'AreaLojaRemoveById',
    entityName: 'RemoveByIdAreaLojaPayload',
    isList: false,
    argsTSName: 'MutationAreaLojaRemoveByIdArgs',
    returnTSName: "Mutation['AreaLojaRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdAreaLojaPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'AreaLojaRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegraMapaCreateOne: {
    type: 'CreateOneRegraMapaPayload',
    schemaKey: 'RegraMapaCreateOne',
    entityName: 'CreateOneRegraMapaPayload',
    isList: false,
    argsTSName: 'MutationRegraMapaCreateOneArgs',
    returnTSName: "Mutation['RegraMapaCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneRegraMapaPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneRegraMapaInput!' }
      ],
      name: 'RegraMapaCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegraMapaUpdateById: {
    type: 'UpdateByIdRegraMapaPayload',
    schemaKey: 'RegraMapaUpdateById',
    entityName: 'UpdateByIdRegraMapaPayload',
    isList: false,
    argsTSName: 'MutationRegraMapaUpdateByIdArgs',
    returnTSName: "Mutation['RegraMapaUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdRegraMapaPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdRegraMapaInput!' }
      ],
      name: 'RegraMapaUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  RegraMapaRemoveById: {
    type: 'RemoveByIdRegraMapaPayload',
    schemaKey: 'RegraMapaRemoveById',
    entityName: 'RemoveByIdRegraMapaPayload',
    isList: false,
    argsTSName: 'MutationRegraMapaRemoveByIdArgs',
    returnTSName: "Mutation['RegraMapaRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdRegraMapaPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'RegraMapaRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FormatoCreateOne: {
    type: 'CreateOneFormatoPayload',
    schemaKey: 'FormatoCreateOne',
    entityName: 'CreateOneFormatoPayload',
    isList: false,
    argsTSName: 'MutationFormatoCreateOneArgs',
    returnTSName: "Mutation['FormatoCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneFormatoPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneFormatoInput!' }
      ],
      name: 'FormatoCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FormatoUpdateById: {
    type: 'UpdateByIdFormatoPayload',
    schemaKey: 'FormatoUpdateById',
    entityName: 'UpdateByIdFormatoPayload',
    isList: false,
    argsTSName: 'MutationFormatoUpdateByIdArgs',
    returnTSName: "Mutation['FormatoUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdFormatoPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdFormatoInput!' }
      ],
      name: 'FormatoUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  FormatoRemoveById: {
    type: 'RemoveByIdFormatoPayload',
    schemaKey: 'FormatoRemoveById',
    entityName: 'RemoveByIdFormatoPayload',
    isList: false,
    argsTSName: 'MutationFormatoRemoveByIdArgs',
    returnTSName: "Mutation['FormatoRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdFormatoPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'FormatoRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PecaMapaCreateOne: {
    type: 'CreateOnePecaMapaPayload',
    schemaKey: 'PecaMapaCreateOne',
    entityName: 'CreateOnePecaMapaPayload',
    isList: false,
    argsTSName: 'MutationPecaMapaCreateOneArgs',
    returnTSName: "Mutation['PecaMapaCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOnePecaMapaPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOnePecaMapaInput!' }
      ],
      name: 'PecaMapaCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PecaMapaUpdateById: {
    type: 'UpdateByIdPecaMapaPayload',
    schemaKey: 'PecaMapaUpdateById',
    entityName: 'UpdateByIdPecaMapaPayload',
    isList: false,
    argsTSName: 'MutationPecaMapaUpdateByIdArgs',
    returnTSName: "Mutation['PecaMapaUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdPecaMapaPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdPecaMapaInput!' }
      ],
      name: 'PecaMapaUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PecaMapaRemoveById: {
    type: 'RemoveByIdPecaMapaPayload',
    schemaKey: 'PecaMapaRemoveById',
    entityName: 'RemoveByIdPecaMapaPayload',
    isList: false,
    argsTSName: 'MutationPecaMapaRemoveByIdArgs',
    returnTSName: "Mutation['PecaMapaRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdPecaMapaPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'PecaMapaRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  PecaMapaAddMultiple: {
    type: 'Boolean',
    schemaKey: 'PecaMapaAddMultiple',
    entityName: 'Boolean',
    isList: false,
    argsTSName: 'MutationPecaMapaAddMultipleArgs',
    returnTSName: "Mutation['PecaMapaAddMultiple']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description: '',
      deprecationReason: null,
      type: 'Boolean',
      args: [{ name: 'pecas', description: null, type: '[JSON]' }],
      name: 'PecaMapaAddMultiple',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MapaPDVCreateOne: {
    type: 'CreateOneMapaPDVPayload',
    schemaKey: 'MapaPDVCreateOne',
    entityName: 'CreateOneMapaPDVPayload',
    isList: false,
    argsTSName: 'MutationMapaPdvCreateOneArgs',
    returnTSName: "Mutation['MapaPDVCreateOne']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Create one document with mongoose defaults, setters, hooks and validation',
      deprecationReason: null,
      type: 'CreateOneMapaPDVPayload',
      args: [
        { name: 'record', description: null, type: 'CreateOneMapaPDVInput!' }
      ],
      name: 'MapaPDVCreateOne',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MapaPDVUpdateById: {
    type: 'UpdateByIdMapaPDVPayload',
    schemaKey: 'MapaPDVUpdateById',
    entityName: 'UpdateByIdMapaPDVPayload',
    isList: false,
    argsTSName: 'MutationMapaPdvUpdateByIdArgs',
    returnTSName: "Mutation['MapaPDVUpdateById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.',
      deprecationReason: null,
      type: 'UpdateByIdMapaPDVPayload',
      args: [
        { name: 'record', description: null, type: 'UpdateByIdMapaPDVInput!' }
      ],
      name: 'MapaPDVUpdateById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  },

  MapaPDVRemoveById: {
    type: 'RemoveByIdMapaPDVPayload',
    schemaKey: 'MapaPDVRemoveById',
    entityName: 'RemoveByIdMapaPDVPayload',
    isList: false,
    argsTSName: 'MutationMapaPdvRemoveByIdArgs',
    returnTSName: "Mutation['MapaPDVRemoveById']",
    isMutation: true,
    isQuery: false,
    isSubscription: false,
    field: {
      description:
        'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.',
      deprecationReason: null,
      type: 'RemoveByIdMapaPDVPayload',
      args: [{ name: '_id', description: null, type: 'MongoID!' }],
      name: 'MapaPDVRemoveById',
      isDeprecated: false
    },
    isNonNull: false,
    kind: 'mutation'
  }
};

export const useClient = useClientFactory<any, Methods>();
