import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Former, rem, SubHeader, UserSession } from '@digi-tim-19/components';

import { PageWrapper } from '../../components/Page/PageWrapper';
import timLogo from '../../assets/images/tim_logo.png';
import bgMerchan from './bg-login.png';
import logoMerchan from '../../assets/images/logo-merchan.png';
import { useClient } from '../../autogenerated/client/client';
import FormLogin from './components/FormLogin/FormLogin';

const StyledPageWrapper = styled(PageWrapper)`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${require('./bg-tim.jpeg')});

  .Login_logo {
    width: 76px;
  }

  .Login_img {
    object-fit: cover;
    margin-top: -1px;
  }

  @media (max-width: 960px) {
    .Login_img {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    .Login_form {
      padding: 30px 30px 0 30px;
    }
  }
`;

const Main = styled.main.attrs({ className: 'ui-flex Login_Main' })`
  width: 100%;
  justify-content: center;
  margin-top: 100px;
`;

const ImageMerchan = styled.div.attrs({
  className: 'ui-flex Login_ImageMerchan'
})`
  width: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    max-width: 35vw;
    display: flex;
  }
`;

const LogoMerchan = styled.div.attrs({
  className: 'ui-flex Login_LogoMerchan'
})`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Right = styled.div.attrs({
  className: 'ui-flex Login_Right'
})`
  width: 100%;
  padding: ${rem(20)};
  max-width: ${rem(430)};
  margin-right: auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1023px) {
    margin-right: 0;
  }
`;

export const Login = () => {
  const state = useClient('UserLoginWithPassword');
  const history = useHistory();

  const tokenClient = useClient('UserLoginWithToken');
  React.useLayoutEffect(() => {
    const token = UserSession.getToken();
    if (!token) return;
    tokenClient.fetch({ variables: { token } }).then((ctx) => {
      if (!ctx.errors) {
        history.push('/');
      } else {
        if (window) {
          window.localStorage.clear();
          window.sessionStorage.clear();
        }
      }
    });
  }, []);

  // React.useEffect(() => {
  //   message.info(
  //     'Pedimos que caso esteja com problemas de login via CNPJ solicite o reset de senha, clicando em Esqueci a senha e utilizando o CNPJ',
  //     10
  //   );
  // }, []);

  return (
    <StyledPageWrapper>
      <SubHeader logoURL={timLogo} />

      <Main>
        <ImageMerchan>
          <img src={bgMerchan} className="Login_img" alt={'Imagem'} />
        </ImageMerchan>

        <Right>
          <LogoMerchan>
            <img src={logoMerchan} alt={'Portal Merchandising'} />
          </LogoMerchan>

          <FormLogin />
        </Right>
      </Main>
    </StyledPageWrapper>
  );
};

export default Login;
