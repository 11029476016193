import casal from './casal.png';
import logoTim from './tim_logo.png';
import tim_rodape from './img_tim_marca_rodape.png';
import logoConsumer from './consumer.png';
import iconYoutube from './footer_youtube.png';
import iconTwitter from './footer_twitter.png';
import iconLinkedin from './footer_linkedin.png';
import iconInstagram from './footer_Instagram.png';
import iconFacebook from './footer_facebook.png';
import googleStore from './google_store.png';
import appleStore from './apple_store.png';

export const siteImages = {
  casal,
  logoTim,
  tim_rodape,
  logoConsumer,
  iconYoutube,
  iconTwitter,
  iconLinkedin,
  iconInstagram,
  iconFacebook,
  googleStore,
  appleStore
};
