/**
 * https://redux-toolkit.js.org/tutorials/basic-tutorial#summary
 */
import {
  createSlice,
  PayloadAction,
  ActionCreatorWithPayload
} from '@reduxjs/toolkit';

import {
  ProductionItem,
  ProductionQuantityByDestinationInput,
  ProductionServerCalcValuesState,
  ProductionStatus
} from '../interfaces/ProductionInterfaces';

import { ProductionReduxState } from './ProductionReduxInterfaces';

import { productionItemsSlice } from './productionItems';
import { quantityModeBySelectedChannels } from './utils';
import { uniqBy } from '@digi-tim-19/utils';

function pricingInitialState(): ProductionServerCalcValuesState {
  return {
    output: {
      byDestination: {},
      byProduct: {}
    },
    loading: false,
    error: ''
  };
}

type FrontendOnlyFields =
  | 'activeGroupId'
  | 'disableProductItemMap'
  | 'serverCalculatedValues';

function initialState(): ProductionReduxState {
  return {
    _id: '',
    code: '',
    activeGroupId: '',
    status: 'draft',
    title: '',
    originBudgetId: undefined,
    productionItems: [],
    selectedLayoutsCodeT: [],
    serverCalculatedValues: pricingInitialState()
  };
}

export const productionSlice = createSlice({
  name: 'production',
  initialState: initialState(),
  reducers: {
    reset() {
      return initialState();
    },
    createBudgetProduction(
      _,
      action: PayloadAction<{
        budgetId: string;
        title: string;
        productionItems: ProductionItem[];
      }>
    ) {
      const { productionItems, budgetId, title } = action.payload;

      const selectedLayoutsCodeT: string[] = [];

      productionItems.forEach((el) => {
        el.quantityByProductInput.forEach((el) => {
          selectedLayoutsCodeT.push(el.layoutCodeT);
        });
        el.quantityByDestinationInput.forEach((el) => {
          selectedLayoutsCodeT.push(el.layoutCodeT);
        });
      });

      return {
        ...initialState(),
        title,
        selectedLayoutsCodeT: uniqBy(selectedLayoutsCodeT, (el) => el),
        originBudgetId: budgetId,
        activeGroupId: productionItems[0]?.frontendGroupId || '',
        productionItems
      };
    },
    initialState(
      state,
      action: PayloadAction<Omit<ProductionReduxState, FrontendOnlyFields>>
    ) {
      return {
        ...initialState(),
        ...action.payload,
        activeGroupId:
          action.payload.productionItems?.[0]?.frontendGroupId || ''
      };
    },

    setActiveGroup(state, action: PayloadAction<string>) {
      if (state.activeGroupId === action.payload) return state;

      const exists = state.productionItems.some(
        (el) => el.frontendGroupId === action.payload
      );

      if (!exists) return state;

      state.activeGroupId = action.payload;
    },

    changeTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },

    changeStatus(state, action: PayloadAction<ProductionStatus>) {
      if (state.status === 'draft') {
        state.status = action.payload;
      } else {
        console.log(
          `Não permitido alterar produção com status ${state.status}`
        );
      }
    },

    replaceSelectedLayouts: (
      state,
      action: PayloadAction<{
        replacementCodeT: string[];
        quantity: number;
      }>
    ) => {
      const newCodes = [...action.payload.replacementCodeT];
      const quantity = action.payload.quantity;

      const added = newCodes.filter((code) => {
        return !state.selectedLayoutsCodeT.includes(code);
      });

      state.selectedLayoutsCodeT = newCodes;

      // Sempre que 1 layout é disponibilizado para
      // a produção (no select "Selecionar campanha ou layout") os layouts selecionados
      // são adicionados em todos os productionItems e se removidos ocorre o inverso
      state.productionItems = state.productionItems.map((productionItem) => {
        const {
          foundDestinationsSAPCodes,
          quantityByProductInput,
          quantityByDestinationInput
        } = productionItem;

        // o modo de selecionar as quantidades depende dos canais selecionados
        const quantityModel = quantityModeBySelectedChannels(
          productionItem.destinationsFilters
        );

        // === remove items ===

        productionItem.quantityByProductInput = quantityByProductInput.filter(
          (el) => {
            return newCodes.includes(el.layoutCodeT);
          }
        );

        productionItem.quantityByDestinationInput =
          quantityByDestinationInput.filter((el) => {
            return newCodes.includes(el.layoutCodeT);
          });

        // === add items ===
        if (quantityModel === 'byProduct') {
          productionItem.quantityByDestinationInput = []; // será preenchido pela api quando o modo é byProduct

          const newItems = added.map((layoutCodeT) => {
            return {
              layoutCodeT,
              quantityByDestination: quantity,
              isQuantityFromFactorial: false,
              extraQuantityByDistributionCenter: 0
            };
          });

          productionItem.quantityByProductInput = [
            ...productionItem.quantityByProductInput,
            ...newItems
          ];
        }

        if (quantityModel === 'byDestination') {
          productionItem.quantityByProductInput = []; // fica vázio quando o modo é byDestination

          const newItems: ProductionQuantityByDestinationInput[] = [];

          // adiciona produtos para cada um dos destinos
          foundDestinationsSAPCodes.forEach((destinationSAPCode) => {
            //
            // adiciona cada um dos produtos
            added.forEach(function (layoutCodeT) {
              newItems.push({
                layoutCodeT,
                destinationSAPCode,
                quantity,
                isQuantityCalculatedFromFactorial: false
              });
            });
          });

          productionItem.quantityByDestinationInput = [
            ...productionItem.quantityByDestinationInput,
            ...newItems
          ];
        }

        return productionItem;
      });
    },

    serverCalcProductionValuesStart(state) {
      state.serverCalculatedValues.loading = true;
    },

    serverCalcProductionValuesError(state, action: PayloadAction<string>) {
      state.serverCalculatedValues.loading = false;
      state.serverCalculatedValues.error = action.payload;
    },

    serverCalcProductionValuesSuccess(
      state,
      action: PayloadAction<ProductionServerCalcValuesState['output']>
    ) {
      state.serverCalculatedValues.error = '';
      state.serverCalculatedValues.loading = false;
      state.serverCalculatedValues.output = action.payload;
    }
  },
  extraReducers: (builder) => {
    //
    // seta um novo item ativo ao remover grupo
    builder.addMatcher(isRemove, (state, { frontendGroupId }) => {
      const removedIndex = state.productionItems.findIndex(
        (el) => el.frontendGroupId === frontendGroupId
      );
      let newActiveId = '';
      if (removedIndex > -1) {
        newActiveId =
          state.productionItems[removedIndex - 1].frontendGroupId || '';
      }
      state.activeGroupId = newActiveId;
    });

    builder.addMatcher(
      // quando a action iniciar com "productionItems/" ela será lidada
      // pelo sub-reducer de productionItemsSlice
      () => true,
      (state, action) => {
        state.productionItems = productionItemsSlice.reducer(
          state.productionItems,
          action
        );
      }
    );
  }
});

// só esta dentro de uma function pra evitar dependencia circular quando const
function isRemove(arg: any) {
  return isAction(productionItemsSlice.actions.removeDestinationGroup)(arg);
}

function isAction<ActionCreator extends ActionCreatorWithPayload<any>>(
  fn: ActionCreator
) {
  return function (action: any): action is Parameters<ActionCreator>[0] {
    return fn?.type === action?.type;
  };
}
