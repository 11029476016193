import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProductionItem,
  ProductionItemDestinationsConfig,
  ProductionQuantityByDestinationInput,
  ProductionQuantityByProductInput,
  SupplierDeliveryPlaceType
} from '../../pages/Production/interfaces/ProductionInterfaces';

import {
  deliveryPlaceTypeBySelectedChannels,
  quantityInputModeByDeliveryPlaceType,
  quantityModeBySelectedChannels
} from '../../pages/Production/redux/utils';

// Factory que cria um Slice responsavel por atualizar productionItems
// de uma produção ou de uma verba (como a logica é a mesma utilizamos um factory)
export function createProductionItemsSlice(name: string) {
  return createSlice({
    name,
    initialState: [] as ProductionItem[],
    reducers: {
      addDestinationGroup: (
        state,
        action: PayloadAction<{
          data: [
            {
              destinations: ProductionItemDestinationsConfig;
              selectedLayoutsCodeT: string[];
            }
          ];
          byDestination?: boolean;
        }>
      ) => {
        const { data, byDestination } = action.payload;

        data.forEach(({ destinations, selectedLayoutsCodeT }) => {
          const quantityInputMode = quantityModeBySelectedChannels(
            destinations.destinationsFilters
          );

          if (quantityInputMode === 'byDestination' || byDestination) {
            const quantityByDestinationInput: ProductionQuantityByDestinationInput[] =
              [];

            destinations.foundDestinationsSAPCodes.forEach(function (
              destinationSAPCode
            ) {
              selectedLayoutsCodeT.forEach(function (layoutCodeT) {
                quantityByDestinationInput.push({
                  layoutCodeT,
                  destinationSAPCode,
                  quantity: 1,
                  isQuantityCalculatedFromFactorial: false
                });
              });
            });

            const item: ProductionItem = {
              frontendGroupId: destinations.frontendGroupId,
              destinationsFilters: destinations.destinationsFilters,
              foundDestinationsSAPCodes: destinations.foundDestinationsSAPCodes,
              quantityInputMode,
              quantityByDestinationInput: quantityByDestinationInput,
              quantityByProductInput: [],
              quantityByProductOutput: [],
              supplierDeliveryDestinationType:
                deliveryPlaceTypeBySelectedChannels(
                  destinations.destinationsFilters
                )
            };

            state.push(item);
          } else {
            const quantityByProductInput: ProductionQuantityByProductInput[] =
              [];

            selectedLayoutsCodeT.forEach(function (layoutCodeT) {
              quantityByProductInput.push({
                layoutCodeT,
                quantityByDestination: 1,
                isQuantityFromFactorial: false,
                extraQuantityByDistributionCenter: 0
              });
            });

            const item: ProductionItem = {
              frontendGroupId: destinations.frontendGroupId,
              title: destinations?.title,
              destinationsFilters: destinations.destinationsFilters,
              foundDestinationsSAPCodes: destinations.foundDestinationsSAPCodes,
              quantityInputMode,
              quantityByDestinationInput: [],
              quantityByProductOutput: [],
              quantityByProductInput: quantityByProductInput,
              supplierDeliveryDestinationType:
                deliveryPlaceTypeBySelectedChannels(
                  destinations.destinationsFilters
                )
            };

            state.push(item);
          }
        });
      },

      removeDestinationGroup: (
        state,
        action: PayloadAction<{ frontendGroupId: string }>
      ) => {
        return state.filter(
          (el) => el.frontendGroupId !== action.payload.frontendGroupId
        );
      },

      updateDestinationGroup: (
        state,
        action: PayloadAction<{
          destinationConfig: ProductionItemDestinationsConfig;
          selectedLayoutsCodeT: string[];
          byDestination?: boolean;
        }>
      ) => {
        const { destinationConfig, selectedLayoutsCodeT, byDestination } =
          action.payload;

        const quantityInputMode = quantityModeBySelectedChannels(
          destinationConfig.destinationsFilters
        );

        const supplierDeliveryDestinationType =
          deliveryPlaceTypeBySelectedChannels(
            destinationConfig.destinationsFilters
          );

        const index = state.findIndex(
          (el) => el.frontendGroupId === destinationConfig.frontendGroupId
        );

        const item = plainObject(state[index]);

        let quantityByDestinationInput: ProductionQuantityByDestinationInput[] =
          [];
        let quantityByProductInput = item.quantityByProductInput;

        if (quantityInputMode === 'byDestination' || byDestination) {
          quantityByProductInput = [];

          quantityByDestinationInput = item.quantityByDestinationInput.filter(
            (el) => {
              return destinationConfig.foundDestinationsSAPCodes.includes(
                el.destinationSAPCode
              );
            }
          );

          const addedSapCodes =
            destinationConfig.foundDestinationsSAPCodes.filter((sapCode) => {
              return !item.foundDestinationsSAPCodes.includes(sapCode);
            });

          addedSapCodes.map((destinationSAPCode) => {
            selectedLayoutsCodeT.forEach(function (layoutCodeT) {
              quantityByDestinationInput.push({
                layoutCodeT,
                destinationSAPCode,
                quantity: 1,
                isQuantityCalculatedFromFactorial: false
              });
            });
          });
        }

        item.title = destinationConfig?.title;
        item.destinationsFilters = destinationConfig.destinationsFilters;
        item.foundDestinationsSAPCodes =
          destinationConfig.foundDestinationsSAPCodes;
        item.quantityInputMode = quantityInputMode;
        item.quantityByProductInput = quantityByProductInput;
        item.quantityByDestinationInput = quantityByDestinationInput;
        item.supplierDeliveryDestinationType = supplierDeliveryDestinationType;

        state[index] = item;
      },

      updateDestinationType: (
        state,
        action: PayloadAction<{
          frontendGroupId: string;
          destinationType: SupplierDeliveryPlaceType;
        }>
      ) => {
        const item = itemById(state, action.payload.frontendGroupId);
        item.supplierDeliveryDestinationType = action.payload.destinationType;
        item.quantityInputMode = quantityInputModeByDeliveryPlaceType(
          item.supplierDeliveryDestinationType
        );
      },

      updateItemFactorial(
        state,
        action: PayloadAction<{
          isQuantityFromFactorial: boolean;
          frontendGroupId: string;
          layoutCodeT: string;
        }>
      ) {
        const { isQuantityFromFactorial, frontendGroupId, layoutCodeT } =
          action.payload;
        const item = itemById(state, frontendGroupId);

        if (item.quantityInputMode === 'byDestination') {
          // não tem fatorial
        } else {
          item.quantityByProductInput.map((el) => {
            if (el.layoutCodeT === layoutCodeT) {
              el.isQuantityFromFactorial = isQuantityFromFactorial;
            }
            return el;
          });
        }
      },

      updateQuantityByProduct(
        state,
        action: PayloadAction<{
          frontendGroupId: string;
          layoutCodeT: string;
          quantity: number;
        }>
      ) {
        const { quantity, frontendGroupId, layoutCodeT } = action.payload;
        const item = itemById(state, frontendGroupId);

        if (item.quantityInputMode !== 'byProduct') {
          return console.error(
            `"${item.quantityInputMode}" não é possivel atualizar por produto`
          );
        }

        item.quantityByProductInput = item.quantityByProductInput.map((el) => {
          if (el.layoutCodeT === layoutCodeT) {
            el.quantityByDestination = quantity;
          }
          return el;
        });
      },

      updateQuantityDestination(
        state,
        action: PayloadAction<{
          frontendGroupId: string;
          layoutCodeT: string;
          destinationSAPCode: string;
          quantity: number;
        }>
      ) {
        const { quantity, frontendGroupId, layoutCodeT, destinationSAPCode } =
          action.payload;

        const item = itemById(state, frontendGroupId);

        // if (item.quantityInputMode !== 'byDestination') {
        //   return console.error(
        //     `"${item.quantityInputMode}" não é possivel atualizar por destino`
        //   );
        // }

        item.quantityByDestinationInput = item.quantityByDestinationInput.map(
          (el) => {
            if (
              el.layoutCodeT === layoutCodeT &&
              el.destinationSAPCode === destinationSAPCode
            ) {
              el.quantity = quantity;
            }
            return el;
          }
        );
      },

      updateExtraQuantity(
        state,
        action: PayloadAction<{
          frontendGroupId: string;
          layoutCodeT: string;
          extra: number;
        }>
      ) {
        let { frontendGroupId, layoutCodeT, extra } = action.payload;

        const item = itemById(state, frontendGroupId);

        const isExtraAllowed =
          item.supplierDeliveryDestinationType ===
            'centro_distribuicao_logistica' &&
          item.quantityInputMode === 'byProduct';

        if (!isExtraAllowed) {
          extra = 0;
        }

        item.quantityByProductInput = item.quantityByProductInput.map((el) => {
          if (el.layoutCodeT === layoutCodeT) {
            el.extraQuantityByDistributionCenter = extra;
          }
          return el;
        });
      }
    }
  });
}

function itemById(items: ProductionItem[], id: string) {
  const item = items.find((el) => el.frontendGroupId === id);
  if (!item) {
    const msg = `Item ${id} não encontrado`;
    alert(msg);
    throw new Error(msg);
  }
  return item;
}

function plainObject<T>(val: T): T {
  return JSON.parse(JSON.stringify(val));
}
