"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var routeNamesCorp;

(function (routeNamesCorp) {
  routeNamesCorp["home"] = "home";
  routeNamesCorp["login"] = "login";
  routeNamesCorp["loginCPF"] = "loginCPF";
  routeNamesCorp["changePassword"] = "changePassword";
  routeNamesCorp["favoritos"] = "favoritos";
  routeNamesCorp["notificacoes"] = "notificacoes";
  routeNamesCorp["notificacoesCadastrar"] = "notificacoesCadastrar";
  routeNamesCorp["notificacoesGerenciar"] = "notificacoesGerenciar";
  routeNamesCorp["notificacoesEditar"] = "notificacoesEditar";
  routeNamesCorp["documentos"] = "documentos";
  routeNamesCorp["documentosCategoria"] = "documentosCategoria";
  routeNamesCorp["documentosSubCategoria"] = "documentosSubCategoria";
  routeNamesCorp["documentosSubSubCategoria"] = "documentosSubSubCategoria";
  routeNamesCorp["documentosGerenciar"] = "documentosGerenciar";
  routeNamesCorp["documentosCadastrar"] = "documentosCadastrar";
  routeNamesCorp["documentosEditar"] = "documentosEditar";
  routeNamesCorp["atividadesRecentes"] = "atividadesRecentes";
  routeNamesCorp["search"] = "search";
  routeNamesCorp["linksUteis"] = "linksUteis";
  routeNamesCorp["linksUteisListar"] = "linksUteisListar";
  routeNamesCorp["linksUteisEditar"] = "linksUteisEditar";
  routeNamesCorp["linksUteisCadastrar"] = "linksUteisCadastrar";
  routeNamesCorp["noticias"] = "noticias";
  routeNamesCorp["noticiasConteudo"] = "noticiasConteudo";
  routeNamesCorp["noticiasCadastrar"] = "noticiasCadastrar";
  routeNamesCorp["noticiasListar"] = "noticiasListar";
  routeNamesCorp["noticiasEditar"] = "noticiasEditar";
  routeNamesCorp["campanhasIncentivo"] = "campanhasIncentivo";
  routeNamesCorp["campanhasIncentivoVigentes"] = "campanhasIncentivoVigentes";
  routeNamesCorp["campanhasIncentivoEncerradas"] = "campanhasIncentivoEncerradas";
  routeNamesCorp["campanhasIncentivoResultados"] = "campanhasIncentivoResultados";
  routeNamesCorp["campanhasIncentivoListar"] = "campanhasIncentivoListar";
  routeNamesCorp["campanhasIncentivoEditar"] = "campanhasIncentivoEditar";
  routeNamesCorp["campanhasIncentivoCadastrar"] = "campanhasIncentivoCadastrar";
  routeNamesCorp["campanhasIncentivoUploadPontos"] = "campanhasIncentivoUploadPontos";
  routeNamesCorp["campanhasIncentivoEstornoUploadExtraListar"] = "campanhasIncentivoEstornoUploadExtraListar";
  routeNamesCorp["campanhasIncentivoEstornoUploadExtra"] = "campanhasIncentivoEstornoUploadExtra";
  routeNamesCorp["videos"] = "videos";
  routeNamesCorp["videosPlayer"] = "videosPlayer";
  routeNamesCorp["videosPlaylist"] = "videosPlaylist";
  routeNamesCorp["videosListar"] = "videosListar";
  routeNamesCorp["videosCadastrar"] = "videosCadastrar";
  routeNamesCorp["videosEditar"] = "videosEditar";
  routeNamesCorp["pdfViewer"] = "pdfViewer";
  routeNamesCorp["htmlViewer"] = "htmlViewer";
  routeNamesCorp["imageViewer"] = "imageViewer";
  routeNamesCorp["filesViewer"] = "filesViewer";
  routeNamesCorp["eventViewer"] = "eventViewer";
  routeNamesCorp["pdfEventViewer"] = "pdfEventViewer";
  routeNamesCorp["htmlEventViewer"] = "htmlEventViewer";
  routeNamesCorp["imageEventViewer"] = "imageEventViewer";
  routeNamesCorp["filesEventViewer"] = "filesEventViewer";
  routeNamesCorp["informativos"] = "informativos";
  routeNamesCorp["informativosListar"] = "informativosListar";
  routeNamesCorp["informativosEditar"] = "informativosEditar";
  routeNamesCorp["informativosCadastrar"] = "informativosCadastrar";
  routeNamesCorp["relatoriosDashboard"] = "relatoriosDashboard";
  routeNamesCorp["relatoriosUsuarios"] = "relatoriosUsuarios";
  routeNamesCorp["relatoriosPontosExpirados"] = "relatoriosPontosExpirados";
  routeNamesCorp["contestacaoListar"] = "contestacaoListar";
  routeNamesCorp["contestacaoEditar"] = "contestacaoEditar";
  routeNamesCorp["minhasContestacoesListar"] = "minhasContestacoesListar";
  routeNamesCorp["minhaContestacaoVisualizar"] = "minhaContestacaoVisualizar";
  routeNamesCorp["bannersListar"] = "bannersListar";
  routeNamesCorp["bannersCadastrar"] = "bannersCadastrar";
  routeNamesCorp["bannersEditar"] = "bannersEditar";
  routeNamesCorp["usuariosAprovadores"] = "usuariosAprovadores";
  routeNamesCorp["usuariosListar"] = "usuariosListar";
  routeNamesCorp["ordemPagamentoListar"] = "ordemPagamentoListar";
  routeNamesCorp["ordemPagamentoCadastrar"] = "ordemPagamentoCadastrar";
  routeNamesCorp["ordemPagamentoEditar"] = "ordemPagamentoEditar";
  routeNamesCorp["informacoesExtrato"] = "informacoesExtrato";
  routeNamesCorp["aprovacoes"] = "aprovacoes";
  routeNamesCorp["pesquisa"] = "pesquisa";
  routeNamesCorp["pesquisaListar"] = "pesquisaListar";
  routeNamesCorp["pesquisaCadastrar"] = "pesquisaCadastrar";
  routeNamesCorp["downloadIncentivo"] = "downloadIncentivo";
  routeNamesCorp["chat"] = "chat";
  routeNamesCorp["eventos"] = "eventos";
  routeNamesCorp["eventosGerenciar"] = "eventosGerenciar";
  routeNamesCorp["eventosCadastrar"] = "eventosCadastrar";
  routeNamesCorp["eventosEditar"] = "eventosEditar";
  routeNamesCorp["eventosComponentes"] = "eventosComponentes";
  routeNamesCorp["eventosComponentesEditar"] = "eventosComponentesEditar";
  routeNamesCorp["eventosModeracao"] = "eventosModeracao";
  routeNamesCorp["eventosModeracaoEditar"] = "eventosModeracaoEditar";
  routeNamesCorp["unsubscribe"] = "unsubscribe";
  routeNamesCorp["researchGerenciar"] = "researchGerenciar";
  routeNamesCorp["researchCadastrar"] = "researchCadastrar";
  routeNamesCorp["researchEditar"] = "researchEditar";
  routeNamesCorp["researchRespostas"] = "researchRespostas";
  routeNamesCorp["researchRespostasUsuarios"] = "researchRespostasUsuarios";
  routeNamesCorp["researchListar"] = "researchListar";
  routeNamesCorp["researchResponder"] = "researchResponder";
})(routeNamesCorp = exports.routeNamesCorp || (exports.routeNamesCorp = {}));