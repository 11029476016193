"use strict";

var _createForOfIteratorHelper = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/createForOfIteratorHelper");

var _toConsumableArray = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
}); // faz parse e valida os valores de um array de colunas de uma planilha

function sheetParser() {
  var types = new Map();

  function createType(config) {
    var type = {
      key: config.key,
      label: config.label || config.key.toString(),
      enum: config.enum,
      required: config.required || false,
      toSheetValue: function toSheetValue(value) {
        return config.toSheetValue ? config.toSheetValue(value) : value;
      },
      fromSheetValue: function fromSheetValue(value, notParsedData) {
        if (config.fromSheetValue) {
          return config.fromSheetValue(value, notParsedData);
        } // precisamos converter para string ou strings
        // com numeros são convertidos para inteiros pelo xlsx por padrão


        return value ? value.toString() : value;
      },
      validate: function validate(obj) {
        return config.validate ? config.validate(obj) : '';
      },
      hidden: config.hidden
    };
    types.set(config.key, type);
    return type;
  }

  var parse = function parse(data) {
    var typesArray = _toConsumableArray(types.values());

    if (!Array.isArray(data) || !data.length) {
      return {
        errors: ['Dados inválidos.'],
        result: []
      };
    }

    var errors = [];
    var result = [];
    data.map(function (json, index) {
      var rowNum = index + 2;
      var xRow = {};
      typesArray.forEach(function (type) {
        var key = type.label;

        function pushError(msg) {
          errors.push("Linha ".concat(index + 1, ", coluna \"").concat(type.label, "\": ").concat(msg));
        }

        try {
          var rawValue = json[key];

          if (type.enum && !type.enum.includes(rawValue)) {
            return pushError("esperado \"".concat(type.enum.join(' ou '), "\". Recebido \"").concat(rawValue, "\""));
          }

          if ((!rawValue || rawValue === '-') && type.required) {
            return pushError('em branco');
          }

          var parsedValue = type.fromSheetValue(rawValue || undefined, json);
          var err = type.validate({
            type: type,
            raw: rawValue,
            parsed: parsedValue,
            json: json,
            row: rowNum
          });

          if (err && typeof err === 'string') {
            return errors.push(err);
          }

          xRow[type.key] = parsedValue;
        } catch (e) {
          errors.push("Linha ".concat(index + 1, ", ").concat(type.label, ": ").concat(e.message));
        }
      });
      result.push(xRow);
    });

    if (result.length < 1) {
      errors.push("Nenhum dado v\xE1lido encontrado.");
    }

    return {
      errors: errors.length ? errors : null,
      result: result
    };
  };

  var toSheetData = function toSheetData(parsed) {
    var typesArray = _toConsumableArray(types.values());

    var rows = [];
    var errors = [];
    parsed.forEach(function (parsedRow) {
      var row = {};
      typesArray.forEach(function (el) {
        if (el.hidden) return;

        try {
          row[el.label] = el.toSheetValue(parsedRow[el.key]);
        } catch (e) {
          console.error(e);
          errors.push(e.message);
        }
      });
      rows.push(row);
    });
    return {
      errors: errors,
      rows: rows
    };
  };

  function attachSchema(schema) {
    var keys = Object.keys(schema);
    keys.forEach(function (key) {
      createType(Object.assign(Object.assign({}, schema[key]), {
        key: key
      }));
    });
    return self;
  }

  var self = {
    createType: createType,
    types: types,
    attachSchema: attachSchema,
    parse: parse,
    toSheetData: toSheetData,
    example: function example(fill) {
      var ex = {};

      var _iterator = _createForOfIteratorHelper(types.values()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var v = _step.value;
          if (v.hidden) continue;
          ex[v.label] = fill || '';
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return [ex];
    }
  };
  return self;
}

exports.sheetParser = sheetParser;