enum EnumRegion {
  TSP = 'TSP',
  TSL = 'TSL',
  TSE = 'TSE',
  TNE = 'TNE',
  TCN = 'TCN'
}

/**
 * @created on Thu Nov 12 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * @param region: regional
 * @param ufs: ufs relacionadas a regional
 * Esse método serve para compilar o schema de uma regions com as respectivas UFs.
 */
const buildRegionsSchema = (region: string, ufs: Array<string>) => {
  const ufsSchema = `${ufs.map(
    (r) => `${r} { positivatorsEnabledTotal percentageSent }`
  )}`;
  return `${region} { percentageSent totalSent, totalEnabled ufs {${ufsSchema} }}`;
};

export const placePositivityResume = `
  ${buildRegionsSchema(EnumRegion.TSP, ['SP'])}
  ${buildRegionsSchema(EnumRegion.TSL, ['PR', 'SC', 'RS'])}
  ${buildRegionsSchema(EnumRegion.TSE, ['MG', 'ES', 'RJ'])}
  ${buildRegionsSchema(EnumRegion.TNE, [
    'PI',
    'CE',
    'RN',
    'PB',
    'PE',
    'AL',
    'SE',
    'BA'
  ])}
  ${buildRegionsSchema(EnumRegion.TCN, [
    'RO',
    'AM',
    'PA',
    'AP',
    'TO',
    'MA',
    'MS',
    'MT',
    'GO',
    'RR',
    'AC',
    'DF'
  ])}
`;

export const placeItemPositivityResume = `
  percentageSent
  totalSent
  totalEnabled
  ps {
    title
    category
    subCategory
  }
  items {
    _id
    createdAt
    imageContent {
      _id
      title
      signedUrl
      downloadUrl
    }
  }
`;

export const placeFragment = `
  setor_atividade
  organizacao_vendas
  canal_distribuicao
  type
  disabled
  CNPJ
  inscricao_estadual
  razao_social
  nome_fantasia
  custcode
  codigo_sap
  adminId
  adminInput {
    email
    name
    DDD
    phone
  }
  region
  DDDs
  modelo_de_loja
  tipo_de_loja
  tecnologia_de_rede
  produtos_comercializados
  foco_de_comunicacao
  cluster
  quantidade_de_funcionarios
  localizacao
  nome_da_galeria
  nome_do_shopping
  parcelamento
  inicio_da_operacao_mes
  inicio_da_operacao_ano
  ultima_modificacao
  data_de_modificacao_mes
  data_de_modificacao_ano
  tipo_de_fachada
  revestimento_externo
  posicoes_de_atendimento
  posicoes_de_pos_venda
  sala_de_gerente
  back_office
  wifi_clientes
  sinal_live
  display_de_senha
  sistema_de_som
  _id
  updatedAt
  createdAt
  metragem {
    terreo
    mezanino
    total
  }
  address {
    zipCode
    address
    complement
    neighborhood
    UF
    cityId
    cityName
    IBGE
    number
    capital
  }
  video_wall{
    kind
    label
    width
    height
    orientation
  }
  estrutura_de_loja {
    productId
    codeTM
    amount
    productName
  }
`;
