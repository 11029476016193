import React from 'react';
import { Button, Input, message } from 'antd';
import {
  formatCnpj,
  formatCPF,
  onlyNumbers,
  UserSession
} from '@digi-tim-19/utils';
import { LoadingIndicator } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useClient } from '../../../../autogenerated/client/client';
import { routes } from '../../../../config/routes';

export const FormLogin = () => {
  const [step, setStep] = React.useState<
    'loginUser' | 'loginPassword' | 'forgotPassword'
  >('loginUser');
  const [insideStep, setInsideStep] = React.useState<
    'CPFCNPJStep' | 'confirmEmailStep' | 'codeStep' | 'passwordStep'
  >('CPFCNPJStep');

  const [user, setUser] = React.useState('');
  const credentials = React.useMemo(() => {
    const data = onlyNumbers(user);
    if (data.length <= 11) {
      return { CPF: data };
    }
    return { CNPJ: data };
  }, [user]);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [maskEmail, setMaskEmail] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');

  const history = useHistory();
  const getUser = useClient('MerchanUserCheck');
  const loginClient = useClient('UserLoginWithPassword');
  const requestNewPassword = useClient('MerchanRequestTempPassword');
  const changePassword = useClient('MerchanChangePassword');
  const validateCode = useClient('MerchanVerifyTempCode');

  const handleLogin = async () => {
    await loginClient
      .fetch({
        variables: {
          user,
          password: password
        },
        fragment: `
                roles
                roleGroup { _id }
                token { token, iat }
                `
      })
      .then((ctx) => {
        if (ctx.errors) {
          const error = ctx.errors.includes('"401"')
            ? 'Usuário não encontrado!'
            : ctx.errors.join(' ');
          message.error(error);
          return;
        }
        const roleGroup = ctx.result?.roleGroup?._id;
        const token = ctx.result?.token?.token;
        // @ts-ignore
        const { pageBeforeLogin } = window;
        const isSupplier = roleGroup?.includes('fornecedor');

        const containsRouterBeforeLogin =
          pageBeforeLogin && pageBeforeLogin.length > 1;

        const initialRouter = containsRouterBeforeLogin
          ? pageBeforeLogin
          : isSupplier
          ? routes.structural.mount()
          : routes.home.mount();

        history.push(initialRouter, { userLogging: true });
        UserSession.setToken(`${token}`);
      });
  };

  const validSize = password.length >= 8;
  const validLetters = /[A-Z]/.test(password) && /[a-z]/.test(password);
  const validNumbers = /\d/.test(password);
  const validChars = /[^a-zA-Z0-9]/.test(password);
  const validPassword =
    password === confirmPassword &&
    validSize &&
    validLetters &&
    validNumbers &&
    validChars;

  return (
    <FormContainer>
      {loginClient.loading && <LoadingIndicator />}
      {step === 'loginUser' && (
        <FirstStep>
          <Input
            name="user"
            placeholder="CPF / CNPJ"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <ButtonForgotPassword>
            <Button
              onClick={() => setStep('forgotPassword')}
              className="forget-password"
            >
              Esqueci a senha
            </Button>
          </ButtonForgotPassword>
          <ButtonNext>
            <Button
              onClick={() => {
                getUser.fetch({ variables: { ...credentials } }).then((ctx) => {
                  setMaskEmail(ctx.result?.maskedEmail ?? '');

                  const canLog =
                    !ctx.result?.firstAccess &&
                    !ctx.result?.tempPassword &&
                    !ctx.result?.blockedByAtempts;

                  if (!canLog) {
                    setStep('forgotPassword');
                  }

                  if (ctx.result?.firstAccess) {
                    message.info(
                      'No seu primeiro acesso você deve solicitar uma nova senha',
                      10
                    );
                  }

                  if (!ctx.result?.firstAccess && ctx.result?.tempPassword) {
                    message.info('A senha temporária deve ser alterada', 10);
                  }

                  if (
                    !ctx.result?.firstAccess &&
                    !ctx.result?.tempPassword &&
                    ctx.result?.blockedByAtempts
                  ) {
                    message.warning(
                      'usuário bloqueado por mais de 10 tentativas incorratas',
                      10
                    );
                  }

                  if (canLog) {
                    setStep('loginPassword');
                  }
                });
              }}
            >
              Próxima
            </Button>
          </ButtonNext>
        </FirstStep>
      )}

      {step === 'loginPassword' && (
        <SecondStep className="second-step">
          <UserProfile>
            <h3>
              Bem vindo
              <Button
                className="btn-logoff"
                onClick={() => setStep('loginUser')}
                icon="close-circle"
              />
            </h3>
            {credentials.CNPJ && <span>{formatCnpj(credentials.CNPJ)}</span>}
            {credentials.CPF && <span>{formatCPF(credentials.CPF)}</span>}
          </UserProfile>
          <Input.Password
            placeholder="Senha"
            onChange={(e) => setPassword(e.target.value)}
          />
          <ButtonLogin>
            <Button onClick={handleLogin}>Acessar</Button>
          </ButtonLogin>
        </SecondStep>
      )}

      {step === 'forgotPassword' && (
        <SecondStep className="second-step">
          {insideStep === 'CPFCNPJStep' && (
            <>
              <Input
                placeholder="Digite CPF / CNPJ"
                defaultValue={user}
                required={true}
                onChange={(e) => setUser(e.target.value)}
              />

              <ButtonForgotPassword>
                <Button
                  onClick={() => setStep('loginUser')}
                  className="forget-password"
                >
                  Voltar
                </Button>
              </ButtonForgotPassword>

              <ButtonLogin>
                <Button
                  onClick={() => {
                    getUser
                      .fetch({ variables: { ...credentials } })
                      .then((ctx) => {
                        setMaskEmail(ctx.result?.maskedEmail ?? '');
                        setInsideStep('confirmEmailStep');
                      });
                  }}
                >
                  Próximo
                </Button>
              </ButtonLogin>
            </>
          )}

          {insideStep === 'confirmEmailStep' && (
            <>
              <UserProfile>
                <h3>Bem vindo!</h3>
                <span>{maskEmail}</span>
              </UserProfile>
              <Input
                placeholder="Confirme o seu e-mail"
                onChange={(e) => setEmail(e.target.value)}
              />

              <ButtonForgotPassword>
                <Button
                  onClick={() => setStep('loginUser')}
                  className="forget-password"
                >
                  Voltar
                </Button>
              </ButtonForgotPassword>

              <ButtonLogin>
                <Button
                  onClick={() => {
                    requestNewPassword
                      .fetch({
                        variables: { email: email, ...credentials }
                      })
                      .then((ctx) => {
                        if (ctx.result) {
                          message.success(
                            'Foi enviada uma senha temporária para o email informado'
                          );
                          setInsideStep('codeStep');
                        }
                      });
                  }}
                >
                  Confirmar
                </Button>
              </ButtonLogin>
            </>
          )}

          {insideStep === 'codeStep' && (
            <>
              <UserProfile>
                <h3>Digite o código que foi enviado para o seu e-mail</h3>
                <span>{maskEmail}</span>
              </UserProfile>
              <Input
                placeholder="Código"
                onChange={(e) => setCode(e.target.value)}
              />

              <ButtonForgotPassword>
                <Button
                  onClick={() => setStep('loginUser')}
                  className="forget-password"
                >
                  Voltar
                </Button>
              </ButtonForgotPassword>

              <ButtonsContainer>
                <ButtonLogin>
                  <Button onClick={() => {
                    validateCode
                    .fetch({
                      variables: { code: code, ...credentials }
                    })
                    .then((ctx) => {
                      if (ctx.result) {
                        message.success(
                          'Altere sua senha de acordo com os padrões indicados'
                        );
                        setInsideStep('passwordStep')
                      }
                    });
                  }}>
                    Confirmar
                  </Button>
                </ButtonLogin>
              </ButtonsContainer>
            </>
          )}

          {insideStep === 'passwordStep' && (
            <ChangePasswordContainer>
              <Input.Password
                placeholder="Nova Senha"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Input.Password
                placeholder="Confirme a nova senha"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div>
                {validSize && <Info>8 ou mais caracteres</Info>}
                {validLetters && (
                  <Info>Inclui letras maiusculas e minúsculas</Info>
                )}
                {validNumbers && <Info>Inclui números</Info>}
                {validChars && <Info>Inclui outros caracteres</Info>}
              </div>

              <ButtonForgotPassword>
                <Button
                  onClick={() => setStep('loginUser')}
                  className="forget-password"
                >
                  Voltar
                </Button>
              </ButtonForgotPassword>

              <ButtonLogin>
                <Button
                  disabled={!validPassword}
                  onClick={() => {
                    changePassword
                      .fetch({
                        variables: {
                          newPassword: password,
                          oldPassword: code,
                          ...credentials
                        }
                      })
                      .then((ctx) => {
                        if (ctx.result) {
                          handleLogin();
                        }
                      });
                  }}
                >
                  Alterar Senha
                </Button>
              </ButtonLogin>
            </ChangePasswordContainer>
          )}
        </SecondStep>
      )}
    </FormContainer>
  );
};

export default FormLogin;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;

  input {
    height: 45px;
  }
`;
const FirstStep = styled.div`
  width: 100%;

  .forget-password {
    border: 0;
    text-decoration: underline;
    color: #00508c;
  }
`;

const ButtonForgotPassword = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 5px;

  button {
    font-style: italic;
    box-shadow: none;
    padding: 0;
  }
`;

const ButtonNext = styled.div`
  display: flex;
  justify-content: right;

  button {
    background: #10508c;
    color: #fff;
  }
`;

const SecondStep = styled.div`
  width: 100%;

  .btn-logoff {
    border: 0;
  }
`;

const ButtonLogin = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;

  button {
    background: #10508c;
    color: #fff;
  }
`;

const UserProfile = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
    margin-bottom: 0;
    color: #10508c;
  }

  span {
    color: #ccc;
    text-align: center;
    font-size: 14px;
  }
`;

const ChangePasswordContainer = styled.div`
  input:first-child {
    margin-bottom: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > div:first-child {
    margin-right: 10px;
  }
`;

const Info = styled.p`
  color: #0d830d;
  font-size: 12;
  line-height: 0.8;
`;
