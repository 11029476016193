import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  DevicePermissionsEnum,
  PlacesPermissionsEnum,
  SupplierPermissionsEnum,
  LogisticsPermissionsEnum,
  DigitalBrochurePermissionsEnum,
  CampaignPermissionsEnum,
  LayoutPermissionsEnum,
  ProductionPermissionsEnum,
  BudgetPermissionsEnum,
  PositivatorPermissionsEnum,
  UserMerchanPermissionsEnum,
  StructuralPermissionsEnum
} from '../autogenerated/client/types';
import { useClient } from '../autogenerated/client/client';
import { verdade, useCurrentUser } from '@digi-tim-19/components';

export type Permissions = {
  devices: DevicePermissionsEnum[];
  places: PlacesPermissionsEnum[];
  suppliers: SupplierPermissionsEnum[];
  logistics: LogisticsPermissionsEnum[];
  digitalBrochure: DigitalBrochurePermissionsEnum[];
  campaign: CampaignPermissionsEnum[];
  layout: LayoutPermissionsEnum[];
  production: ProductionPermissionsEnum[];
  budget: BudgetPermissionsEnum[];
  positivator: PositivatorPermissionsEnum[];
  userMerchan: UserMerchanPermissionsEnum[];
  structural: StructuralPermissionsEnum[];
};

export type PermissionContextType = {
  isLoading?: boolean;
  error?: any;
} & Permissions;

export const PermissionsContext = createContext<PermissionContextType>({
  devices: [],
  places: [],
  logistics: [],
  suppliers: [],
  digitalBrochure: [],
  campaign: [],
  layout: [],
  production: [],
  budget: [],
  positivator: [],
  userMerchan: [],
  structural: [],
  error: null,
  isLoading: false
});

export const PermissionsProvider = (props: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const clientConfig = {
    cache: true
  };

  const [devicePermissions, setDevicePermissions] = useState<
    DevicePermissionsEnum[]
  >([]);

  const [placesPermissions, setPlacesPermissions] = useState<
    PlacesPermissionsEnum[]
  >([]);

  const [logisticsPermissions, setLogisticsPermissions] = useState<
    LogisticsPermissionsEnum[]
  >([]);

  const [suppliersPermissions, setSuppliersPermissions] = useState<
    SupplierPermissionsEnum[]
  >([]);

  const [digitalBrochurePermissions, setDigitalBrochurePermissions] = useState<
    DigitalBrochurePermissionsEnum[]
  >([]);

  const [campaignPermissions, setCampaignPermissions] = useState<
    CampaignPermissionsEnum[]
  >([]);

  const [layoutPermissions, setLayoutPermissions] = useState<
    LayoutPermissionsEnum[]
  >([]);

  const [productionPermissions, setProductionPermissions] = useState<
    ProductionPermissionsEnum[]
  >([]);

  const [budgetPermissions, setBudgetPermissions] = useState<
    BudgetPermissionsEnum[]
  >([]);

  const [positivatorPermissions, setPositivatorPermissions] = useState<
    PositivatorPermissionsEnum[]
  >([]);

  const [userMerchanPermissions, setUserMerchanPermissions] = useState<
    UserMerchanPermissionsEnum[]
  >([]);

  const [structuralPermissions, setStructuralPermissions] = useState<
    StructuralPermissionsEnum[]
  >([]);

  const DevicePermissions = useClient('DevicePermissions', clientConfig);

  const UserSupplierPermissions = useClient(
    'SupplierPermissions',
    clientConfig
  );

  const UserPlacesPermissions = useClient('PlacesPermissions', clientConfig);

  const UserLogisticsPermissions = useClient(
    'UserLogisticsPermissions',
    clientConfig
  );

  const DigitalBrochurePermissions = useClient(
    'DigitalBrochurePermissions',
    clientConfig
  );

  const CampaignPermissions = useClient('CampaignPermissions', clientConfig);

  const LayoutPermissions = useClient('LayoutPermissions', clientConfig);

  const ProductionPermissions = useClient(
    'ProductionPermissions',
    clientConfig
  );

  const BudgetPermissions = useClient('BudgetPermissions', clientConfig);

  const PositivatorPermissions = useClient(
    'PositivatorPermissions',
    clientConfig
  );

  const UserMerchanPermissions = useClient(
    'UserMerchanPermissions',
    clientConfig
  );

  const StructuralPermissions = useClient(
    'StructuralPermissions',
    clientConfig
  );

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (
      DevicePermissions.loading ||
      UserSupplierPermissions.loading ||
      UserPlacesPermissions.loading ||
      DigitalBrochurePermissions.loading ||
      CampaignPermissions.loading ||
      LayoutPermissions.loading ||
      BudgetPermissions.loading ||
      PositivatorPermissions.loading ||
      UserMerchanPermissions.loading ||
      StructuralPermissions.loading
    ) {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [
    DevicePermissions.loading,
    UserSupplierPermissions.loading,
    UserPlacesPermissions.loading,
    DigitalBrochurePermissions.loading,
    CampaignPermissions.loading,
    LayoutPermissions.loading,
    BudgetPermissions.loading,
    PositivatorPermissions.loading,
    UserMerchanPermissions.loading,
    StructuralPermissions.loading
  ]);

  const permissionError =
    DevicePermissions.error ||
    UserSupplierPermissions.error ||
    UserPlacesPermissions.error ||
    DigitalBrochurePermissions.error ||
    CampaignPermissions.error ||
    LayoutPermissions.error ||
    ProductionPermissions.error ||
    BudgetPermissions.error ||
    PositivatorPermissions.error ||
    UserMerchanPermissions.error ||
    StructuralPermissions.error;

  if (permissionError) {
    setError(permissionError);
  }

  useEffect(() => {
    if (currentUser.resolved && currentUser.result !== null) {
      DevicePermissions.fetch();
      UserSupplierPermissions.fetch();
      UserPlacesPermissions.fetch();
      UserLogisticsPermissions.fetch();
      DigitalBrochurePermissions.fetch();
      CampaignPermissions.fetch();
      LayoutPermissions.fetch();
      ProductionPermissions.fetch();
      BudgetPermissions.fetch();
      PositivatorPermissions.fetch();
      UserMerchanPermissions.fetch();
      StructuralPermissions.fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.result, currentUser.resolved]);

  useEffect(() => {
    if (DevicePermissions.data) {
      setDevicePermissions(verdade(DevicePermissions.data));
    }
  }, [DevicePermissions.data]);

  useEffect(() => {
    if (UserSupplierPermissions.data) {
      setSuppliersPermissions(verdade(UserSupplierPermissions.data));
    }
  }, [UserSupplierPermissions.data]);

  useEffect(() => {
    if (UserPlacesPermissions.data) {
      setPlacesPermissions(verdade(UserPlacesPermissions.data));
    }
  }, [UserPlacesPermissions.data]);

  useEffect(() => {
    if (UserLogisticsPermissions.data) {
      setLogisticsPermissions(verdade(UserLogisticsPermissions.data));
    }
  }, [UserLogisticsPermissions.data]);

  useEffect(() => {
    if (DigitalBrochurePermissions.data) {
      setDigitalBrochurePermissions(verdade(DigitalBrochurePermissions.data));
    }
  }, [DigitalBrochurePermissions.data]);

  useEffect(() => {
    if (CampaignPermissions.data) {
      setCampaignPermissions(verdade(CampaignPermissions.data));
    }
  }, [CampaignPermissions.data]);

  useEffect(() => {
    if (LayoutPermissions.data) {
      setLayoutPermissions(verdade(LayoutPermissions.data));
    }
  }, [LayoutPermissions.data]);

  useEffect(() => {
    if (ProductionPermissions.data) {
      setProductionPermissions(verdade(ProductionPermissions.data));
    }
  }, [ProductionPermissions.data]);

  useEffect(() => {
    if (BudgetPermissions.data) {
      setBudgetPermissions(verdade(BudgetPermissions.data));
    }
  }, [BudgetPermissions.data]);

  useEffect(() => {
    if (PositivatorPermissions.data) {
      setPositivatorPermissions(verdade(PositivatorPermissions.data));
    }
  }, [PositivatorPermissions.data]);

  useEffect(() => {
    if (UserMerchanPermissions.data) {
      setUserMerchanPermissions(verdade(UserMerchanPermissions.data));
    }
  }, [UserMerchanPermissions.data]);

  useEffect(() => {
    if (StructuralPermissions.data) {
      setStructuralPermissions(verdade(StructuralPermissions.data));
    }
  }, [StructuralPermissions.data]);

  return (
    <PermissionsContext.Provider
      value={{
        isLoading,
        error,
        devices: devicePermissions,
        places: placesPermissions,
        logistics: logisticsPermissions,
        suppliers: suppliersPermissions,
        digitalBrochure: digitalBrochurePermissions,
        campaign: campaignPermissions,
        layout: layoutPermissions,
        production: productionPermissions,
        budget: budgetPermissions,
        positivator: positivatorPermissions,
        userMerchan: userMerchanPermissions,
        structural: structuralPermissions
      }}
    >
      {props.children}
    </PermissionsContext.Provider>
  );
};

export const useUserPermissions = () => useContext(PermissionsContext);
