"use strict";

var _taggedTemplateLiteral = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  height: 1200px;\n  width: 100%;\n\n  > div {\n    height: auto;\n  }\n\n  div {\n    position: unset;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n\n  > div {\n    height: 100%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  .card-content-wrapper {\n    height: 150px;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    background-color: ", ";\n\n    & h3 {\n      -webkit-line-clamp: 2;\n      color: ", ";\n      font-size: 18px;\n      padding: 10px 20px 0 20px;\n\n      @media screen and (min-width: 768px) {\n        font-size: 16px;\n      }\n    }\n  }\n\n  img {\n    border-bottom-left-radius: 20px;\n    border-bottom-right-radius: 20px;\n    height: 150px;\n    width: 100%;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      &:hover {\n        filter: saturate(0);\n      }\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 40px;\n  width: 195px;\n\n  .card-header {\n    display: flex;\n    flex-direction: column;\n    height: 85px;\n\n    h2 {\n      color: ", ";\n      font-size: 16px;\n      font-weight: 400;\n      margin-top: 0;\n      margin-bottom: 0 !important;\n      max-height: 48px;\n      overflow: hidden;\n      text-transform: uppercase;\n    }\n\n    .card-status,\n    .card-validity {\n      font-size: 12px;\n    }\n\n    .card-validity {\n      /* margin-top: auto; */\n    }\n  }\n\n  .Card__content {\n    border-radius: 10px;\n    margin-bottom: 10px;\n    background-color: #95b0ce;\n    width: inherit;\n\n    &_titulo {\n      color: ", ";\n      font-size: 18px;\n      margin-left: 20px;\n      padding-top: 10px;\n      word-break: break-all;\n\n      @media screen and (min-width: 768px) {\n        font-size: 16px;\n      }\n    }\n  }\n\n  button {\n    font-size: 14px;\n    font-weight: bold;\n    padding: 0;\n    width: 110px;\n  }\n\n  @media screen and (min-width: 465px) {\n    margin-right: 20px;\n  }\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 147px;\n\n  img {\n    max-height: 100%;\n    height: 100%;\n    width: 100%;\n    border-radius: 10px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 150px;\n  border-top-left-radius: 10px;\n  border-top-right-radius: 10px;\n  background-color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n\n  @media screen and (max-width: 480px) {\n    justify-content: center;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styled_components_1 = __importStar(require("styled-components"));

exports.Wrapper = styled_components_1.default.div(_templateObject());
exports.WrapperHeaderCard = styled_components_1.default.div(_templateObject2(), function (_ref) {
  var theme = _ref.theme;
  return theme.grayLight2;
});
exports.WrapperImageCard = styled_components_1.default.div(_templateObject3());
exports.CardItem = styled_components_1.default.article(_templateObject4(), function (_ref2) {
  var theme = _ref2.theme;
  return theme.red;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.blue;
}, function (_ref4) {
  var withResults = _ref4.withResults;
  return withResults && styled_components_1.css(_templateObject5());
});
exports.CardItemContent = styled_components_1.default.div(_templateObject6(), function (_ref5) {
  var theme = _ref5.theme;
  return theme.grayLight2;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.blue;
});
exports.WrapperCard = styled_components_1.default.div(_templateObject7());
exports.CardScrollWrapper = styled_components_1.default.div(_templateObject8());