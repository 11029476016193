import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext
} from 'react';
import { FilterKey } from '../TypeDefs';
import { Place } from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { verdade } from '@digi-tim-19/utils';
import { chunk, intersection } from 'lodash';
import { useHistory } from 'react-router';

type SearchPool = {
  place: Place;
  args: Record<FilterKey, string[]>;
}[];

interface IPlaceGroupData {
  children: React.ReactNode;
}

const appendToFragment = `
  address {
    UF
    cityId
    cityName
  }
  estrutura_de_loja {
    codeTM
    productName
  }
`;

export const PlaceGroupDataProvider = (props: IPlaceGroupData) => {
  const { children } = props;
  const { listen } = useHistory();

  const { result, loading, refetch } = useClient('PlaceFindMany', {
    appendToFragment,
    cache: true,
    variables: {
      limit: 5000,
      filter: { disabled: false }
    }
  });

  const [placesCache, setPlacesCache] = useState<Place[]>([]);
  const [searchPool, setSearchPool] = useState<SearchPool>([]);

  useEffect(() => {
    listen((location) => {
      if (location.pathname !== '/login' && !placesCache.length) {
        refetch();
      }
    });
  }, []);

  useEffect(() => {
    if (result) {
      setPlacesCache(verdade(result));
      mountSearchPool(verdade(result)).then(setSearchPool);
    }
  }, [result]);

  const applyFilters = useCallback(createSearch(searchPool), [searchPool]);

  return (
    <PlaceGroupDataContext.Provider
      value={{
        loading,
        placesCache,
        applyFilters,
        refetch: async () => {
          refetch();
        }
      }}
    >
      {children}
    </PlaceGroupDataContext.Provider>
  );
};

type PlaceGroupDataCtx = {
  loading: boolean;
  placesCache: Place[];
  applyFilters: (checked: Record<FilterKey, string[]>) => Promise<Place[]>;
  refetch: () => Promise<void>;
};

const PlaceGroupDataContext = createContext<PlaceGroupDataCtx>({
  loading: true,
  placesCache: [],
  applyFilters: async () => [],
  refetch: async () => {}
});

export const usePlaceGroupData = () => useContext(PlaceGroupDataContext);

const mountSearchPool = async (places: Place[]): Promise<SearchPool> => {
  const chuncks = chunk(places, places.length / 4);
  const promises = chuncks.map((value) => createSearchPool(value));
  return await Promise.all(promises).then((data) => data.flat());
};

const createSearchPool = async (places: Place[]): Promise<SearchPool> => {
  return places.map((place) => ({
    place,
    args: {
      type: [`${place.type}`],
      region: [`${place.region}`],
      UF: [`${place.address?.UF}`],
      DDD: verdade(place.DDDs),
      cityId: [`${place.address?.cityId}`],
      storeModel: [`${place.modelo_de_loja}`],
      communicationFocus: [`${place.foco_de_comunicacao}`],
      marketedProduct: verdade(place.produtos_comercializados) as string[],
      networkTechnology: [`${place.tecnologia_de_rede}`],
      estrutura_de_loja: verdade(place.estrutura_de_loja)?.map(
        (el) => `${el?.codeTM} - ${el?.productName}`
      ),
      CNPJ: [`${place.CNPJ}`.replace(/\D/g, '')]
    }
  })) as SearchPool;
};

const createSearch = (pool: SearchPool) => {
  return async (checked: Record<FilterKey, string[]>): Promise<Place[]> => {
    const payloadKeys = Object.keys(checked) as FilterKey[];

    const allUnmarked = payloadKeys
      .map((key) => !checked[key].length)
      .every((el) => el);

    if (allUnmarked) return [];
    const places = pool
      .filter(({ args }) =>
        payloadKeys
          // OR comparsion inside filter group
          .map((key) => {
            const poolArgs = args[key] || [];
            const sentArgs = checked[key] || [];
            return sentArgs.length > 0 ? compareOR(poolArgs, sentArgs) : true;
          })
          // AND commparsion among filter groups
          .every((result) => result)
      )
      .map(({ place }) => place);

    return places;
  };
};

const compareOR = (poolArgs: string[], payloadArgs: string[]) =>
  intersection(poolArgs, payloadArgs).length > 0;
