/**
 * ============================ (c) 2020 Liz Informática ============================
 * @created on Mon Aug 24 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * What is this file?
 * Esse hook é responsável por compilar as permissões de um módulo para o  usuário logado.
 * Recebe o nome do módulo que será utilizado. Os nomes dos módulos estão definidos no
 * arquivo src/config/modules. Cada arquivo relacionado a uma página possui o nome do
 * modulo definido no final do arquivo. Exemplo: GestaoLayout ao final do arquivo possui
 * GestaoLayout.moduleName = Module.LAYOUT.
 * ==================================================================================
 */

import * as React from 'react';
import { Module } from '../../config/modules';
import { Permission } from '../../config/permissions';
import { useCurrentUser } from '@digi-tim-19/components';
import { verdade } from '@digi-tim-19/utils';

export function usePermission(module: Module | Array<Module>) {
  const [initialized, setInitialized] = React.useState<boolean>();
  const [permissions, setPermissions] = React.useState<any>();
  const state = useCurrentUser();
  const user = state.result;

  React.useEffect(() => {
    if (!user || !user?._id) {
      return;
    }
    const p = buildPermissions();
    setPermissions(p);
    setInitialized(true);
  }, [user?._id, JSON.stringify(module)]);

  /**
   * @created on Tue Aug 25 2020
   * @author Emir Marques - emirdeliz@gmail.com
   * Esse método serve para compilar as permissões de um usuário para o modulo(s) em questão
   */
  const buildPermissions = () => {
    const isArrayModule = Array.isArray(module);
    let result = {} as any;

    if (isArrayModule) {
      ((module || []) as Array<Module>).forEach((m: Module) => {
        result[m] = getPermissions(verdade(user?.roles), m);
      });
      return result;
    } else {
      result = getPermissions(verdade(user?.roles), module as Module);
    }
    return result;
  };

  return {
    loading: state.loading,
    initialized,
    ...permissions
  };
}

/**
 * @created on Tue Aug 25 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * Esse método serve para obter as permissões dos papéis para um modulo(s)
 * @param roles: lista com papéis do usuário
 * @param module: modulo a ser verificado
 */
const getPermissions = (roles: Array<string>, module: Module) => {
  /**
   * @created on Mon Aug 24 2020
   * @author Emir Marques - emirdeliz@gmail.com
   * As permissões para o usuário estão em um array dispostas no formato
   * <tipo da permissão>_<modulo>, por exemplo, "read_device" para permissão
   * de leitura de de devices
   */
  const create = !!roles?.find((r: any) => {
    return r === `${Permission.CREATE}_${module}`;
  });

  const del = !!roles?.find((r: any) => {
    return r === `${Permission.DELETE}_${module}`;
  });

  const read = !!roles?.find((r: any) => {
    return r === `${Permission.READ}_${module}`;
  });

  const update = !!roles?.find((r: any) => {
    return r === `${Permission.UPDATE}_${module}`;
  });

  return {
    create,
    delete: del,
    read,
    update
  };
};
