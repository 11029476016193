import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { useRouteMatch } from 'react-router';
import { LoadingIndicator } from '@digi-tim-19/components';

import { useClient } from '../../autogenerated/client/client';

const ConfirmEmailReadWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  justify-content: center;
  align-items: flex-start;
`;

export const ConfirmEmailRead = (props: TConfirmEmailReadProps) => {
  const { className } = props;
  const { params } = useRouteMatch();
  const token = params?.token;

  const client = useClient('EmailMarkAsRead');

  React.useEffect(() => {
    if (!token) return;
    client.fetch({ variables: { token } });
  }, [token]);

  return (
    <ConfirmEmailReadWrapper
      className={cx(
        className,
        'ui-flex ConfirmEmailRead ConfirmEmailRead_Wrapper'
      )}
    >
      <h1>Obrigado por confirmar a leitura!</h1>

      {client.loading && <LoadingIndicator />}
    </ConfirmEmailReadWrapper>
  );
};

export default ConfirmEmailRead;

export type TConfirmEmailReadProps = {
  className?: string;
  children?: React.ReactNode;
};
