"use strict";

var _slicedToArray = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var build_1 = require("@digi-tim-19/utils/build");

var types_1 = require("../types");

var LikeVideo_1 = require("../CardPlaylistVideo/LikeVideo");

var Comments_1 = require("../Comments/Comments");

var ActionButton_1 = require("../ActionButton/ActionButton");

var MaterialActionButton_1 = require("../ActionButton/MaterialActionButton");

var __1 = require("..");

var S = __importStar(require("./styles"));

exports.VideoPlayer = function (_ref) {
  var material = _ref.material,
      videosPlaylist = _ref.videosPlaylist,
      videoRef = _ref.videoRef,
      onClickVideo = _ref.onClickVideo;

  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;

  var _react_1$useState = react_1.useState(true),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 1),
      repAuto = _react_1$useState2[0];

  var _react_1$useState3 = react_1.useState(true),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      muted = _react_1$useState4[0],
      setMuted = _react_1$useState4[1];

  var _react_1$useState5 = react_1.useState(false),
      _react_1$useState6 = _slicedToArray(_react_1$useState5, 2),
      showComments = _react_1$useState6[0],
      setShowComments = _react_1$useState6[1];

  var getCategory = (_b = (_a = material) === null || _a === void 0 ? void 0 : _a.categories) === null || _b === void 0 ? void 0 : _b.find(function (category) {
    var _a;

    return (_a = category) === null || _a === void 0 ? void 0 : _a.name;
  });
  var src = (_e = (_d = (_c = material) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d.find(function (f) {
    var _a;

    return ((_a = f) === null || _a === void 0 ? void 0 : _a.kind) === types_1.EnumFileKind.VideoMp4;
  })) === null || _e === void 0 ? void 0 : _e.signedUrl;
  var nextVideoId = videosPlaylist && videosPlaylist[0] && videosPlaylist[0]._id;
  return react_1.default.createElement(__1.MaterialProvider, {
    material: material
  }, react_1.default.createElement(S.PlayerWrapper, null, react_1.default.createElement(S.Player, {
    autoPlay: true,
    controlsList: "nodownload",
    controls: true,
    muted: muted,
    src: src || '',
    height: '500px',
    disablePictureInPicture: true,
    width: "100%",
    onCanPlay: function onCanPlay() {
      return setMuted(false);
    },
    onEnded: function onEnded() {
      return repAuto && nextVideoId && onClickVideo(nextVideoId);
    },
    ref: function ref(v) {
      if (v && videoRef) {
        videoRef(v);
      }
    }
  }, "Your browser does not support the video tag."), react_1.default.createElement("div", null, react_1.default.createElement(S.Category, null, ((_f = getCategory) === null || _f === void 0 ? void 0 : _f.name) || ''), react_1.default.createElement(S.Date, null, ((_g = material) === null || _g === void 0 ? void 0 : _g.createdAt) ? build_1.formatDate((_h = material) === null || _h === void 0 ? void 0 : _h.createdAt, 'DD/MM/YYYY') : ''), react_1.default.createElement(S.Title, null, (_j = material) === null || _j === void 0 ? void 0 : _j.title), react_1.default.createElement(S.Description, null, (_k = material) === null || _k === void 0 ? void 0 : _k.description), react_1.default.createElement(ActionButton_1.ActionContainer, null, react_1.default.createElement(ActionButton_1.Icon, {
    amount: ((_m = (_l = material) === null || _l === void 0 ? void 0 : _l.views) === null || _m === void 0 ? void 0 : _m.count) || 0,
    name: "eye"
  }), react_1.default.createElement(LikeVideo_1.LikeVideo, {
    videoId: (_o = material) === null || _o === void 0 ? void 0 : _o._id,
    liked: ((_q = (_p = material) === null || _p === void 0 ? void 0 : _p.likes) === null || _q === void 0 ? void 0 : _q.liked) || false,
    count: ((_s = (_r = material) === null || _r === void 0 ? void 0 : _r.likes) === null || _s === void 0 ? void 0 : _s.count) || 0
  }), react_1.default.createElement(MaterialActionButton_1.ActionButtonsS2, {
    share: true,
    pin: true
  }), react_1.default.createElement(ActionButton_1.Icon, {
    name: "message",
    onClick: function onClick() {
      return setShowComments(!showComments);
    }
  }))), !!showComments && react_1.default.createElement(Comments_1.Comments, {
    parentId: (_t = material) === null || _t === void 0 ? void 0 : _t._id,
    isSubComment: false
  })));
};