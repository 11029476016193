import * as React from 'react';
import { Provider } from 'react-redux';
import { reduxStore } from './store';

export const ReduxProvider = (props: TReduxProducerProps) => {
  return <Provider store={reduxStore}>{props.children}</Provider>;
};

export type TReduxProducerProps = {
  children?: React.ReactNode;
};
