import React from 'react';
import { Menu, useCurrentUser } from '@digi-tim-19/components';
import { UserSession } from '@digi-tim-19/utils';
import { EditAvatar } from '../../pages/User/EditAvatar';
import { useHistory } from 'react-router';
import { routes } from '../../config/routes';
import { Module, ModuleArray } from '../../config/modules';
import { Permission } from '../../config/permissions';
import { usePermission } from '../../hooks/user/usePermission';
import { ItemMenuProp } from '@digi-tim-19/components/build/main/Menu/ItemMenu';
import {
  DevicePermissionsEnum,
  PlacesPermissionsEnum,
  PositivatorPermissionsEnum,
  LogisticsPermissionsEnum,
  SupplierPermissionsEnum,
  StructuralPermissionsEnum,
  UserMerchanPermissionsEnum
} from '../../autogenerated/client/types';
import {
  PermissionContextType,
  useUserPermissions
} from '../../hooks/useUserPermissions';
import { useClient } from '../../autogenerated/client/client';
import { placeFragment } from '../../hooks/place/placeFragment';

export type MainMenuConfig = {
  menuIsOpen: boolean;
  toggleMenu: Function;
  hasMenuWord?: boolean;
};

const checkReadPermission = (permissions: any, module: Module) => {
  return permissions[module] && permissions[module][Permission.READ];
};

const buildLogisticaMenu = (
  userPermissions: PermissionContextType,
  history: any
) => {
  const canListDelivery = userPermissions.logistics.includes(
    LogisticsPermissionsEnum.LogisticsViewDelivery
  );

  const canListInventory = userPermissions.logistics.includes(
    LogisticsPermissionsEnum.LogisticsUpdateInventory
  );

  const canListGrades = userPermissions.logistics.includes(
    LogisticsPermissionsEnum.LogisticsCreateGrade
  );

  const items: ItemMenuProp[] = [];

  if (canListDelivery) {
    items.push({
      text: 'Entregas',
      link: () => history.push(routes.logisticaEntrega.mount())
    });
  }

  if (canListInventory) {
    items.push({
      text: 'Estoque',
      link: () => history.push(routes.logisticaEstoque.mount())
    });
  }

  if (canListGrades) {
    items.push({
      text: 'Grades',
      link: () => history.push(routes.logisticaGrades.mount())
    });
  }

  return items;
};

const buildProductionMenu = (permissions: any, history: any) => {
  const productionMenu = [];

  if (checkReadPermission(permissions, Module.CAMPAIGN)) {
    productionMenu.push({
      text: 'Campanha',
      link: () => history.push(routes.campaigns.mount())
    });
  }

  if (checkReadPermission(permissions, Module.LAYOUT)) {
    productionMenu.push({
      text: 'Layout',
      link: () => history.push(routes.layouts.mount())
    });
  }

  if (
    checkReadPermission(permissions, Module.PRODUCTION) &&
    // checamos se o user pode ver budget, se nao puder provavel que seja um fornecedor 🤢
    checkReadPermission(permissions, Module.BUDGET)
  ) {
    productionMenu.push({
      text: 'Produzir',
      link: () => history.push(routes.productions.mount())
    });
  }

  if (checkReadPermission(permissions, Module.BUDGET)) {
    productionMenu.push({
      text: 'Verbas',
      link: () => history.push(routes.budgets.mount())
    });
  }

  if (checkReadPermission(permissions, Module.REGIONAL_BUDGET)) {
    productionMenu.push({
      text: 'Verbas Regionais',
      link: () => history.push(routes.regionalBudgets.mount())
    });
  }

  return productionMenu;
};

const buildSuppliersMenu = (
  permissions: SupplierPermissionsEnum[],
  history: any
) => {
  const suppliersMenu = [];

  const canViewodule = permissions.includes(
    SupplierPermissionsEnum.SupplierViewModule
  );

  if (canViewodule) {
    suppliersMenu.push({
      text: 'Fornecedores',
      link: () => history.push(routes.suppliers.mount())
    });

    suppliersMenu.push({
      text: 'Ativos TIM',
      link: () => history.push(routes.ativos.mount())
    });
  }

  return suppliersMenu;
};

const buildTablePriceMenu = (
  permissions: DevicePermissionsEnum[],
  history: any
) => {
  const tablePriceMenu = [];

  const canViewodule = permissions.includes(
    DevicePermissionsEnum.DevicesViewModule
  );

  if (canViewodule) {
    tablePriceMenu.push({
      text: 'Aparelhos',
      link: () => history.push(routes.devices.mount())
    });

    tablePriceMenu.push({
      text: 'Tabela de Preços',
      link: () => history.push(routes.tablePrice.mount())
    });

    tablePriceMenu.push({
      text: 'Alertas',
      link: () => history.push(routes.devicesNotFoundAlerts.mount())
    });

    tablePriceMenu.push({
      text: 'Textos Legais',
      link: () => history.push(routes.tablepriceLegalText.mount())
    });
  }

  return tablePriceMenu;
};

const buildPositivatorMenu = (
  permissions: PositivatorPermissionsEnum[],
  history: any
) => {
  const positivatorMenu = [];

  const canViewModule = permissions.includes(
    PositivatorPermissionsEnum.PositivatorViewModule
  );

  if (canViewModule) {
    positivatorMenu.push({
      text: 'Resumo',
      link: () => history.push(routes.positivatorResume.mount())
    });
  }

  const canCreatePlace = permissions.includes(
    PositivatorPermissionsEnum.CreatePositivatorplace
  );

  if (canCreatePlace) {
    positivatorMenu.push({
      text: 'Cadastrar',
      link: () => history.push(routes.positivatorEdit.mount())
    });
  }

  const canNotify = permissions.includes(
    PositivatorPermissionsEnum.CreateNotification
  );

  if (canNotify) {
    positivatorMenu.push({
      text: 'Notificações',
      link: () => history.push(routes.positivatorNotification.mount())
    });
  }

  return positivatorMenu;
};

const buildPositivatorMenuBeta = (
  permissions: PositivatorPermissionsEnum[],
  history: any
) => {
  const positivatorMenu = [];

  const canCreatePlace = permissions.includes(
    PositivatorPermissionsEnum.CreatePositivatorplace
  );

  const canNotify = permissions.includes(
    PositivatorPermissionsEnum.CreateNotification
  );

  const canViewModule = permissions.includes(
    PositivatorPermissionsEnum.PositivatorViewModule
  );

  if (canViewModule) {
    positivatorMenu.push({
      typeIcon: 'false',
      text: 'Mapa de PDV',
      children: [
        {
          text: 'Area da Loja',
          link: () => history.push(routes.mapaAreaLoja.mount())
        },
        {
          text: 'Conheça os Formatos',
          link: () => history.push(routes.mapaFormatos.mount())
        },
        {
          text: 'Regramento de Comunicação',
          link: () => history.push(routes.mapaRegramentoComunicacao.mount())
        },
        {
          text: 'Regramento de Aplicação de Cluster',
          link: () => history.push(routes.mapaRegramentoCluster.mount())
        },
        {
          text: 'Guia Para Mapa de PDV',
          link: () => history.push(routes.mapaGuia.mount())
        },
        {
          text: 'Mapa de PDV',
          link: () => history.push(routes.mapaPDV.mount())
        }
      ]
    });
  }

  if (canViewModule) {
    positivatorMenu.push({
      typeIcon: 'false',
      text: 'Positivador',
      children: [
        {
          text: 'Cadastrar',
          link: () => history.push(routes.positivatorEdit.mount())
        },
        {
          text: 'Notificações',
          link: () => history.push(routes.positivatorNotification.mount())
        }
      ]
    });
  }

  if (canViewModule) {
    positivatorMenu.push({
      text: 'Relatórios',
      link: () => history.push(routes.positivatorResume.mount())
    });
  }

  return positivatorMenu;
};

const buildUserMenuWithPermissions = (
  permissions: any,
  userPermissions: PermissionContextType,
  history: any,
  user?: any
) => {
  const menu = [];

  const canViewHome = userPermissions.userMerchan.includes(
    UserMerchanPermissionsEnum.UserMerchanViewHome
  );

  const canViewDash = userPermissions.userMerchan.includes(
    UserMerchanPermissionsEnum.UserMerchanViewDash
  );

  const canViewUsers = userPermissions.userMerchan.includes(
    UserMerchanPermissionsEnum.UserMerchanViewModule
  );

  const canViewPlaces = userPermissions.places.includes(
    PlacesPermissionsEnum.PlacesViewModule
  );

  const canViewSuppliers = userPermissions.suppliers.includes(
    SupplierPermissionsEnum.SupplierViewModule
  );

  if (canViewHome) {
    menu.push({
      text: 'Home',
      typeIcon: 'home',
      children: [
        {
          text: 'Home',
          link: () => history.push(routes.home.mount())
        },
        {
          text: 'Focal Regional',
          link: () => history.push(routes.team.mount())
        }
      ]
    });
  }

  if (canViewUsers) {
    menu.push({
      text: 'Usuários',
      link: () => history.push(routes.users.mount()),
      typeIcon: 'user'
    });
  }

  if (canViewPlaces) {
    menu.push({
      text: 'Locais',
      link: () => history.push(routes.places.mount()),
      typeIcon: 'shop'
    });
  }

  const suppliersMenu = buildSuppliersMenu(userPermissions.suppliers, history);
  if (canViewSuppliers) {
    if (suppliersMenu.length) {
      menu.push({
        typeIcon: 'idcard',
        text: 'Fornecedores',
        // children: suppliersMenu
        link: () => history.push(routes.suppliers.mount())
      });
    }
  }

  const tablePriceMenu = buildTablePriceMenu(userPermissions.devices, history);
  if (tablePriceMenu.length) {
    menu.push({
      typeIcon: 'mobile',
      text: 'Devices',
      children: tablePriceMenu
    });
  }

  const productionMenu = buildProductionMenu(permissions, history);
  if (productionMenu.length) {
    menu.push({
      typeIcon: 'build',
      text: 'Produção',
      children: productionMenu
    });
  }

  const logisticaMenu = buildLogisticaMenu(userPermissions, history);
  if (logisticaMenu.length) {
    menu.push({
      typeIcon: 'shopping-cart',
      text: 'Logística',
      link: () => history.push(routes.logisticaEntrega.mount()),
      children: logisticaMenu
    });
  }

  if (checkReadPermission(permissions, Module.DEVICE)) {
    menu.push({
      typeIcon: 'bar-chart',
      text: 'Gerador de lâminas',
      link: () => history.push(routes.geradorDeLaminas.mount())
    });
  }

  if (checkReadPermission(permissions, Module.DIGITAL_BROCHURE)) {
    menu.push({
      typeIcon: 'file-sync',
      text: 'Folheto digital',
      link: () => history.push(routes.digitalBrochures.mount())
    });
  }

  /**
   * Não implementado ainda
   */
  const positivatorMenu = buildPositivatorMenu(
    userPermissions.positivator,
    history
  );
  const positivatorMenuBeta = buildPositivatorMenuBeta(
    userPermissions.positivator,
    history
  );

  if (positivatorMenu.length) {
    if (user?.isBeta) {
      menu.push({
        // imageIcon: <div>TESTE</div>,
        typeIcon: 'stock',
        text: 'Mapa de PDV',
        link: () => history.push('/menu/MapaPDV')
        // children: positivatorMenuBeta
      });
    } else {
      menu.push({
        typeIcon: 'stock',
        text: 'Positivador',
        children: positivatorMenu
      });
    }
  }

  const canViewStructural = userPermissions.structural.includes(
    StructuralPermissionsEnum.ReadStructural
  );
  if (canViewStructural) {
    menu.push({
      typeIcon: 'block',
      text: 'Estrutural',
      link: () => history.push(routes.structural.mount())
    });
  }

  if (canViewDash) {
    menu.push({
      text: 'DashBoard',
      link: () => history.push(routes.dashBoard.mount()),
      typeIcon: 'bar-chart'
    });
  }

  return menu;
};

export const MainMenu = ({ menuIsOpen, toggleMenu }: MainMenuConfig) => {
  const history = useHistory();
  const permissions = usePermission(ModuleArray);
  const userPermissions = useUserPermissions();

  const user = useCurrentUser().result;

  const { fetch: fetchPlaceData } = useClient('PlaceFindOne', {
    variables: { filter: { CNPJ: user?.CNPJ as string } },
    fragment: placeFragment,
    fetchOnMount: false
  });
  const { fetch: fetchUserEmail } = useClient('UserSearch', {
    fragment: `email`
  });

  const [imgUser, setImgUser] = React.useState('');
  const [profession, setProfession] = React.useState('');
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (!user) return;
    const isPlaceUser = user?.roleGroup?._id === 'usuario_loja';

    if (isPlaceUser) {
      const variables = { filter: { CNPJ: user.CNPJ } };
      fetchPlaceData({ variables })
        .then((ctx) => ctx.result?.adminId)
        .then(async (adminId) => {
          const result = await fetchUserEmail({
            variables: { search: adminId || '' }
          }).then((ctx) => ctx.result);
          return result?.pop()?.email.toUpperCase();
        })
        .then((email) => {
          setProfession(
            `${user?.region} | ${user?.roleGroup?.name} | ${email}`
          );
        });
    } else {
      const email = user?.email?.toUpperCase();
      setProfession(`${user?.region} | ${user?.roleGroup?.name} | ${email}`);
    }
  }, [user]);

  React.useEffect(() => {
    if (!!user?.avatar?.signedUrl) setImgUser(user?.avatar?.signedUrl || '');
  }, [user?.avatar?.signedUrl]);

  const config = React.useMemo(() => {
    return {
      lastAcess: new Date(),
      configMiniProfile: {
        id: 'id',
        img: imgUser,
        alt: 'Foto do perfil',
        showLinksBotton: false,
        name: (user?.name || '').split(' ')[0],
        profession: profession,
        menuIsOpen: true,
        hasMenuWord: false,
        onClickEditProfile: () => {
          setVisible(true);
        }
      },
      itemsMenuSettings: {
        id: 'menuMerchan',
        items: buildUserMenuWithPermissions(
          permissions,
          userPermissions,
          history,
          user
        )
      }
    };
  }, [user, imgUser, permissions]); //eslint-disable-line

  return (
    <>
      <Menu
        {...config}
        hasMenuWord={false}
        menuIsOpen={menuIsOpen}
        toggleMenu={toggleMenu}
        configMiniProfile={{
          id: 'id',
          img: imgUser,
          alt: 'Foto do perfil',
          showLinksBotton: false,
          name: (user?.name || '').split(' ')[0],
          profession: profession,
          menuIsOpen: true,
          dots: 0,
          onClickEditProfile: () => {
            setVisible(true);
          }
        }}
        onClickLogout={() => {
          UserSession.clear();

          if (user?.matricula) {
            document.location.href =
              'https://pacportal.timbrasil.com.br/pac/logoff.jsp';
          } else {
            document.location.href = '/';
          }
        }}
      />

      {user && (
        <EditAvatar
          modalVisible={visible}
          setImgUser={(imgUrl: string) => {
            setImgUser(imgUrl);
            setVisible(false);
          }}
          imgUser={imgUser}
          setVisible={(isVisible: boolean) => {
            setVisible(isVisible);
          }}
          user={user}
        />
      )}
    </>
  );
};
