import {
  Context,
  fetchGraphql,
  GraphQLClient,
  GraphQLStore
} from 'graphql-clientgen';
import { groupBy, nonNullValues, sortBy, verdade } from '@digi-tim-19/utils';

import {
  MutationRegionalBudgetParseItemsArgs,
  ProductionParsedItemsInput,
  ProductionParseItemsPayload,
  RegionalBudgetParseItemsPayload,
  RegionalBudgetParseItemsPayloadByDestination,
  RegionalBudgetParseItemsPayloadByProduct
} from '../../autogenerated/client/types';

import {
  ProductionFormDataByDestination,
  ProductionFormDataByProduct,
  ProductionItem,
  ProductionServerCalcValuesState
} from '../../pages/Production/interfaces/ProductionInterfaces';

import { RegionalBudgetServerCalcValuesState } from '../../pages/RegionalBudget/redux/RegionalBudgetReduxInterfaces';

// Faz chamada a API para que  calcular as quantidades e preços finais
export async function fetchParseBudgetProductionItems(
  productionItems: ProductionItem[],
  parseVariables: (
    items: ProductionParsedItemsInput[]
  ) => MutationRegionalBudgetParseItemsArgs
): Promise<RegionalBudgetServerCalcValuesState['output']> {
  const store = GlobalGlobalContext.get().store;

  const result: Context<any, RegionalBudgetParseItemsPayload> =
    await fetchGraphql(
      'RegionalBudgetParseItems',
      {
        variables: parseVariables(productionItems.map(stateItemToInputItem)),
        fragment: fragment(true)
      },
      store
    )();

  return {
    ...parseResultItems({
      byProduct: verdade(result.result?.byProduct),
      byDestination: verdade(result.result?.byDestination)
    }),
    regionalUsedAmount: result?.result?.regionalUsedAmount || NaN,
    error: result?.errors?.join() || ''
  };
}

// Faz chamada a API para que  calcular as quantidades e preços finais
export async function fetchParseProductionItems(
  productionItems: ProductionItem[]
): Promise<ProductionServerCalcValuesState['output']> {
  const store = GlobalGlobalContext.get().store;

  const result: Context<any, ProductionParseItemsPayload> = await fetchGraphql(
    'ProductionParseItems',
    {
      variables: { items: productionItems.map(stateItemToInputItem) },
      fragment: fragment(false)
    },
    store
  )();

  return {
    ...parseResultItems({
      byProduct: verdade(result.result?.byProduct),
      byDestination: verdade(result.result?.byDestination)
    }),
    error: result?.errors?.join()
  };
}

function parseResultItems(result: {
  byProduct: RegionalBudgetParseItemsPayloadByProduct[];
  byDestination: RegionalBudgetParseItemsPayloadByDestination[];
}) {
  const byProduct: ProductionFormDataByProduct[] = [];
  const byDestination: ProductionFormDataByDestination[] = [];

  result.byProduct.forEach((productionItem) => {
    const placesInFatorial =
      verdade(productionItem.placesInFatorial).map((data) => ({
        name: `${data?.name}`,
        sapCode: `${data?.sapCode}`,
        structural: `${data?.structural}`,
        qnt: data?.qnt || 0
      })) || [];

    byProduct.push(
      nonNullValues({
        frontendGroupId: productionItem.frontendGroupId,
        layoutCodeT: productionItem.layoutCodeT,
        extraQuantityByDistributionCenter:
          productionItem.extraQuantityByDistributionCenter,
        totalPlacesInFactorial: productionItem.totalPlacesInFactorial,
        totalPlaces: productionItem.totalPlaces,
        quantityFromFactorial: productionItem.quantityFromFactorial,
        isQuantityFromFactorial: productionItem.isQuantityFromFactorial,
        quantityByDestination: productionItem.quantityByDestination,
        groupTotal: productionItem.groupTotal || 0,
        placesInFatorial,

        layoutId: productionItem.layoutId,
        layoutTitle: productionItem.layoutTitle,
        productTitle: productionItem.productTitle,
        supplierName: productionItem.supplierName,
        productTotalPrice: productionItem.productTotalPrice,
        productFinalPriceByItem: productionItem.productFinalPriceByItem,
        productAmount: productionItem.productAmount,
        destinationsCount: productionItem.destinationsCount,

        productPriceInterval: verdade(productionItem.productPriceInterval).map(
          ({ start, end, price }) => {
            return nonNullValues({
              start,
              end,
              price
            });
          }
        )
      })
    );
  });

  result.byDestination.forEach((productionItem) => {
    byDestination.push(
      nonNullValues({
        frontendGroupId: productionItem.frontendGroupId,
        destinationCNPJ: productionItem.destinationCNPJ,
        destinationSAPCode: productionItem.destinationSAPCode,
        destinationRazaoSocial: productionItem.destinationRazaoSocial,
        destinationNomeFantasia: productionItem.destinationNomeFantasia,
        destinationDDD: productionItem.destinationDDD,

        layoutId: productionItem.layoutId,
        layoutCodeT: productionItem.layoutCodeT,
        layoutTitle: productionItem.layoutTitle,
        productTitle: productionItem.productTitle,
        supplierName: productionItem.supplierName,
        productTotalPrice: productionItem.productTotalPrice,
        productFinalPriceByItem: productionItem.productFinalPriceByItem,
        productAmount: productionItem.quantity,
        destinationsCount: productionItem.destinationsCount,

        productPriceInterval: verdade(productionItem.productPriceInterval).map(
          ({ start, end, price }) => {
            return nonNullValues({
              start,
              end,
              price
            });
          }
        )
      })
    );
  });

  const byProductSorted = sortBy(byProduct, (el) => el.layoutCodeT);
  const byDestinationSorted = sortBy(byDestination, (el) => el.layoutCodeT);

  return {
    byProduct: groupBy(byProductSorted, (el) => el.frontendGroupId),
    byDestination: groupBy(byDestinationSorted, (el) => el.frontendGroupId)
  };
}

function stateItemToInputItem(
  item: ProductionItem
): ProductionParsedItemsInput {
  const { foundDestinationsSAPCodes, ...cleared } = item;

  return {
    ...cleared,
    supplierDeliveryDestinationType:
      cleared.supplierDeliveryDestinationType as any,
    quantityInputMode: cleared.quantityInputMode as any
  };
}

type GraphqlGlobalContext = {
  graphqlClient: GraphQLClient;
  store: GraphQLStore;
};

declare global {
  interface Window {
    __GRAPHQL_GLOBAL_CONTEXT__?: GraphqlGlobalContext;
  }
}

export const GlobalGlobalContext = {
  get() {
    const data = window.__GRAPHQL_GLOBAL_CONTEXT__;
    if (!data) {
      throw new Error(`__GRAPHQL_GLOBAL_CONTEXT__ não foi definido`);
    }
    return data;
  },
  set(data: GraphqlGlobalContext) {
    window.__GRAPHQL_GLOBAL_CONTEXT__ = data;
  }
};

const fragment = (isRegionalBudget: boolean) => `
  count

  ${isRegionalBudget ? 'regionalUsedAmount' : ''}

  byDestination {
    frontendGroupId

    layoutId
    productTitle
    supplierName
    productAmount
    destinationsCount

    destinationCNPJ
    destinationRazaoSocial
    destinationNomeFantasia
    destinationDDD
    layoutCodeT
    layoutTitle

    destinationSAPCode
    quantity
    extraQuantityByDistributionCenter
    productTotalPrice
    productPriceInterval {
      start
      end
      price
    }
    productFinalPriceByItem
  }

  byProduct {
    layoutId
    productTitle
    supplierName
    productAmount
    destinationsCount

    frontendGroupId
    extraQuantityByDistributionCenter
    layoutCodeT
    quantityFromFactorial
    isQuantityFromFactorial
    extraQuantityByDistributionCenter
    quantityByDestination
    totalPlacesInFactorial
    layoutTitle
    groupTotal
    totalPlaces
    placesInFatorial {
      sapCode
      name
      structural
      qnt
    }
    totalPlacesInFactorial

    productTotalPrice
    productPriceInterval {
      start
      end
      price
    }
    productFinalPriceByItem
  }
`;
