import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { PDF, LoadingIndicator } from '@digi-tim-19/components';
import { Empty } from 'antd';
import { useClient } from '../../autogenerated/client/client';
import { Module } from '../../config/modules';

const ViewerFolhetoWrapper = styled.div`
  margin: auto;
  width: min(100%);
`;

export const ViewerFolhetoDigital = () => {
  const { brochure_id } = useParams();
  const BrochureFindById = useClient('DigitalBrochureFindById');
  const google = 'https://docs.google.com/viewer?url=';

  useEffect(() => {
    BrochureFindById.fetch({
      variables: { _id: brochure_id },
      appendToFragment: `fileContent {signedUrl}`
    });
  }, [brochure_id]);
  const isLoading = BrochureFindById.loading;
  const result = BrochureFindById.result;
  const fileSignedUrl = result?.fileContent?.signedUrl;

  return (
    <>
      <ViewerFolhetoWrapper>
        {isLoading && <LoadingIndicator />}
        {!isLoading && result === null && <Empty />}
        {!isLoading && !!fileSignedUrl && (
          <PDF
            src={
              google +
              encodeURIComponent(fileSignedUrl || '') +
              '&embedded=true'
            }
          />
        )}
      </ViewerFolhetoWrapper>
    </>
  );
};

export default ViewerFolhetoDigital;

ViewerFolhetoDigital.moduleName = Module.DIGITAL_BROCHURE;
