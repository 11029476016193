import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { productionSlice } from '../pages/Production/redux/production';
import { budgetSlice } from '../pages/Budget/redux/budget';
import { ProductionReduxState } from '../pages/Production/redux/ProductionReduxInterfaces';
import { BudgetReduxState } from '../pages/Budget/redux/BudgetReduxInterfaces';

import {
  RegionalBudgetReduxState,
  regionalBudgetSlice
} from '../pages/RegionalBudget/redux/reginalBudget';

export interface ReduxRootState {
  production: ProductionReduxState;
  budget: BudgetReduxState;
  regionalBudget: RegionalBudgetReduxState;
}

const reducers: { [key in keyof ReduxRootState]: Reducer<any, any> } = {
  production: productionSlice.reducer,
  budget: budgetSlice.reducer,
  regionalBudget: regionalBudgetSlice.reducer
};

export const rootReducer = combineReducers(reducers);
