import React, { lazy, useState } from 'react';
import { Switch, Route, Router } from 'react-router';
import { GraphQLClient, GraphQLProvider, Middleware } from 'graphql-clientgen';
import { createBrowserHistory } from 'history';

import {
  AsyncLoad,
  ThemeProvider,
  DataProviders,
  routerPageWrapper,
  NotFound,
  LoadingIndicator,
  RouteWrapper,
  graphqlLogsMiddleware,
  UserSession
} from '@digi-tim-19/components';

import { ReduxProvider } from './redux/ReduxProvider';

import { PermissionsProvider } from './hooks/useUserPermissions';
import { API_URL } from './config/apiURL';
import { Permission } from './config/permissions';
import { routes, UPLOAD_URL } from './config/routes';
import { AppMethods, AppMethodsInfo } from './autogenerated/client/client';
import { usePermission } from './hooks/user/usePermission';
import { siteTheme } from './styles/theme';
import { Module } from './config/modules';
import { MainPage } from './components/Layout/MainPage';

// pages
import { Login } from './pages/Login/Login';
import { ViewerFolhetoDigital } from './pages/DigitalBrochure/ViewerFolhetoDigital';
import { ConfirmEmailRead } from './pages/ConfirmEmailRead/ConfirmEmailRead';

import { GlobalGlobalContext } from './redux/productionItems/fetchProductionItemsParser';
import { message } from 'antd';
import { PlaceGroupDataProvider } from './components/PlaceGroupFilter/Providers/PlaceGroupData';
import { PlaceFilterOptionsProvider } from './components/PlaceGroupFilter/Providers/PlaceFilterOptions';

const Home = () => lazy(() => import('./pages/Home/Home'));

const Team = () => lazy(() => import('./pages/Home/Team'));

const DashBoard = () => lazy(() => import('./pages/DashBoard/DashBoard'));

const GestaoUsuarios = () => lazy(() => import('./pages/User/GestaoUsuarios'));

const CheckoutBudget = () =>
  lazy(() => import('./pages/Budget/CheckoutBudget'));
const CadastrarUsuarios = () =>
  lazy(() => import('./pages/User/CadastrarUsuarios'));
const GestaoLocal = () => lazy(() => import('./pages/Place/GestaoLocal'));
const AdicionarLocal = () => lazy(() => import('./pages/Place/AdicionarLocal'));
const EditarLocal = () => lazy(() => import('./pages/Place/EditarLocal'));

const GestaoFornecedores = () =>
  lazy(() => import('./pages/Supplier/GestaoFornecedores'));

const AtivosTim = () => lazy(() => import('./pages/AtivosTim/Ativos'));
const AdicionarFornecedor = () =>
  lazy(() => import('./pages/Supplier/AdicionarFornecedor'));
const EditarFornecedor = () =>
  lazy(() => import('./pages/Supplier/EditarFornecedor'));

const GestaoDevice = () => lazy(() => import('./pages/Device/GestaoDevice'));
const DevicesNotFound = () =>
  lazy(() => import('./pages/Device/components/DeviceAlerts/DevicesNotFound'));
const AdicionarDevice = () =>
  lazy(() => import('./pages/Device/AdicionarDevice'));
const EditarDevice = () => lazy(() => import('./pages/Device/EditarDevice'));

const GestaoCampanha = () =>
  lazy(() => import('./pages/Campaign/GestaoCampanha'));
const AdicionarCampanha = () =>
  lazy(() => import('./pages/Campaign/AdicionarCampanha'));
const EditarCampanha = () =>
  lazy(() => import('./pages/Campaign/EditarCampanha'));

const GestaoLayout = () => lazy(() => import('./pages/Layout/GestaoLayout'));
const AdicionarLayout = () =>
  lazy(() => import('./pages/Layout/AdicionarLayout'));
const EditarLayout = () => lazy(() => import('./pages/Layout/EditarLayout'));

const GestaoBudget = () => lazy(() => import('./pages/Budget/GestaoBudget'));

const AdicionarBudget = () =>
  lazy(() => import('./pages/Budget/AdicionarBudget'));

const VisualizarBudget = () =>
  lazy(() => import('./pages/Budget/VisualizarBudget'));

const GestaoRegionalBudgets = () =>
  lazy(() => import('./pages/RegionalBudget/GestaoRegionalBudgets'));

const RegionalBudgetView = () =>
  lazy(() => import('./pages/RegionalBudget/RegionalBudget'));

const AdicionarProduction = () =>
  lazy(() => import('./pages/Production/AdicionarProduction'));
const VisualizarProduction = () =>
  lazy(() => import('./pages/Production/VisualizarProduction'));
const GestaoProduction = () =>
  lazy(() => import('./pages/Production/GestaoProduction'));

const GestaoFolhetoDigital = () =>
  lazy(() => import('./pages/DigitalBrochure/GestaoFolhetoDigital'));
const AdicionarFolhetoDigital = () =>
  lazy(() => import('./pages/DigitalBrochure/AdicionarFolhetoDigital'));
const EditarFolhetoDigital = () =>
  lazy(() => import('./pages/DigitalBrochure/EditarFolhetoDigital'));

const GeradorDeLaminas = () =>
  lazy(() => import('./pages/GeradorDeLaminas/GeradorDeLaminas'));
const EditarUsuario = () => lazy(() => import('./pages/User/EditarUsuario'));
const GestaoTablePrice = () =>
  lazy(() => import('./pages/TablePrice/GestaoTablePrice'));
const AdicionarTablePrice = () =>
  lazy(() => import('./pages/TablePrice/AdicionarTablePrice'));
const EditarTablePrice = () =>
  lazy(() => import('./pages/TablePrice/EditarTablePrice'));
const AdicionarLegalText = () =>
  lazy(() => import('./pages/TablePrice/AdicionarLegalText'));

const EditarPositivator = () =>
  lazy(() => import('./pages/Positivator/EditarPositivator'));
const VisualizarPositivatorResume = () =>
  lazy(() => import('./pages/PositivatorResume/VisualizarPositivatorResume'));

const GestaoPositivatorNotification = () =>
  lazy(
    () =>
      import('./pages/PositivatorNotification/GestaoPositivatorNotification')
  );
const AdicionarPositivatorNotification = () =>
  lazy(
    () =>
      import('./pages/PositivatorNotification/AdicionarPositivatorNotification')
  );
const VisualizarPositivatorNotification = () =>
  lazy(
    () =>
      import(
        './pages/PositivatorNotification/VisualizarPositivatorNotification'
      )
  );

const LogisticaEntregas = () =>
  lazy(() => import('./pages/Logistica/LogisticaEntregas/LogisticaEntregas'));
const LogisticaEstoque = () =>
  lazy(() => import('./pages/Logistica/LogisticaEstoque/LogisticaEstoque'));
const LogisticaGrades = () =>
  lazy(() => import('./pages/Logistica/LogisticaGrades/LogisticaGrades'));
const NewGradeIndependente = () =>
  lazy(
    () => import('./pages/Logistica/GradesIndependentes/CriarGradeIndependente')
  );
const EditGradeIndependente = () =>
  lazy(
    () =>
      import('./pages/Logistica/GradesIndependentes/EditarGradeIndependente')
  );

const Structural = async () => lazy(() => import('./pages/Structural'));
const StructuralNewProject = async () =>
  lazy(() => import('./pages/Structural/StructuralNewProject'));
const StructuralProjectEdit = async () =>
  lazy(() => import('./pages/Structural/StructuralProjectEdit'));
const StructuralProjectDetails = async () =>
  lazy(() => import('./pages/Structural/StructuralProjectDetails'));

const AreaLoja = () => lazy(() => import('./pages/MapaPDV/AreaLoja/AreaLoja'));
const RegraComunicacao = () => lazy(() => import('./pages/MapaPDV/RegraMapa/RegraComunicacao'));
const RegraEspacoLoja = () => lazy(() => import('./pages/MapaPDV/RegraMapa/RegraEspacoLoja'));
const Formatos = () => lazy(() => import('./pages/MapaPDV/Formatos/Formatos'));
const Pecas = () => lazy(() => import('./pages/MapaPDV/Pecas/Pecas'));
const MapaPDV = () => lazy(() => import('./pages/MapaPDV/CenarioMapaPDV/CenarioMapaPDV'));
const MapaPDVForm = () => lazy(() => import('./pages/MapaPDV/CenarioMapaPDV/CenarioMapaPDVForm'));

const MapaPDVMenu = () => lazy(() => import('./pages/MapaPDV/MapaPDVMenu'));

const PageAccessNotAllowed = async () =>
  lazy(() => import('./components/PageAccessNotAllowed/PageAccessNotAllowed'));

export function handleApiErrors<CTX extends { [key: string]: any }>(ctx: CTX) {
  if (!ctx.errors) return;
  if (ctx.action !== 'complete') return;
  if (ctx.methodConfig.entityName === 'User') return; // nao notificar 401 quando fetch de user
  const error = ctx.errors.join();

  const duration = Math.max(error.length / 20, 10);

  ctx.errors.forEach(function (err: string) {
    if (err !== '401') return;

    if (!window.location.pathname.match(/login/)) {
      const msg = `ACCESS_DENIED: ${ctx.methodConfig.entityName} ${err}`;
      document.write(`<h1>${msg}</h1>`);
      document.close();
      alert(msg);
      window.location.href = '/';
    }
  });

  if (error.match(/CLIENT_INFO::/)) {
    message.info(error.replace('CLIENT_INFO::', ''), duration);
    return;
  }

  if (error.match(/CLIENT_WARNING::/)) {
    message.warning(error.replace('CLIENT_WARNING::', ''), duration);
    return;
  }

  if (error !== 'Unauthorized')
    message.error(`${ctx.methodConfig.entityName}: ${error}`, 10);
  return;
}

export const graphqlMiddleware: Middleware = async (ctx) => {
  const token = UserSession.getToken();

  if (token) {
    ctx.requestConfig.headers = {
      ...ctx.requestConfig.headers,
      Authorization: `bearer ${token}`,
      'x-location-pathname': window.location.pathname // usado no cms para capturar a url da origin da requisição
    };
  }

  // quando usamos um token temporario, a api pode retornar
  // um novo token permanente no response header authorization
  // quando isso ocorre, salvamos o novo token permanente
  if (ctx.fetchResponse) {
    const newToken = ctx.fetchResponse.headers.get('authorization');
    if (newToken) {
      UserSession.setItem('temp_token', newToken);
    }
  }

  handleApiErrors(ctx);

  return ctx;
};

export const App = () => {
  const isLocalHost = window.location.host.match(/localhost:/);

  const [graphqlClient] = useState(() => {
    return new GraphQLClient({
      url: API_URL,
      middleware: [
        graphqlLogsMiddleware,
        graphqlMiddleware,
        async function (ctx) {
          if (ctx.requestConfig) {
            // utilizado para auth com pcs e captutar cookie do analytics
            ctx.requestConfig.credentials = isLocalHost ? 'omit' : 'include';
          }
          return ctx;
        }
      ],
      methods: AppMethods,
      methodsInfo: AppMethodsInfo
    });
  });

  return (
    <ThemeProvider theme={siteTheme}>
      <GraphQLProvider
        client={graphqlClient}
        render={(store) => {
          GlobalGlobalContext.set({ graphqlClient, store });

          return (
            <DataProviders
              store={store}
              uploadUrl={UPLOAD_URL}
              parseUserPermissions={(_user) => {
                return {
                  manageOtherUsersComments: false
                };
              }}
            >
              <ReduxProvider>
                <PermissionsProvider>
                  <Routes />
                </PermissionsProvider>
              </ReduxProvider>
            </DataProviders>
          );
        }}
      />
    </ThemeProvider>
  );
};

/**
 * @created on Mon Aug 24 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * Esse método serve para compilar o wrapper que verifica a permissão de acesso
 * a página pelo usuário logado. Para mais detalhes sobre as regras de permissão
 * verificar o arquivo src/hooks/user/usePermission
 * @param comp: componente que será renderizado caso o usuário tenha permissão.
 */
const routerPermissionWrapper = (comp: any) => {
  return (props: any) => <PermissionWrapper {...props} comp={comp} />;
};

const PermissionWrapper = (props: any) => {
  const [moduleName, setModuleName] = useState();
  const { comp } = props;
  /**
   * @created on Mon Aug 24 2020
   * @author Emir Marques - emirdeliz@gmail.com
   * A variável "moduleName" é definida no final do arquivo de cada página
   */
  const permissions = usePermission(moduleName || ({} as Module)) as any;
  const hasReadPermission = !moduleName || permissions[Permission.READ];
  const hasCreatePermission = !moduleName || permissions[Permission.CREATE];
  const hasUpdatePermission = !moduleName || permissions[Permission.UPDATE];
  const hasDeletePermission = !moduleName || permissions[Permission.DELETE];

  if (!hasReadPermission) {
    return permissions.loading || !permissions.initialized ? (
      <LoadingIndicator />
    ) : (
      <AsyncLoad component={PageAccessNotAllowed} />
    );
  }

  return (
    <RouteWrapper
      extraProps={{
        ...props,
        component: comp,
        onLoad: (module: any) => setModuleName(module.moduleName),
        props: {
          ...props,
          hasCreatePermission,
          hasUpdatePermission,
          hasDeletePermission
        }
      }}
      pageToRender={AsyncLoad}
    />
  );
};

const buildBasePath = (path: string) => {
  return path.split('/')[1];
};

const history = createBrowserHistory();
const Routes = () => {
  React.useEffect(() => {
    history.listen(function () {
      window.scroll(0, 0);
    });
  }, []);

  return (
    <Router history={history}>
      <PlaceGroupDataProvider>
        <PlaceFilterOptionsProvider>
          <Switch>
            <Route
              render={(props) => {
                const location = props.location || {};
                const pathname = location.pathname || '';
                const hideSidebar = !![
                  routes.login.path,
                  routes.confirmEmailRead.path,
                  routes.changePassword.path,
                  routes.digitalBrochureViewer.path
                ].find((r) => pathname.indexOf(buildBasePath(r)) > -1);

                return (
                  <MainPage hideSidebar={hideSidebar}>
                    <Switch>
                      <Route
                        path={routes.home.path}
                        exact
                        component={routerPermissionWrapper(Home)}
                      />
                      <Route
                        path={routes.team.path}
                        exact
                        component={routerPermissionWrapper(Team)}
                      />
                      <Route
                        path={routes.dashBoard.path}
                        exact
                        component={routerPermissionWrapper(DashBoard)}
                      />
                      <Route
                        path={routes.users.path}
                        exact
                        component={routerPermissionWrapper(GestaoUsuarios)}
                      />
                      <Route
                        path={routes.userNew.path}
                        exact
                        component={routerPermissionWrapper(CadastrarUsuarios)}
                      />
                      <Route
                        path={routes.userEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarUsuario)}
                      />
                      <Route
                        path={routes.places.path}
                        exact
                        component={routerPermissionWrapper(GestaoLocal)}
                      />
                      <Route
                        path={routes.placesNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarLocal)}
                      />
                      <Route
                        path={routes.placeEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarLocal)}
                      />
                      <Route
                        path={routes.suppliers.path}
                        exact
                        component={routerPermissionWrapper(GestaoFornecedores)}
                      />
                      <Route
                        path={routes.supplierNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarFornecedor)}
                      />
                      <Route
                        path={routes.supplierEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarFornecedor)}
                      />
                      <Route
                        path={routes.ativos.path}
                        exact
                        component={routerPermissionWrapper(AtivosTim)}
                      />
                      <Route
                        path={routes.digitalBrochures.path}
                        exact
                        component={routerPermissionWrapper(
                          GestaoFolhetoDigital
                        )}
                      />
                      <Route
                        path={routes.digitalBrochureNew.path}
                        exact
                        component={routerPermissionWrapper(
                          AdicionarFolhetoDigital
                        )}
                      />
                      <Route
                        path={routes.digitalBrochureEdit.path}
                        exact
                        component={routerPermissionWrapper(
                          EditarFolhetoDigital
                        )}
                      />
                      <Route
                        path={routes.devices.path}
                        exact
                        component={routerPermissionWrapper(GestaoDevice)}
                      />
                      <Route
                        path={routes.devicesNotFoundAlerts.path}
                        exact
                        component={routerPermissionWrapper(DevicesNotFound)}
                      />
                      <Route
                        path={routes.deviceNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarDevice)}
                      />
                      <Route
                        path={routes.deviceEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarDevice)}
                      />
                      <Route
                        path={routes.campaigns.path}
                        exact
                        component={routerPermissionWrapper(GestaoCampanha)}
                      />
                      <Route
                        path={routes.campaignNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarCampanha)}
                      />
                      <Route
                        path={routes.campaignEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarCampanha)}
                      />
                      <Route
                        path={routes.layouts.path}
                        exact
                        component={routerPermissionWrapper(GestaoLayout)}
                      />
                      <Route
                        path={routes.layoutNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarLayout)}
                      />
                      <Route
                        path={routes.layoutEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarLayout)}
                      />
                      <Route
                        path={routes.budgets.path}
                        exact
                        component={routerPermissionWrapper(GestaoBudget)}
                      />
                      <Route
                        path={routes.budgetNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarBudget)}
                      />
                      <Route
                        path={routes.budgetView.path}
                        exact
                        component={routerPermissionWrapper(VisualizarBudget)}
                      />
                      <Route
                        path={routes.budgetCheckout.path}
                        exact
                        component={routerPermissionWrapper(CheckoutBudget)}
                      />
                      <Route
                        path={routes.regionalBudgets.path}
                        exact
                        component={routerPermissionWrapper(
                          GestaoRegionalBudgets
                        )}
                      />
                      <Route
                        path={routes.regionalBudgetView.path}
                        exact
                        component={routerPermissionWrapper(RegionalBudgetView)}
                      />
                      <Route
                        path={routes.productions.path}
                        exact
                        component={routerPermissionWrapper(GestaoProduction)}
                      />
                      <Route
                        path={routes.productionNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarProduction)}
                      />
                      <Route
                        path={routes.productionView.path}
                        exact
                        component={routerPermissionWrapper(
                          VisualizarProduction
                        )}
                      />
                      <Route
                        path={routes.logisticaEntrega.path}
                        exact
                        component={routerPermissionWrapper(LogisticaEntregas)}
                      />
                      <Route
                        path={routes.logisticaEstoque.path}
                        exact
                        component={routerPermissionWrapper(LogisticaEstoque)}
                      />
                      <Route
                        path={routes.logisticaGrades.path}
                        exact
                        component={routerPermissionWrapper(LogisticaGrades)}
                      />
                      <Route
                        path={routes.logisticaGradeIndependenteNew.path}
                        exact
                        component={routerPermissionWrapper(
                          NewGradeIndependente
                        )}
                      />
                      <Route
                        path={routes.logisticaGradeIndependenteEdit.path}
                        exact
                        component={routerPermissionWrapper(
                          EditGradeIndependente
                        )}
                      />
                      <Route
                        path={routes.tablePrice.path}
                        exact
                        component={routerPermissionWrapper(GestaoTablePrice)}
                      />
                      <Route
                        path={routes.tablePriceNew.path}
                        exact
                        component={routerPermissionWrapper(AdicionarTablePrice)}
                      />
                      <Route
                        path={routes.tabelaPricesEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarTablePrice)}
                      />
                      <Route
                        path={routes.tablepriceLegalText.path}
                        exact
                        component={routerPermissionWrapper(AdicionarLegalText)}
                      />
                      <Route
                        path={routes.geradorDeLaminas.path}
                        exact
                        component={routerPermissionWrapper(GeradorDeLaminas)}
                      />
                      <Route
                        path={routes.home.path}
                        exact
                        component={routerPageWrapper(Login)}
                        hideSidebar
                      />
                      <Route
                        path={routes.login.path}
                        exact
                        component={routerPageWrapper(Login)}
                      />
                      <Route
                        path={routes.confirmEmailRead.path}
                        exact
                        component={routerPageWrapper(ConfirmEmailRead)}
                      />
                      <Route
                        path={routes.digitalBrochureViewer.path}
                        exact
                        component={ViewerFolhetoDigital}
                      />
                      <Route
                        path={routes.positivatorResume.path}
                        exact
                        component={routerPermissionWrapper(
                          VisualizarPositivatorResume
                        )}
                      />
                      <Route
                        path={routes.positivatorEdit.path}
                        exact
                        component={routerPermissionWrapper(EditarPositivator)}
                      />
                      <Route
                        path={routes.positivatorNotification.path}
                        exact
                        component={routerPermissionWrapper(
                          GestaoPositivatorNotification
                        )}
                      />
                      {/*<Route*/}
                      {/*  path={routes.positivatorNotificationEdit.path}*/}
                      {/*  exact*/}
                      {/*  component={routerPermissionWrapper(*/}
                      {/*    EditarPositivatorNotification*/}
                      {/*  )}*/}
                      {/*/>*/}
                      <Route
                        path={routes.positivatorNotificationNew.path}
                        exact
                        component={routerPermissionWrapper(
                          AdicionarPositivatorNotification
                        )}
                      />
                      <Route
                        path={routes.positivatorNotificationView.path}
                        exact
                        component={routerPermissionWrapper(
                          VisualizarPositivatorNotification
                        )}
                      />
                      <Route
                        path={routes.structural.path}
                        exact
                        component={routerPermissionWrapper(Structural)}
                      />
                      <Route
                        path={routes.structuralNewProject.path}
                        exact
                        component={routerPermissionWrapper(
                          StructuralNewProject
                        )}
                      />
                      <Route
                        path={routes.structuralProjectDetail.path}
                        exact
                        component={routerPermissionWrapper(
                          StructuralProjectDetails
                        )}
                      />
                      <Route
                        path={routes.structuralProjectEdit.path}
                        exact
                        component={routerPermissionWrapper(
                          StructuralProjectEdit
                        )}
                      />
                      {/* MAPA DE PDV */}
                      <Route
                        path={routes.mapaAreaLoja.path}
                        exact
                        component={routerPermissionWrapper(AreaLoja)}
                      />
                      <Route
                        path={routes.mapaFormatos.path}
                        exact
                        component={routerPermissionWrapper(Formatos)}
                      />
                      <Route
                        path={routes.mapaPecas.path}
                        exact
                        component={routerPermissionWrapper(Pecas)}
                      />
                      <Route
                        path={routes.mapaRegramentoComunicacao.path}
                        exact
                        component={routerPermissionWrapper(RegraComunicacao)}
                      />
                      <Route
                        path={routes.mapaPDV.path}
                        exact
                        component={routerPermissionWrapper(MapaPDV)}
                      />
                      <Route
                        path={routes.mapaPDVCreate.path}
                        exact
                        component={routerPermissionWrapper(MapaPDVForm)}
                      />
                      <Route
                        path={routes.mapaPDVEdit.path}
                        exact
                        component={routerPermissionWrapper(MapaPDVForm)}
                      />
                      <Route
                        path={'/menu/MapaPDV'}
                        exact
                        component={routerPermissionWrapper(MapaPDVMenu)}
                      />
                      <Route
                        path={'/mapaPDV/espaco-loja'}
                        exact
                        component={routerPermissionWrapper(RegraEspacoLoja)}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </MainPage>
                );
              }}
            />
          </Switch>
        </PlaceFilterOptionsProvider>
      </PlaceGroupDataProvider>
    </Router>
  );
};
