import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { Place } from '../../../autogenerated/client/types';
import { usePlaceGroupData } from './PlaceGroupData';
import { verdade } from '@digi-tim-19/utils';
import { groupBy, uniqBy } from 'lodash';
import { CustomOption, FilterKey } from '../TypeDefs';

interface IPlaceFilterOptions {
  children: React.ReactNode;
}

export const PlaceFilterOptionsProvider = (props: IPlaceFilterOptions) => {
  const { children } = props;
  const { placesCache } = usePlaceGroupData();

  const { dddOptions, cityOptions, structuralOptions } = useMemo(() => {
    const dddOptions = getDDDOptions(placesCache);
    const cityOptions = getCityOptions(placesCache);
    const structuralOptions = getStructuralOptions(placesCache);
    return { dddOptions, cityOptions, structuralOptions };
  }, [placesCache]);

  const getOptions = useCallback(
    (key: FilterKey, filterState: Record<FilterKey, string[]>) => {
      switch (key) {
        case 'region': {
          return getMappedOptions([], region);
        }
        case 'UF': {
          return getMappedOptions(filterState['region'], UF);
        }
        case 'DDD': {
          return getMappedOptions(filterState['UF'], dddOptions);
        }
        case 'cityId': {
          return getMappedOptions(filterState['DDD'], cityOptions);
        }
        case 'type': {
          return getMappedOptions([], type);
        }
        case 'storeModel': {
          return getMappedOptions(filterState['type'], storeModel);
        }
        case 'communicationFocus': {
          return getMappedOptions([], communicationFocus);
        }
        case 'marketedProduct': {
          return getMappedOptions([], marketedProduct);
        }
        case 'networkTechnology': {
          return getMappedOptions([], networkTechnology);
        }
        case 'estrutura_de_loja': {
          return getMappedOptions([], structuralOptions);
        }
        case 'CNPJ':
          return [];
      }
    },
    [dddOptions, cityOptions, structuralOptions]
  );

  return (
    <PlaceFilterOptionsContext.Provider value={{ getOptions }}>
      {children}
    </PlaceFilterOptionsContext.Provider>
  );
};

type PlaceFilterOptionsCtx = {
  getOptions: (
    key: FilterKey,
    filterState: Record<FilterKey, string[]>
  ) => CustomOption[];
};

const PlaceFilterOptionsContext = createContext<PlaceFilterOptionsCtx>({
  getOptions: () => []
});

export const usePlaceFilterOptions = () =>
  useContext(PlaceFilterOptionsContext);

// Helper functions
const getDDDOptions = (places: Place[]) =>
  groupBy(
    uniqBy(
      places.flatMap((place) =>
        verdade(place.DDDs).map((DDD) => ({
          key: `${place.address?.UF}`,
          label: DDD,
          value: DDD
        }))
      ),
      (el) => el.value
    ),
    (el) => el.key
  );

const getCityOptions = (places: Place[]) =>
  groupBy(
    uniqBy(
      places.map((place) => ({
        key: `${place.DDDs?.pop()}`,
        label: `${place.address?.cityName}`,
        value: `${place.address?.cityId}`
      })),
      (el) => el.value
    ),
    (el) => el.key
  );

const getStructuralOptions = (places: Place[]) =>
  uniqBy(
    places.flatMap((place) =>
      verdade(place.estrutura_de_loja).map((structural) => ({
        label: `${structural?.codeTM} - ${structural?.productName}`,
        value: `${structural?.codeTM} - ${structural?.productName}`
      }))
    ),
    (el) => el.value
  );

const getMappedOptions = (
  dependency: string[],
  data: Record<string, CustomOption[]> | CustomOption[]
) =>
  Array.isArray(data) ? data : dependency.flatMap((key) => data[key] || []);

// Static Options
export const type = [
  {
    label: 'Loja própria',
    value: 'loja_propria'
  },
  {
    label: 'Revenda',
    value: 'revenda'
  },
  {
    label: 'Master',
    value: 'master'
  },
  {
    label: 'Varejo',
    value: 'varejo'
  },
  {
    label: 'Distribuição',
    value: 'distribuicao'
  },
  {
    label: 'Escritório regional',
    value: 'escritorio_regional'
  },
  {
    label: 'Centro de distribuição logístico',
    value: 'centro_de_distribuicao_logistico'
  }
];

export const region = [
  { label: 'TNE', value: 'TNE' },
  { label: 'TSL', value: 'TSL' },
  { label: 'TCN', value: 'TCN' },
  { label: 'TSE', value: 'TSE' },
  { label: 'TSP', value: 'TSP' }
];

const UF = {
  TCN: [
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'GO', value: 'GO' },
    { label: 'DF', value: 'DF' },
    { label: 'TO', value: 'TO' },
    { label: 'RR', value: 'RR' },
    { label: 'AP', value: 'AP' },
    { label: 'PA', value: 'PA' },
    { label: 'AM', value: 'AM' },
    { label: 'MA', value: 'MA' },
    { label: 'RO', value: 'RO' },
    { label: 'AC', value: 'AC' }
  ],
  TNE: [
    { label: 'RN', value: 'RN' },
    { label: 'PB', value: 'PB' },
    { label: 'CE', value: 'CE' },
    { label: 'BA', value: 'BA' },
    { label: 'SE', value: 'SE' },
    { label: 'AL', value: 'AL' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' }
  ],
  TSP: [{ label: 'SP', value: 'SP' }],
  TSE: [
    { label: 'MG', value: 'MG' },
    { label: 'RJ', value: 'RJ' },
    { label: 'ES', value: 'ES' }
  ],
  TSL: [
    { label: 'PR', value: 'PR' },
    { label: 'SC', value: 'SC' },
    { label: 'RS', value: 'RS' }
  ]
};

export const storeModel = {
  loja_propria: [
    {
      label: 'Loja antiga',
      value: 'loja_antiga'
    },
    {
      label: 'Compacta',
      value: 'compacta'
    },
    {
      label: 'Experiencia',
      value: 'experiencia'
    },
    {
      label: 'Store',
      value: 'store'
    },
    {
      label: 'Loja high digital',
      value: 'loja_high_digital'
    },
    {
      label: 'Loja prime digital',
      value: 'loja_prime_digital'
    },
    {
      label: 'Quiosque fechado',
      value: 'quiosque_fechado'
    },
    {
      label: 'Quiosque aberto',
      value: 'quiosque_aberto'
    },
    {
      label: 'Lounge',
      value: 'lounge'
    }
  ],
  revenda: [
    {
      label: 'Revenda antiga',
      value: 'revenda_antiga'
    },
    {
      label: 'Revenda antiga facelift',
      value: 'revenda_antiga_facelift'
    },
    {
      label: 'Revenda hight',
      value: 'revenda_high'
    },
    {
      label: 'Revenda high digital',
      value: 'revenda_high_digital'
    },
    {
      label: 'Revenda express',
      value: 'revenda_express'
    }
  ]
};

export const communicationFocus = [
  {
    label: 'Controle',
    value: 'controle'
  },
  {
    label: 'Pós',
    value: 'pos'
  },
  {
    label: 'Pós Puro Alto Valor',
    value: 'pos_puro_alto_valor'
  },
  {
    label: 'Pós Puro e Controle',
    value: 'pos_puro_controle'
  }
];

export const marketedProduct = [
  {
    label: 'Pré',
    value: 'pre'
  },
  {
    label: 'Pós',
    value: 'pos'
  },
  {
    label: 'Controle',
    value: 'controle'
  },
  {
    label: 'Fixo',
    value: 'fixo'
  },
  {
    label: 'Live',
    value: 'live'
  },
  {
    label: 'WTTX',
    value: 'wttx'
  },
  {
    label: 'SMB',
    value: 'smb'
  },
  {
    label: 'Seguro',
    value: 'seguro'
  },
  {
    label: 'Trade-in',
    value: 'trade_in'
  },
  {
    label: 'Acessórios',
    value: 'acessorios'
  }
];

export const networkTechnology = [
  {
    label: '2G',
    value: '2g'
  },
  {
    label: '3G',
    value: '3g'
  },
  {
    label: '4G',
    value: '4g'
  },
  {
    label: '5G',
    value: '5g'
  }
];

export const cluster = [
  {
    label: 'Standard',
    value: 'standard'
  },
  {
    label: 'Prime',
    value: 'prime'
  },
  {
    label: 'C',
    value: 'c'
  }
];
