import { configureStore } from '@reduxjs/toolkit';

import { regionalBudgetPricingMiddleware } from '../pages/RegionalBudget/redux/regionalBudgetPricingMiddleware';
import { recalculatePricesMiddleware } from '../pages/Production/redux/productionPricingMiddleware';
import { ReduxRootState, rootReducer } from './rootReducer';
import { useStore } from 'react-redux';

export const useReduxStore = () => {
  return useStore<ReduxRootState>();
};

export const reduxStore = configureStore({
  middleware: [recalculatePricesMiddleware, regionalBudgetPricingMiddleware],
  reducer: rootReducer
});

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./rootReducer', () => {
    reduxStore.replaceReducer(require('./rootReducer').rootReducer);
  });
}
