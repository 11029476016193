"use strict";

var _classCallCheck = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var z = __importStar(require("zod"));

var prefix = 'entity_crud#';
var EntitySocketEventEnum;

(function (EntitySocketEventEnum) {
  EntitySocketEventEnum["create"] = "create";
  EntitySocketEventEnum["update"] = "update";
  EntitySocketEventEnum["delete"] = "delete";
})(EntitySocketEventEnum = exports.EntitySocketEventEnum || (exports.EntitySocketEventEnum = {})); // schema para validar o retorno do objeto vindo da resposta do websocket
// antes de executar os callbacks


exports.ZEntitySocketEventPayload = z.object({
  action: z.nativeEnum(EntitySocketEventEnum),
  room: z.string(),
  _id: z.string(),
  roomParentId: z.string()
});

var EntitySocketEvents = function EntitySocketEvents() {
  _classCallCheck(this, EntitySocketEvents);
};

exports.EntitySocketEvents = EntitySocketEvents;
EntitySocketEvents.actions = EntitySocketEventEnum;
EntitySocketEvents.prefix = prefix;
EntitySocketEvents.mountRoom = mountRoom;

function mountRoom(input) {
  var entity = input.entity,
      roomId = input.roomId;
  var entityName = (typeof entity === 'string' ? entity : entity.entityName).toLowerCase();
  return "entity_crud#".concat(entityName, "#roomParentId(").concat(roomId, ")");
}