export enum Module {
  BUDGET = 'budget',
  REGIONAL_BUDGET = 'regionalbudget',
  CAMPAIGN = 'campaign',
  DEVICE = 'device',
  DIGITAL_BROCHURE = 'digitalbrochure',
  // GERADOR_DE_LAMINAS = 'geradordelaminas',
  LAYOUT = 'layout',
  PLACE = 'place',
  POSITIVATOR = 'positivator',
  PRODUCTION = 'production',
  SUPPLIER_DELIVERY = 'supplierdelivery',
  SUPPLIER = 'supplier',
  PRODUCT = 'product',
  TABLE_PRICE = 'tableprice',
  USER = 'user',
  STRUCTURAL = 'structural'
}

export const ModuleArray = Object.values(Module);
