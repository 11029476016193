import { Middleware } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { ReduxRootState } from '../../../redux/rootReducer';
import { regionalBudgetSlice } from './reginalBudget';
import { fetchParseBudgetProductionItems } from '../../../redux/productionItems/fetchProductionItemsParser';
import {
  EnumProductionParsedItemsQuantityInputMode,
  EnumProductionParsedItemsSupplierDeliveryDestinationType
} from '../../../autogenerated/client/types';

let timeoutRef = null as any;

/**
 * Middleware para recalcular os valores ao alterar os quantidades
 * - os preços dos produtos mudam de acordo com as quantidades,
 *   sempre que as quantidades de um dos layouts é alterada, precisamos
 *   recalcular os preços, valores totais, etc
 * @param api
 */
export const regionalBudgetPricingMiddleware: Middleware =
  (api) => (next) => (action: PayloadAction) => {
    const getState: () => ReduxRootState = api.getState;

    const type = action.type || '';

    const isBudgetRegionalAction = type.startsWith('budgetProductionItems/');
    const initialStateActionType =
      regionalBudgetSlice.actions.initialState.type;
    const isInitialStateAction = type === initialStateActionType;
    const shouldHandle = isBudgetRegionalAction || isInitialStateAction;

    if (!shouldHandle) {
      return next(action);
    }

    const isLoading = getState().production.serverCalculatedValues.loading;
    if (!isLoading) {
      next(action);
    }

    clearInterval(timeoutRef);
    timeoutRef = setTimeout(handler, 500);

    function handler() {
      const { actions } = regionalBudgetSlice;

      let state = getState().regionalBudget;
      const isLoading = state.serverCalculatedValues.loading;

      if (isLoading) return;

      api.dispatch(actions.serverCalcProductionValuesStart());

      fetchParseBudgetProductionItems(state.productionItems, (items) => {
        const isVarejo = state.availableAtChannels.includes('varejo');
        return {
          items: isVarejo
            ? items.map((item) => ({
                ...item,
                quantityInputMode:
                  EnumProductionParsedItemsQuantityInputMode.ByDestination,
                supplierDeliveryDestinationType:
                  EnumProductionParsedItemsSupplierDeliveryDestinationType.AgenciaVarejo
              }))
            : items,
          parentBudgetId: state.parentBudgetId,
          regionalBudgetId: state._id
        };
      }).then((result) => {
        if (result.error) {
          api.dispatch(actions.serverCalcProductionValuesError(result.error));
        } else {
          api.dispatch(actions.serverCalcProductionValuesSuccess(result));
        }
      });
    }
  };
