import { Middleware } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { productionSlice } from './production';
import { ReduxRootState } from '../../../redux/rootReducer';
import { ProductionReduxState } from './ProductionReduxInterfaces';
import { fetchParseProductionItems } from '../../../redux/productionItems/fetchProductionItemsParser';

let timeoutRef = null as any;

/**
 * Middleware para recalcular os valores ao alterar os quantidades
 * - os preços dos produtos mudam de acordo com as quantidades,
 *   sempre que as quantidades de um dos layouts é alterada, precisamos
 *   recalcular os preços, valores totais, etc
 * @param api
 */
export const recalculatePricesMiddleware: Middleware =
  (api) => (next) => (action: PayloadAction) => {
    const getState: () => ReduxRootState = api.getState;

    const type = action.type || '';

    const { createBudgetProduction } = productionSlice.actions;

    const isInitialState = type === 'production/initialState';
    const isCreateBudgetProduction = type === createBudgetProduction.type;
    const isSelectedLayoutsUpdate =
      type === 'production/replaceSelectedLayouts';
    const isProductionItemUpdate = type.startsWith('productionItems/');

    const shouldHandle =
      isInitialState ||
      isProductionItemUpdate ||
      isSelectedLayoutsUpdate ||
      isCreateBudgetProduction;

    if (!shouldHandle) {
      return next(action);
    }

    const isLoading = getState().production.serverCalculatedValues.loading;
    if (!isLoading) {
      next(action);
    }

    clearInterval(timeoutRef);
    timeoutRef = setTimeout(handler, 500);

    function handler() {
      let state: ProductionReduxState = getState().production;
      const isLoading = state.serverCalculatedValues.loading;

      if (isLoading) return;
      state = getState().production;

      const { actions } = productionSlice;

      api.dispatch(actions.serverCalcProductionValuesStart());

      fetchParseProductionItems(state.productionItems).then((result) => {
        if (result.error) {
          api.dispatch(actions.serverCalcProductionValuesError(result.error));
        } else {
          api.dispatch(actions.serverCalcProductionValuesSuccess(result));
        }
      });
    }
  };
