import {
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { regionalBudgetProductionItemsSlice } from './regionalBudgetProductionItems';
import { RegionalBudgetInterface } from '../../Production/interfaces/BudgetInterfaces';

import {
  initialRegionalBudgetData,
  RegionalBudgetFrontendData,
  RegionalBudgetServerCalcValuesState
} from './RegionalBudgetReduxInterfaces';

export interface RegionalBudgetReduxState
  extends RegionalBudgetInterface,
    RegionalBudgetFrontendData {}

export const regionalBudgetSlice = createSlice({
  name: 'regionalBudget',
  initialState: initialRegionalBudgetData.initial(),

  reducers: {
    reset() {
      return initialRegionalBudgetData.initial();
    },

    initialState(state, action: PayloadAction<RegionalBudgetInterface>) {
      return {
        ...action.payload,
        ...initialRegionalBudgetData.frontendInitialData(),
        activeGroupId:
          action.payload.productionItems?.[0]?.frontendGroupId || ''
      };
    },

    setActiveGroup(state, action: PayloadAction<string>) {
      if (state.activeGroupId === action.payload) return state;

      const exists = state.productionItems.some(
        (el) => el.frontendGroupId === action.payload
      );

      if (!exists) return state;

      state.activeGroupId = action.payload;
    },

    serverCalcProductionValuesStart(state) {
      state.serverCalculatedValues.loading = true;
    },

    serverCalcProductionValuesError(state, action: PayloadAction<string>) {
      state.serverCalculatedValues.loading = false;
      state.serverCalculatedValues.error = action.payload;
    },

    serverCalcProductionValuesSuccess(
      state,
      action: PayloadAction<RegionalBudgetServerCalcValuesState['output']>
    ) {
      state.serverCalculatedValues.error = '';
      state.serverCalculatedValues.loading = false;
      state.serverCalculatedValues.output = action.payload;
      state.usedValue = action.payload.regionalUsedAmount;
      state.remainingValue =
        state.maxValue - (action.payload.regionalUsedAmount || 0);
    }
  },
  extraReducers: (builder) => {
    //
    // seta um novo item ativo ao remover grupo
    builder.addMatcher(isRemove, (state, { frontendGroupId }) => {
      const removedIndex = state.productionItems.findIndex(
        (el) => el.frontendGroupId === frontendGroupId
      );
      let newActiveId = '';
      if (removedIndex > -1) {
        newActiveId =
          state.productionItems[removedIndex - 1].frontendGroupId || '';
      }
      state.activeGroupId = newActiveId;
    });

    builder.addMatcher(
      // quando a action iniciar com "budgetProductionItemsSlice/" ela será lidada
      // pelo sub-reducer de budgetProductionItemsSlice
      () => true,
      (state, action) => {
        state.productionItems = regionalBudgetProductionItemsSlice.reducer(
          state.productionItems,
          action
        );
      }
    );
  }
});

// só esta dentro de uma function pra evitar dependencia circular quando const
function isRemove(arg: any) {
  return isAction(
    regionalBudgetProductionItemsSlice.actions.removeDestinationGroup
  )(arg);
}

function isAction<ActionCreator extends ActionCreatorWithPayload<any>>(
  fn: ActionCreator
) {
  return function (action: any): action is Parameters<ActionCreator>[0] {
    return fn?.type === action?.type;
  };
}
