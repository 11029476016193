import {
  SupplierDeliveryPlaceType,
  SupplierDeliveryPlaceTypes
} from '../../pages/Production/interfaces/ProductionInterfaces';
import { EnumPlaceType } from '../../autogenerated/client/types';

function specialCases(): SupplierDeliveryPlaceType[] {
  return ['agencia_varejo', 'distribuicao'];
}

function defaultOptions() {
  return SupplierDeliveryPlaceTypes.filter(
    (el) => !specialCases().includes(el)
  ).map((value) => {
    return {
      label: translateDeliveryPlaceType(value),
      value
    };
  });
}

type Option = { value: SupplierDeliveryPlaceType; label: string };

export function deliveryPlaceOptions(
  selectedChannels: (EnumPlaceType | string)[] = []
): Option[] {
  const containsVarejo = selectedChannels.includes(EnumPlaceType.Varejo);
  const containsDistribuicao = selectedChannels.includes(
    EnumPlaceType.Distribuicao
  );

  if (containsDistribuicao || containsVarejo) {
    const options: Option[] = [];

    if (containsVarejo) {
      options.push({
        label: 'Varejo',
        value: 'agencia_varejo'
      });
    }

    if (containsDistribuicao) {
      options.push({
        label: 'Distribuição',
        value: 'distribuicao'
      });
    }

    return options;
  }

  return [...defaultOptions()];
}

export function translateDeliveryPlaceType(type: SupplierDeliveryPlaceType) {
  return {
    centro_distribuicao_logistica: 'Centro de distribuição logístico',
    escritorio_regional: 'Escritório regional',
    distribuicao: 'Distribuição',
    agencia_varejo: 'Varejo',
    pdv: 'PDV'
  }[type];
}
