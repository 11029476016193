"use strict";

var _defineProperty = require("/home/jenkins/tim-merchan/packages/site-merchan/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _routes;

function getRoutes() {
  if (!_routes) {
    throw new Error("no routes defined. register routes calling registerRoutes(myRoutes);");
  }

  return _routes;
}

exports.getRoutes = getRoutes;

function addRoutePrefix(route) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var routesToIgnorePrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var withoutSlash = route.replace(/^\//, '');

  if (routesToIgnorePrefix.indexOf(route) !== -1 || routesToIgnorePrefix.indexOf(withoutSlash) !== -1) {
    return route;
  }

  if (!prefix) return route;
  return "".concat(prefix, "/").concat(withoutSlash);
}

exports.addRoutePrefix = addRoutePrefix;

function registerRoutes(routes) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var routesToIgnorePrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var parsed = {};
  Object.keys(routes).forEach(function (key) {
    var old = routes[key];
    var newEntry = {
      path: addRoutePrefix(old.path, prefix, routesToIgnorePrefix),
      mount: function mount() {
        return addRoutePrefix(old.mount.apply(old, arguments), prefix, routesToIgnorePrefix);
      }
    };
    parsed = Object.assign(Object.assign({}, parsed), _defineProperty({}, key, newEntry));
  });
  _routes = parsed;
  return parsed;
}

exports.registerRoutes = registerRoutes;