import { Header } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import * as React from 'react';
import logoMerchan from '../../assets/images/logo-merchan.png';

const dataGenericHeader = [
  {
    name: 'Regional',
    defaultValue: 'Regional',
    options: [
      {
        label: 'Regional 1',
        value: '1'
      },
      {
        label: 'Regional 2',
        value: '2'
      },
      {
        label: 'Regional 3',
        value: '3'
      }
    ]
  },
  {
    name: 'UF',
    defaultValue: 'UF',
    options: [
      {
        label: 'UF 1',
        value: '1'
      },
      {
        label: 'UF 2',
        value: '2'
      },
      {
        label: 'UF 3',
        value: '3'
      }
    ]
  },
  {
    name: 'Canais',
    defaultValue: 'Canais',
    options: [
      {
        label: 'Canal 1',
        value: '1'
      },
      {
        label: 'Canal 2',
        value: '2'
      },
      {
        label: 'Canal 3',
        value: '3'
      }
    ]
  },
  {
    name: 'Cargos',
    defaultValue: 'Cargos',
    options: [
      {
        label: 'Cargo 1',
        value: '1'
      },
      {
        label: 'Cargo 2',
        value: '2'
      },
      {
        label: 'Cargo 3',
        value: '3'
      }
    ]
  }
];

export const MainHeader = () => null;
