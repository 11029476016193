import { EnumPlaceType } from '../../../autogenerated/client/types';

import {
  DestinationPlaceChannel,
  ProductionDestinationFilter,
  ProductionQuantityInputMode,
  SupplierDeliveryPlaceType
} from '../interfaces/ProductionInterfaces';

import { deliveryPlaceOptions } from '../../../components/SelectDeliveryPlaceType/deliveryPlaceTypeOptions';

// Por padrão, o modo de definir as quantidades é:
// - `byProduct` para os canais “Master”, “Revenda” ou “Loja própria”
// - `byDestination` para os canais “Distribuicao” ou “Varejo”

// - Exemplo de definição por produto: "Entregar 2 produtos para cada local na lista"
// - Exemplo de definição por local: "Entregar 3 itens para o local ABC, 2 itens para o local XYZ, etc".
export function quantityModeBySelectedChannels(
  filters: ProductionDestinationFilter
): ProductionQuantityInputMode {
  //
  const channels = (filters.type || []) as DestinationPlaceChannel[];

  const containsDistribuicaoOuVarejo = channels.some((el) => {
    return el === EnumPlaceType.Varejo || el === EnumPlaceType.Distribuicao;
  });

  return containsDistribuicaoOuVarejo ? 'byDestination' : 'byProduct';
}

export function deliveryPlaceTypeBySelectedChannels(
  filters: ProductionDestinationFilter
): SupplierDeliveryPlaceType {
  return deliveryPlaceOptions(filters.type || [])[0].value;
}

export function quantityInputModeByDeliveryPlaceType(
  type: SupplierDeliveryPlaceType
): ProductionQuantityInputMode {
  switch (type) {
    case 'agencia_varejo':
      return 'byDestination';

    case 'distribuicao':
      return 'byDestination';

    default:
      return 'byProduct';
  }
}
