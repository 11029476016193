import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from '../../../autogenerated/client/types';
import { BudgetReduxState } from './BudgetReduxInterfaces';
import moment from 'moment';

const defaultDeadline = moment(Date.now()).add(1, 'year').toDate();

const initialState = (): BudgetReduxState => {
  return {
    _id: '',
    status: 'draft',
    title: '',
    value: 0,
    selectedLayouts: [],
    layouts: [],
    regionalValues: [
      {
        region: 'TSL',
        value: 0,
        deadline: defaultDeadline
      },
      {
        region: 'TSE',
        value: 0,
        deadline: defaultDeadline
      },
      {
        region: 'TCN',
        value: 0,
        deadline: defaultDeadline
      },
      {
        region: 'TSP',
        value: 0,
        deadline: defaultDeadline
      },
      {
        region: 'TNE',
        value: 0,
        deadline: defaultDeadline
      }
    ],
    isRegionalProduction: false,
    channels: [],
    regionalBudgets: [],
    usedInProductionId: '',

    regionalBudgetsCheckout: {
      layoutAmount: 0,
      layoutCodeT: '',
      layoutTitle: '',
      layoutFinalPriceByItem: 0,
      layoutTotalPrice: 0,
      productPriceInterval: [
        {
          end: 0,
          price: 0,
          start: 0
        }
      ],
      regions: {}
    }
  };
};

export const budgetSlice = createSlice({
  name: 'budget',
  initialState: initialState(),
  reducers: {
    reset: () => initialState(),
    initialState: (_, action: PayloadAction<BudgetReduxState>) => {
      return action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      const title = action.payload || '';
      return {
        ...state,
        title
      };
    },
    setLayoutQuantity: (
      state,
      action: PayloadAction<{ codeT: string; baseQuantity: number }>
    ) => {
      const baseLayouts = state.selectedLayouts.map(
        ({ codeT, baseQuantity }) => ({
          codeT,
          baseQuantity
        })
      );
      const selectedLayouts = baseLayouts.map((selected) =>
        selected.codeT === action.payload.codeT ? action.payload : selected
      );

      return {
        ...state,
        selectedLayouts
      };
    },
    setSelectedLayouts: (state, action: PayloadAction<string[]>) => {
      const selectedLayouts = action.payload.map((codeT) => ({ codeT }));
      return {
        ...state,
        selectedLayouts
      };
    },
    removeLayout: (state, action: PayloadAction<string>) => {
      const selectedLayouts = state.selectedLayouts.filter(
        ({ codeT }) => action.payload !== codeT
      );
      return {
        ...state,
        selectedLayouts
      };
    },
    setLayouts: (state, action: PayloadAction<Layout[]>) => {
      const layouts = action.payload;
      return {
        ...state,
        layouts
      };
    },
    setProductionResponsibility: (
      state,
      action: PayloadAction<'hq' | 'regional'>
    ) => {
      const isRegionalProduction = action.payload === 'regional';
      return {
        ...state,
        isRegionalProduction
      };
    },
    setChannels: (
      state,
      action: PayloadAction<BudgetReduxState['channels']>
    ) => {
      return {
        ...state,
        channels: action.payload
      };
    },
    setTotalValue: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        value: action.payload
      };
    },
    setRegionalData: (
      state,
      action: PayloadAction<BudgetReduxState['regionalValues'][number]>
    ) => {
      const index = state.regionalValues.findIndex(
        ({ region }) => action.payload.region === region
      );

      const regionalValues = state.regionalValues
        .slice(0, index)
        .concat([action.payload])
        .concat(state.regionalValues.slice(index + 1));

      return {
        ...state,
        regionalValues
      };
    }
  }
});
