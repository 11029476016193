import React from 'react';
import { SubHeader } from '@digi-tim-19/components';
import styled from 'styled-components';

import { MainHeader } from './MainHeader';
import { MainMenu } from './MainMenu';
import { SiteFooter } from './SiteFooter';
import { siteImages } from '../../assets/images';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

interface IMainPage {
  children: any;
  hideSidebar: boolean;
}

export const MainPage = (props: IMainPage) => {
  const [menuIsOpen, toggleMenu] = React.useState(false);
  const { children, hideSidebar } = props;

  return hideSidebar ? (
    children
  ) : (
    <>
      <SubHeader logoURL={siteImages.logoTim} />
      <MainHeader />
      <StyledContainer>
        <MainMenu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
        {children}
      </StyledContainer>
      <SiteFooter />
    </>
  );
};
